import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

export const createInstitutionContactRegister = createAsyncThunk("InstitutionContactRegister/createInstitutionContactRegister",async (payload,thunk) => {
    try {
      let InstitutionContactRegister = await axios.post(`institutionContactRegister/createInstitutionContactRegister`, payload.data)
        .then(async (res) => {
          if (res.data.status) {
            message.success(res.data.message)
            await thunk.dispatch(getAllInstitutionContactRegister(payload.obj))

            return res.data;
          }
        });
      return InstitutionContactRegister;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getInstitutionContactRegisterById = createAsyncThunk("InstitutionContactRegister/getInstitutionContactRegisterById",async (payload) => {
    try {
      let InstitutionContactRegister = await axios.get(`institutionContactRegister/getInstitutionContactRegisterById/${payload.id}`).then((res) => {
          if (res) {
            return res.data;
          }
        });
      return InstitutionContactRegister;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getAllInstitutionContactRegister = createAsyncThunk("InstitutionContactRegister/getAllInstitutionContactRegister",async (payload) => {
    try {
            let InstitutionContactRegister = await axios.post(`institutionContactRegister/getAllInstitutionContactRegister`, payload).then((res) => {
          if (res.data.success) {
            return res.data;
          }
        });
      return InstitutionContactRegister;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);


export const updateInstitutionContactRegister = createAsyncThunk("InstitutionContactRegister/updateInstitutionContactRegister", async (payload, thunk) => {
    try {
      let InstitutionContactRegister = await axios.put(`institutionContactRegister/updateInstitutionContactRegister/${payload.id}`, payload.data).then(async (res) => {
        if (res.status) {
          message.success(res.data.data.message)
        await thunk.dispatch(getAllInstitutionContactRegister(payload.obj))        
        return res.data
        }
      })
      return InstitutionContactRegister
    } catch (error) {
      message.error(error.response.data.message)
  
    }
  })

  export const deleteInstitutionContactRegister = createAsyncThunk('InstitutionContactRegister/deleteInstitutionContactRegister', async (payload, thunk) => {
    try{
        let InstitutionContactRegister = await axios.delete(`institutionContactRegister/deleteInstitutionContactRegister/${payload.id}`).then(async (res) => {
            if (res.data.success) {
              message.success(res.data.data.message)
              await thunk.dispatch(getAllInstitutionContactRegister(payload.obj))   
              return res.data     
          }
          })
          return InstitutionContactRegister
    }
      catch(error){
        message.error(error.response.data.message)
         }
        })
    
  

const initialState = {
    institutionContactRegisterData:[],
    isLoading: false,
    totalDataCount:0
};

const InstitutionContactRegisterSlice = createSlice({
  name: "InstitutionContactRegisterSlice",
  initialState,
  extraReducers: {
    [createInstitutionContactRegister.pending]:(state)=>{
        state.isLoading=true
    },
    [createInstitutionContactRegister.fulfilled]:(state,actions)=>{
        state.isLoading=false
    },
    [createInstitutionContactRegister.rejected]:(state)=>{
        state.isLoading=false
    },
    [getAllInstitutionContactRegister.pending]:(state) =>{
        state.isLoading=true
    },
    [getAllInstitutionContactRegister.fulfilled]:(state,actions) =>{
      state.institutionContactRegisterData=actions.payload.data.getInstitutionContactRegisterData
      state.totalDataCount=actions.payload.data.getInstitutionContactRegisterCount.InstitutionContactRegisterCount
      state.isLoading=false
    },
    [getAllInstitutionContactRegister.rejected]:(state) =>{
        state.isLoading=false
    },
    [getInstitutionContactRegisterById.pending]:(state) =>{
      state.isLoading=true
    },
    [getInstitutionContactRegisterById.fulfilled]:(state) =>{
      state.isLoading=false
    },
    [getInstitutionContactRegisterById.rejected]:(state) =>{
      state.isLoading=false
    },
    [updateInstitutionContactRegister.pending]:(state) =>{
        state.isLoading=true
    },
    [updateInstitutionContactRegister.fulfilled]:(state,actions) =>{
        state.isLoading=false
    },
    [updateInstitutionContactRegister.rejected]:(state) =>{
        state.isLoading=false
    },
    [deleteInstitutionContactRegister.pending]:(state) =>{
        state.isLoading=true
    },
    [deleteInstitutionContactRegister.fulfilled]:(state,actions) =>{
        state.isLoading=false
    },
    [deleteInstitutionContactRegister.rejected]:(state) =>{
        state.isLoading=false
    },
    
  },
});

export default InstitutionContactRegisterSlice.reducer;
