import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

export const addtestimonials = createAsyncThunk("testimonials/addtestimonials",async (payload,thunk) => {
    try {
      let testimonials = await axios.post(`testimonials/addtestimonials`, payload.data)
        .then(async (res) => {
          if (res.data.status) {
            message.success(res.data.message)
            await thunk.dispatch(getAllTestimonials(payload.obj))

            return res.data;
          }
        });
      return testimonials;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getTestimonialById = createAsyncThunk("testimonials/getTestimonialById",async (payload) => {
    try {
      let testimonials = await axios.get(`testimonials/getTestimonialById/${payload}`).then((res) => {
          if (res) {
            return res;
          }
        });
      return testimonials;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getAllTestimonials = createAsyncThunk("testimonials/getAllTestimonials",async (payload) => {
    try {
            let testimonials = await axios.post(`testimonials/getTestimonials`, payload).then((res) => {
          if (res.data.success) {
            return res.data;
          }
        });
      return testimonials;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const updateTestimonials = createAsyncThunk("testimonials/updateTestimonials", async (payload, thunk) => {
    try {
      let testimonials = await axios.put(`testimonials/updateTestimonials/${payload.id}`, payload.data).then(async (res) => {
        if (res.status) {
          message.success(res.data.message)
        await thunk.dispatch(getAllTestimonials(payload.obj))        
        return res.data
        }
      })
      return testimonials
    } catch (error) {
      message.error(error.response.data.message)
  
    }
  })


  export const deleteTestimonials = createAsyncThunk('testimonials/deleteTestimonials', async (payload, thunk) => {
    try{
        let testimonials = await axios.delete(`testimonials/testimonialsDetails/${payload.id}`).then(async (res) => {
            if (res.data.success) {
              message.success(res.data.data.message)
              await thunk.dispatch(getAllTestimonials(payload.obj))   
              return res.data     
          }
          })
          return testimonials
    }
      catch(error){
        message.error(error.response.data.message)
         }
        })



const initialState = {
    testimonialsData:[],
    isLoading: false,
    totalDataCount:0
};

const TestimonialsSlice = createSlice({
    name: "TestimonialsSlice",
    initialState,
    extraReducers: {
      [addtestimonials.pending]:(state)=>{
          state.isLoading=true
      },
      [addtestimonials.fulfilled]:(state,actions)=>{
          state.isLoading=false
      },
      [addtestimonials.rejected]:(state)=>{
          state.isLoading=false
      },
      [getAllTestimonials.pending]:(state)=>{
        state.isLoading=true
    },
    [getAllTestimonials.fulfilled]:(state,actions)=>{
        state.testimonialsData = actions.payload.data.gettestiData
        state.totalDataCount = actions.payload.data.gettestiCount.testiCount
        state.isLoading=false
    },
    [getAllTestimonials.rejected]:(state)=>{
        state.isLoading=false
    },
    [getTestimonialById.pending]:(state)=>{
        state.isLoading=true
    },
    [getTestimonialById.fulfilled]:(state,actions)=>{
        state.isLoading=false
    },
    [getTestimonialById.rejected]:(state)=>{
        state.isLoading=false
    },
    [updateTestimonials.pending]:(state)=>{
        state.isLoading=true
    },
    [updateTestimonials.fulfilled]:(state,actions)=>{
        state.isLoading=false
    },
    [updateTestimonials.rejected]:(state)=>{
        state.isLoading=false
    },
    [deleteTestimonials.pending]:(state)=>{
        state.isLoading=true
    },
    [deleteTestimonials.fulfilled]:(state,actions)=>{
        state.isLoading=false
    },
    [deleteTestimonials.rejected]:(state)=>{
        state.isLoading=false
    },
}
})


export default TestimonialsSlice.reducer