import { React, forwardRef, useImperativeHandle, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Select, Button, Modal, Image } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import 'react-quill/dist/quill.snow.css';
import '../components/styles/CourseOverView.scss'
import { useState } from 'react';
import courseImage from "../asset/image/Picture.svg";
import { insertCourseMaster, updateCourseMaster, getClusterType, getVideoType, getLanguageType } from "./reducers/clusterCourseSlice";
import { getAllEducatorsByClusterId } from './reducers/CurriculumSlice';
import { getClusterName } from "./reducers/ClusterGroupMasterSlice";
import { getCourseDetailsById } from './reducers/clusterCourseSlice';
import { handleToast } from "../utils/HelperFunction.js";
import { formValidationSubjects } from "../utils/HelperFunction.js";
import { removeCourseOverviewDetails, setSaveStatus } from './reducers/clusterCourseSlice';
import Loading from './Loading';
import BeforeUnloadHandler from './Table/BeforeUnloadHandler';

const { TextArea } = Input;

const CourseOverview = forwardRef(({ setTempCourseMappingId, tempCourseMappingId, handleSaveAndNextChanges }, ref) => {

    const { courseMappingId, courseId } = useParams();
    const [isEditAction, setEditAction] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageUrl, setIsImageUrl] = useState(false);
    const [file, setFile] = useState(null);
    const { isLoading, isTestMasterLoading, getCourseDataCount, getClusterTypeData, getVideoTypeData, getLanguageTypeData, courseOverviewData } = useSelector(
        (state) => state.ClusterCourseSlice
    );

    const { getClusterNameData } = useSelector(
        (state) => state.ClusterGroupMasterSlice
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentId, setCurrentId] = useState(null);
    const [submittedData, setSubmittedData] = useState(null);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
    const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
    const [isFormModified, setIsFormModified] = useState(false);

    useEffect(() => {
        setEditAction(!!courseMappingId);
    }, [courseMappingId]);

    const patchFormikValues = useCallback((values) => {
        if (!values) return;

        let { videoTypeId, languageTypeId, thumbnailUrl, thumbnailKey, clusterGroupId, clusterName, ...rest } = values;
  
        if (clusterName) {
            dispatch(getClusterType(clusterName)).unwrap()
                .then(() => {
                    formik.setValues({
                        ...rest,
                        clusterType: clusterGroupId,
                        clusterName,
                        videoType: videoTypeId,
                        language: languageTypeId
                    });
                })
                .catch();
            dispatch(getAllEducatorsByClusterId({ id: clusterName }));
        };

        if (thumbnailUrl) {
            setIsImageUrl(true);
            setSelectedImage(thumbnailUrl);
        };
    }, []);

    useEffect(() => {
        dispatch(getClusterName())
        dispatch(getVideoType())
        dispatch(getLanguageType())
    }, []);

    useEffect(() => {
        if (!isEditAction) {
            dispatch(removeCourseOverviewDetails());
            dispatch(setSaveStatus({ field: 'courseOverViewSaveStatus', value: true }));
            dispatch(setSaveStatus({ field: 'curriculumSaveStatus', value: true }));
            dispatch(setSaveStatus({ field: 'pricingSaveStatus', value: true }));
            formik.resetForm({ values: initialValues });
        };
    }, [!isEditAction, dispatch]);

    useEffect(() => {
        if (courseMappingId && isEditAction) {
            setCurrentId(courseId);
            dispatch(getCourseDetailsById(courseMappingId));
        };
    }, [courseMappingId, isEditAction, dispatch]);

    useEffect(() => {
        if (courseOverviewData) {
            patchFormikValues(courseOverviewData);
        }
    }, [courseOverviewData, patchFormikValues]);

    const handleImageChange = (e) => {
        const file = e?.target?.files?.[0];
        if (file) {
            setIsImageUrl(false);
            setSelectedImage(file);
            setFile(file);
        };
    };

    const onUploadImage = () => {
        document.getElementById('fileInput').click();
    };

    const handleCourseOverview = async (values) => {

        let data = new FormData();

        const { clusterName, clusterType, courseName, about, videoType, language } = values;

        data.append("clusterId", clusterName);
        data.append("clusterGroupId", clusterType);
        data.append("name", courseName);
        data.append("videoTypeId", videoType);
        data.append("languageTypeId", language);

        if (about) {
            data.append("about", about);
        };

        if (file) {
            data.append(`file`, file);
        };

        let datas = {
            data,
        };

        return datas;
    };

    const initialValues = {
        clusterName: '',
        clusterType: '',
        courseName: '',
        about: '',
        videoType: '',
        language: ''
    };

    const validationSchema = yup.object({
        clusterName: yup.string()
            .required("Cluster Name is Required"),
        clusterType: yup.string()
            .required("Cluster Type  is Required"),
        courseName: yup.string()
            .required("Course Name is Required")
            .min(2, "Course Name Needed At Least Two characters")
            .max(255, "Course Name not more than 255 characters"),
        about: yup.string()
            .min(2, "About Needed At Least Two characters"),
        // .max(100, "About  not more than 100 characters"),
        videoType: yup.string()
            .required("Video Type is Required"),
        language: yup.string()
            .required("Language is Required")
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const datas = await handleCourseOverview(values);
            dispatch(insertCourseMaster(datas)).unwrap()
                .then((res) => {
                    handleToast(res, true);
                    if (res?.resSuccess) {
                        dispatch(getCourseDetailsById(res?.resId)).unwrap();
                        dispatch(setSaveStatus({ field: 'courseOverViewSaveStatus', value: false }));
                        setTempCourseMappingId(res?.resId);
                        handleSaveAndNextChanges();
                    };
                })
                .catch((error) => {
                });
        },
    });

    useImperativeHandle(ref, () => ({
        insertCourseOverview: () => {
            formik.handleSubmit();
        },
        updateCourseOverview: () => {
            updateData();
        }
    }));

    useEffect(() => {
        formValidationSubjects.courseOverview.next(formik.isValid);
    }, [formik.values, formik.errors, formValidationSubjects.courseOverview]);

    const onChangeCluster = (value) => {
        formik.setFieldValue("clusterName", value);
        formik.setFieldValue("clusterType", '');
        dispatch(getClusterType(value))
        dispatch(getAllEducatorsByClusterId({ id: value }));
    };

    const onTypeCluster = (value) => {
        formik.setFieldValue("clusterType", value);
    };

    const onVideoType = (value) => {
        formik.setFieldValue("videoType", value);
    };

    const onLanguageType = (value) => {
        formik.setFieldValue("language", value);
    };

    const showUpdateConfirmation = () => {
        setUpdateModalVisible(true);
    };

    const showSubmitConfirmation = (values) => {
        setSubmittedData(values);
        setSubmitModalVisible(true);
    };

    const closeUpdateModal = () => {
        setUpdateModalVisible(false);
        onCancel();
    };

    const closeSubmitModal = () => {
        setSubmitModalVisible(false);
        onCancel();
    };

    const updateData = async () => {

        const datas = await handleCourseOverview(formik?.values);
        datas['id'] = currentId ? currentId : tempCourseMappingId;

        dispatch(updateCourseMaster(datas))
            .unwrap()
            .then(async (res) => {
                handleToast(res, true);
                if (res?.resSuccess) {
                    await dispatch(getCourseDetailsById(courseMappingId ? courseMappingId : tempCourseMappingId)).unwrap();
                    handleSaveAndNextChanges();
                };
            })
            .catch((error) => {
            });
    };

    const onCancel = () => {
        formik.resetForm();
        setFile(null);
        setSelectedImage(null);
    };

    return (
        <>
              <BeforeUnloadHandler isFormModified={isFormModified} />

            <div className='course-overview-main'>
                <div className="newTest-label">
                </div>

                <div id="cluster-basic-content" className="test-basic-content">

                    <div id="cluster-course" className='basic-form'>

                        <Form>
                            <div className='form-control'>
                                <label htmlFor='clusterName'>Cluster Name
                                    <span style={{ color: 'red' }}> *</span>
                                </label>
                                <div>
                                    <Select
                                        id="clusterName"
                                        placeholder="Enter Cluster Name"
                                        name="clusterName"
                                        style={{ width: "100%" }}
                                        onChange={onChangeCluster}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.clusterName}
                                    >
                                        {getClusterNameData?.map((data, index) => (
                                            <Select.Option key={`${data.id}-${index}`} value={data.id}>{data.name}</Select.Option>
                                        ))}
                                    </Select>
                                </div>
                                {formik.touched.clusterName && formik.errors.clusterName && (
                                    <div className='error' style={{ color: 'red' }}>
                                        {formik.errors.clusterName}
                                    </div>
                                )}
                            </div>
                            <div className='form-control'>
                                <label htmlFor='clusterType'>
                                    Cluster Type<span style={{ color: 'red' }}> *</span>
                                </label>
                                <div>
                                    <Select
                                        id="clusterType"
                                        placeholder="Enter Cluster Type"
                                        name="clusterType"
                                        style={{ width: "100%" }}
                                        onChange={onTypeCluster}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.clusterType}
                                    >
                                        {getClusterTypeData?.map((data, index) => (
                                            <Select.Option key={`${data.id}-${index}`} value={data.id}>{data.name}</Select.Option>
                                        ))}
                                    </Select>
                                    {formik.touched.clusterType && formik.errors.clusterType ? <div className='error' style={{ color: 'red' }}>{formik.errors.clusterType}</div> : null}

                                </div>
                            </div>
                            <div className='form-control'>
                                <label htmlFor='courseName'>
                                    Course Name<span style={{ color: 'red' }}> *</span>
                                </label>
                                <Input maxLength={255} type='text' id='courseName' name='courseName' placeholder="Enter a Course Name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.courseName} />
                                {formik.touched.courseName && formik.errors.courseName ? <div className='error' style={{ color: 'red' }}>{formik.errors.courseName}</div> : null}
                            </div>
                            <div className='form-control'>
                                <label htmlFor='about'>
                                    About the Course <span style={{ color: 'red' }}> *</span>
                                </label>
                                <TextArea rows={4} type='text' id='about' name='about' placeholder="Enter a about the course" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.about} />
                                {formik.touched.about && formik.errors.about ? <div className='error' style={{ color: 'red' }}>{formik.errors.about}</div> : null}
                            </div>
                            <div className='form-control'>
                                <label htmlFor='videoType'>
                                    Video type<span style={{ color: 'red' }}> *</span>
                                </label>
                                <div>
                                    <Select
                                        id="videoType"
                                        name="videoType"
                                        style={{ width: "100%" }}
                                        onChange={onVideoType}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.videoType}
                                    >
                                        {getVideoTypeData?.map((data, index) => (
                                            <Select.Option key={`${data.id}-${index}`} value={data.id}>{data.name}</Select.Option>
                                        ))}
                                    </Select>
                                    {formik.touched.videoType && formik.errors.videoType ? <div className='error' style={{ color: 'red' }}>{formik.errors.videoType}</div> : null}

                                </div>
                            </div>
                            <div className='form-control'>
                                <label htmlFor='language'>
                                    Language<span style={{ color: 'red' }}> *</span>
                                </label>
                                <div>
                                    <Select
                                        id="language"
                                        name="language"
                                        style={{ width: "100%" }}
                                        onChange={onLanguageType}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.language}
                                    >
                                        {getLanguageTypeData?.map((data, index) => (
                                            <Select.Option key={`${data.id}-${index}`} value={data.id}>{data.name}</Select.Option>
                                        ))}
                                    </Select>
                                    {formik.touched.language && formik.errors.language ? <div className='error' style={{ color: 'red' }}>{formik.errors.language}</div> : null}
                                </div>
                            </div>
                        </Form>

                    </div>

                    <div className='cluster-thumbnail'>
                        <p className='Thumbnail'>Thumbnail</p>
                        <div className='thumbnail-content'>
                            <Image className="thumbnail-image" preview={false} src={selectedImage ? (!isImageUrl ? URL.createObjectURL(selectedImage) : selectedImage) : courseImage} />
                            <p>Drop your image here, or <span className='browse-image' onClick={onUploadImage}>browse</span></p>
                        </div>
                        <div className='border-undertext'>
                            <p className='border-para'>Recommended size: 330 x 200</p>
                            <p className='border-para'>Supports: PNG, JPG, JPEG, WEBP</p>
                        </div>
                        {/* Hidden file input */}
                        <input
                            id="fileInput"
                            type="file"
                            accept="image/png, image/jpeg, image/webp"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />

                        {/* <div className='footer'>
                            <div>
                                <Button className='primary-cancel-button'  onClick={() => onCancel()}>Cancel</Button>

                                {
                                    currentId ?
                                        <Button type="primary" className={(!formik.isValid || !formik.dirty) ? 'disabled-button' : 'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showUpdateConfirmation}>Update</Button>
                                        :
                                        <Button type='primary' className={(!formik.isValid || !formik.dirty) ? 'disabled-button' : 'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showSubmitConfirmation}>Save</Button>
                                }
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>

            {/* update Confirmation */}

            <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
                <div>
                    <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
                    <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
                </div>
            }>
                <p>Are you sure you want to update?</p>
            </Modal>

            {/* submit confirmation */}
            <Modal title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
                <div>
                    <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel </Button>
                    <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}>Submit</Button>
                </div>
            } >
                <p>Are you sure you want to submit?</p>
            </Modal>

            {
                isLoading &&
                <div className='loading-container'>
                    <Loading></Loading>
                </div>
            }

        </>

    );
});

export default CourseOverview;
