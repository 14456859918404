import './styles/dashboard.scss';
import {useNavigate} from 'react-router-dom'
function AdminDashboard(){

    const navigate=useNavigate()

    const routeToList=()=>{
        navigate('/list')
    }

    return(
        <div>
        <p className='dashboard-header'>Dashboard</p>
        <div className="admin-dashboard">
        <p className='admin-question-header'>Questions</p>
        <div className="admin-question-wrapper">
          <div className="admin-approvals" onClick={()=>routeToList()}> 
            <div className="admin-approvals-header">Approvals</div>
            <div className="admin-question-waiting">
              <p className='admin-question-waiting-header'>Questions Waiting for Approval</p>
              <p className='admin-question-waiting-count'>150</p>
            </div>
          </div>
          <div className="admin-created" onClick={()=>routeToList()}>
            <p className='admin-created-header'>Created</p>
            <div className="admin-created-wrapper">
              <div className="admin-created-pending">
                <p className="admin-created-pending-header">Pending</p>
                <p className='admin-created-pending-count'>150</p>
              </div>
              <div className="admin-created-rejected">
                <p className="admin-created-rejected-header">Rejected</p>
                <p className='admin-created-rejected-count'>150</p>
              </div>
            </div>
          </div>
        </div>
        <div className="admin-chapter-header">Chapters</div>
        <div className="admin-created-chapter" onClick={()=>routeToList()}>
            <p className='admin-created-chapter-header'>Created</p>
            <div className="admin-created-chapter-wrapper">
              <div className="admin-created-chapter-pending">
                <p className="admin-created-chapter-pending-header">Pending</p>
                <p className='admin-created-chapter-pending-count'>150</p>
              </div>
              <div className="admin-created-chapter-rejected">
                <p className="admin-created-chapter-rejected-header">Rejected</p>
                <p className='admin-created-chapter-rejected-count'>150</p>
              </div>
            </div>
          </div>
      </div>
        </div>
    )
}

export default AdminDashboard;
