import './App.scss';
import 'antd/dist/antd.css';
import React from "react";
import Layout from './components/Layout/Layout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Dashboard from './components/Dashboard';
import QuestionBank from './components/QuestionBank';
import TestMaster from './components/TestMaster';
import SubjectMaster from './components/SubjectMaster';
import ChapterMaster from './components/ChapterMaster';
import ExamMaster from './components/ExamMaster';
import ClusterMaster from './components/ClusterMaster';
import ClusterGroupMaster from './components/ClusterGroupMaster';
import ClusterCourse from './components/clusterCourse';
import ExamMasterSingleTest from './components/ExamMasterSingleTest';
import Login from './components/Login';
import Admin from './components/Admin';
import SubscriptionMaster from './components/SubscriptionMaster';
import TestBundle from './components/TestBundle';
import NewTestBundle from './components/NewTestBundle';
import TestBundleTest from './components/TestBundleTest';
import TestBundlePricing from './components/TestBundlePricing';
import RequiredLoginAuth from './utils/RequiredLoginAuth';
import RequiredAuth from './utils/RequiredAuth';
import InstitutionMaster from './components/InstitutionMaster';
import IndividualInstitute from './components/IndividualInstitute';
import CouponMaster from './components/CouponMaster';
import CouponStudent from './components/CouponStudent';
import RaiseADispute from './components/RaiseADispute';
import SetPassword from './components/SetPassword';
import VerifyTokenPage from './components/verifyToken';
import ForgotPassword from './components/forgotPassword';
import DataEntryDashboard from './components/DataEntryDashboard';
import SubjectAdminDashboard from './components/SubjectAdminDashboard';
import AdminDashboard from './components/AdminDashboard';
import { NoMatchRoute } from './components/NoRouteMatch';
import ForgotPasswordEmailVerify from './components/forgotPasswordEmailVerify';
import ExamGroup from './components/ExamGroup';
import ExamGroupSingleTest from './components/ExamGroupSingleTest';
import NewTestChanges from './components/NewTestChanges';
import CourseMaster from './components/CourseMaster';
import CoursePage from './components/CoursePage';
import PurchasedTest from './components/PurchasedTest';
import BatchList from './components/BatchList';
import BatchDetails from './components/BatchDetails';
import TestResultsList from './components/TestResultsList';
import TestResultsDetails from './components/TestResultsDetails';
import PublishedTest from './components/PublishedTest';
import IndividualInstituteDetails from './components/IndividualInstituteDetails';
import IndividualPurchaseList from './components/IndividualPurchaseList';
import BatchStudents from './components/BatchStudents';
import BatchTestDetails from './components/BatchTestDetails';
import InstitutionVerifyToken from './components/InstitutionVerifyToken';
import InstitutionSetPassword from './components/InstitutionSetPassword';
import InstitutionForgotPassword from './components/InstitutionForgotPassword';
import BatchTestResult from './components/BatchTestResult';
import PreviousYearExam from './components/PreviousYearExam';
import PreviousYearQuestions from './components/PreviousYearQuestions'
import Community from './components/Community';
import Stats from './components/Stats'
import InstitutionContactRegister from './components/InstitutionContactRegister'
import KeyFeatures from './components/KeyFeatures'
import CurrentAffairs from "./components/CurrentAffairs";
import Announcement from "./components/Announcement";
import Tutorial from './components/Tutorial';
import Leads from './components/leads';
import Products from './components/Products';
import Testimonials from './components/Testimonials';
import Notification  from './components/notification';
import StepperComponent from './components/CourseStepper';
import OtherVideo from './components/OtherVideo';
import EducatorMaster from './components/EducatorMaster';
import LeadGeneration from './components/LeadGeneration';
import CareerDetails from './components/CareerDetails';
import SingleTestPaperCategory from './components/SingleTestPaperCategory';
import WebsiteNotification from './components/WebsiteNotification';

const App = () => {
  return (
    <Routes>

      <Route path='login' element={<RequiredLoginAuth><Login /> </RequiredLoginAuth>} />
      <Route path='verifyingEmail/:token' element={<RequiredLoginAuth><VerifyTokenPage /> </RequiredLoginAuth>} />
      <Route path='verifyingForgotPasswordEmail/:token' element={<RequiredLoginAuth><ForgotPasswordEmailVerify /> </RequiredLoginAuth>} />
      <Route path='setPassword/:token' element={<RequiredLoginAuth><SetPassword /> </RequiredLoginAuth>} />
      <Route path='institution/verifyingEmail/:token' element={<RequiredLoginAuth><InstitutionVerifyToken /> </RequiredLoginAuth>} />
      <Route path='InstitutionsetPassword/:token' element={<RequiredLoginAuth><InstitutionSetPassword /> </RequiredLoginAuth>} />

      {/* <Route  path='verifyingEmail/:token' element={ <VerifyTokenPage /> } />
      <Route  path='setPassword/:token' element={ <SetPassword /> } /> */}
      <Route path='forgotPassword' element={<RequiredLoginAuth><ForgotPassword /> </RequiredLoginAuth>} />
      <Route path='institutionforgotPassword' element={<RequiredLoginAuth><InstitutionForgotPassword /> </RequiredLoginAuth>} />

      <Route path='/' element={<RequiredAuth> <Layout /> </RequiredAuth>} >
        {
          (localStorage.getItem('institutionAdmin') == true || localStorage.getItem('institutionAdmin') == 'true') ?
            <Route index element={<RequiredAuth> <CourseMaster /> </RequiredAuth>} />
            :
            <Route index element={<RequiredAuth> <Dashboard /> </RequiredAuth>} />
        }
        {/* <Route index element={<RequiredAuth> <Dashboard /> </RequiredAuth>} /> */}
        <Route path='Dashboard' element={<RequiredAuth><Dashboard /> </RequiredAuth>} />
        <Route path='ExamMaster/:examId/:subId/:chapterId' element={<RequiredAuth><QuestionBank /> </RequiredAuth>} />
        <Route path='Questions/:statusName' element={<RequiredAuth><QuestionBank /> </RequiredAuth>} />
        <Route path='ExamList/:statusName' element={<RequiredAuth><ExamMaster /> </RequiredAuth>} />
        <Route path='SubjectMaster/:statusName' element={<RequiredAuth><SubjectMaster /> </RequiredAuth>} />
        <Route path='ChapterMaster/:statusName' element={<RequiredAuth>< ChapterMaster /> </RequiredAuth>} />
        <Route path='TestMaster/:statusName' element={<RequiredAuth> <TestMaster /> </RequiredAuth>} />
        <Route path='TestMaster' element={<RequiredAuth> <TestMaster /> </RequiredAuth>} />
        <Route path='SingleTestExamExam/:examId' element={<RequiredAuth> <PreviousYearExam /> </RequiredAuth>} />
        <Route path='PreviousYearExam/:previousYearExamId' element={<RequiredAuth><PreviousYearQuestions /></RequiredAuth>} />
        <Route path='CouponMaster' element={<RequiredAuth> <CouponMaster /> </RequiredAuth>} />
        <Route path='CouponStudent' element={<RequiredAuth> <CouponStudent /> </RequiredAuth>} />
        <Route path='TestBundle' element={<RequiredAuth><TestBundle /> </RequiredAuth>} />
        <Route path='NewTestBundle' element={<RequiredAuth> <NewTestBundle />  </RequiredAuth>} />
        <Route path='TestBundleTest/:id' element={<RequiredAuth> <TestBundleTest /> </RequiredAuth>} />
        <Route path='EditTestBundle/:id' element={<RequiredAuth> <NewTestBundle /> </RequiredAuth>} />
        <Route path='TestBundlePricing/:id' element={<RequiredAuth> <TestBundlePricing /> </RequiredAuth>} />
        {/* <Route path='NewTest' element={ <RequiredAuth>  <NewTest /> </RequiredAuth> } />
        <Route path='EditTest/:id' element={<RequiredAuth> <NewTest /> </RequiredAuth> } />
        <Route path='EditTest/:id/:statusName' element={<RequiredAuth> <NewTest /> </RequiredAuth> } /> */}


        <Route path='NewTest' element={<RequiredAuth>  <NewTestChanges /> </RequiredAuth>} />
        <Route path='EditTest/:id' element={<RequiredAuth> <NewTestChanges /> </RequiredAuth>} />
        <Route path='EditTest/draft/:draftId' element={<RequiredAuth> <NewTestChanges /> </RequiredAuth>} />
        <Route path='EditTest/:id/:statusName' element={<RequiredAuth> <NewTestChanges /> </RequiredAuth>} />


        <Route path='ExamMaster/:examId' element={<RequiredAuth> <SubjectMaster /> </RequiredAuth>} />
        <Route path='ExamMaster/:examId/:subId' element={<RequiredAuth> <ChapterMaster /> </RequiredAuth>} />
        <Route path='ExamMaster' element={<RequiredAuth> <ExamMaster /> </RequiredAuth>} />
        <Route path='ClusterMaster' element={<RequiredAuth> <ClusterMaster /> </RequiredAuth>} />
        <Route path='ClusterCourse' element={<RequiredAuth> <ClusterCourse /> </RequiredAuth>} />
        <Route path='ClusterGroupMaster' element={<RequiredAuth> <ClusterGroupMaster /> </RequiredAuth>} />
        <Route path='CourseMaster' element={<RequiredAuth> <CourseMaster /> </RequiredAuth>} />
        <Route path='StepperComponent' element={<RequiredAuth> <StepperComponent /> </RequiredAuth>} />
        <Route path='StepperComponent/:courseMappingId/:courseId' element={<RequiredAuth> <StepperComponent /> </RequiredAuth>} />
        <Route path='OtherVideo' element={<RequiredAuth> <OtherVideo /> </RequiredAuth>} />
        <Route path='EducatorMaster' element={<RequiredAuth> <EducatorMaster /> </RequiredAuth>} />
        <Route path='SingleTestPaperCategory' element={<RequiredAuth> <SingleTestPaperCategory /> </RequiredAuth>} />


        <Route path='ExamMasterSingleTest/:examGroupId' element={<RequiredAuth> <ExamMasterSingleTest /> </RequiredAuth>} />
        {/* <Route path='ExamMasterSingleTest' element={<RequiredAuth> <ExamMasterSingleTest /> </RequiredAuth>} /> */}
        <Route path='ExamGroup' element={<RequiredAuth> <ExamGroup /> </RequiredAuth>} />
        <Route path='ExamGroupSingleTest' element={<RequiredAuth> <ExamGroupSingleTest /> </RequiredAuth>} />
        <Route path='RaiseADispute' element={<RequiredAuth> <RaiseADispute /> </RequiredAuth>} />
        <Route path='InstitutionMaster' element={<RequiredAuth><InstitutionMaster /> </RequiredAuth>} />
        <Route path='IndividualInstitute/:id' element={<RequiredAuth><IndividualInstitute /> </RequiredAuth>} >
          <Route path='getIndividualInstituteDetails' element={<RequiredAuth><IndividualInstituteDetails /> </RequiredAuth>} />
          <Route path='getIndividualInstitutePurchaseList' element={<RequiredAuth><IndividualPurchaseList /> </RequiredAuth>} />
        </Route>
        <Route path='course' element={<RequiredAuth><CourseMaster /> </RequiredAuth>} />
        <Route path='course/:courseId' element={<RequiredAuth> <CoursePage /> </RequiredAuth>}>
          <Route path='batch' element={<RequiredAuth><BatchDetails /> </RequiredAuth>} />
          <Route path='getAllTestResults' element={<RequiredAuth><TestResultsList /> </RequiredAuth>} />
          <Route path='getAllStudentsResults' element={<RequiredAuth><TestResultsDetails /> </RequiredAuth>} />
        </Route>
        <Route path='course/:courseId/batch/:batchId' element={<RequiredAuth><BatchList /> </RequiredAuth>}>
          <Route path='student' element={<RequiredAuth><BatchStudents /> </RequiredAuth>} />
          {/* <Route path='overallrank' element={<RequiredAuth><StudentRankResult /> </RequiredAuth>} /> */}
          <Route path='batchtestdetails' element={<RequiredAuth><BatchTestDetails /> </RequiredAuth>} />
        </Route>

        <Route path='PurchasedTest' element={<RequiredAuth><PurchasedTest /> </RequiredAuth>} />
        <Route path='PublishedTest' element={<RequiredAuth><PublishedTest /> </RequiredAuth>} />

        <Route path='Admin' element={<RequiredAuth> <Admin /> </RequiredAuth>} />
        <Route path='SubscriptionMaster' element={<RequiredAuth> <SubscriptionMaster /> </RequiredAuth>} />
        {/* <Route path='Settings' element={ <RequiredAuth> <Settings /> </RequiredAuth> } /> */}
        <Route path='AdminDashboard' element={<RequiredAuth> <AdminDashboard /> </RequiredAuth>} />
        <Route path='DataEntryDashboard' element={<RequiredAuth> <DataEntryDashboard /> </RequiredAuth>} />
        <Route path='SubjectAdminDashBoard' element={<RequiredAuth> <SubjectAdminDashboard /> </RequiredAuth>} />
        <Route path='pageNotFound' element={<RequiredAuth> <SubjectAdminDashboard /> </RequiredAuth>} />
        <Route path='batchTestResult/:courseId/:batchId/test/:testId/assign/:assignId' element={<RequiredAuth><BatchTestResult /> </RequiredAuth>} />


        {/* route for a websites */}
        <Route path='CurrentAffairs' element={<RequiredAuth><CurrentAffairs /></RequiredAuth>}></Route>
        <Route path='Announcement' element={<RequiredAuth><Announcement /></RequiredAuth>}></Route>
        <Route path='Tutorial' element={<RequiredAuth><Tutorial /></RequiredAuth>}></Route>
        <Route path='Community' element={<RequiredAuth><Community /></RequiredAuth>} />
        <Route path='Stats' element={<RequiredAuth><Stats /></RequiredAuth>} />
        <Route path='InstitutionContactRegister' element={<RequiredAuth><InstitutionContactRegister /></RequiredAuth>} />
        <Route path='KeyFeatures' element={<RequiredAuth><KeyFeatures /></RequiredAuth>} />
        <Route path='leads' element={<RequiredAuth><Leads /></RequiredAuth>} />
        <Route path='product' element={<RequiredAuth>< Products /></RequiredAuth>} />
        <Route path='testimonials' element={<RequiredAuth><Testimonials /></RequiredAuth>} />
        <Route path='leadGeneration' element={<RequiredAuth><LeadGeneration /></RequiredAuth>} />
        <Route path='careerDetails' element={<RequiredAuth><CareerDetails /></RequiredAuth>} />
        <Route path='WebsiteNotification' element={<RequiredAuth><WebsiteNotification /></RequiredAuth>} />



        <Route path='/Notification' element={<RequiredAuth><Notification /></RequiredAuth>}></Route>
      </Route>
      <Route path='*' element={<NoMatchRoute />} />

    </Routes>
  )
};
export default App;
