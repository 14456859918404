import React, { useState, useEffect } from 'react';
import { Image, Button, Drawer, Input, message, Select, Modal, Spin, Tabs } from 'antd';
import importImage from '../asset/image/Import.svg';
import { DownOutlined } from '@ant-design/icons';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import TableComponent from './Table/tableComponent';
import './styles/chapterMaster.scss';
import { Formik, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getChapterMasterTable, insertChapterMasterTable, editChapterMasterTable, deleteChapterMasterTable, approveChapter, bulkChapterApprove, downloadChapterQuestionsCount } from './reducers/chapterSlice';
import { getSubjectMasterTable } from './reducers/subjectSlice';
import DeleteImage from '../asset/image/deleteIcon.svg'
import { useDebouncedCallback } from 'use-debounce';
import { getChapterBySubject } from './reducers/masterSlice';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Loading from './Loading';
import moment from 'moment';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BreadCrumb from './BreadCrumb';
import { getAllTypeData } from './reducers/dashboardSlice';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const { Option } = Select;
function ChapterMaster() {
  const antIcon = (<LoadingOutlined style={{ fontSize: 24, }} spin />);
  let tableHeight = 320
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const params = useParams()
  const [filterData, setFilterData] = useState("");
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [disabledButton, setDisabled] = useState(false);
  const [chapterDetails, setChapterDetails] = useState('');
  const [editId, setEditId] = useState();
  const [record, setRecord] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const [currentId, setCurrentId] = useState(null)
  const [currentTitle, setCurrentTitle] = useState("");
  const [buttonState, setButtonState] = useState(1);
  const [approveId, setApprovedId] = useState(null);
  const [status, setStatus] = useState();
  const [isApproval, setIsApproval] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)
  const [remarksValue, setRemarksValue] = useState(null)

  const roleId = localStorage.getItem("roleId")

  const [currentFindObj, setCurrentFindObj] = useState(() => {
    if (roleId == 1 || roleId == 3) {
      return { id: 5, statusId: 2, name: 'approved', displayName: 'Approved' }
    } else if (roleId == 4) {
      return { id: 2, statusId: 1, name: 'pending', displayName: 'Pending' }
    }
  })


  const { TextArea } = Input;

  const [superAdminRoleArray, setSuperAdminRoleArray] = useState([
    { id: 1, statusId: 1, name: 'pendingForApproval', displayName: "Pending For Approval" },
    { id: 3, statusId: 3, name: 'rejectedForApproval', displayName: "Rejected For Approval" },
    { id: 5, statusId: 2, name: 'approved', displayName: "Approved" }
  ]);


  const [roleArray, setRoleArray] = useState([
    { id: 1, statusId: 1, name: 'pending', displayName: 'Pending' },
    { id: 2, statusId: 1, name: 'pendingForApproval', displayName: 'Pending For Approval' },
    { id: 3, statusId: 3, name: 'rejected', displayName: 'Rejected' },
    { id: 4, statusId: 3, name: 'rejectedForApproval', displayName: 'Rejected For Approval' },
    { id: 5, statusId: 2, name: 'approved', displayName: 'Approved' }
  ]);
  const [dataRoleArray, setDataRoleArray] = useState([
    { id: 2, statusId: 1, name: 'pending', displayName: 'Pending' },
    { id: 3, statusId: 3, name: 'rejected', displayName: 'Rejected' },
    { id: 4, statusId: 2, name: 'approved', displayName: 'Approved' }
  ]);


  const statusChanged = (e) => {
    let findObj
    if (roleId == 4) {
      findObj = dataRoleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    } else if (roleId == 1) {
      findObj = roleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    } else if (roleId == 3) {
      findObj = superAdminRoleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    }
    else {
    }
    setStatus(e)
    let url = {
      examId: +params.examId,
      subId: +params.subId,
    }
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
      status: findObj
    }
    let data = {
      url, obj
    }

    dispatch(getChapterBySubject(data))
  }



  const { chapterMasterTableData, isChapterLoading, chapterDataCount, breadCrumbData } = useSelector((state) => state.ChapterMaster)
  const { subjectByChapter, isLoading, chapterBreadCrumbData } = useSelector((state) => state.MasterSlice)
  const { listData, isDashLoading } = useSelector((state) => state.DashboardSlice)


  useEffect(() => {

    if (!params?.statusName) {
      setStatus(currentFindObj?.id)
      let url = {
        examId: +params.examId,
        subId: +params.subId,
      }
      let obj = {
        pagination: -1,
        searchValue: filterData,
        limit: limit,
        status: currentFindObj

      }
      let data = {
        url, obj
      }
      dispatch(getChapterBySubject(data))
    }
    else {
      let payload = {
        filterType: params?.statusName,
        type: 'chapter'
      }
      dispatch(getAllTypeData(payload))
    }


  }, [])

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Chapter Name',
      dataIndex: 'name',
      key: 'name',
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            if (!params?.statusName) {
              navigate(`/ExamMaster/${params.examId}/${params.subId}/${record.id}`)

            }
            else {
              editData(record)
            }
          }
        };
      }
    },
    {
      title: 'Subject',
      dataIndex: 'subjectName',
      key: 'subjectName',
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            if (!params?.statusName) {
              navigate(`/ExamMaster/${params.examId}/${params.subId}/${record.id}`)

            }
            else {
              editData(record)
            }

          }
        };
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => {
        return (
          <div>
            <span>{moment(record?.updatedAt).format('DD MMM YYYY')}</span>
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            if (!params?.statusName) {
              navigate(`/ExamMaster/${params.examId}/${params.subId}/${record.id}`)

            }
            else {
              editData(record)
            }


          }
        };
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            {
              roleId == 1 || roleId == 3 || roleId == 4
                ?
                <div className='table-action-link'>
                  <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
                  {
                    roleId == 3 && !params?.statusName ?
                      <Tooltip title="Delete" style={{ padding: 0 }}>
                        <IconButton>
                          <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                        </IconButton>
                      </Tooltip>
                      : null
                  }

                </div>

                : <div>
                  Not Authorized
                </div>
            }

          </>

        )
      },
    },
  ]
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);


  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let url = {
      examId: +params?.examId,
      subId: +params?.subId,
      statusName: params?.statusName,
    }
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
      status: currentFindObj
    }
    let data = {
      url, obj
    }
    if (!params?.statusName) {
      dispatch(getChapterBySubject(data))
    }

  }


  const editData = (record) => {
    if (record?.isApproval) {
      setIsApproval(true)
    } else {
      setIsApproval(false)
    }
    if (record?.remarks != null) {
      setRemarksValue(record?.remarks)
    } else {
      setRemarksValue(null)
    }
    setCurrentId(record.id)
    setButtonState(2)
    if (!params?.statusName) {

      let currentUser = subjectByChapter['getChapterData'].filter((e) => e.id == record.id)
    }
    formik.values.name = record.name
    setVisible(true)
    setCurrentTitle("Edit Chapter")
  }

  const deleteData = (record) => {
    setRecord(record)
    setIsModalVisible(true)
    setButtonState(1)
  }
  function confirmDelete() {
    setIsModalVisible(false)
    let id = record.id

    let payload = {
      id: record.id,
      examId: +params?.examId,
      subjectId: +params?.subId,
      statusName: params?.statusName
    }
    let data = {
      payload,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
        status: currentFindObj

      }
    }
    dispatch(deleteChapterMasterTable(data))
  }
  function onModalClose() {
    setIsModalVisible(false);
    setIsRejectModalVisible(false)
    remarkFormik.resetForm()
  }
  const onSearch = (value) => {

    setFilterData(value)

    let url = {
      examId: +params?.examId,
      subId: +params?.subId,
      statusName: params?.statusName,
    }
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
      status: currentFindObj
    }
    let data = {
      url, obj
    }
    debounced(data)
  }
  const debounced = useDebouncedCallback((obj) => {
    if (!params?.statusName) {
      dispatch(getChapterBySubject(obj))
    }
  }, 400)

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  function onClose() {
    setVisible(false);
    setEditVisible(false);
    setDisabled(false);
    formik.resetForm();
  }

  const reject = () => {
    setIsRejectModalVisible(true)
  }

  const initialValues = { name: '' }
  const validationSchema = yup.object({
    name: yup.string()
      .required(" Chapter Name is Required")
      .min(2, " Chapter Name Needed At Least Two characters")
      .max(100, " Chapter Name not more than 100 characters"),
    // .matches(/^\s*[A-Za-z0-9]+\s*( \s*[A-Za-z0-9]+\s*)*$/, "Name should only contain letters, numbers, and spaces with leading/trailing spaces"),
  })



  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };


  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setVisible(false)
      let payload = {
        name: values.name,
        examId: +params.examId,
        subjectId: +params.subId,
      }
      let data = {
        payload,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
          status: currentFindObj

        }
      }
      setSubmitModalVisible(false);
      setVisible(false)
      dispatch(insertChapterMasterTable(data))

    },
    validationSchema,
  })
  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };

  const remarkInitialValues = { remarks: '' }
  const remarkValidationSchema = yup.object({
    remarks: yup.string().required("Remarks is required")
  })

  const remarkFormik = useFormik({
    initialValues: remarkInitialValues,
    validationSchema: remarkValidationSchema
  })

  const addChapter = () => {
    setIsApproval(false)

    setCurrentId(null)
    setVisible(true)
    setCurrentTitle("New Chapter")
    setButtonState(1)
    formik.resetForm({ values: initialValues })
  }


  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };
  const updateData = () => {
    let payload = {}
    if (params?.statusName) {
      payload = {
        id: currentId,
        name: formik.values.name,
        statusName: params?.statusName,
      }
    }
    else {
      payload = {
        id: currentId,
        name: formik.values.name,
        examId: +params?.examId,
        subjectId: +params?.subId,
        statusName: params?.statusName,
      }
    }

    let data = {
      payload: payload,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
        status: currentFindObj

      }
    }
    setUpdateModalVisible(false);
    setVisible(false)
    dispatch(editChapterMasterTable(data))

  }

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  // reject function
  const onReject = () => {
    let data = {
      chapterId: selectedRowKeys,
      statusId: 2
    }
    dispatch(bulkChapterApprove(data)).unwrap().then((res) => {
      if (res.status) {
        setSelectedRowKeys([])
        message.success(res.message)
      }
    })
  }

  // approve funcction
  const onApprove = () => {
    let data = {
      chapterId: selectedRowKeys,
      statusId: 2
    }
    dispatch(bulkChapterApprove(data)).unwrap().then((res) => {
      if (res.status) {
        setSelectedRowKeys([])
        message.success(res.message)
      }
    })
  }

  const viewQuestion = (record) => {
    setApprovedId(record.id)
    setVisible(true);
    setButtonState(3);
    setCurrentTitle("Chapter")
    formik.setFieldValue('name', record.name)
  }

  const approve = () => {
    setVisible(false)

    let url = {
      examId: +params.examId,
      subId: +params.subId,
    }
    let obj = {
      pagination: -1,
      searchValue: filterData,
      limit: limit,
      status: currentFindObj
    }
    let statusData = {
      chapterId: currentId,
      statusId: 2,
      data: {
        remarks: 'string'
      }
    }
    let data = {
      url, obj, getData: statusData
    }
    dispatch(approveChapter(data)).unwrap().then((res) => {
      message.success(res.message)
    })
  }

  const addRemarks = () => {
    setIsRejectModalVisible(false)


    let url = {
      examId: +params?.examId,
      subId: +params?.subId,
      statusName: params?.statusName
    }
    let obj = {
      pagination: -1,
      searchValue: filterData,
      limit: limit,
      status: currentFindObj
    }
    let statusData = {
      chapterId: currentId,
      data: { remarks: remarkFormik.values.remarks },
      statusId: 3
    }
    let data = {
      url, obj, getData: statusData
    }

    dispatch(approveChapter(data)).unwrap().then((res) => {
      message.success(res.message)
    })
    setVisible(false)
    remarkFormik.setValues(remarkInitialValues)
  }

  const downloadQuestionsCount = () => {
    console.log("download questions count");
    const subjectId = +params?.subId
    dispatch(downloadChapterQuestionsCount(subjectId))
      .unwrap()
      .then((res) => {
        console.log(res, "uuuuuuuuuuuuuuuuuuuuuuuu")
        const buffer = res.data.data
        const arrayBuffer = new Uint8Array(buffer).buffer;
        const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelFile], { type: 'application/octet-stream' });
        saveAs(blob, 'Chapter Questions Count.xlsx');
      });
  }


  return (
    <>
      <div className='chapter-master-page'>
        <div>
          <div className='breadCrumb-Data'>
            <BreadCrumb data={chapterBreadCrumbData} loading={isLoading}></BreadCrumb>
          </div>
          <div className='chapter-header'>
            <div className='chapter-header-wrapper' ><h3 className='primary-header'>Chapter Master</h3>

            </div>
            <div className="download-new-btn">
              <div class="countDownloadBtn">
                <Button
                  type="primary"
                  className="primary-submit-button"
                  onClick={() => downloadQuestionsCount()}
                >
                  {" "}
                  Download Questions Count
                </Button>
              </div>
              <div className='add-chapter'>
                {
                  roleId != 5 && !params.statusName ?
                    <span className='add-new-chapter'><Button type='primary' className='primary-submit-button' onClick={() => addChapter()}> New Chapter</Button></span>
                    : null
                }
              </div>
            </div>
          </div>
          <div className="table-header">
            <div className='list-dropdown'>
              {
                roleId != 5 && !params?.statusName ?
                  <div>
                    <Select id="status" name="status" defaultValue={status} placeholder="Select Status" onChange={statusChanged} value={status}  >
                      {
                        roleId == 4 ? dataRoleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                          : roleId == 1 ? roleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                            : roleId == 3 ? superAdminRoleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                              : null
                      }
                    </Select>
                  </div>
                  : null
              }
            </div>
            {!params?.statusName ?
              <div className="golbalSearch" >
                <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
              </div>
              : null
            }
          </div>

          {/* Add chapter drawer */}

          <Drawer closable={false} title={currentTitle} placement="right" onClose={onClose} open={visible} footer={(
            <div className='footer'>
              <div className='footer-button'>
                <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose()}>Cancel</Button>
                {
                  isApproval ?
                    <>
                      <Button className='reject-btn' onClick={() => reject()}>Reject</Button>
                      <Button className={!formik.isValid ? 'disabled-button' : 'approve-btn'} disabled={!formik.isValid} style={{ margin: '0px 26px' }} onClick={() => updateData()} >Approve</Button>
                    </>
                    :
                    <>
                      {
                        currentId ?
                          <Button type="primary" className={!formik.isValid ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.isValid} onClick={showUpdateConfirmation}>Update </Button>

                          // <Button className={!formik.isValid  ?'disabled-button':'primary-submit-button'} disabled={!formik.isValid} type='primary' onClick={()=>updateData()} >Update</Button>
                          :
                          <Button type='primary' className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} onClick={showSubmitConfirmation}>Submit</Button>

                        // {/* <Button className={!formik.isValid || !formik.dirty ?'disabled-button':'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} type='primary' onClick={() => formik.handleSubmit()}>Submit</Button> */}
                      }

                    </>
                }
              </div>
            </div>
          )}>
            <div className='drawer-content'>
              {
                remarksValue ?
                  <div className='form-control reject-remarks'>

                    <span > ** {remarksValue}</span>
                  </div>
                  : null

              }
              <form onKeyDown={onKeyDown}>
                <div className='form-control'>
                  <label htmlFor='name'>
                    Chapter Name :<span style={{ color: 'red' }}>*</span>
                  </label>
                  <Input maxLength={100} type='text' id='name' placeholder="Enter a Chapter Name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.name} />
                  {formik.touched.name && formik.errors.name ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.name}</div> : null}
                </div>
              </form>
            </div>
          </Drawer>

          {/* delete modal */}

          <Modal title='Delete' open={isModalVisible} onCancel={onModalClose} footer={
            <div>
              <Button onClick={() => onModalClose()}>Cancel</Button>
              <Button type='primary' onClick={() => confirmDelete()}>OK</Button>
            </div>
          }>
            <p>Are you sure you want to delete?</p>
          </Modal>

          {/* update model */}

          <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
            <div>
              <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
              <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
            </div>
          }>
            <p>Are you sure you want to update?</p>
          </Modal>



          {/* submit Confirmation */}

          <Modal
            title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
              <div>
                <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
                <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}> Submit </Button>
              </div>
            }
          >
            <p>Are you sure you want to submit?</p>
          </Modal>

          {/* reject modal */}

          <Modal title='Rejection' open={isRejectModalVisible} onCancel={onModalClose} footer={
            <div>
              <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
              <Button type='default' className='primary-submit-button' id={!remarkFormik.isValid || !remarkFormik.dirty ? 'disabled-button' : null} disabled={!remarkFormik.isValid || !remarkFormik.dirty} onClick={() => addRemarks()}>OK</Button>
            </div>
          }>
            <div>
              <label htmlFor="remarks">Remarks <span style={{ color: 'red' }}>*</span> </label>
              <TextArea id='remarks' style={{ marginTop: 10, height: 176 }} onChange={remarkFormik.handleChange} onBlur={remarkFormik.handleBlur} value={remarkFormik.values.remarks} > </TextArea>
              {remarkFormik.touched.remarks && remarkFormik.errors.remarks ? <div className='error'>{remarkFormik.errors.remarks}</div> : null}
            </div>
          </Modal>
        </div>
        <div>
          {
            !params.statusName ?
              <TableComponent dataSource={subjectByChapter?.getChapterData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={subjectByChapter?.getChapterCount?.chapterCount} currentPage={paginationData + 1} loadingStatus={isLoading} limit={limit} />
              :
              <TableComponent dataSource={listData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={listData?.length ? listData.length : 0} currentPage={paginationData + 1} loadingStatus={isDashLoading} limit={limit} />
          }
        </div>
      </div>
      {
        isChapterLoading ? <div className='loading-container'>

          <Loading></Loading>

        </div> : null
      }
    </>
  );
}
export default ChapterMaster;