import { Button, Col, Divider, Image, Input, Row, Tooltip, Select, Drawer, Tabs, Modal, message } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import TableComponent from './Table/tableComponent';
import { SearchOutlined } from '@ant-design/icons';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { IconButton } from '@material-ui/core';
import DeleteImage from '../asset/image/deleteIcon.svg'
import { useSelector, useDispatch } from 'react-redux';
// import { getCourseMasterTable } from './reducers/CourseMasterSlice'
import { getBatchMasterTable, insertStudentByBatch, getStudentByBatch, getInstitutionStudentById, updateStudentByBatch, deleteStudentByBatch, excelBulkUpload, resetPassInstitutionStudent } from './reducers/BatchMasterSlice'
import { useNavigate, useParams } from 'react-router-dom';
// import { Option } from 'antd/lib/mentions';
import file from '../asset/file/institutionStudentBulk.xlsx'
import { useDebouncedCallback } from 'use-debounce';
import closeIcon from '../asset/image/deleteIcon.svg'
import xlIcon from '../asset/image/xl-icon.svg'

const BatchStudents = () => {
  const breadCrumbData = {}
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(10)
  const roleId = localStorage.getItem("roleId")
  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [updatedStatusValue, setUpdatedStatus] = useState(false);
  const [visibleBulk, setVisibleBulk] = useState(false);
  const hiddenFile = useRef(null);
  const institutionId = localStorage.getItem("institutionId")
  const params = useParams()
  const institutionAdmin = localStorage.getItem("institutionAdmin")
  const [currentId, setCurrentId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [record, setRecord] = useState({})
  const [filterData, setFilterData] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bulkUploadFileName, setBulkUploadFileName] = useState("");
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const batchCode = localStorage.getItem('batchCode')

  const dispatch = useDispatch();
  let tableHeight = 320
  const initialValues = { name: '', email: '', mobileNumber: '', batchCode: batchCode }
  let validationSchema = yup.object({
    name: yup.string()
      .required("Name is Required")
      .min(2, "Name Needed At Least Two characters")
      .max(100, "Name not more than 100 characters"),
    batchCode: yup.string().required("Batch code is Required").trim() // Remove leading/trailing spaces
      .matches(/^[^\s@\-_][\w\s@\-_]*$/, {
        message: 'Invalid format. The input should not start with @, -, or _',
        excludeEmptyString: true,
      }),
    email: yup.string().email("Please Enter a Valid Email").required('Email is required'),
    mobileNumber: yup.string()
      .required("Mobile Number is Required")
      .matches('^[0-9 ]+$', "Invalid Mobile Number")
      .trim()
      .min(10, 'Mobile Number Must Be Exactly 10')
      .max(10, 'Mobile Number Must Be Exactly 10')
      .nullable()
  })

  const { studentDetails, isLoading, totalDataCount } = useSelector((state) => (state.BatchSlice))


  useEffect(() => {
    let obj = {
      pagination: -1,
      searchValue: '',
      limit: 50
    }
    dispatch(getStudentByBatch({ data: obj, institutionId: institutionId, courseId: +params.courseId, batchId: +params.batchId }));
  }, [])

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };


  const showSubmitConfirmation = (values) => {
    setSubmitModalVisible(true);
  };

  function onSubmit(values) {
    setVisible(false);
    let data = {
      name: values.name,
      email: values.email,
      mobileNumber: values.mobileNumber,
      courseId: +params.courseId,
      batchId: +params.batchId,
      batchCode: batchCode
    }
    setSubmitModalVisible(false)
    dispatch(insertStudentByBatch({ data: data, institutionId: +institutionId }))
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  const openDrawer = () => {
    setVisible(true)
    setUpdatedStatus(false)
    setCurrentTitle("Add Student")
    formik.setValues({ name: '', email: '', mobileNumber: '', batchCode: batchCode })
  }
  const openBulkDrawer = () => {
    setVisibleBulk(true)
    setCurrentTitle("Students bulk upload")
    formik.resetForm()
  }
  function onClose(values) {
    setVisible(false);
  }
  function onCloseBulk(values) {
    setVisibleBulk(false);
    setBulkUploadFileName("")
  }
  const editData = (data) => {
    setCurrentId(data.id)
    setUpdatedStatus(true)
    dispatch(getInstitutionStudentById(data)).unwrap().then((res) => {
      if (res) {
        let tempValues = res
        formik.setValues(tempValues)
        setVisible(true)
      }
    })
    setCurrentTitle("Edit Student")
  }

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };
  const updateData = (values) => {
    setVisible(false)
    formik.values['mobileNumber'] = +formik.values['mobileNumber']
    let datas = {
      id: currentId,
      data: {
        ...values
      },
      obj: {
        pagination: paginationData,
        searchValue: '',
        limit: limit

      }
    }
    setUpdateModalVisible(false);
    dispatch(updateStudentByBatch(datas)).unwrap().then((res) => {
    })
    formik.setValues(initialValues)
    formik.resetForm()
  }

  const deleteData = (_data) => {
    setRecord(_data)
    setCurrentId(_data.id)
    setIsModalVisible(true)
  }
  const onModalClose = () => {
    setIsModalVisible(false);
  }

  const confirmDelete = () => {
    setIsModalVisible(false)
    let data = {
      id: currentId,
      obj: {
        pagination: paginationData,
        searchValue: '',
        limit: limit
      },
      data: {
        ...record
      }
    }
    dispatch(deleteStudentByBatch(data)).unwrap().then((res) => {

    })
  }

  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit
    }
    debounced(obj)
  }

  const debounced = useDebouncedCallback((obj) => {
    dispatch(getStudentByBatch({ data: obj, institutionId: institutionId, courseId: +params.courseId, batchId: +params.batchId }));
  }, 1000)


  const bulkExcelUpload = () => {
    hiddenFile.current.click()
  }

  const removeFile = () => {
    setBulkUploadFileName("")
  }

  // bulk file upload 
  const excelChooseFile = (event) => {
    var fileFilter = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
    if (fileFilter.includes(event.target.files[0].type)) {
      var excelFile = new FormData();
      excelFile.append("excelFile", event.target.files[0])
      setBulkUploadFileName(event.target.files[0].name)
      let data = {
        excelFile: excelFile,
        institutionId: institutionId,
        courseId: params.courseId,
        batchId: params.batchId
      }
      dispatch(excelBulkUpload(data)).unwrap().then((res) => {
        setVisibleBulk(false);
        setBulkUploadFileName("")
      })
    }
    else {
      message.warn("Only excel files are allowed")
    }
  }

  const bulkUploadDrawerClose = () => {
    setVisibleBulk(false)
    setBulkUploadFileName("")
  }

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getStudentByBatch({ data: obj, institutionId: institutionId, courseId: +params.courseId, batchId: +params.batchId }));
  }

  const resetPasswordInstitutionStudent = () => {
    dispatch(resetPassInstitutionStudent(currentId)).unwrap().then((res) => {
      setVisible(false)
      setUpdateModalVisible(false);
    })
  }



  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Student Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => text && text.replace(/\b\w/g, (c) => c.toUpperCase()),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Created Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            {
              institutionAdmin ?
                <div className='table-action-link'>
                  <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
                  {
                    institutionAdmin ?
                      <Tooltip title="Delete" style={{ padding: 0 }}>
                        <IconButton>
                          <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                        </IconButton>
                      </Tooltip>
                      : null
                  }
                </div>
                : <div>
                  Not Authorized
                </div>
            }
          </>
        )
      },
    },
  ]
  return (
    <>
      <div className='batchList-sider'>
        <div className="golbalSearch" >
          <Input placeholder="Search Student" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
        </div>
        <div className='add-student-button'>
          <span><Button onClick={() => openBulkDrawer()}>Bulk Upload</Button></span>
          <span className='add-new-exam'><Button type='primary' className='primary-submit-button' onClick={() => openDrawer()}>Add Student</Button></span>
        </div>
        <Drawer closable={false} title={currentTitle} placement="right" onClose={() => setVisible(false)} open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='primary-btn' onClick={() => resetPasswordInstitutionStudent()}>Reset Password</Button>
              <Button className='primary-cancel-button' onClick={() => onClose()}>Cancel</Button>
              {
                !updatedStatusValue ?
                  <Button className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} type="primary" disabled={!formik.dirty || !formik.isValid} onClick={showSubmitConfirmation} >Add </Button>
                  :
                  <Button className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} type='primary' onClick={showUpdateConfirmation}>Update</Button>
              }
            </div>
          </div>
        )}>
          <form>
            <div>
              <div className='form-control'>
                <label>Name <span style={{ color: 'red' }}>*</span></label>
                <Input maxLength={50} placeholder='Enter a Name' id='name' name='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
                {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
              </div>
              <div className='form-control'>
                <label>Email<span style={{ color: 'red' }}>*</span></label>
                <Input disabled={updatedStatusValue} maxLength={50} placeholder='Enter a Email' type='email' id='email' name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email}></Input>
                {formik.touched.email && formik.errors.email ? <div className='error'>{formik.errors.email}</div> : null}
              </div>
              <div className='form-control'>
                <label>Mobile<span style={{ color: 'red' }}>*</span></label>
                <Input maxLength={50} placeholder='Enter a Mobile Number' type='text' id='mobileNumber' name='mobileNumber' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNumber}></Input>
                {formik.touched.mobileNumber && formik.errors.mobileNumber ? <div className='error'>{formik.errors.mobileNumber}</div> : null}
              </div>
              <div className='form-control'>
                <label>Batch Code <span style={{ color: 'red' }}>*</span></label>
                <Input disabled={true} maxLength={50} placeholder='Enter a Batch Code' id='batchCode' name='batchCode' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.batchCode}></Input>
                {formik.touched.batchCode && formik.errors.batchCode ? <div className='error'>{formik.errors.batchCode}</div> : null}
              </div>
            </div>
          </form>
        </Drawer >
        <Drawer closable={false} title={currentTitle} placement="right" onClose={() => bulkUploadDrawerClose()} open={visibleBulk} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onCloseBulk()}>Cancel</Button>
            </div>
          </div>
        )}>
          <form>
            <div>
              <div className='upload-container'>
                <div className='text-upload'>
                  <p>Upload a file to import members into your batch</p>
                </div>
                <div className='choose-button'>
                  {bulkUploadFileName.length != 0 ? <div className='institute-bulk-upload-filename'><Image src={xlIcon} preview={false} /> <span>{bulkUploadFileName}</span> <Image src={closeIcon} preview={false} onClick={() => removeFile()} /> </div> : <Button className='primary-submit-button' onClick={() => bulkExcelUpload()}>Choose File</Button>}
                  <input type="file" ref={hiddenFile} hidden={true} onChange={(event) => excelChooseFile(event)}></input>
                </div>
              </div>
            </div>
            <div className='footer-text'>
              <p>Import users with your own file, or <a href={file} download>download existing users (CSV file)</a> </p>
            </div>
          </form>
        </Drawer>
      </div >
      <div>
        <TableComponent dataSource={studentDetails} columns={columns} tableHeight={tableHeight} limit={limit} loadingStatus={isLoading} rowSelection={rowSelection} tableOnChange={tableOnChange} currentPage={paginationData + 1} totalData={totalDataCount} />
      </div>

      {/* add modal */}

      <Modal
        title="Add Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
          <div>
            <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
            <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}> Add </Button>
          </div>
        }
      >
        <p>Are you sure you want to Add?</p>
      </Modal>


      {/* update model */}
      <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
        <div>
          <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() => updateData(formik.values)} >Update</Button>
        </div>
      }>
        <p>Are you sure you want to update?</p>
      </Modal>
      <Modal title="Deletion" open={isModalVisible} onOk={() => confirmDelete()} onCancel={() => onModalClose()}>
        <p>Are you sure want to delete ?</p>
      </Modal>

    </>
  )
}

export default BatchStudents