import React from 'react'
import { Image, Modal, Spin } from 'antd';

const antIcon = (
 <div className='spinSvg'>


 <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.0742 28.2L21.3742 14.8C20.4742 13.3 18.8742 12.5 17.1742 12.6C15.4742 12.5 13.8742 13.4 12.9742 14.8L5.2742 28.2C4.0742 30.4 4.8742 33.1 7.0742 34.3C9.1742 35.5 11.8742 34.8 13.1742 32.7L17.2742 25.5L21.3742 32.7C22.6742 34.8 25.4742 35.5 27.5742 34.2C29.5742 33 30.2742 30.4 29.0742 28.2Z" fill="#07BD81"/>
<path d="M12.175 12.6C13.275 10.9 15.175 9.90002 17.175 10C19.175 9.90002 21.075 10.9 22.175 12.6L30.875 27.7C36.475 20.1 34.875 9.40002 27.275 3.80002C19.675 -1.79998 8.975 -0.199979 3.375 7.40002C-1.125 13.4 -1.125 21.7 3.375 27.7L12.175 12.6Z" fill="#2D81F7"/>
<path d="M27.7758 26C25.0758 21.4 18.2758 23.8 17.1758 25.6L21.2758 32.8C22.5758 34.9 25.3758 35.6 27.4758 34.3C29.5758 33 30.2758 30.4 29.0758 28.2L27.7758 26Z" fill="#0BAA71"/>
</svg>
 </div>
);

const Loading = () => {
   return(
    <div className='loading'>
       <Modal
       className="loading-modal"
        title="Modal 1000px width"
        centered
        open={true}
      >
    <Spin tip="loading....." indicator={antIcon} >

    </Spin>
    </Modal>
  </div>
   )
}

export default Loading