import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


//  get All Question Bank Page
export const getData = createAsyncThunk("QuestionBank/getAllData", async (payload,thunk) => {
  try {
    let data = await axios.get("/masters/getCountInDashboardByUser").then((res)=>{
        if(res.data.status){
            return res.data.data
        }
    })
    return data
    

  } catch (error) {
  }
})

//  Here Type is based on subject chapter question
export const getAllTypeData = createAsyncThunk("QuestionBank/getAllTypeData", async (payload,thunk) => {
  try {
    let data = await axios.get(`/masters/getListInDashboardByUser?filterType=${payload.filterType}&&type=${payload.type}&&questionType=${payload.questionType}`).then((res)=>{
        if(res.data.status){
            return res.data.data
        }
    })
    return data
    

  } catch (error) {
  }
})

const initialState = {
  isDashLoading: false,
  currentData: {},
  listData:[]


}

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {

  },
  extraReducers: {
    [getData.pending]:(state)=>{
        state.isDashLoading = true

    },
    [getData.fulfilled]:(state,action)=>{
        state.isDashLoading = false
        state.currentData = action.payload
    },
    [getData.rejected]:(state)=>{
        state.isDashLoading = false

    },
    [getAllTypeData.pending]:(state)=>{
      state.isDashLoading = true

  },
  [getAllTypeData.fulfilled]:(state,action)=>{
      state.isDashLoading = false
      state.listData = action.payload
  },
  [getAllTypeData.rejected]:(state)=>{
      state.isDashLoading = false

  },
  }
})

export default DashboardSlice.reducer