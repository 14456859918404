import React, { useState } from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from "moment";
import { getSubjectByExamId } from "./masterSlice";
import { getAllTypeData } from "./dashboardSlice";

export const getSubjectMasterTable = createAsyncThunk(
  'SubjectMaster/getSubjectMasterTable', async (payload, thunk) => {
    let tabledata = []
    let tableData = await axios.post('masters/getSubject', payload)
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)

// Insert Subject details

export const insertSubjectMasterTable = createAsyncThunk(
  'SubjectMaster/insertSubjectMasterTable', async (payload, thunk) => {
    let insertedData = [];
    let InsertMessage = '';
    let success = '';
    let insertData = await axios.post('masters/createSubject', payload.payload)
      .then(async (res) => {
        if (res?.data?.createSubject?.status) {
          let data = {
            examId: payload.payload.examId,
            obj: payload.obj
          }
          await thunk.dispatch(getSubjectByExamId(data))
        }
        insertedData = res.data.createSubject.data
        InsertMessage = res.data.createSubject.message
        success = res.data.createSubject.success
      })
      .catch((error) => {
        let err = error.response.data.createSubject
        message.error(err.message)
      })
    return {
      insertedData: insertedData,
      InsertMessage: InsertMessage,
      success: success
    };
  }
)


// edit subject

export const editSubjectMasterTable = createAsyncThunk(
  'SubjectMaster/editSubjectMasterTable', async (payload, thunk) => {
    let editedData = [];
    let UpdateMessage = '';
    let success = '';
    let editData = await axios.put('masters/updateSubject/' + payload.payload.id, payload.payload)
      .then(async (res) => {
        let data = {
          examId: payload.payload.examId,
          obj: payload.obj
        }
        await thunk.dispatch(getSubjectByExamId(data))
        editedData = res.data.updateSubject.data;
        UpdateMessage = res.data.updateSubject.message;
        success = res.data.updateSubject.success;
      })
      .catch((error) => {
      })
    return {
      editedData: editedData,
      UpdateMessage: UpdateMessage,
      success: success
    };
  }
)

// delete subject

export const deleteSubjectMasterTable = createAsyncThunk(
  'SubjectMaster/deleteSubjectMasterTable', async (payload, thunk) => {
    let deleted = []
    let deleteData = await axios.delete('masters/deleteSubject/' + payload.payload.id)
      .then(async (res) => {
        deleted = res.data.data
        if (res?.data?.data?.status) {
          let data = {
            examId: payload.payload.examId,

            obj: payload.obj
          }
          await thunk.dispatch(getSubjectByExamId(data))

        }
      })
      .catch((error) => {
      })
    return {
      id: payload,
      status: deleted.status,
      deleteMessage: deleted.message
    };
  }
)

// subject approve
export const approveSubject = createAsyncThunk(
  'SubjectMaster/approveSubject', async (payload, thunk) => {
    try {
      let approvedSubject = await axios.put(`masters/approvedStatusSubject/${payload.subjectId}/${payload.statusId}`, payload.data)
        .then(async (res) => {
          if (res.data.status) {
            let getAll = {}
            getAll['examId'] = payload.getData.examId
            getAll['obj'] = payload.getData.obj
            if (payload.getData?.statusName) {
              let typePayload = {
                filterType: payload.getData?.statusName,
                type: 'subject'
              }
              await thunk.dispatch(getAllTypeData(typePayload))
            } else {

              await thunk.dispatch(getSubjectByExamId(getAll))
            }
            return res.data;

          }
        })
      return approvedSubject;
    }
    catch (error) {
    }
  }
)

// bulk subject approve
// bulk exam approve
export const bulkSubjectApprove = createAsyncThunk(
  'ExamMaster/bulkSubjectApprove', async (payload) => {
    try {
      let data = {
        subjectId: payload.subjectId,
      }
      let approvedSubjectBulk = await axios.put('/masters/subjectStatusBulkUpdate/' + payload.statusId, data)
        .then((res) => {
          return res.data;
        })
      return approvedSubjectBulk;
    }
    catch (error) {
    }
  }
)


export const downloadSubjectQuestionsCount = createAsyncThunk("Masters/downloadSubjectQuestionsCount", async (payload) => {
  try {
    let getQuestionsCount = await axios.get(`/masters/downloadSubjectQuestionsCountXl/${payload}`).then((res) => {
      if (res) {
        message.success(res.data.message)
        return res.data
      } 
    })
    return getQuestionsCount
  } catch (error) {
  }
})

const initialState = {
  subjectMasterTableData: [],
  isSubjectLoading: false,
  subjectTotalData: 0,
  breadCrumbData: {}

}

const SubjectMasterSlice = createSlice({
  name: 'SubjectMaster',
  initialState,
  extraReducers: {
    [getSubjectMasterTable.pending]: (state) => {
      state.isSubjectLoading = true
    },
    [getSubjectMasterTable.fulfilled]: (state, actions) => {
      state.subjectMasterTableData = actions.payload.getSubjectData;
      state.subjectMasterTableData.map((item) => {
        item.createdAt = moment(item.createdAt).format('DD MMM YYYY')
      })
      state.subjectTotalData = +actions.payload?.getSubjectCount?.subjectCount
      state.breadCrumbData = actions.payload?.breadcrumbs

      state.isSubjectLoading = false
    },
    [getSubjectMasterTable.rejected]: (state) => {
      state.isSubjectLoading = false
    },
    [insertSubjectMasterTable.pending]: (state) => {
      state.isSubjectLoading = true
    },
    [insertSubjectMasterTable.fulfilled]: (state, actions) => {
      let tableData = actions.payload.insertedData
      let success = actions.payload.success
      let InsertMessage = actions.payload.InsertMessage
      if (success == true) {
        tableData.map((item) => {
          item.createdAt = moment(item.createdAt).format('DD MMM YYYY')
        })
        state.subjectMasterTableData = [...tableData, ...state.subjectMasterTableData,]
        message.success(InsertMessage)
      }
      state.isSubjectLoading = false
    },
    [insertSubjectMasterTable.rejected]: (state) => {
      state.isSubjectLoading = false
    },
    [editSubjectMasterTable.pending]: (state) => {
      state.isSubjectLoading = true
    },
    [editSubjectMasterTable.fulfilled]: (state, actions) => {
      let editdata = actions.payload
      // let data = editdata.editedData[0];
      message.success(editdata.UpdateMessage)
      // state.subjectMasterTableData.map((item, i) => {
      //   if (item.id == data.id) {
      //     item.name = data.name;
      //     message.success(editdata.UpdateMessage)
      //   }
      // })
      state.isSubjectLoading = false
    },
    [editSubjectMasterTable.rejected]: (state) => {
      state.isSubjectLoading = false
    },
    [deleteSubjectMasterTable.pending]: (state) => {
      state.isSubjectLoading = true
    },
    [deleteSubjectMasterTable.fulfilled]: (state, actions) => {
      let deleteData = actions.payload
      if (deleteData.status == true) {
        let examTableData = state.subjectMasterTableData
        state.subjectMasterTableData = examTableData.filter((item) => item.id != deleteData.id)
        message.success(deleteData.deleteMessage)
      }
      state.isSubjectLoading = false
    },
    [deleteSubjectMasterTable.rejected]: (state) => {
      state.isSubjectLoading = false
    },
    [approveSubject.pending]: (state) => {
      state.isSubjectLoading = true
    },
    [approveSubject.fulfilled]: (state, action) => {
      state.isSubjectLoading = false
    },
    [approveSubject.rejected]: (state) => {
      state.isSubjectLoading = false
    },
    [bulkSubjectApprove.pending]: (state) => {
      state.isSubjectLoading = true
    },
    [bulkSubjectApprove.fulfilled]: (state, action) => {
      state.isSubjectLoading = false
    },
    [bulkSubjectApprove.rejected]: (state) => {
      state.isSubjectLoading = false
    },
    [downloadSubjectQuestionsCount.pending]: (state) => {
      state.isSubjectLoading = true
    },
    [downloadSubjectQuestionsCount.fulfilled]: (state, action) => {
      state.isSubjectLoading = false
    },
    [downloadSubjectQuestionsCount.rejected]: (state,) => {
      state.isSubjectLoading = false
    }
  }
});

export default SubjectMasterSlice.reducer;