import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TestBundleQuestionImg from '../asset/image/groupimg.svg';
import { Image, Button, Modal, Input, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TableComponent from './Table/tableComponent';
import { SearchOutlined } from '@ant-design/icons';
import './styles/TestBundleQuestion.scss';
import { getTestMasterTable } from './reducers/TestMasterSlice';
import { insertTestBundleTest, getTestBundleTest,removeTestBundleTest,testBundlePricing } from './reducers/TestBundleSlice';
import BackImage from '../asset/image/leftArrow.svg';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';




function TestBundleQuestion() {

  const dispatch = useDispatch()

  const params = useParams();

  const navigate=useNavigate();

  const { testMasterTableData,isTestMasterLoading ,totalDataCount} = useSelector((state) => state.TestMaster)

  const { testBundleTestTData, isLoading,totalDataCountTestBundle} = useSelector((state) => state.TestBundle)
  const [paginationData, setPaginationData] = useState(0);
  const [paginationDataTestBundle, setPaginationDataTestBundle] = useState(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState(null);

  const [filterData, setFilterData] = useState("");
  const [filterDataTestBundle, setFilterDataTestBundle] = useState("");
  const [limitTestBundle , setLimitTestBundle] = useState(10);
  const [limit, setlimit] = useState(10);

  const [arr2,setArr2]=useState();

  let tableHeight = 320

  const antIcon = (<LoadingOutlined style={{ fontSize: 24, }} spin />);

  useEffect(() => {
    let objTestBundle = {
      pagination: paginationDataTestBundle,
      searchValue: filterDataTestBundle,
      limit: limitTestBundle,
    }
    let data = {
      id:params.id,
      obj:objTestBundle
    }
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    }
    dispatch(getTestBundleTest(data));
    dispatch(getTestMasterTable(obj));
  }, [])


  const [visible, setVisible] = useState()

  // Add new question function

  function addQuestion() {
    setVisible(true)
  }

  function onModalClose() {
    setVisible(false);
  }

  // on select change funciton

  const onSelectChange = (selectData) => {
    if (selectData.length==0){
      setSelectedRowKeys(null)
    }
    if (selectData.length!=0){
      setSelectedRowKeys(selectData)
    }
  }

  // row selection function

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  // onTable change function

  const tableOnChangeTestBundle = (pagination, filters, sort, extra) => {
    setPaginationDataTestBundle(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterDataTestBundle,
      limit :limitTestBundle
    }
    let data = {
      id : params.id,
      obj:obj
    }
    dispatch(getTestBundleTest(data))
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit:limit
    }

    dispatch(getTestMasterTable(obj))
  }


  // Select question function

  function selectedQuestion() {
    let keys=[...arr2,...selectedRowKeys]
    let array=[]
    keys.forEach((item)=>{
      if(!array.includes(item)){
        array.push(item)
      }
    })
    let data = {
      bundleId: Number(params.id),
      selectedRowKeys: array
    }
    dispatch(insertTestBundleTest(data)).unwrap().then((res)=>{
          setSelectedRowKeys(null)
    })

    setVisible(false);
    setSelectedRowKeys(null)
  }

  const onSearchTestBundle = (value) => {
    setFilterDataTestBundle(value)
    let obj = {
      pagination: paginationDataTestBundle,
      searchValue: value,
      limit:limitTestBundle
    }
    let data = {
      id : params.id,
      obj:obj
    }
    debouncedTestBundle(data)

  }
  const debouncedTestBundle = useDebouncedCallback((data) =>{
    dispatch(getTestBundleTest(data))


  },1000)

  const onSearch= (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit :limit
    }
    debounced(obj)
  }
  const debounced = useDebouncedCallback((obj) =>{
    dispatch(getTestMasterTable(obj))

  },1000)

  // remove test function

  function removeTest(){
    let removeMessage=''
    let data={
      bundleId:Number(params.id),
      selectedRowKeys:selectedRowKeys
    }
    dispatch(removeTestBundleTest(data)).unwrap().then((res)=>{
      removeMessage=res.removeMessage
        if(res.success==true){
          dispatch(getTestBundleTest(params.id)).unwrap().then((res)=>{
            message.success(removeMessage);
            setSelectedRowKeys(null)
          })
        }
    })
  }
  function addTest(){
    let arr=[]
      testBundleTestTData.map((bundle)=>{
        arr.push(bundle.testMasterId);
      })
      setArr2(arr)
    setVisible(true)

  }
  const columns = [
    {
      title: 'Test Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Languages',
      dataIndex: 'noOfTest',
    },
    {
      title: 'No of Buys',
      dataIndex: 'noOfBuys',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
    },
    {
      title: 'Time limit',
      key: 'action',
    },
  ]

  function onBackClick(){
    navigate('/TestBundle');
 }

  return (
    <div style={{ height: '100%' }}>
       <div className='new-bundle-header' style={{paddingBottom:10}}>
      <div><Image src={BackImage} preview={false} onClick={()=>onBackClick()}></Image></div>
      <div className='bundle-heading'>Create a Bundle</div>
      </div>
      { filterDataTestBundle == "" && !testBundleTestTData.length   ?
        <div className='overall-homepage'>
          <div className='home-page'>
            <div><Image height={200} width={200} preview={false} src={TestBundleQuestionImg}></Image></div>
            <Button onClick={() => addQuestion()} style={{ marginTop: '14%' }} type='primary'>Select Test</Button>
          </div>
        </div>
        : 
        <div>
          <div className='test-bundle-header'>
            <div><h3 style={{ fontWeight: 600, fontSize: 18, color: '#1B2228' }}>Select Test</h3></div>
            <div className='add-test-bundle'>
              <span className='add-new-test-bundle'><Button type='primary' style={{marginRight:10}} onClick={()=>navigate('/TestBundlePricing/'+params.id)}>Next</Button></span>
              <span className='add-new-test-bundle'><Button type='primary' style={{marginRight:10}} onClick={()=>addTest()}> Add Test</Button></span>
              <span className='add-new-test-bundle'><Button type='primary' disabled={selectedRowKeys==null ? true:false} onClick={()=>removeTest()}> Remove Test</Button></span>
            </div>
          </div>
          <div className="golbalSearch" style={{ paddingBottom: 16, paddingTop: 16, paddingLeft: 16, background: '#fff', justifyContent: 'center' }}>
            <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event)=>onSearchTestBundle(event.target.value)} value={filterDataTestBundle} />
          </div>
          <div>
            <TableComponent dataSource={testBundleTestTData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChangeTestBundle} tableHeight={tableHeight} totalData={totalDataCountTestBundle} currentPage={paginationDataTestBundle + 1} loadingStatus={isLoading}  />
          </div>
        </div>
        }
      <Modal closable={false} centered open={visible} onOk={() => selectedQuestion()} onCancel={() => onModalClose()} width={1000} height={800}>
        <div className='test-bundle-header'>
          <div><h3 style={{ fontWeight: 600, fontSize: 18, color: '#1B2228' }}>Test Bundle</h3></div>
          <div className='add-test-bundle'>
            <span className='add-new-test-bundle'><Button type='primary' onClick={() => selectedQuestion()}>Select Test</Button></span>
          </div>
        </div>
        <div className="golbalSearch" style={{ paddingBottom: 16, paddingTop: 16, paddingLeft: 16, background: '#fff', justifyContent: 'center' }}>
          <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }}  onChange={(event)=>onSearch(event.target.value)} value={filterData} />
        </div>
        {/* <div> */}
        <TableComponent dataSource={testMasterTableData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} totalData={totalDataCount} currentPage={paginationData + 1} loadingStatus={isTestMasterLoading}  />
        {/* </div> */}
      </Modal>
    </div>
  )
}

export default TestBundleQuestion;
