import {useNavigate} from 'react-router-dom'
import './styles/dashboard.scss';

function SubjectAdminDashboard(){

    const navigate=useNavigate()

    const routeToList=()=>{
        navigate('/list')
    }
    return(
        <div>
            <p className='dashboard-header'>Dashboard</p>
        <div className="subject-admin-dashboard">
        <p className='question-header'>Questions</p>
        <div className="question-cards-wrapper">
            <div className="question-cards" onClick={()=>routeToList()}>
              <div className="question-cards-header">Questions Waiting for Approval</div>
              <div className="question-waiting-count">150</div>
            </div>
            <div className="question-cards" onClick={()=>routeToList()}>
              <div className="question-cards-header">Questions Approved</div>
              <div className="question-approved-count">12</div>
            </div>
            <div className="question-cards" onClick={()=>routeToList()}>
              <div className="question-cards-header">Questions Rejected </div>
              <div className="question-rejected-count">2</div>
            </div>
        </div>
        <p className='chapter-header'>Chapter</p>
        <div className="chapter-cards-wrapper">
            <div className="chapter-cards" onClick={()=>routeToList()}>
              <div className="chapter-cards-header">Chapters Created</div>
              <div className="chapter-created-count">60</div>
            </div>
            <div className="chapter-cards" onClick={()=>routeToList()}>
              <div className="chapter-cards-header">Pending chapter Approval</div>
              <div className="chapter-pending-count">20</div>
            </div>
            <div className="chapter-cards" onClick={()=>routeToList()}>
              <div className="chapter-cards-header">Rejected chapters</div>
              <div className="chapter-rejected-count">2</div>
            </div>
        </div>


      </div>
        </div>
    )
}

export default SubjectAdminDashboard;