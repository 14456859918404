import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Button,
  Drawer,
  Input,
  Modal,
  Spin,
  Upload,
  message,
  Tabs,
} from "antd";
import importImage from "../asset/image/Import.svg";
import { Formik, useFormik } from "formik";
import {
  SearchOutlined,
  LoadingOutlined,
  ToTopOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TableComponent from "./Table/tableComponent";
import { useDispatch, useSelector } from "react-redux";
import DeleteImage from "../asset/image/deleteIcon.svg";
import {
  insertExamMasterTable,
  getExamMasterTable,
  updateExamMasterTable,
  deleteExamMasterTable,
  approveExam,
  bulkExamApprove,
  downloadExamQuestionsCount
} from "./reducers/ExamMasterSlice";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import * as yup from "yup";
import FormList from "antd/lib/form/FormList";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BreadCrumb from "./BreadCrumb";
import { addBreadCrumbData } from "./reducers/masterSlice";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function ExamMaster() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [editVisible, setEditVisible] = useState(false);
  const [examDetails, setExamDetails] = useState();
  const [editId, setEditId] = useState();
  const [record, setRecord] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const hiddenFile = useRef(null);
  const [image, setImage] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [error, setError] = useState(false);
  const [imageOpened, setImageOpened] = useState(false);
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [file, setFile] = useState([]);
  const [buttonState, setButtonState] = useState(1);
  const [approveId, setApproveId] = useState(null);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const { TextArea } = Input;
  const [isApproval, setIsApproval] = useState(false);

  const roleId = localStorage.getItem("roleId");

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    };
    dispatch(getExamMasterTable(obj));
    dispatch(addBreadCrumbData({}));
  }, []);
  const { examMasterTableData, isLoading, examDataCount } = useSelector(
    (state) => state.ExamMaster
  );
  let tableHeight = 320;

  const [visible, setVisible] = useState(false);
  const [disabledButton, setDisabled] = useState(false);

  const [chapterDetails, setChapterDetails] = useState("");

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: "Exam Name",
      dataIndex: "name",
      key: "name",
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/ExamMaster/" + record.id);
          },
        };
      },
    },
    {
      title: "Created Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (_, record) => {
        return (
          <div>
            <span>{moment(record.updatedAt).format("DD MMM YYYY")}</span>
          </div>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/ExamMaster/" + record.id);
          },
        };
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <>
            {roleId == 1 || roleId == 3 ? (
              <div className="table-action-link">
                <a
                  style={{ paddingRight: 10 }}
                  onClick={() => editData(record)}
                >
                  Edit
                </a>
                {roleId == 3 ? (
                  <Tooltip title="Delete" style={{ padding: 0 }}>
                    <IconButton>
                      <Image
                        src={DeleteImage}
                        preview={false}
                        onClick={() => deleteData(record)}
                      ></Image>
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
            ) : (
              <div>Not Authorized</div>
            )}
          </>
        );
      },
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const editData = (record) => {
    if (record?.isApproval) {
      setIsApproval(true);
    } else {
      setIsApproval(false);
    }
    setFile([{ url: record.examImage, thumbUrl: record.examImage }]);
    setCurrentId(record.id);
    setButtonState(2);
    formik.values.name = record.name;
    formik.values.examImage = [record.examImage];
    setVisible(true);
    setCurrentTitle("Edit Exam");
  };

  const viewQuestion = (record) => {
    setApproveId(record.id);
    setVisible(true);
    setButtonState(3);
    setCurrentTitle("Exam");
    setFile([{ url: record.examImage, thumbUrl: record.examImage }]);
    formik.values.name = record.name;
    formik.values.examImage = [record.examImage];
  };
  function onClose(values) {
    setVisible(false);
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
    };

    dispatch(getExamMasterTable(obj));
  };
  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getExamMasterTable(obj));
  }, 1000);

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  function onSubmit(values) {
    console.log("Into onsubmit", values);
    let data = new FormData();
    data.append("examImage", values.examImage[0]?.originFileObj);
    data.append("name", values.name);
    let datas = {
      data,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit

      }
    }
    setSubmitModalVisible(false);
    dispatch(insertExamMasterTable(datas)).unwrap().then((res) => {
      try {
        if (!res?.success) {
          setVisible(true)
          return
        } else {
          setVisible(false)
        }
      }
      catch (error) {
        setVisible(true)
      }
    })
  }

  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };

  const deleteData = (record) => {
    setRecord(record);
    setIsModalVisible(true);
    setButtonState(1);
  };
  function confirmDelete() {
    setIsModalVisible(false);
    let id = record.id;

    let payload = {};
    let data = {
      id: record.id,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };

    dispatch(deleteExamMasterTable(data));
  }
  function onModalClose() {
    setIsModalVisible(false);
    setIsRejectModalVisible(false);
    remarkFormik.resetForm();
  }
  const initialValues = { name: "", examImage: [] };
  let validationSchema = yup.object({
    name: yup.string()
      .required("Name is Required")
      .min(2, "Name Needed At Least Two characters")
      .max(100, "Name not more than 100 characters"),
    //   .matches(/^\s*[A-Za-z0-9]+\s*( \s*[A-Za-z0-9]+\s*)*$/, "Name should only contain letters, numbers, and spaces with leading/trailing spaces"),

    examImage: yup.array().min(1, "Image is Required").required("Image is Required"),
  })
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const remarkInitialValues = { remarks: "" };
  const remarkValidationSchema = yup.object({
    remarks: yup.string().required("Remarks is required"),
  });

  const remarkFormik = useFormik({
    initialValues: remarkInitialValues,
    validationSchema: remarkValidationSchema,
  });
  const openDrawer = () => {
    setIsApproval(false);

    setFile([]);
    setCurrentId(null);
    setVisible(true);
    setButtonState(1);
    setCurrentTitle("New Exam");
    formik.resetForm({ values: initialValues });
  };
  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
    let data = new FormData();
    data.append("examImage", formik.values.examImage[0]?.originFileObj);
    data.append("name", formik.values.name);
    let datas = {
      id: currentId,
      data,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };
    setUpdateModalVisible(false);
    setVisible(false);
    dispatch(updateExamMasterTable(datas))
      .unwrap()
      .then((res) => {
        try {
          if (res.status) {
            setVisible(false);
          }
        } catch (error) {
          setVisible(true);
        }
      });
  };

  const filehandleChange = (file) => {
    if (file.fileList.length) {
      if (
        file.file.type == "image/jpeg" ||
        file.file.type == "image/jpg" ||
        file.file.type == "image/png"
      ) {
        setFile(file.fileList);
        formik.values.examImage = file.fileList;
      } else {
        message.error("please upload jpeg, jpg and png formats only ");
      }
    } else {
      setFile(file.fileList);
      formik.values.examImage = file.fileList;
      formik.validateForm();
    }
  };
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const onTabsChange = (key) => { };

  // reject function
  const onReject = () => {
    let data = {
      examId: selectedRowKeys,
      statusId: 2,
    };

    dispatch(bulkExamApprove(data))
      .unwrap()
      .then((res) => {
        if (res.status) {
          setSelectedRowKeys([]);
          message.success(res.message);
        }
      });
  };
  // approve funcction
  const onApprove = () => {
    let data = {
      examId: selectedRowKeys,
      statusId: 2,
    };

    dispatch(bulkExamApprove(data))
      .unwrap()
      .then((res) => {
        if (res.status) {
          setSelectedRowKeys([]);
          message.success(res.message);
        }
      });
  };

  const reject = () => {
    setIsRejectModalVisible(true);
  };

  const approve = () => {
    setVisible(false);
    let data = {
      examId: currentId,
      statusId: 3,
    };
    dispatch(approveExam(data))
      .unwrap()
      .then((res) => {
        message.success(res.message);
      });
  };

  const addRemarks = () => {
    setIsRejectModalVisible(false);
    let data = {
      examId: currentId,
      remarks: remarkFormik.values.remarks,
      statusId: 3,
    };
    dispatch(approveExam(data))
      .unwrap()
      .then((res) => {
        message.success(res.message);
      });
    setVisible(false);
    remarkFormik.setValues(remarkInitialValues);
  };
  const breadCrumbData = {};

  const downloadQuestionsCount = () => {
    console.log("download questions count");
    dispatch(downloadExamQuestionsCount())
      .unwrap()
      .then((res) => {
        console.log(res, "uuuuuuuuuuuuuuuuuuuuuuuu")
        const buffer = res.data.data
        const arrayBuffer = new Uint8Array(buffer).buffer;
        const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelFile], { type: 'application/octet-stream' });
        saveAs(blob, 'Exam Questions Count.xlsx');
      });
  }

  return (
    <div className="exam-master-page">
      <div>
        <div className="breadCrumb-Data">
          <BreadCrumb data={breadCrumbData} loading={isLoading}></BreadCrumb>
        </div>
        <div className="exam-header">
          <div>
            <h3 className="primary-header">Exam Master</h3>
          </div>
          <div className="download-new-exam-btn">
            <div className="count-download-btn">
              <Button
                type="primary"
                className="primary-submit-button"
                onClick={() => downloadQuestionsCount()}
              >
                {" "}
                Download Questions Count
              </Button>
            </div>
            <div className="add-exam">
              {roleId == 3 && !params.statusName ? (
                <span className="add-new-exam">
                  <Button
                    type="primary"
                    className="primary-submit-button"
                    onClick={() => openDrawer()}
                  >
                    {" "}
                    New Exam
                  </Button>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="table-header">
          <div></div>
          <div className="golbalSearch">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
              style={{ width: 262, height: 32 }}
              onChange={(event) => onSearch(event.target.value)}
            />
          </div>
        </div>

        {/* Add exam drawer */}

        <Drawer
          closable={false}
          title={currentTitle}
          placement="right"
          onClose={() => onClose(formik.values)}
          open={visible}
          footer={
            <div className="footer">
              <div className="footer-button">
                <Button
                  className="primary-cancel-button"
                  style={{ marginRight: 42.5 }}
                  onClick={() => onClose(formik.values)}
                >
                  Cancel
                </Button>
                {isApproval ? (
                  <>
                    <Button className="reject-btn" onClick={() => reject()}>
                      Reject
                    </Button>
                    <Button
                      className={
                        !formik.isValid
                          ? "disabled-button"
                          : "primary-submit-button"
                      }
                      disabled={!formik.isValid}
                      style={{ margin: "0px 26px" }}
                      onClick={() => updateData()}
                    >
                      Approve
                    </Button>
                  </>
                ) : (
                  <>
                    {currentId ? (
                      // <Button type='primary' className={!formik.isValid?'disabled-button':'primary-submit-button'} disabled={!formik.isValid} onClick={() => updateData()}>Update</Button>
                      <Button
                        type="primary"
                        className={
                          !formik.isValid || !formik.dirty
                            ? "disabled-button"
                            : "primary-submit-button"
                        }
                        disabled={!formik.isValid || !formik.dirty}
                        onClick={showUpdateConfirmation}
                      >
                        Update{" "}
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className={
                          !formik.isValid || !formik.dirty
                            ? "disabled-button"
                            : "primary-submit-button"
                        }
                        disabled={!formik.dirty || !formik.isValid}
                        onClick={() => showSubmitConfirmation(formik.values)}
                      >
                        Submit
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          }
        >
          <form onKeyDown={onKeyDown}>
            <div>
              <div className="form-control">
                <label>
                  Exam Name<span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  maxLength={50}
                  id="name"
                  name="name"
                  placeholder="Enter exam name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                ></Input>
                {formik.touched.name && formik.errors.name ? (
                  <div className="error">{formik.errors.name}</div>
                ) : null}
              </div>
              <div className="exam-upload-file">
                <div className="form-control">
                  <Upload
                    className="ImageUpload"
                    listType="picture"
                    onChange={(file) => filehandleChange(file)}
                    beforeUpload={() => false}
                    fileList={file}
                    maxCount={1}
                  >
                    <Button
                      className="exam-image-upload-button"
                      name="examImage"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      icon={<UploadOutlined />}
                    >
                      Choose file
                    </Button>
                  </Upload>
                  {formik.touched.examImage && formik.errors.examImage ? (
                    <div className="error">{formik.errors.examImage}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </Drawer>
        {/* delete modal */}

        <Modal
          title="Deletion"
          open={isModalVisible}
          onCancel={onModalClose}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={() => onModalClose()}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => confirmDelete()}
              >
                OK
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to delete?</p>
        </Modal>

        {/* update confirmation */}

        <Modal
          title="Update Confirmation"
          open={isUpdateModalVisible}
          onCancel={closeUpdateModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeUpdateModal}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={updateData}
              >
                Update
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to update?</p>
        </Modal>

        {/* submit Confirmation */}

        <Modal
          title="Submit Confirmation"
          open={isSubmitModalVisible}
          onCancel={closeSubmitModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeSubmitModal}
              >
                Cancel{" "}
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => onSubmit(formik.values)}
              >
                {" "}
                Submit{" "}
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to submit?</p>
        </Modal>

        {/* reject modal */}
        <Modal
          title="Rejection"
          open={isRejectModalVisible}
          onCancel={onModalClose}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={() => onModalClose()}
              >
                Cancel
              </Button>
              <Button
                type="default"
                className="primary-submit-button"
                id={
                  !remarkFormik.isValid || !remarkFormik.dirty
                    ? "disabled-button"
                    : null
                }
                disabled={!remarkFormik.isValid || !remarkFormik.dirty}
                onClick={() => addRemarks()}
              >
                OK
              </Button>
            </div>
          }
        >
          <div>
            <label htmlFor="remarks">
              Remarks <span style={{ color: "red" }}>*</span>{" "}
            </label>
            <TextArea
              id="remarks"
              style={{ marginTop: 10, height: 176 }}
              onChange={remarkFormik.handleChange}
              onBlur={remarkFormik.handleBlur}
              value={remarkFormik.values.remarks}
            >
              {" "}
            </TextArea>
            {remarkFormik.touched.remarks && remarkFormik.errors.remarks ? (
              <div className="error">{remarkFormik.errors.remarks}</div>
            ) : null}
          </div>
        </Modal>
      </div>

      <div>
        <TableComponent
          dataSource={examMasterTableData}
          columns={columns}
          rowSelection={rowSelection}
          tableOnChange={tableOnChange}
          tableHeight={tableHeight}
          totalData={examDataCount}
          currentPage={paginationData + 1}
          loadingStatus={isLoading}
          limit={limit}
        />
      </div>
    </div>
  );
}
export default ExamMaster;
