import { Button, Checkbox, Col, Divider, Form, Image, Input, message, Modal, Radio, Row, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import leftArrow from "../asset/image/leftArrow.svg"
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Option } from 'antd/lib/mentions';
import { useNavigate, useParams, Location, useLocation } from 'react-router-dom';
import { FieldArray, Formik, useFormik, getIn, TextField } from 'formik';
import { render } from '@testing-library/react';
import { insertTestMasterTable, editTestMasterTable, getEditData, insertTestMasterPricing, getSubscription, getPricingEditData, getTestMasterTable, approveTest, getTestById, updateTestById, getTestByDraftId, updateDraftTestById, insertDraftTestMasterTable, questionAvailability, checkAvailableQuestionSelection, discardDraftTest } from './reducers/TestMasterSlice';
import { getAllDifficulty, getAllSubject, getAllQuestionType, getChapterBySubject, getAllExam, getSubjectByExamId, getAllTypeOfTest } from './reducers/masterSlice';
import { ConsoleSqlOutlined, LoadingOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import CloseIcon from '../asset/image/close.svg'
import 'react-quill/dist/quill.snow.css';
import './styles/NewTest.scss'
import Loading from './Loading';
import { useCallbackPrompt } from '../hooks/useCallbackPrompt'

const { TextArea } = Input;
const { TabPane } = Tabs;
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);
const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
    ]
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image"
];



const NewTestChanges = () => {
    const [difficultyShow, setDifficultyShow] = useState(true);
    const [isNegative, setIsNegative] = useState(false);
    const [isSubjectShow, setIsSubjectShow] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false);
    const [isPricing, setIsPricing] = useState(false);
    const [currentTitle, setCurrentTitle] = useState("New Test");
    const [paginationData, setPaginationData] = useState(0);
    const [limit, setLimit] = useState(10);
    const [isAllQuestionAvailable, setIsAllQuestionAvailable] = useState(true);
    const [id, setId] = useState()
    const [tabStatus, setTabStatus] = useState("1")
    const [questionTypeData, setQuestionTypeData] = useState([{ id: 1, name: "Normal" }, { id: 2, name: "Comprehension", }]);
    const [isSubmitModalVisible,setSubmitModalVisible]=useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);

    // page reload
    const [showDialog, setShowDialog] = useState(false)
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
    const [rejectVisible, setIsRejectVisible] = useState(false)

    const [newCreationInitialValues, setNewCreationInitialValues] = useState({
        name: '',
        instructions: '',
        timeLimit: '',
        typeOfTestId: "",
        examId: "",
        subjectId: null,
        eachQuestionMark: "",
        isNegativeMarkContain: false,
        negativeMark: 0,
        questionSelection: [],
        pricingPlan: false,
        price: null


    });
    const newCreationValidationSchema = yup.object({
        name: yup.string()
        .required("Test Name is Required").trim()
        .min(2, " Test Name Needed At Least Two characters")
        .max(100, " Test Name not more than 100 characters"),
        // .matches(/^[a-zA-Z0-9@_]+$/, 'Only @ and _ special characters are allowed'),
        timeLimit: yup
        .number()
        .required('Time Limit is Required')
        .positive('Time Limit must be a positive number')
        .integer('Time Limit must be a positive number')
        .nullable(),
        typeOfTestId: yup.string().required('Type Of Test  is Required'),
        examId: yup.string().required('Exam is Required'),
        eachQuestionMark: yup
        .number()
        .required('Each Question Mark is required')
        .positive('Each Question Mark must be a positive number')
        .integer('Each Question Mark must be a positive number')
        .nullable(),
    
        negativeMark: isNegative ?  yup
        .number()
        .required(' Negative Mark is required')
        // .positive(' Negative Mark must be a positive number')
        // .integer('  Negative Mark must be a positive number')
        .nullable():null,
        questionSelection: yup.array().min(1 ,"Minimum One Section must be select").of(
            yup.object().shape({
                subjectId: yup.string().required("Subject is Required").nullable(),
                chapterId: yup.string().required("Chapter is Required").nullable(),
                numberOfQuestions: yup.number().required("No Of Question is Required"),
                // .matches(/^[0-9\b]+$/, "Please Don't Enter Any Special Character").trim(),
                questionTypeId: yup.number().required("Question Type is Required"),

            })
        ),
        pricingPlan: yup.boolean().required("pricing Plan is required."),
        price: yup.string().when('pricingPlan', {
            is: true,
            then: yup
              .string()
              .required("Test price is required.")
              .matches(/^\d*\.?\d+$/, "Please enter a valid positive number.")
              .trim()
              .nullable(),
            otherwise: yup.string().nullable()
          }),
    })
    const params = useParams()

    const { editData, isAddTest, currentDataId, isTestMasterLoading, subscriptionList, editPricingData, isApproval, currentRemarks, newtestChanges } = useSelector((state) => state.TestMaster)
    useEffect(() => {
        setIsAvailable(false)

        if (isAddTest) {
            newCreationFormik.setValues({
                name: '',
                instructions: '',
                timeLimit: '',
                typeOfTestId: "",
                examId: "",
                subjectId: null,
                eachQuestionMark: "",
                isNegativeMarkContain: false,
                negativeMark: 0,
                questionSelection: [],
                pricingPlan: false,
                price: null
            })
        }
    }, [isAddTest])


    useEffect(() => {

        newCreationFormik.setValues(editData);
        if (editData?.examId) {
            let obj = {
                pagination: -1,
                searchValue: "",
                limit: 10,
                status: { statusId: -1 }
            }
            let data = {
                examId: editData?.examId,
                obj
            }
            dispatch(getSubjectByExamId(data))
        }
        if (editData?.subjectId) {
            let url = {
                examId: editData.examId,
                subId: editData.subjectId,
            }
            let obj = {
                pagination: -1,
                searchValue: "",
                limit: limit,
            }
            let data = {
                url, obj
            }
            dispatch(getChapterBySubject(data))
        }
        // debugger
        if (editData?.questionSelection.length) {

            for (let i = 0; i < editData?.questionSelection.length; i++) {
                let url = {
                    examId: editData.examId,
                    subId: editData.questionSelection?.[i].subjectId,
                }
                let obj = {
                    pagination: -1,
                    searchValue: "",
                    limit: limit,
                }
                let data = {
                    url, obj
                }
                dispatch(getChapterBySubject(data))

            }

        }


        let isNegative = editData?.isNegativeMarkContain == 1 ? true : false
        newCreationFormik.setFieldValue("isNegativeMarkContain", isNegative)
        setIsNegative(isNegative)
        if (editData.typeOfTestId != 1) {
            setIsSubjectShow(true)
        } else {
            setIsSubjectShow(false)

        }

        setIsAvailable(false)

    }, [editData])

    useEffect(() => {

        if (!isAddTest && !editData.hasOwnProperty('id')) {
            navigate('/TestMaster')
        }


    }, [editData, isAddTest])

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { subjectData, difficultLevelData, chapterData, examData, typeOfQuestion, subjectByChapter, subjectDataByExam, typeOfTest, objSubjectByChapter } = useSelector((state) => state.MasterSlice)
    const { questionSelection, isQuestionLoading } = useSelector((state) => state.QuestionSlice)
    const [Options, setSubOptions] = useState(false);
    const [checkedValues, setCheckedValues] = useState([])
    const [filterData, setFilterData] = useState("");


    useEffect(() => {

        let obj = {
            pagination: -1,
            searchValue: "",
            limit: 0
        }
        let masterObj = {
            pagination: -1,
            searchValue: "",
            limit: 0
        }
        dispatch(getAllExam(masterObj))
        dispatch(getAllTypeOfTest())
        if (params.hasOwnProperty('id')) {
            dispatch(getTestById(params.id)).unwrap().then((data) => {
                setNewCreationInitialValues(data)
            });
            setCurrentTitle("Edit Test")
        }
        if (params.hasOwnProperty('draftId')) {
            dispatch(getTestByDraftId(params.draftId)).unwrap().then((data) => {
                setNewCreationInitialValues(data)
            });
            setCurrentTitle("Edit Test")
        }
    }, [])

    const newCreationFormikSubmit = (e) => {
        setShowDialog(false)
        if (newCreationFormik.isValid) {
            newCreationFormik.handleSubmit(e)
        } else {

            newCreationFormik.setTouched({
                name: true,
                instructions: true,
                timeLimit: true,
                typeOfTestId: true,
                examId: true,
                subjectId: true,
                eachQuestionMark: true,
                isNegativeMarkContain: true,
                // negativeMark: true,
                // questionSelection: [],
                pricingPlan: true,
                // price: true,
                questionSelection: newCreationFormik.values.questionSelection.map((obj, index) => {
                    return Object.keys(obj).reduce((acc, key) => ({ ...acc, [`${key}`]: true }), {})
                })
            })
        }
    }

    const newCreationFormik = useFormik({
        initialValues: newCreationInitialValues,
        validationSchema: newCreationValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setShowDialog(false)
            // debugger
            if (newCreationFormik.isValid) {
                var insertedId = 0

                if (params.hasOwnProperty('draftId')) {
                    values['id'] = +params.draftId;
                    values['isTestId'] = false

                    let isTestId = values['isTestId']
                    values['isNegativeMarkContain'] = values['isNegativeMarkContain'] ? true : false
                    dispatch(updateDraftTestById(values)).unwrap().then((res) => {
                        // if(res.status){
                        //     // setTabStatus("2")
                        // }

                        insertedId = params.draftId
                        let obj = {
                            pagination: paginationData,
                            searchValue: filterData,
                            limit: limit
                        }
                        if (insertedId) {
                            dispatch(getTestByDraftId(insertedId)).unwrap().then((data) => {
                                // navigate("/EditTest/draft/" + insertedId)
                                navigate("/TestMaster")
                                // setCurrentTitle("Edit T/est")
                            })
                        }


                    });
                } else if (params.hasOwnProperty('id')) {

                    values['id'] = +params.id;
                    values['isTestId'] = true

                    // values['pricingPlan'] = values['pricingPlan']
                    values['isNegativeMarkContain'] = values['isNegativeMarkContain'] ? true : false

                    dispatch(insertDraftTestMasterTable(values)).unwrap().then(async (res) => {
                        // newCreationFormik.resetForm()
                        insertedId = res.id
                        let obj = {
                            pagination: paginationData,
                            searchValue: filterData,
                            limit: limit
                        }
                        if (res.id) {
                            dispatch(getTestByDraftId(insertedId)).unwrap().then((data) => {
                                navigate("/TestMaster")
                                // navigate("/EditTest/draft/" + insertedId)
                                // setCurrentTitle("Edit Test")
                            })
                        }

                    })
                }
                else {
                    // values['pricingPlan'] = values['pricing']
                    values['isNegativeMarkContain'] = values['isNegativeMarkContain'] ? true : false

                    // let checkAllQuestion = true;
                    // if(checkAllQuestion){
                    //     dispatch(insertTestMasterTable(values)).unwrap().then(async (res) => {
                    //         // newCreationFormik.resetForm()
                    //         insertedId = res.id
                    //         let obj = {
                    //             pagination: paginationData,
                    //             searchValue: filterData,
                    //             limit: limit
                    //         }
                    //         dispatch(getTestById(insertedId)).unwrap().then((data) => {
                    //             navigate("/EditTest/" + insertedId)
                    //             setCurrentTitle("Edit Test")

                    //         })
                    //     })
                    // }else{
                    dispatch(insertDraftTestMasterTable(values)).unwrap().then(async (res) => {
                        // newCreationFormik.resetForm()
                        insertedId = res.id
                        let obj = {
                            pagination: paginationData,
                            searchValue: filterData,
                            limit: limit
                        }
                        if (res.id) {
                            dispatch(getTestByDraftId(insertedId)).unwrap().then((data) => {
                                navigate("/TestMaster")
                                // navigate("/EditTest/draft/" + insertedId)
                                // setCurrentTitle("Edit Test")
                            })
                        }

                    })
                }


                // }
            } else {
                // console.log(message)
                message.info("Fill all required fields")


                newCreationFormik.setTouched({
                    name: true,
                    instructions: true,
                    timeLimit: true,
                    typeOfTestId: true,
                    examId: true,
                    subjectId: true,
                    eachQuestionMark: true,
                    isNegativeMarkContain: true,
                    // negativeMark: true,
                    // questionSelection: [],
                    pricingPlan: true,
                    // price: true,
                    questionSelection: newCreationFormik.values.questionSelection.map((obj, index) => {
                        return Object.keys(obj).reduce((acc, key) => ({ ...acc, [`${key}`]: true }), {})
                    })
                })


            }


        }
    })


    const closeSubmitModal = () => {
        setSubmitModalVisible(false);
      };
    
     
      const showSubmitConfirmation = (values) => {
        setSubmittedData(values);
        setSubmitModalVisible(true);
      };

    const publishTest = (values) => {
        if (newCreationFormik.isValid) {
            let insertedId = 0;
            // values['pricingPlan'] = values['pricing']
            values['isNegativeMarkContain'] = values['isNegativeMarkContain'] ? true : false
            if (!params.hasOwnProperty('id') && !params.hasOwnProperty('draftId')) {
                dispatch(insertTestMasterTable(values)).unwrap().then(async (res) => {
                    // newCreationFormik.resetForm()
                    insertedId = res.id
                    let obj = {
                        pagination: paginationData,
                        searchValue: filterData,
                        limit: limit
                    }
                    if (res.id) {
                        setSubmitModalVisible(false);
                        dispatch(getTestById(insertedId)).unwrap().then((data) => {
                            // navigate("/EditTest/" + insertedId)
                            navigate("/TestMaster")
                            setCurrentTitle("Edit Test")

                        })
                    }

                })
            } else {
                values['id'] = +params.draftId ? +params.draftId : +params.id
                values['isTestId'] = +params.id ? true : false
                let isTestId = values['isTestId']

                dispatch(insertTestMasterTable(values)).unwrap().then(async (res) => {
                    // newCreationFormik.resetForm()
                    insertedId = res.id
                    let obj = {
                        pagination: paginationData,
                        searchValue: filterData,
                        limit: limit
                    }
                    if (res.id) {
                        dispatch(getTestById(insertedId)).unwrap().then((data) => {
                            navigate("/TestMaster")
                            // navigate("/EditTest/" + insertedId)
                            setCurrentTitle("Edit Test")

                        })
                    }

                })
            }


        } else {
            newCreationFormik.setTouched({
                name: true,
                instructions: true,
                timeLimit: true,
                typeOfTestId: true,
                examId: true,
                subjectId: true,
                eachQuestionMark: true,
                isNegativeMarkContain: true,
                // negativeMark: true,
                // questionSelection: [],
                pricingPlan: true,
                // price: true,
                questionSelection: newCreationFormik.values.questionSelection.map((obj, index) => {
                    return Object.keys(obj).reduce((acc, key) => ({ ...acc, [`${key}`]: true }), {})
                })
            })
        }
    }


    const handleFormValidation = (isValid) => {
        setIsFormValid(isValid);
      };

    const remarksFormik = useFormik({
        initialValues: { remarks: "" },
        validationSchema: yup.object({
            remarks: yup.string().required("Remarks Is Required")
        }),
        onSubmit: (values) => {
        }
    })

    const addSection = () => {
        let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))
        tempValues['questionSelection'].push({ indexId: Date.now(), subjectId: newCreationFormik.values.subjectId == null ? null : newCreationFormik.values.subjectId, chapterId: '', numberOfQuestions: '', questionTypeId: '', showCount: false })
        newCreationFormik.setValues(tempValues)
    }
    const remove = (index) => {
        let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))
        tempValues['questionSelection'].splice(index, 1)
        newCreationFormik.setValues(tempValues)
    }
    function onBackClick() {
        navigate('/TestMaster');
    }

    const onSelected = (e, i, name) => {
        setIsAvailable(false)
        // if (!objSubjectByChapter.hasOwnProperty(e)) {

        let url = {
            examId: editData.examId ? editData.examId : newCreationFormik.values.examId,
            subId: e,
        }
        let obj = {
            pagination: -1,
            searchValue: "",
            limit: 10
        }
        let data = {
            url, obj
        }

        dispatch(getChapterBySubject(data))
        // }
        let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))
        tempValues['questionSelection'][i][name] = e
        tempValues['questionSelection'][i]['chapterId'] = ""
        tempValues['questionSelection'][i]['showCount'] = false;
        newCreationFormik.setValues(tempValues)
    }

    const onChangeSelect = (e, i, name) => {
        setIsAvailable(false)
        let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))
        tempValues['questionSelection'][i][name] = e;
        tempValues['questionSelection'][i]['showCount'] = false;
        newCreationFormik.setValues(tempValues)
    }

    const optionsOnChange = (i, e, name) => {
        setIsAvailable(false)
        let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))
        tempValues['questionSelection'][i][name] = +e.target.value
        tempValues['questionSelection'][i]['showCount'] = false;
        newCreationFormik.setValues(tempValues)
    }

    const onExamChange = (e) => {
        newCreationFormik.setFieldValue("examId", e)

        let obj = {
            pagination: -1,
            searchValue: "",
            limit: 10,
            status: { statusId: -1 }
        }
        let data = {
            examId: e,
            obj
        }
        dispatch(getSubjectByExamId(data))
        setIsAvailable(false)

    }

    const onTypeofTestChange = (e, data) => {
        setIsAvailable(false)

        newCreationFormik.setFieldValue("typeOfTestId", e)
        newCreationFormik.setTouched({
            typeOfTestId: false
        })

        if (e != 1) {
            setIsSubjectShow(true)
            let id = editData.subjectId
            newCreationFormik.setFieldValue("subjectId", id)
        }
        else {
            setIsSubjectShow(false)
            newCreationFormik.setFieldValue("subjectId", null)
        }
    }

    const onCheckedChange = (e) => {
        newCreationFormik.setFieldValue("isNegativeMarkContain", e.target.checked)
        setIsNegative(e.target.checked)
        if (!e.target.checked) {
            newCreationFormik.setFieldValue("negativeMark", 0)
        }
    }
    const onChangeSubject = (e) => {
        setIsAvailable(false)
        newCreationFormik.setFieldValue('subjectId', e)
        let url = {
            examId: editData.examId ? editData.examId : newCreationFormik.values.examId,
            subId: e,
        }
        let obj = {
            pagination: -1,
            searchValue: "",
            limit: 10
        }
        let data = {
            url, obj
        }
        dispatch(getChapterBySubject(data))

    }
    const count = () => {
        return newCreationFormik.values?.questionSelection?.reduce((prev, curr) => +prev + +curr.numberOfQuestions, 0)
    }
    const onTabChange = (data) => {
        setTabStatus(data)
    }
    const approved = () => {
        let data = {
            testMasterId: params.id,
            statusId: 2
        }
        dispatch(approveTest(data)).unwrap().then((res) => {
            if (params?.statusName) {
                navigate(`/TestMaster/${params?.statusName}`)

            }
            message.success(res.message)

        }).catch((error) => {
        })
    }
    const reject = () => {
        setIsRejectVisible(true)
    }


    const handleOk = () => {
        let data = {
            testMasterId: params.id,
            statusId: 3,
            data: remarksFormik.values
        }
        dispatch(approveTest(data)).unwrap().then((res) => {
            if (params?.statusName) {
                navigate(`/TestMaster/${params?.statusName}`)

            }
            setIsRejectVisible(false)
            message.success(res.message)

        }).catch((error) => {
        })
    };
    const handleCancel = () => {
        setIsRejectVisible(false)

    };
    const checkAvailability = (index, subjectId, chapterId) => {
        let payload = {
            subjectId, chapterId, isFree: !newCreationFormik.values.pricingPlan
        }
        dispatch(questionAvailability(payload)).unwrap().then((res) => {
            let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))
            tempValues['questionSelection'][index]['availableCount'] = +res.data.testCount;
            tempValues['questionSelection'][index]['showCount'] = true;
            newCreationFormik.setValues(tempValues)

        })
    }

    const CheckAllQuestion = () => {
        let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))
        let isNotAvailable = false
        for (let i = 0; i < tempValues.questionSelection.length; i++) {
            if (tempValues.questionSelection?.[i]?.['availableCount'] <= tempValues.questionSelection?.[i]?.['numberOfQuestions']) {
                isNotAvailable = true
                break
            }
        }

        setIsAvailable(!isNotAvailable)
        // newCreationFormik.setValues(tempValues)
    }
    const showCheckAll = () => {
        let data = JSON.parse(JSON.stringify(newCreationFormik.values.questionSelection))
        if (data?.[0].id) {
            for (let i = 0; i < data.length; i++) {
                data[i]['indexId'] = data[i]['id'] ? data[i]['id'] : data[i]['indexId']
            }
        }
        let payload = {
            isFree: newCreationFormik.values.pricingPlan ? 0 : 1,
            checkQuestionSelection: data
        }

        dispatch(checkAvailableQuestionSelection(payload)).unwrap().then((res) => {
            // console.log("res    ===== ",res)
            let count = 0
            for (const element of res) {
                // element['id'] = element['id'] ?  element['id']  : null
                if (element?.questionStatus) {
                    count++
                }
                // data[i]['chapterId'] = data[i]['chapterId'] == null ? -1 : data[i]['chapterId']
            }
            if (count == res.length) {
                setIsAvailable(true)
            } else {
                setIsAvailable(false)

            }
            let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))
            tempValues['questionSelection'] = res
            newCreationFormik.setValues(tempValues)

        })

        // let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))

        //  let length = tempValues.questionSelection.length;
        // for( let i = 0 ; i<tempValues.questionSelection.length;i++ ){
        //     console.log("tempValues",tempValues)
        //     tempValues.questionSelection[i]['easy']= 14
        //     tempValues.questionSelection[i]['intermediate']= 22
        //     tempValues.questionSelection[i]['hard']= 23
        //     tempValues.questionSelection[i]['isAvailable']= true
        //     // tempValues.questionSelection[i]['isAvailable']= true,
        // }

        // newCreationFormik.setValues(tempValues)
        // console.log("new ",tempValues)



        // let tempValues = JSON.parse(JSON.stringify(newCreationFormik.values))

        // let count = 0;
        // let length = tempValues.questionSelection.length;
        // for( let i = 0 ; i<tempValues.questionSelection.length;i++ ){
        //     if(tempValues.questionSelection?.[i]?.['availableCount'] >= tempValues.questionSelection?.[i]?.['numberOfQuestions'] && tempValues.questionSelection?.[i]?.['showCount'] ){
        //         count++
        //     }
        // }
        // if(count == length ){
        //     message.success("All Question Sections ares Checked and Have a Available Questions. now You Can Publish This Test ")
        // setIsAvailable(true)

        //     return true

        // }else{
        //     message.info("Some Of The Question Sections are not Checked Or It hasn't sufficient Question")
        // setIsAvailable(false)

        //     return false
        // }

    }
    const discard = (_id) => {
        //  console.log(_id)   
        dispatch(discardDraftTest(_id)).unwrap().then((res) => {
            // console.log(res)
            if (res?.status) {
                navigate('/TestMaster')
                message.success(res.message)
            }
        })
    }

    return (
        <>
            <div className='newTest-page' style={{ height: '100%' }}>
                <div className='newTest-header'>

                    <div className="newTest-label">
                        <Image width={15} preview={false} src={leftArrow} onClick={() => onBackClick()} />
                        <span className='label-content'>{currentTitle}</span>
                    </div>
                    {
                        currentRemarks ?
                            <div className='form-control reject-remarks'>
                                <span > ** {currentRemarks}</span>
                            </div>
                            : null
                    }
                    <div className='newTest-button'>
                        {
                            isApproval ?
                                <>
                                    <div className='approval-button'>
                                        <Button className='primary-cancel-button' type="primary" onClick={() => reject()} >Reject </Button>
                                    </div>
                                    <div className='approval-button'>
                                        <Button className='primary-submit-button' type="primary" onClick={() => approved()} >Approve </Button>
                                    </div></>
                                : null
                        }
                    </div>
                    <div className=''>
                        <div className='test-basic-footer-button'>
                            <Button className='primary-cancel-button' onClick={() => navigate('/TestMaster')}>Cancel {isAvailable} </Button>

                            {
                                params?.draftId ?
                                    <Button className='primary-submit-button' type="primary" onClick={() => discard(params.draftId)} >Discard </Button>

                                    : null
                            }
                            <Button className='primary-submit-button' type="primary" onClick={newCreationFormik.handleSubmit} >Draft Save </Button>
                            {
                                isAvailable ?
                                    <Button className='primary-submit-button' type="primary" onClick={() => showSubmitConfirmation(newCreationFormik.values)} >Publish  {isAvailable}</Button>
                                    :
                                    null
                            }

                        </div>
                    </div>
                </div>
                <div className='newTest-body'>
                    <Tabs activeKey={tabStatus} onChange={onTabChange}>
                        <TabPane tab="Test Details" key="1">
                            <div className="test-basic-content">
                                <h2 className='test-basic-header'>Test Basic Details</h2>
                                <div className='basic-form'>
                                    <>
                                        <Form>
                                            <div className='form-control' >
                                                <label htmlFor='examId'>
                                                    Exam <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <div>
                                                    <Select id="examId" name="examId" showSearch filterOption={(input, option) => option.children.includes(input)} placeholder="Select Option" onChange={(e) => onExamChange(e)} onBlur={newCreationFormik.handleBlur} value={newCreationFormik.values?.examId}   >

                                                        {examData.map((data, index) => (
                                                            <Option value={data.id} key={data.id} >{data.name}</Option>
                                                        ))}
                                                    </Select>
                                                    {newCreationFormik.touched.examId && newCreationFormik.errors.examId ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.examId}</div> : null}

                                                </div>
                                            </div>
                                            <div className='form-control'>
                                                <label htmlFor='name'>
                                                    Test Name <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Input type='text' id='name' placeholder="Enter Test Name" onBlur={newCreationFormik.handleBlur} onChange={newCreationFormik.handleChange} value={newCreationFormik.values.name} />
                                                {newCreationFormik.touched.name && newCreationFormik.errors.name ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.name}</div> : null}
                                            </div>

                                            <div className='form-control' >
                                                <label htmlFor='typeOfTestId'>
                                                    Type of Test <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <div>
                                                    <Select id="typeOfTestId" name="typeOfTestId" showSearch filterOption={(input, option) => option.children.includes(input)} placeholder="Select Option" onChange={(e) => onTypeofTestChange(e, newCreationFormik)} onBlur={newCreationFormik.handleBlur} value={newCreationFormik.values.typeOfTestId}   >
                                                        {typeOfTest.map((data, index) => (
                                                            <Option value={data.id} key={data.id} >{data.name}</Option>
                                                        ))}
                                                    </Select>
                                                    {newCreationFormik.touched.typeOfTestId && newCreationFormik.errors.typeOfTestId ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.typeOfTestId}</div> : null}

                                                </div>
                                            </div>
                                            {
                                                isSubjectShow ?
                                                    <div className='form-control' >
                                                        <label htmlFor='subjectId'>
                                                            Subject <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <div>
                                                            <Select id="subjectId" name="subjectId" showSearch filterOption={(input, option) => option.children.includes(input)} placeholder="Select Option" onChange={(e) => onChangeSubject(e)} onBlur={newCreationFormik.handleBlur} value={newCreationFormik.values?.subjectId}   >
                                                                {subjectDataByExam?.getSubjectData?.length ? subjectDataByExam?.getSubjectData.map((data, index) => (
                                                                    <Option value={data.id} key={data.id} >{data.name}</Option>
                                                                )) : null}
                                                            </Select>
                                                            {newCreationFormik.touched?.subjectId && newCreationFormik.errors?.subjectId ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors?.subjectId}</div> : null}

                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className='form-control'>
                                                <label htmlFor='instructions'>
                                                    Instructions to be shown at Start of Quiz <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <ReactQuill name="instructions" theme="snow" id='instructions' modules={modules} formats={formats} placeholder="Instructions" onChange={(value) => newCreationFormik.setFieldValue("instructions", value)} value={newCreationFormik.values.instructions} />

                                            </div>
                                            <div className='form-control'>
                                                <label htmlFor='timeLimit'>
                                                    Time Limit (in minutes) <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Input type='number' id='timeLimit' placeholder="Enter Time Limit" onBlur={newCreationFormik.handleBlur} onChange={newCreationFormik.handleChange} value={newCreationFormik.values.timeLimit} />
                                                {newCreationFormik.touched.timeLimit && newCreationFormik.errors.timeLimit ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.timeLimit}</div> : null}
                                            </div>
                                            <div className='form-control'>
                                                <label htmlFor='eachQuestionMark'>
                                                    Mark of Each Question <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <Input type='number' id='eachQuestionMark' placeholder="Enter Question Mark " onBlur={newCreationFormik.handleBlur} onChange={newCreationFormik.handleChange} value={newCreationFormik.values.eachQuestionMark} />
                                                {newCreationFormik.touched.eachQuestionMark && newCreationFormik.errors.eachQuestionMark ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.eachQuestionMark}</div> : null}
                                            </div>
                                            <div className='form-control'>
                                                <Checkbox checked={isNegative} onChange={(e) => onCheckedChange(e)} value={isNegative}> Is Negative Mark</Checkbox>
                                            </div>
                                            {
                                                isNegative ?

                                                    <div className='form-control'>
                                                        <label htmlFor='negativeMark'>
                                                            Negative Mark <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <Input type='number' id='negativeMark' placeholder="Enter Negative Mark" onBlur={newCreationFormik.handleBlur} onChange={newCreationFormik.handleChange} value={newCreationFormik.values.negativeMark} />
                                                        {newCreationFormik.touched.negativeMark && newCreationFormik.errors.negativeMark ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.negativeMark}</div> : null}
                                                    </div>
                                                    : null
                                            }
                                        </Form>
                                    </>
                                </div>
                            </div>

                        </TabPane>
                        <TabPane tab="Question selections" key="2">
                            <div className='Section-Container'>
                                <div className='Section-body'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 27 }}>
                                        <h1 style={{ fontWeight: 600, fontSize: 16, color: '#021F55' }}>Sections - {count()} Questions</h1>
                                        <div>
                                            {
                                                newCreationFormik?.values && newCreationFormik?.values?.questionSelection && newCreationFormik?.values?.questionSelection?.length ?
                                                    <Button className='primary-submit-button' type="primary" onClick={() => showCheckAll()} >Check All Section </Button>
                                                    :
                                                    null
                                            }
                                            <span className='addSectionClass' onClick={() => { addSection() }}>+ ADD Section</span>
                                        </div>
                                    </div>
                                    <>
                                        <Form>
                                            <div>
                                                {
                                                    newCreationFormik?.values && newCreationFormik?.values?.questionSelection && newCreationFormik?.values?.questionSelection?.length ?
                                                        newCreationFormik?.values?.questionSelection.map((option, i) => {
                                                            return (
                                                                <div className='Section-form-container' key={i}>
                                                                    <div className='Section-form-Header'>

                                                                        <div>
                                                                            <h2 className='Header-text'>Section {i + 1} </h2>


                                                                        </div>
                                                                        <div className='close-block'>
                                                                            {newCreationFormik.values.questionSelection?.[i]?.questionStatus == true ?
                                                                                <>
                                                                                    <p className='sufficient'>  It has sufficient Question  </p>
                                                                                </>

                                                                                : newCreationFormik.values.questionSelection?.[i]?.questionStatus == false ?
                                                                                    <>
                                                                                        <p className='in-sufficient'>  It hasn't sufficient Question  </p>
                                                                                    </>
                                                                                    : null

                                                                            }
                                                                            {

                                                                                newCreationFormik.values.questionSelection?.[i]?.count && newCreationFormik.values.questionSelection?.[i]?.count.length ?

                                                                                    <div className='form-control'>
                                                                                        <p>
                                                                                            {
                                                                                                newCreationFormik.values.questionSelection?.[i]?.count.map((e, eIndex) => {
                                                                                                    return <span style={{ padding: 10 }}>{e.difficultyLevelId == 1 ? 'Basic :' : e.difficultyLevelId == 2 ? "Intermediate : " : "Advanced :"} {e.value}</span>

                                                                                                })
                                                                                            }
                                                                                        </p>
                                                                                    </div>

                                                                                    : null

                                                                            }
                                                                            <p className='cancelSection' onClick={() => remove(i)}  ><Image preview={false} src={CloseIcon} height={25} width={25} style={{ cursor: 'pointer' }}></Image></p>
                                                                        </div>


                                                                        {/* <h2 className='Header-text'>Section {i + 1} </h2>
                                                                        <p className='cancelSection' onClick={() => remove(i)}  ><Image preview={false} src={CloseIcon} height={25} width={25} style={{cursor:'pointer'}}></Image></p>
                                                                    */}
                                                                    </div>
                                                                    <div className='Section-form'>
                                                                        <Row>
                                                                            <Col span={7}>
                                                                                <div className='form-control' >
                                                                                    <label htmlFor={`subjectId.${i}`}>
                                                                                        Subject :

                                                                                    </label>
                                                                                    <div>
                                                                                        <Select id={`subjectId.${i}`} name={`subjectId.${i}`} style={{ width: "100%" }} placeholder="Select Option" onChange={(e) => { onSelected(e, i, 'subjectId') }} onBlur={newCreationFormik.handleBlur} disabled={newCreationFormik?.values?.subjectId != null ? true : false} value={newCreationFormik?.values?.subjectId == null ? newCreationFormik?.values?.questionSelection?.[i]?.['subjectId'] : newCreationFormik?.values?.subjectId}  >
                                                                                            {subjectDataByExam?.getSubjectData?.length ?
                                                                                                subjectDataByExam?.getSubjectData?.map((data, index) => (
                                                                                                    <Option value={data.id} key={index} >{data.name}</Option>
                                                                                                )) : null}
                                                                                        </Select>
                                                                                        {newCreationFormik?.touched?.questionSelection && newCreationFormik?.touched?.questionSelection.length && newCreationFormik?.touched?.questionSelection?.[i]?.subjectId && newCreationFormik.errors.questionSelection?.[i]?.subjectId ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik?.errors?.questionSelection?.[i]?.['subjectId']}</div> : null}

                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col span={7}>
                                                                                <div className='form-control' >
                                                                                    <label htmlFor={`chapterId.${i}`}>
                                                                                        Chapter :
                                                                                    </label>
                                                                                    <div>
                                                                                        <Select id={`chapterId.${i}`} name={`chapterId.${i}`} style={{ width: "100%" }} placeholder="Select Option" onChange={(e) => { onChangeSelect(e, i, 'chapterId') }} onBlur={newCreationFormik.handleBlur} value={newCreationFormik.values.questionSelection?.[i]?.['chapterId'] == null ? -1 : newCreationFormik.values.questionSelection?.[i]?.['chapterId']}   >
                                                                                            <Option value={-1} key={Date.now()} >ALL</Option>

                                                                                            {objSubjectByChapter[newCreationFormik.values.subjectId == null ? newCreationFormik?.values?.questionSelection?.[i]?.['subjectId'] : newCreationFormik.values.subjectId]?.map((data, index) => (
                                                                                                <Option value={data.id} key={data.id} >{data.name}</Option>
                                                                                            ))}
                                                                                        </Select>
                                                                                        {newCreationFormik?.touched?.questionSelection && newCreationFormik?.touched?.questionSelection.length && newCreationFormik?.touched?.questionSelection?.[i]?.chapterId && newCreationFormik.errors.questionSelection?.[i]?.chapterId ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.questionSelection?.[i]?.['chapterId']}</div> : null}

                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col span={5}>
                                                                                <div className='form-control' >
                                                                                    <label htmlFor={`questionTypeId.${i}`}>
                                                                                        Question Type :
                                                                                    </label>
                                                                                    <div>
                                                                                        <Select id={`questionTypeId.${i}`} name={`questionTypeId.${i}`} style={{ width: "100%" }} placeholder="Choose Option" defaultActiveFirstOption={true} onChange={(e) => { onChangeSelect(e, i, 'questionTypeId') }} onBlur={newCreationFormik.handleBlur} value={newCreationFormik.values.questionSelection?.[i]?.['questionTypeId']}  >
                                                                                            {questionTypeData.map((data, index) => (
                                                                                                <Option key={index} value={data.id}>{data.name}</Option>
                                                                                            ))}
                                                                                        </Select>
                                                                                    </div>
                                                                                    {newCreationFormik?.touched?.questionSelection && newCreationFormik?.touched?.questionSelection.length && newCreationFormik?.touched?.questionSelection?.[i]?.questionTypeId && newCreationFormik.errors.questionSelection?.[i]?.questionTypeId ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.questionSelection?.[i]?.['questionTypeId']}</div> : null}

                                                                                </div>
                                                                                {/* <div className='form-control'>
                                                                                    <label htmlFor={`numberOfQuestions.${i}`}>
                                                                                        No.of Questions  :
                                                                                    </label>
                                                                                    <Input type='number' id={`numberOfQuestions.${i}`} name={`numberOfQuestions.${i}`} placeholder="" onChange={(e) => { optionsOnChange(i, e, 'numberOfQuestions') }} onBlur={newCreationFormik.handleBlur} value={newCreationFormik.values.questionSelection[i]['numberOfQuestions']} />
                                                                                    {newCreationFormik.touched['numberOfQuestions'] && newCreationFormik.touched['numberOfQuestions'][i] && newCreationFormik.errors.questionSelection && newCreationFormik.errors.questionSelection[i]?.numberOfQuestions ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.questionSelection[i]['numberOfQuestions']}</div> : null}

                                                                                </div> */}
                                                                            </Col>
                                                                            <Col span={5}>
                                                                                <div className='form-control'>
                                                                                    <label htmlFor={`numberOfQuestions.${i}`}>
                                                                                        No.of Questions  :
                                                                                    </label>
                                                                                    <Input type='number' id={`numberOfQuestions.${i}`} name={`numberOfQuestions.${i}`} placeholder="" onChange={(e) => { optionsOnChange(i, e, 'numberOfQuestions') }} onBlur={newCreationFormik.handleBlur} value={newCreationFormik.values.questionSelection?.[i]?.['numberOfQuestions']} />
                                                                                    {newCreationFormik?.touched?.questionSelection && newCreationFormik?.touched?.questionSelection.length && newCreationFormik?.touched?.questionSelection?.[i]?.numberOfQuestions && newCreationFormik.errors.questionSelection?.[i]?.numberOfQuestions ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik.errors.questionSelection?.[i]?.['numberOfQuestions']}</div> : null}

                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : ''
                                                }
                                            </div>
                                            {newCreationFormik.values.questionSelection.length ?
                                                <></> :
                                                newCreationFormik?.touched?.questionSelection && newCreationFormik?.errors?.questionSelection ? <div className='errorMessage' style={{ color: 'red' }}>{newCreationFormik?.errors?.questionSelection}</div> : null}

                                        </Form>
                                    </>

                                </div>

                            </div>

                        </TabPane>
                        <TabPane tab="Pricing" key="3">
                            <Form>
                                <div className='overall-pricing'>
                                    <div className='pricing'>
                                        <Radio.Group id='pricing' onBlur={newCreationFormik.handleBlur} onChange={(e) => { newCreationFormik.setFieldValue('pricingPlan', e.target.value); setIsAvailable(false) }} value={newCreationFormik.values.pricingPlan ? true : false}>
                                            <div className={newCreationFormik.values.pricingPlan == false ? 'payment' : 'pricing-free'}>
                                                <Radio value={false}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p>Free</p>
                                                    </div>
                                                    <p style={{ fontWeight: 400, fontSize: 11 }}> Allow access to your content free of charge </p>
                                                </Radio>
                                            </div>
                                            <div style={{ marginTop: 0, marginLeft: 16, marginBottom: 0, marginRight: 34 }} className={newCreationFormik.values.pricingPlan == true ? 'payment' : 'pricing-payment'}>
                                                <Radio value={true}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p>Payment</p>
                                                    </div>
                                                    <p style={{ fontWeight: 400, fontSize: 11 }}> Learner can access the course by making payment </p>
                                                </Radio>
                                            </div>
                                        </Radio.Group>
                                        {newCreationFormik.touched.pricingPlan && newCreationFormik.errors.pricingPlan ? <div className='errorMessage' style={{ color: 'red', paddingLeft: 25, position: 'absolute' }}>{newCreationFormik.errors.pricingPlan}</div> : null}
                                    </div>
                                    {newCreationFormik.values.pricingPlan == true ?
                                        <div>
                                            <div className='pricing-details'>
                                                <div className='pricing-details-header'>Pricing Details</div>
                                                <div className='test-price'>
                                                    <label htmlFor="price" >Test Price <span style={{ color: 'red' }}>*</span> </label>
                                                    <Input id='price' type='number' name='price' onBlur={newCreationFormik.handleBlur} onChange={newCreationFormik.handleChange} value={newCreationFormik.values.price}></Input>
                                                    {newCreationFormik.touched.price && newCreationFormik.errors.price ? <div className='errorMessage' style={{ color: 'red', position: 'absolute', bottom: -20 }}>{newCreationFormik.errors.price}</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                </div>
                            </Form>

                        </TabPane>
                    </Tabs>
                </div>
            </div>
            {
                isQuestionLoading || isTestMasterLoading ? <div className='loading-container'>

                    <Loading></Loading>

                </div> : null
            }
            {
                showPrompt ?
                    <div>
                        <Modal className="pageBackButton" title="Confirmation" open={true} onOk={confirmNavigation} onCancel={cancelNavigation}>

                            <div className='modal-container'>
                                <p className='modal-header'>Are you sure want to reload page ?</p>
                            </div>
                        </Modal>
                    </div>


                    : null
            }

            {
                rejectVisible ?
                    <div>
                        <Modal className="pageBackButton" title="Remarks" open={true} onOk={() => handleOk()} onCancel={() => handleCancel()}>

                            <div className='modal-container'>
                                <div className='form-control' >
                                    <label htmlFor='remarks'>
                                        Remarks <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <div>
                                        <TextArea id="remarks" name="remarks" style={{ height: 120, resize: 'none', }} placeholder="Type Here" onChange={remarksFormik.handleChange} onBlur={remarksFormik.handleBlur} value={remarksFormik.values.remarks}   >
                                        </TextArea>
                                        {remarksFormik.touched.remarks && remarksFormik.errors.remarks ? <div className='errorMessage' style={{ color: 'red' }}>{remarksFormik.errors.remarks}</div> : null}

                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>


                    : null
            }
            {/* submit modal */}

        <Modal
        title="Publish Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
          <div>
            <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
            <Button className="primary-submit-button" type="default" onClick={()=>publishTest(newCreationFormik.values)}> Publish </Button>
          </div>
        }
      >
        <p>Are you sure you want to Publish?</p>
      </Modal>
        </>
    )
}

export default NewTestChanges