/* eslint-disable */
import { DeleteOutlined, DownloadOutlined, MailOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Drawer, Form, Input, Modal, Select, Spin, Image, Tabs, message, Menu, Collapse } from 'antd'
import { FieldArray, Field, Formik, getIn, useFormik, connect } from 'formik';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreviousYearExamById, addQuestionData, deleteQuestionData, getAllQuestion, updateQuestionData, getQuestionById, getPreYearCompreQuestionById, excelUpload, ApprovalQuestionStatus, getComprehensionById, deleteComprehensionData, movingQuestion, getAllSubjectList, getAllChapterList, copyAndPasteQuestion, createComphrensiveQuestion } from './reducers/PreviousYearQuestionsSlice';
import TableComponent from './Table/tableComponent';
import * as yup from 'yup';
import { Option } from 'antd/lib/mentions';
import { LoadingOutlined, ToTopOutlined } from '@ant-design/icons';
import DeleteImage from '../asset/image/deleteIcon.svg';
import './styles/questionBank.scss'
import { useDebouncedCallback } from 'use-debounce';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Loading from './Loading';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Editor from "./Editor";
import { addStyles, EditableMathField, StaticMathField } from "react-mathquill";
import 'react-quill/dist/quill.snow.css';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BreadCrumb from './BreadCrumb';
import { getAllTypeData } from './reducers/dashboardSlice';
import axios from "axios";
import DynamicQuesionArray from './dynamicQuesionArray';
import BuildEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'


addStyles();


const { Panel } = Collapse;
const CUSTOM_OPERATORS = [
  ["\\pm", "\\pm"],
  ["\\sqrt{x}", "\\sqrt"],
  ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
  ["\\sqrt[n]{x}", "\\nthroot"],
  ["\\frac{x}{y}", "\\frac"],
  ["\\sum^{s}_{x}{d}", "\\sum"],
  ["\\prod^{s}_{x}{d}", "\\prod"],
  ["\\coprod^{s}_{x}{d}", "\\coprod"],
  ["\\int^{s}_{x}{d}", "\\int"],
  ["\\binom{n}{k}", "\\binom"]
];

const { TextArea } = Input;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const QuestionBank = () => {
  const arrayOfQuestion = useRef([]);
  const arrayOfSolution = useRef([]);
  const exportRef = useRef();
  const questionQuill = useRef();
  const comprehensionQuill = useRef();
  const solutionQuill = useRef();
  const [latex, setLatex] = useState("\\frac{1}{\\sqrt{2}}\\cdot 2");
  // const editorContainerRef = useRef();
  let realEditor;

  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const paramsQuestionTypeId = searchParams?.get('questionTypeId')

  const dispatch = useDispatch()
  const hiddenFile = useRef(null);
  const hiddenImage = useRef(null);
  const [currentId, setCurrentId] = useState(null)
  const [currentTitle, setCurrentTitle] = useState("")
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const [filterData, setFilterData] = useState("");
  const [questionTypeData, setQuestionTypeData] = useState([{ id: 1, name: "Normal" }, { id: 2, name: "Comprehension", }]);
  const [uploadImage, setUploadImage] = useState();
  const [image, setImage] = useState(null);
  const [arrayAnswer, setArrayAnswer] = useState([]);
  const [buttonState, setButtonState] = useState(1);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)
  const [isApproval, setIsApproval] = useState(false)
  const [difficulty, setDifficulty] = useState()
  const [defaultQuestionTypeId, setDefaultQuestionTypeId] = useState(1)
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [previousExamById, setpreviousExamById] = useState();

  const init = {
    questionTypeId: defaultQuestionTypeId,
    isFree: false,
    questionText: '',
    questionOption: [],
    solution: ''
  }

  const [initialValues, setInitialValues] = useState(init)

  const { TextArea } = Input;
  const options = { displayHistory: true, operators: CUSTOM_OPERATORS };
  const roleId = localStorage.getItem("roleId")
  const [status, setStatus] = useState();
  const [currentFindObj, setCurrentFindObj] = useState(() => {
    if (roleId == 5) {
      return { id: 1, statusId: 1, name: 'pending', displayName: "Pending" }
    } else {
      return { id: 5, statusId: 2, name: 'approved', displayName: "Approved" }
    }
  })
  const [currentEquation, setCurrentEquation] = useState(questionQuill);
  const { questionData, currentQuestion, isQuestionLoading, totalDataCount, breadCrumbData, getAllExamListData, getAllSubjectListData, getAllChapterListData } = useSelector((state) => state.PreviousYearQuestionsSlice)
  const { subjectData, difficultLevelData, chapterData, examData, typeOfQuestion, subjectDataByExam, objSubjectByChapter } = useSelector((state) => state.MasterSlice)
  const { listData, isDashLoading } = useSelector((state) => state.DashboardSlice)

  const [superAdminRoleArray, setSuperAdminRoleArray] = useState([
    { id: 1, statusId: 1, name: 'pendingForApproval', displayName: "Pending For Approval" },
    { id: 3, statusId: 3, name: 'rejectedForApproval', displayName: "Rejected For Approval" },
    { id: 5, statusId: 2, name: 'approved', displayName: "Approved" }
  ]);

  const [roleArray, setRoleArray] = useState([
    { id: 1, statusId: 1, name: 'pendingForApproval', displayName: "Pending For Approval" },
    { id: 2, statusId: 1, name: 'pending', displayName: "Pending" },
    { id: 3, statusId: 3, name: 'rejectedForApproval', displayName: "Rejected For Approval" },
    { id: 4, statusId: 3, name: 'rejected', displayName: "Rejected" },
    { id: 5, statusId: 2, name: 'approved', displayName: "Approved" }
  ]);
  const [dataRoleArray, setDataRoleArray] = useState([
    { id: 1, statusId: 1, name: 'pending', displayName: "Pending" },
    { id: 2, statusId: 3, name: 'rejected', displayName: "Rejected" },
    { id: 3, statusId: 2, name: 'approved', displayName: "Approved" }
  ]);


  const statusChanged = (e) => {
    let findObj
    if (roleId == 5) {
      findObj = dataRoleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    } else {
      findObj = roleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    }
    setStatus(e)
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
      statusName: findObj.name,
      statusId: findObj.statusId
    }
    let data = {
      obj,
      questionTypeId: defaultQuestionTypeId,
      previousYearExamId: params?.previousYearExamId
    }
    dispatch(getAllQuestion(data))
  }
  let [defaultOption, setDefaultOption] = useState(0)
  let nodeComprehensive = React.createRef();
  let nodeNormal = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      nodeComprehensive.current
    ]);
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      nodeNormal.current
    ]);
  }
  useEffect(() => {
    const fetchPreviousExamData = async () => {
      try {
        const data = await dispatch(getPreviousYearExamById(params.previousYearExamId));
        if (data) {
          setpreviousExamById(data);
        }
      } catch (error) {
        console.error('Error fetching previous exam data:', error);
      }
    };

    fetchPreviousExamData();
  }, [dispatch, params.previousYearExamId]);



  useEffect(() => {
    if (!params?.statusName) {
      setStatus(currentFindObj.id)
      let obj = {
        pagination: -1,
        searchValue: filterData,
        limit: limit,
        statusName: currentFindObj.name,
        statusId: currentFindObj.statusId
      }
      let data = {
        obj,
        questionTypeId: defaultQuestionTypeId,
        previousYearExamId: params?.previousYearExamId
      }
      dispatch(getAllQuestion(data))
    } else {
      setDefaultQuestionTypeId(paramsQuestionTypeId)
      let payload = {
        filterType: params?.statusName,
        type: 'question',
        questionType: paramsQuestionTypeId

      }
      dispatch(getAllTypeData(payload))
    }
  }, [])

  useEffect(() => {
    setInitialValues(currentQuestion)
  }, [currentQuestion])

  const [validationSchema, setValidationSchema] = useState(() => {
    return yup.object().shape({
      questionTypeId: yup.number().notRequired(),
      questionParagraphText: yup.string().when('questionTypeId', {
        is: 2,
        then: yup.string().required("Comprehension Text is Required"),
        otherwise: yup.string().notRequired()
      }),
      questions: yup.array().when('questionTypeId', {
        is: 2,
        then: yup.array().of(
          yup.object().shape({
            questionText: yup.string().required("Question Text is Required"),
            questionOption: yup.array().min(2).of(
              yup.object().shape({
                questionOption: yup.string().required("Question Option is Required")
              })
            ).required("Question Option is Required"),
            solution: yup.string().required("Solution is Required")
          })
        ).min(1, "At least one question is required"),
        otherwise: yup.array().notRequired()
      }),
      questionText: yup.string().when('questionTypeId', {
        is: 1,
        then: yup.string().required("Question Text is Required"),
        otherwise: yup.string().notRequired()
      }),
      questionOption: yup.array().when('questionTypeId', {
        is: 1,
        then: yup.array().min(2, "At least two options are required").of(
          yup.object().shape({
            questionOption: yup.string().required("Question Option is Required")
          })
        ).required("Question Option is Required"),
        otherwise: yup.array().notRequired()
      }),
      solution: yup.string().when('questionTypeId', {
        is: 1,
        then: yup.string().required("Solution is Required"),
        otherwise: yup.string().notRequired()
      })
    });
  })

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };


  const updateData = () => {

    let datas = {
      id: currentId,
      data: formik.values,
      previousYearExamId: params?.previousYearExamId,
      obj: {
        questionTypeId: defaultQuestionTypeId,
        status: currentFindObj,
        previousYearExamId: params?.previousYearExamId,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
          statusName: currentFindObj.name,
          statusId: currentFindObj.statusId
        },
      }

    }


    setUpdateModalVisible(false);
    setIsVisible(false)
    dispatch(updateQuestionData(datas))
  }

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };


  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsVisible(false)
      let questionData = {}
      if (defaultQuestionTypeId == 1) {
        questionData = {
          questionText: values.questionText,
          questionOption: values.questionOption,
          questionTypeId: defaultQuestionTypeId,
          solution: values.solution,
          file: image,
          previousYearExamId: params?.previousYearExamId
        }
      } else {
        questionData = {
          questionParagraphText: values.questionParagraphText,
          questions: values.questions,
          questionTypeId: defaultQuestionTypeId,
          file: image,
          previousYearExamId: params?.previousYearExamId
        }
      }

      let datas = {
        payload: questionData,
        obj: {
          questionTypeId: defaultQuestionTypeId,
          previousYearExamId: params?.previousYearExamId,
          obj: {
            pagination: paginationData,
            searchValue: filterData,
            limit: limit,
            statusName: currentFindObj.name,
            statusId: currentFindObj.statusId
          },
        }
      }
      setSubmitModalVisible(false);
      dispatch(addQuestionData(datas))
      formik.resetForm()
      setUploadImage(null)
    },
    enableReinitialize: true
  })
  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);


  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
                return (
          <div className='index-id'>{(i+1)+(paginationData>0?(paginationData)*limit:0)}
          </div>
        )
      },
    },
    {
      title: defaultQuestionTypeId == 1 ? "Question Text" : "Comprehension Text",
      dataIndex: "questionText",
      key: "questionText",
      render: (_, record) => {
        return (
          <div className='question-text-wrapper' dangerouslySetInnerHTML={{ __html: defaultQuestionTypeId == 1 ? record.questionText : record.questionText }}>
          </div>
        )
      },
    },
    {
      title: previousExamById?.payload[0].isPublish === 0 ? 'Action' : '',
      key: 'action',
      render: (_, record) => {
        return (
          <div className='table-action-link'>
            <>
              {
                record.isPublish === 0 ? (
                  <>
                    <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
                    {
                      roleId == 3 && !params.statusName ?
                        <Tooltip title="Delete" style={{ padding: 0 }}>
                          <IconButton>
                            <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                          </IconButton>
                        </Tooltip>
                        : null
                    }
                  </>
                ) : ''
              }
            </>
          </div>
        )
      },
    },

  ]


  let tableHeight = 320;

  const editData = (record) => {
    if (record?.isApproval) {
      setIsApproval(true)
    } else {
      setIsApproval(false)
    }
    setCurrentId(record.id)
    if (defaultQuestionTypeId == 1) {
      dispatch(getQuestionById(record.id)).unwrap().then((res) => {
        setIsVisible(true)
      })
    } else {
      dispatch(getPreYearCompreQuestionById(record.id)).unwrap().then((res) => {
        setIsVisible(true)
      })
    }
  }

  const deleteData = (record) => {
    setIsModalVisible(true)
    setCurrentId(record.id)
    // dispatch(deleteQuestionData(record.id))

    // let data = {
    //   payload: questionData,
    //   obj: {
    //     pagination: paginationData,
    //     searchValue: filterData,
    //     limit: limit,
    //     status: currentFindObj
    //   }
    // }

    // dispatch(getAllQuestion(data))
  }


  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
      statusId: currentFindObj.statusId
    }
    let data = {
      obj,
      statusName: params?.statusName,
      questionTypeId: defaultQuestionTypeId,
      status: currentFindObj,
      previousYearExamId: params?.previousYearExamId
    }
    if (!params?.statusName) {
      dispatch(getAllQuestion(data))
    }
  }

  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
      statusName: currentFindObj?.name,
      statusId: currentFindObj.statusId
    }
    let data = {
      obj,
      questionTypeId: defaultQuestionTypeId,
      previousYearExamId: params?.previousYearExamId
    }
    debounced(data)
  }

  const debounced = useDebouncedCallback((data) => {
    if (!params?.statusName) {
      dispatch(getAllQuestion(data))
    }

  }, 1000)

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const reject = () => {
    setIsRejectModalVisible(true)
  }

  const openDrawer = () => {
    formik.resetForm()
    if (defaultQuestionTypeId == 2) {
      setInitialValues({
        questionTypeId: defaultQuestionTypeId,
        difficultyLevelId: '',
        isFree: false,
        questionParagraphText: '',
        questions: []
      });
    } else {
      setInitialValues({
        questionTypeId: defaultQuestionTypeId,
        difficultyLevelId: '',
        isFree: false,
        questionText: '',
        questionOption: [],
        solution: '',
      });
    }
    setDifficulty()
    setIsApproval(false)
    setUploadImage()
    setButtonState(1)

    setCurrentId(null)
    setIsVisible(true)
    setCurrentTitle("New Question")
    setArrayAnswer([])
  }

  const modalOnCancel = () => {
    setIsModalVisible(false)
  }
  const modalOnOk = () => {
    setIsModalVisible(false)
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
      statusName: currentFindObj?.name,
      statusId: currentFindObj.statusId

    }
    let data = {
      obj,
      id: currentId,
      statusName: params?.statusName,
      questionTypeId: defaultQuestionTypeId,
      status: currentFindObj,
      previousYearExamId: params?.previousYearExamId
    }
    if (defaultQuestionTypeId == 1) {
      dispatch(deleteQuestionData(data))
    } else {
      dispatch(deleteComprehensionData(data))

    }

  }

  const drawerCloser = () => {
    setIsVisible(false)
  }

  const onChangeQuestionType = (e) => {
    setDefaultQuestionTypeId(e)
    if (e == 2) {
      setInitialValues({
        questionTypeId: 2,
        difficultyLevelId: difficulty,
        isFree: false,
        questionParagraphText: '',
        questions: []
      });
    } else {
      setInitialValues({
        questionTypeId: 1,
        difficultyLevelId: difficulty,
        isFree: false,
        questionText: '',
        questionOption: [],
        solution: ''
      });
    }

    let obj = {
      pagination: -1,
      searchValue: filterData,
      limit: limit,
      statusName: currentFindObj.name,
      statusId: currentFindObj.statusId
    }
    let data = {
      obj,
      questionTypeId: e,
      previousYearExamId: params?.previousYearExamId,
    }
    dispatch(getAllQuestion(data))
  }
  const optionsOnChange = (i, e) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['questionOption'][i]['questionOption'] = e.target.value
    formik.setValues(tempValues)
  }

  const optionsOnChecked = (i, e) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues.questionOption.forEach((option, index) => {
      if (e.target.checked) {
        if (index != i) {
          option['value'] = false
        }
      }
    })
    tempValues['questionOption'][i]['value'] = e.target.checked

    formik.setValues(tempValues)
  }

  const addNewRow = () => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    if (tempValues['questionOption']?.length) {
      tempValues['questionOption'].push({ questionOption: '', id: null, value: false })

    } else {
      tempValues['questionOption'] = []
      tempValues['questionOption'].push({ questionOption: '', id: null, value: false })

    }
    formik.setValues(tempValues)
  }

  const addQuestions = () => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    if (tempValues['questions']?.length) {
      tempValues['questions'].push({ questionId: null, questionText: '', questionOption: [], solution: '' })
    } else {
      tempValues['questions'] = []
      tempValues['questions'].push({ questionId: null, questionText: '', questionOption: [], solution: '' })
    }
    formik.setValues(tempValues)
    setArrayAnswer([...arrayAnswer, false]);
  }

  const remove = (index) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['questionOption'].splice(index, 1)
    formik.setValues(tempValues)
  }

  function onDrawerClose() {
    formik.resetForm()
    setIsVisible(false)
    setUploadImage(null)
  }


  const onModalClose = () => {
    setIsModalVisible(false);
    setIsRejectModalVisible(false)
    remarkFormik.resetForm()
  }

  const checkArrayAnswer = (data, indexArg, i) => {
    setArrayAnswer(prevArray => {
      const newArray = [...prevArray]; // create a copy of the previous array
      newArray[indexArg] = data ? data : false; // update the value at the specified index
      return newArray;
    });

  }

  const checkAllArr = () => {
    const allTrue = arrayAnswer.every((value) => {
      return value === true;
    });
    return allTrue
  }

  const questionRemove = (i) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['questions'].splice(i, 1)
    setArrayAnswer(prevArray => {
      const newArray = [...prevArray]; // create a copy of the previous array
      newArray.splice(i, 1); // remove the element at the specified index
      return newArray;
    });
    formik.setValues(tempValues)
  }



  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append("file", file);
            axios
              .post("/masters/customFileUpload", formData)
              .then((res) => {
                const imageUrl = res.data.data.url;
                setUploadImage(imageUrl);
                resolve({ default: imageUrl });
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const remarkInitialValues = { remarks: '' }
  const remarkValidationSchema = yup.object({
    remarks: yup.string().required("Remarks is required")
  })

  const remarkFormik = useFormik({
    initialValues: remarkInitialValues,
    validationSchema: remarkValidationSchema
  })

  const addRemarks = () => {
    setIsModalVisible(false)
    setIsRejectModalVisible(false)
    remarkFormik.resetForm()
    setIsVisible(false)
    let obj = {
      pagination: -1,
      searchValue: filterData,
      limit: limit,
      statusName: currentFindObj?.name,
      statusId: currentFindObj.statusId
    }
    let payload = {
      questionId: currentId,
      statusId: 3,
      questionTypeId: defaultQuestionTypeId,
      data: {
        remarks: remarkFormik.values.remarks
      },
      getAllData: {
        questionTypeId: defaultQuestionTypeId,
        previousYearExamId: params?.previousYearExamId,
        obj: obj
      }
    }
    dispatch(ApprovalQuestionStatus(payload)).unwrap().then(res => {
      if (res.status) {
        message.success(res.message)
      }
    })
  }

  return (
    <>
      <div className='questionBank-page'>
        <div className='breadCrumb-Data'>
          <BreadCrumb data={breadCrumbData} loading={isQuestionLoading}></BreadCrumb>
        </div>
        <div className='questionBankHeader'>

          <div className='questionBankHeaderWrapper'>
            <h3 className='primary-header' >Question Bank</h3>

          </div>
          <div className='quesitonBank-button' >
            {
              !params.statusName && previousExamById?.payload[0].isPublish === 0 ?
                <>
                  <Button style={{ marginBottom: 20 }} id="newQuestion" className='primary-submit-button' onClick={openDrawer}>New Question </Button>
                </>
                : null
            }
          </div>
        </div>
        <div className='all-table-component' style={{ padding: 0, margin: 0 }}>
          <div className="table-header">
            <div className='list-dropdown' >
              {
                !params?.statusName ?
                  <div>

                    <Select id="status" name="status" className='' placeholder="Select Status" defaultValue={roleId == 5 ? 0 : 5} onChange={statusChanged} value={status}  >
                      {
                        roleId == 5 ? dataRoleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                          : roleId == 3 ? superAdminRoleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                            : roleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                      }
                    </Select>
                  </div>

                  : null
              }
              {
                !params?.statusName ?
                  <div>

                    <Select id="questionTypeId" name="questionTypeId" style={{ width: "100%", marginLeft: 10 }} placeholder="Choose Option" defaultActiveFirstOption={true} defaultValue={defaultQuestionTypeId} onChange={(e) => onChangeQuestionType(e)} value={defaultQuestionTypeId} >
                      {questionTypeData.map((data, index) => (
                        <Option key={index} value={data.id}>{data.name}</Option>
                      ))}
                    </Select>
                  </div>

                  : null
              }
              {
                !params?.statusName ?
                  <div className="golbalSearch" >
                    <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
                  </div>
                  : null
              }
            </div>
          </div>
          <TableComponent dataSource={questionData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={totalDataCount} currentPage={paginationData + 1} loadingStatus={isQuestionLoading} limit={limit} defaultQuestionTypeId={defaultQuestionTypeId == 1 ? true : false} />
        </div>
        <Drawer title={currentTitle} onClose={() => onDrawerClose()} width="2000px" closable={false} closeIcon={false} placement="right" open={isVisible} footer={
          (
            <div className='footer'>
              <div className='footer-button'>
                <Button className='primary-cancel-button' onClick={drawerCloser}>Cancel</Button>
                {
                  isApproval ?
                    <>
                      <Button className='reject-btn' onClick={() => reject()}>Reject</Button>
                      <Button className='approve-btn' style={{ margin: '0px 26px' }} onClick={updateData}>Approve</Button>
                    </>
                    : <>
                      {currentId ? (
                        <Button
                          type='primary'
                          className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'}
                          disabled={!formik.isValid}
                          onClick={showUpdateConfirmation}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          className={!formik.isValid || !formik.dirty || !checkAllArr() ? 'disabled-button' : 'primary-submit-button'}
                          type="primary"
                          disabled={!formik.isValid || !formik.dirty || !checkAllArr()} onClick={showSubmitConfirmation}

                        >
                          Save
                        </Button>
                      )}
                    </>
                }
              </div>
            </div>
          )
        } >
          <Form>
            {
              defaultQuestionTypeId == 1 ?
                <>
                  <div>
                  </div>
                  <div className='form-control'>
                    <label htmlFor='questionText'>
                      Question Text  :
                    </label>
                    <CKEditor
                      editor={BuildEditor}
                      name="questionText"
                      id='questionText'
                      ref={questionQuill}
                      config={{
                        removePlugins: ['CKFinder'],
                        extraPlugins: [uploadPlugin],
                      }}
                      onReady={() => {
                      }}
                      data={formik.values.questionText}
                      onChange={(_event, editor) => {
                        const questionDataText = editor.getData();
                        formik.setFieldValue("questionText", questionDataText)
                      }
                      }
                      onFocus={(event, editor) => {
                        setCurrentEquation(questionQuill)
                      }}
                    />
                    <div className="add-equation-wrapper">
                    </div>
                    {
                      formik.values && formik.values.questionOption?.length && !formik.values.questionOption.some(option => option.value)
                        ?
                        <span className='answer-option-error-message'> Answer Must be select in the below option</span>
                        : null
                    }
                    {
                      formik.values && formik.values.questionOption ?
                        formik.values.questionOption.map((option, i) => {
                          const questionOption = `questionOption[${i}]`;
                          const touchedQuestion = getIn(formik.touched, questionOption);
                          const errorQuestion = getIn(formik.errors, `questionOption[${i}].questionOption`);
                          return (
                            <div className='question-option-wrapper' key={i}>
                              <div className="formik-question-option">
                                <Checkbox style={{ marginLeft: 10, marginRight: 10 }} onChange={(e) => { optionsOnChecked(i, e) }} checked={formik.values['questionOption'][i]['value']}></Checkbox>
                                <div className='formik-input-field'>
                                  <Input type="text" id={`questionOption.${i}`} name={`questionOption.${i}`} onChange={(e) => { optionsOnChange(i, e) }} onBlur={formik.handleBlur} placeholder="Options" value={formik.values['questionOption'][i]['questionOption']} ></Input>
                                </div>
                                <DeleteOutlined style={{ marginLeft: 10, marginRight: 10 }} onClick={() => remove(i)} />
                              </div>
                              {touchedQuestion && errorQuestion ? <div className='errorMessage answer-option-error-message' style={{ color: 'red' }}>{errorQuestion}</div> : null}

                            </div>
                          )
                        })
                        : ''
                    }
                    <span className='add-option' onClick={() => { addNewRow() }}>+ Add Option</span>
                  </div>
                  <div className='form-control solution'>
                    <label htmlFor='solution'>
                      Solution  :
                    </label>
                    <CKEditor
                      editor={BuildEditor}
                      name="solution"
                      id='solution'
                      ref={solutionQuill}
                      config={{
                        removePlugins: ['CKFinder'],
                        extraPlugins: [uploadPlugin],

                      }}

                      onReady={editor => {
                      }}

                      data={formik.values.solution}
                      onChange={(event, editor) => {
                        const solutionDataText = editor.getData();
                        formik.setFieldValue("solution", solutionDataText)
                      }
                      }

                      onFocus={(event, editor) => {
                        setCurrentEquation(solutionQuill)
                      }}
                    />
                  </div>
                </>
                : defaultQuestionTypeId == 2 ?
                  <>
                    <div className='form-control'>
                      <label htmlFor='questionParagraphText'>
                        Comprehension  :
                      </label>
                      <CKEditor
                        editor={BuildEditor}
                        name="questionParagraphText"
                        id='questionParagraphText'
                        ref={comprehensionQuill}
                        config={{
                          removePlugins: ['CKFinder'],
                          extraPlugins: [uploadPlugin],

                        }}

                        onReady={editor => {
                        }}

                        data={formik.values.questionParagraphText}
                        onChange={(event, editor) => {
                          const comprehensiveDataText = editor.getData();
                          formik.setFieldValue("questionParagraphText", comprehensiveDataText)
                        }
                        }

                        onFocus={(event, editor) => {
                          setCurrentEquation(comprehensionQuill)
                        }}
                      />
                    </div>
                    {
                      formik.values?.questions?.length ?

                        formik.values?.questions.map((e, index) => {
                          return (
                            <>
                              <Collapse accordion expandIconPosition='end'>
                                <Panel header={` Question ${index + 1}`} key={index + 1}>
                                  <div>

                                    <DynamicQuesionArray key={index} formik={formik} index={index} onCheckArr={checkArrayAnswer} editCheckAnswer={[true]}></DynamicQuesionArray>

                                    <p className="remove-question" onClick={() => questionRemove(index)} >Remove</p>

                                  </div>
                                </Panel>
                              </Collapse>
                            </>
                          )
                        })
                        :
                        null
                    }
                    <span className='add-option' onClick={() => { addQuestions() }}>+ Add Question</span>
                  </>
                  :
                  <>
                  </>
            }
          </Form>
        </Drawer>

        {/* reject modal */}
        <Modal title='Rejection' visible={isRejectModalVisible} onCancel={onModalClose} footer={
          <div>
            <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
            <Button type='default' className='primary-submit-button' id={!remarkFormik.isValid || !remarkFormik.dirty ? 'disabled-button' : null} disabled={!remarkFormik.isValid || !remarkFormik.dirty} onClick={() => addRemarks()}>OK</Button>
          </div>
        }>
          <div>
            <label htmlFor="remarks">Remarks <span style={{ color: 'red' }}>*</span> </label>
            <TextArea id='remarks' style={{ marginTop: 10, height: 176 }} onChange={remarkFormik.handleChange} onBlur={remarkFormik.handleBlur} value={remarkFormik.values.remarks} > </TextArea>
            {remarkFormik.touched.remarks && remarkFormik.errors.remarks ? <div className='error'>{remarkFormik.errors.remarks}</div> : null}
          </div>
        </Modal>

        <Modal title='Deletion' open={isModalVisible} onCancel={modalOnCancel} onOk={() => modalOnOk()}  >
          <p>Are you sure want to delete?</p>
        </Modal>

        {/* update model */}
        <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
          <div>
            <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
            <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
          </div>
        }>
          <p>Are you sure you want to update?</p>
        </Modal>

        {/* submit modal */}
        <Modal
          title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
            <div>
              <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
              <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}> Submit </Button>
            </div>
          }
        >
          <p>Are you sure you want to submit?</p>
        </Modal>

      </div>
    </>
  )
}

export default QuestionBank