import React, { useState, useEffect } from "react";
import { Button, Input, Drawer, Modal, Table, Image, Form, Checkbox, Tag,Upload,message,textarea} from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { getAllKeyFeatures, createKeyFeatures, getKeyFeaturesById, updateKeyFeatures, deleteKeyFeatures} from "../components/reducers/KeyFeaturesSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { useDebouncedCallback } from "use-debounce";
import TableComponent from "./Table/tableComponent";
import Loading from "./Loading";
const { TextArea } = Input;


const KeyFeatures = () => {

    const dispatch = useDispatch();
    let tableHeight = 320;
  
    const { keyFeaturesData, isLoading, totalDataCount } = useSelector(
      (state) => state.KeyFeatures
    );
    const [file, setFile] = useState([]);
    const [paginationData, setPaginationData] = useState(0);
    const [filterData, setFilterData] = useState("");
    const [limit, setLimit] = useState(50);
    const [currentTitle, setCurrentTitle] = useState("");
    const [visible, setVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
    const [currentId, setCurrentId] = useState(null);

    useEffect(() => {
        let obj = {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        };
        dispatch(getAllKeyFeatures(obj));
      }, []);


      const initialValues = {
        title: "",
        subTitle:"",
        icon: [],
        content:"",
        orderNo: "",
        isActive: false
      };

      const validationSchema = yup.object({
        title: yup
          .string()
          .required("Title is Required")
          .min(2, "Title Needed At Least Two characters")
          .max(100, "Title not more than 100 characters"),

          subTitle: yup
          .string()
          .required("Sub Title is Required")
          .min(2, "Sub Title Needed At Least Two characters")
          .max(100, "Sub Title not more than 100 characters"),

          icon : yup.array().min(1, "Icon is Required").required("Icon is Required").nullable(),

          content: yup
          .string()
          .required("Content is Required")
          .min(2, "Content Needed At Least Two characters")
          .max(500, "Content not more than 500 characters"),
    
          orderNo: yup
          .string()
          .required("Order Number is Required")
          .matches(/^[1-9][0-9]*$/, "Order Number must be a number and cannot consist only of 0")
      });


      const columns = [
        {
          title: "S.No",
          dataIndex: "index",
          key: "index",
          width: "6%",
          render: (_, record, i) => {
            return (
              <div className="index-id">
                {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
              </div>
            );
          },
        },
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          render: (_, record) => {
            return (
              <div>
                <p>{record.title}</p>
              </div>
            );
          },
        },
        {
          title: "Sub Title",
          dataIndex: "subTitle",
          key: "subTitle",
          render: (_, record) => {
            return (
              <div>
                <p>{record.subTitle}</p>
              </div>
            );
          },
        },
        {
            title: "Content",
            dataIndex: "content",
            key: "content",
            render: (_, record) => {
              return (
                <div>
                  <p>{record.content}</p>
                </div>
              );
            },
          },
        {
          title: "Order No",
          dataIndex: "orderNo",
          key: "orderNo",
          render: (_, record) => {
            return (
              <div>
                <p>{record.orderNo}</p>
              </div>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "isActive",
          render: (_, record) => {
            return (
              <div>
                <span>
                  {" "}
                  {record.isActive ? (
                    <Tag color="green">Active</Tag>
                  ) : (
                    <Tag color="red">inActive</Tag>
                  )}
                </span>
              </div>
            );
          },
        },
        {
          title: "Action",
          key: "action",
          render: (_, record) => {
            return (
              <div className="table-action-link">
                <a style={{ paddingRight: 10 }} onClick={() => editData(record)}>
                  Edit
                </a>
                <Tooltip style={{ padding: 0 }} title="Delete">
                  <IconButton>
                    <Image
                      src={DeleteImage}
                      preview={false}
                      onClick={() => deleteData(record)}
                    ></Image>
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      ];


      const filehandleChange = (file) => {

        if(file.fileList.length){
          if(file.file.type == 'image/jpeg' || file.file.type == 'image/jpg' || file.file.type == 'image/png'){
            setFile(file.fileList)
        formik.values.icon = file.fileList
          }
          else{
            message.error("please upload jpeg, jpg and png formats only ")
          }
        }
        else{
          setFile(file.fileList)
          formik.values.icon = file.fileList
          formik.validateForm()
        }
      }


      const editData = (record) => {
        setCurrentId(record.id)
        setVisible(true)
        dispatch(getKeyFeaturesById(record)).unwrap().then((res) => {
          setFile([{ url: res?.data.iconUrl, thumbUrl: res?.data.iconUrl }])
              let tempValues = JSON.parse(JSON.stringify(res.data))
              formik.values.title =  tempValues.title
              formik.values.subTitle =  tempValues.subTitle
              formik.values['icon'] =  [tempValues['iconUrl']]
              formik.values.content =  tempValues.content
              formik.values.orderNo =  tempValues.orderNo
              formik.values.isActive =  tempValues.isActive == 1 ? true : false
              setVisible(true)
        })
        setCurrentTitle("Edit Key Features")
    
      }
    
      const deleteData = (record) => {
        setCurrentId(record.id)
        setIsModalVisible(true)
      }


      const onSearch = (value) => {
        setFilterData(value);
        let obj = {
          pagination: paginationData,
          searchValue: value,
          limit: limit,
        };
        debounced(obj);
      };
      const debounced = useDebouncedCallback((obj) => {
        dispatch(getAllKeyFeatures(obj));
      }, 1000);
    
      const [selectedRowKeys, setSelectedRowKeys] = useState([]);
      const onSelectChange = (selectData) => {
        setSelectedRowKeys(selectData);
      };
    
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };
    
      const tableOnChange = (pagination, filters, sort, extra) => {
        setPaginationData(pagination?.current - 1);
        let obj = {
          pagination: pagination?.current - 1,
          searchValue: filterData,
          limit: limit,
        };
    
        dispatch(getAllKeyFeatures(obj));
      };


      const openDrawer = () => {
        setCurrentId(null);
        setFile([])
        formik.resetForm({ values: initialValues });
    
        setVisible(true);
        setCurrentTitle("Create Key Features");
      };
    
      const closeSubmitModal = () => {
        setSubmitModalVisible(false);
      };
    
      const showSubmitConfirmation = (values) => {
        setSubmittedData(values);
        setSubmitModalVisible(true);
      };
    
      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
          setVisible(false);
          let data = new FormData();
          data.append("title", formik.values.title)
          data.append("subTitle", formik.values.subTitle)
          data.append("icon", formik.values.icon[0].originFileObj)
          data.append("content", formik.values.content)
          data.append("orderNo", formik.values.orderNo)
          data.append("isActive", formik.values.isActive)

          let payload = {
            data,
            obj: {
              pagination: paginationData,
              searchValue: filterData,
              limit: limit
            }
          }
          setSubmitModalVisible(false);
          dispatch(createKeyFeatures(payload));
        },
      });


      const handleFormValidation = (isValid) => {
        setIsFormValid(isValid);
      };
    
      const closeUpdateModal = () => {
        setUpdateModalVisible(false);
      };
    
      const showUpdateConfirmation = () => {
        setUpdateModalVisible(true);
      };
    
      const updateData = () => {
        let data = new FormData();
        data.append("title", formik.values.title)
        data.append("subTitle", formik.values.subTitle)
        data.append("icon", formik.values.icon[0]?.originFileObj)
        data.append("content", formik.values.content)
        data.append("orderNo",formik.values.orderNo)
        data.append("isActive",formik.values.isActive)
        let datas = {
          id: currentId,
          data,
            obj: {
              pagination: paginationData,
              searchValue: filterData,
              limit: limit
            }
          }
    
        setUpdateModalVisible(false);
        setVisible(false)
        dispatch(updateKeyFeatures(datas))
        formik.resetForm();
      }
    

      const modalOnCancel = () => {
        setIsModalVisible(false);
      };
      const modalOnOk = () => {
        setIsModalVisible(false);
        setIsModalVisible(false)
        let id = currentId
        let data = {
          id: id,
          obj: {
              pagination: paginationData,
              searchValue: filterData,
              limit: limit
            }
          }
        dispatch(deleteKeyFeatures(data));
      };
      const drawerCloser = () => {
        setVisible(false);
        formik.resetForm();
      };
    
      useEffect(() => {
        formik.validateForm();
      }, []);

      return (
        <>
          <div className="admin-page">
            <div className="adminHeader">
              <h3 className="primary-header">Key Features</h3>
              <div className="admin-button">
                <Button
                  className="primary-submit-button"
                  type="primary"
                  onClick={openDrawer}
                >
                  Add Key Features{" "}
                </Button>
              </div>
            </div>
            <div className="all-table-component" style={{ padding: 0, margin: 0 }}>
              <div className="golbalSearch">
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
                  style={{ width: 262, height: 32 }}
                  onChange={(event) => onSearch(event.target.value)}
                />
              </div>
              <TableComponent
                dataSource={keyFeaturesData}
                columns={columns}
                rowSelection={rowSelection}
                tableOnChange={tableOnChange}
                tableHeight={tableHeight}
                totalData={totalDataCount}
                currentPage={paginationData + 1}
                loadingStatus={isLoading}
                limit={limit}
              />
            </div>
            <Drawer
              title={currentTitle}
              onClose={() => drawerCloser()}
              width="518px"
              closable={false}
              closeIcon={false}
              placement="right"
              open={visible}
              footer={
                <div className="footer">
                  <div className="footer-button">
                    <Button
                      className="cancel-Button primary-cancel-button"
                      onClick={drawerCloser}
                    >
                      Cancel
                    </Button>
                    {currentId == null ? (
                      <Button
                        className={
                          !formik.isValid || !formik.dirty
                            ? "disabled-button"
                            : "primary-submit-button"
                        }
                        type="primary"
                        disabled={!formik.dirty || !formik.isValid}
                        onClick={showSubmitConfirmation}
                      >
                        Submit{" "}
                      </Button>
                    ) : (
                      <Button
                        className={
                          !formik.isValid || !formik.dirty
                            ? "disabled-button"
                            : "primary-submit-button"
                        }
                        type="primary"
                        disabled={!formik.isValid || !formik.dirty}
                        onClick={showUpdateConfirmation}
                      >
                        Update{" "}
                      </Button>
                    )}
                  </div>
                </div>
              }
            >
            <div className='basic-form'>
              <Formik validateOnChange={false} validateOnBlur={false}>
                <Form>
                  <div className="form-control">
                    <label htmlFor="title">
                      Title<span style={{ color: "red" }}> *</span>
                    </label>
                    <Input
                      type="text"
                      id="title"
                      placeholder="Enter a Title"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className="errorMessage" style={{ color: "red" }}>
                        {formik.errors.title}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-control">
                    <label htmlFor="subTitle">
                      Sub Title<span style={{ color: "red" }}> *</span>
                    </label>
                    <Input
                      type="text"
                      id="subTitle"
                      placeholder="Enter a Sub Title"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.subTitle}
                    />
                    {formik.touched.subTitle && formik.errors.subTitle ? (
                      <div className="errorMessage" style={{ color: "red" }}>
                        {formik.errors.subTitle}
                      </div>
                    ) : null}
                  </div>
                  <div className='exam-upload-file'>
                  <label htmlFor="icon">
                      Key Features Icon<span style={{ color: "red" }}> *</span>
                    </label>
              <div className='form-control'>
                <Upload
                  className='ImageUpload'
                  listType="picture"
                  onChange={(file) => filehandleChange(file)}
                  beforeUpload={() => false}
                  fileList={file}
                  maxCount={1}
                >
                  <Button className='exam-image-upload-button'  name="icon" onChange={formik.handleChange} onBlur={formik.handleBlur} icon={<UploadOutlined />}>Choose file</Button>
                </Upload>
                {formik.touched.icon && formik.errors.icon ? <div className='error' >{formik.errors.icon}</div> : null}
              </div>
            </div>
            <div className='form-control'>
                <label htmlFor='content'>Content<span style={{ color: "red" }}> *</span></label>
                <TextArea type='text' id='content' placeholder="Type Here" style={{ height:100, resize: 'none' }} onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.content} maxLength={500} />
                {formik.touched.content && formik.errors.content ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.content}</div> : null}

              </div>

                  <div className="form-control">
                    <label htmlFor="orderNo">
                      Order No<span style={{ color: "red" }}> *</span>
                    </label>
                    <Input
                      type="number"
                      id="orderNo"
                      placeholder="Enter a Order No"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.orderNo}
                    />
                    {formik.touched.orderNo && formik.errors.orderNo ? (
                      <div className="errorMessage" style={{ color: "red" }}>
                        {formik.errors.orderNo}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-control">
                    <Checkbox
                      id="isActive"
                      name="isActive"
                      checked={formik.values.isActive}
                      onChange={formik.handleChange}
                      // value={formik.values.isActive}
                    >
                      Active
                    </Checkbox>
                  </div>
                </Form>
              </Formik>
              </div>
            </Drawer>
            <Modal
              title="Deletion"
              open={isModalVisible}
              onCancel={modalOnCancel}
              onOk={modalOnOk}
              footer={
                <div>
                  <Button
                    className="primary-cancel-button"
                    onClick={() => modalOnCancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primary-submit-button"
                    type="default"
                    onClick={() => modalOnOk()}
                  >
                    OK
                  </Button>
                </div>
              }
            >
              <p>Are you sure want to delete ?</p>
            </Modal>
    
            {/* update model */}
            <Modal
              title="Update Confirmation"
              open={isUpdateModalVisible}
              onCancel={closeUpdateModal}
              footer={
                <div>
                  <Button
                    className="primary-cancel-button"
                    onClick={closeUpdateModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primary-submit-button"
                    type="default"
                    onClick={() => updateData(formik.values)}
                  >
                    Update
                  </Button>
                </div>
              }
            >
              <p>Are you sure you want to update?</p>
            </Modal>
    
            {/* submit modal */}
    
            <Modal
              title="Submit Confirmation"
              open={isSubmitModalVisible}
              onCancel={closeSubmitModal}
              footer={
                <div>
                  <Button
                    className="primary-cancel-button"
                    onClick={closeSubmitModal}
                  >
                    Cancel{" "}
                  </Button>
                  <Button
                    className="primary-submit-button"
                    type="default"
                    onClick={formik.handleSubmit}
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                </div>
              }
            >
              <p>Are you sure you want to Submit?</p>
            </Modal>
          </div>
    
          {isLoading ? (
            <div className="loading-container">
              <Loading></Loading>
            </div>
          ) : null}
        </>
      );
    
    

}

export default KeyFeatures