import { Button, Drawer, Form, Image, Input, message, Modal, Select, Tooltip } from 'antd'
import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useDebouncedCallback } from 'use-debounce'
import { Option } from 'antd/lib/mentions';
import { useDispatch, useSelector } from 'react-redux'
import { insertEducatorsDetails, updateEducatorDetails, deleteEducator, getEducatorsTable, getEducatorDetailsById, getClusterFilter } from './reducers/EducatorMasterSlice'
import { getClusterName } from "./reducers/ClusterGroupMasterSlice";
import TableComponent from './Table/tableComponent'
import { IconButton } from '@material-ui/core'
import DeleteImage from '../asset/image/deleteIcon.svg'
import moment from 'moment';
import { Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { costomEllipsize } from "../utils/HelperFunction.js";
import { handleToast, filehandleChangeHelper, handleFileRemoveHelper, getCurrentDate } from "../utils/HelperFunction.js";
import { imageFilter, imageFilterMessage } from "../utils/Variable.js"

import FileUpload from "./Table/FileUpload.js";


const EducatorMaster = () => {

    const dispatch = useDispatch()

    const [visible, setVisible] = useState(false);
    const [filterData, setFilterData] = useState("");
    const [paginationData, setPaginationData] = useState(0);
    const [limit, setLimit] = useState(50);
    const [uploadFile, setFile] = useState([]);
    const roleId = localStorage.getItem("roleId");
    const [currentId, setCurrentId] = useState(null);
    const [currentRecord, setCurrentRecord] = useState({});
    const [currentTitle, setCurrentTitle] = useState("");
    const { isLoading, educatorTableData, tableTotalCount, clusterNameFilter } = useSelector((state) => state.EducatorMasterSlice);
    const { getClusterNameData } = useSelector((state) => state.ClusterGroupMasterSlice);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
    const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [open, setOpen] = useState(true);
    const [inActive, setInActive] = useState(false);
    const [clusterFilterData, setClusterFilterData] = useState("");
    const [deletedAttachment, setDeletedAttachment] = useState([]);


    const tableHeight = 320;

    const handleActiveChange = (checked) => {
        setInActive(checked);
    };

    useEffect(() => {
        dispatch(getEducatorsTable()).unwrap().then((res) => { });
        dispatch(getClusterName());
        dispatch(getClusterFilter())
    }, [])

    const columns = [
        {
            title: 'Educator',
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                },
                multiple: 3,
            },
            render: (_, record) => {
                return (
                    <div>
                        <span style={{ textTransform: 'capitalize' }}>{record.name}</span>
                    </div>
                )
            }
        },
        {
            title: 'Cluster',
            dataIndex: 'clusterMapping',
            key: 'clusterMapping',
            sorter: {
                compare: (a, b) => {
                    const clusterA = a.clusterMapping.filter(Boolean).join(' | ');
                    const clusterB = b.clusterMapping.filter(Boolean).join(' | ');
        
                    if (clusterA < clusterB) return -1;
                    if (clusterA > clusterB) return 1;
                    return 0;
                },
                multiple: 3,
            },
        
            render: (_, record) => {
                const clusterMappingArray = Array.isArray(record?.clusterMapping) ? record.clusterMapping : [];
                const processClusterMapping = clusterMappingArray.filter(Boolean).join(' | ') || '';
                const ellipsizedText = costomEllipsize(processClusterMapping, 30); // adjust the maxLength as needed
            
                return (
                    <Tooltip title={processClusterMapping}>
                        <span style={{ textTransform: 'capitalize' }}>{ellipsizedText}</span>
                    </Tooltip>
                );
            }
            
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            sorter: {
                compare: (a, b) => {
                    if (a.mobileNumber < b.mobileNumber) return -1;
                    if (a.mobileNumber > b.mobileNumber) return 1;
                    return 0;
                },
                multiple: 3,
            },
            render: (_, record) => {
                return (
                    <div>
                        <span style={{ textTransform: 'capitalize' }}>{record.mobileNumber}</span>
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <>
                        {
                            roleId == 1 || roleId == 3 ?
                                <div className='table-action-link'>
                                    <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
                                    {
                                        roleId == 3 ?
                                            <Tooltip title="Delete" style={{ padding: 0 }}>
                                                <IconButton>
                                                    <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                                                </IconButton>
                                            </Tooltip>
                                            : null
                                    }

                                </div>
                                : <div>
                                    Not Authorized
                                </div>
                        }

                    </>

                )
            },
        },
    ];

    const handleEducator = async (values) => {

        values.mobileNumber = String(values.mobileNumber);

        let data = new FormData();
        const { name, email, clusterMapping, mobileNumber, dateOfBirth, attachment } = values;

        data.append("name", name);
        data.append("email", email);
        data.append("isActive", inActive);
        data.append("clusterMapping", clusterMapping);
        data.append("mobileNumber", mobileNumber);
        data.append("dateOfBirth", dateOfBirth);

        const tempFile = uploadFile?.[0] || {};

        if (tempFile) {
            data.append(`file`, tempFile);
        };

        let datas = {
            data,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
            }
        };

        return datas;
    };

    const initialValues = { name: '', email: '', clusterMapping: [], mobileNumber: null, dateOfBirth: '', attachment: "" };

    let validationSchema = yup.object({
        name: yup
            .string()
            .required("Name is Required")
            .min(2, "Name Needed At Least Two characters")
            .max(100, "Name not more than 100 characters"),
        email: yup.string().email('Invalid Email Id').required("Email is Required"),
        clusterMapping: yup
            .array()
            .min(1, "At least one Cluster Name is required")
            .required("Cluster Name is required"),
        dateOfBirth: yup
            .date()
            .required("Date Of Birth is Required")
            .max(new Date(), "Date of Birth cannot be in the future"),
        mobileNumber: yup
            .string()
            .matches(/^[0-9\b]+$/, "Please Don't Enter Any Special Character")
            .trim()
            .min(10, 'Mobile Number Must Be Exactly 10')
            .max(10, 'Mobile Number Must Be Exactly 10')
            .required('Mobile Number is Required')
            .nullable()
    });

    const onSearch = (value) => {
        setFilterData(value);
        let obj = {
            offSet: paginationData,
            searchInput: filterData,
            limit: limit,
            clusterFilter: clusterFilterData,
        };
        debounced(obj);
    };

    const debounced = useDebouncedCallback((obj) => {
        dispatch(getEducatorsTable(obj));
    }, 1000);

    const handleClusterChange = (e, value) => {
        setClusterFilterData(e);
        let obj = {
            offSet: paginationData,
            limit: limit,
            searchInput: filterData,
            clusterFilter: e,
        };

        dispatch(getEducatorsTable(obj));
    };

    const formik = useFormik({
        initialValues,
        validationSchema,

        onSubmit: async (values) => {
            setVisible(false);

           let datas = await handleEducator(values);

            setSubmitModalVisible(false);
            dispatch(insertEducatorsDetails(datas)).unwrap()
                .then((res) => {
                    handleToast(res);
                    !res?.resSuccess ? setVisible(true) : setVisible(false);
                })
                .catch((error) => {
                    setVisible(true);
                });
        }
    })

    const editData = (_data) => {
        formik.resetForm();
        setCurrentId(_data.id);
        setInActive(false);

        dispatch(getEducatorDetailsById(_data.id)).unwrap().then((res) => {

            if (res.success) {

                let { dateOfBirth, isActive, attachmentUrl, attachmentName, ...rest } = res?.data;

                attachmentUrl && attachmentName ? setFile([{ url: attachmentUrl, name: attachmentName, id: _data?.id }]) : setFile([]);

                dateOfBirth = dateOfBirth ? moment(dateOfBirth, "YYYY-MM-DD").format("YYYY-MM-DD") : null
                formik.setValues({
                    ...rest,
                    dateOfBirth,
                });
                setInActive(isActive === 1);
                setVisible(true);
            };
        });
        setCurrentTitle("Edit Educator");
    };

    const deleteData = (record) => {
        setCurrentId(record?.id);
        setIsModalVisible(true);
    };

    const openDrawer = () => {
        setFile([])
        setCurrentId(null)
        setVisible(true)
        setInActive(false);
        setCurrentTitle("New Educator")
        formik.resetForm({ values: initialValues })
    };

    const showUpdateConfirmation = () => {
        setUpdateModalVisible(true);
    };

    const showSubmitConfirmation = (values) => {
        setSubmittedData(values);
        setSubmitModalVisible(true);
    };

    const onModalClose = () => {
        setIsModalVisible(false);
    };

    const confirmDelete = () => {
        setIsModalVisible(false)
        let data = {
            id: currentId,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
            }
        };
        dispatch(deleteEducator(data)).unwrap().then((res) => {
            handleToast(res);
        });
    };

    const tableOnChange = (pagination, filters, sort, extra) => {
        setPaginationData(pagination?.current - 1)

        let obj = {
            offSet: pagination?.current - 1,
            searchInput: filterData,
            limit: limit
        };

        dispatch(getEducatorsTable(obj));
    };

    const closeUpdateModal = () => {
        setUpdateModalVisible(false);
    };

    const closeSubmitModal = () => {
        setSubmitModalVisible(false);
    };

    const updateData = async () => {

        let datas = await handleEducator(formik?.values);
        datas['id'] = currentId;

        setUpdateModalVisible(false);
        setVisible(false)
        dispatch(updateEducatorDetails(datas))
            .unwrap()
            .then((res) => {
                try {
                    handleToast(res);
                    !res?.resSuccess ? setVisible(true) : setVisible(false);
                } catch (error) {
                    setVisible(true);
                    handleToast(res);
                }
            });
    };

    function onClusterName(value) {
        formik.setFieldValue("clusterMapping", value);
    };

    const filehandleChange = async ({ file, fileList }) => {
        await filehandleChangeHelper(imageFilter, imageFilterMessage, file, uploadFile, setFile, formik, 'attachment', 1);
    };

    const handleFileRemove = async (file) => {
        await handleFileRemoveHelper(file, setDeletedAttachment, deletedAttachment, setFile, formik, 'attachment');
    };

    return (

        <>
            <div className='educator-master-container'>
                <div className='header'>
                    <h3 className='primary-header'>Educator Master</h3>
                    <div className='add-exam'>
                        {
                            roleId == 3 ?
                                <span className='add-new-exam'><Button type='primary' className='primary-submit-button m-0' onClick={() => openDrawer()}> New Educator</Button></span>
                                : null
                        }
                    </div>
                </div>
                <div className='all-table-component'>

                    <div className="table-header">
                        <div className="golbalSearch" >
                            <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
                        </div>
                        <div className="cluster-normalPage">
                            <div className="form-control">
                                <label>
                                    <span style={{ color: "#646C7A", fontSize: "13px", fontWeight: "500" }}>Sort by</span>
                                </label>
                            </div>
                            <div className='form-control'>
                                {/* <Select
                                    id="typeOfTestId"
                                    name="typeOfTestId"
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    placeholder="Cluster"
                                    onChange={handleClusterChange}
                                    allowClear
                                >
                                    {[...new Set(educatorTableData.map(record => record.cluster))].map(clusterName => (
                                        <Option key={clusterName} value={clusterName}>
                                            {clusterName}
                                        </Option>
                                    ))}
                                </Select> */}
                                <Select
                                    id="clusterFilter"
                                    name="clusterFilter"
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    placeholder="Cluster"
                                    onChange={handleClusterChange}
                                    allowClear
                                >
                                    {clusterNameFilter.map((clusterName, index) => (
                                        <Option key={index} value={clusterName?.name}>
                                            {clusterName?.name}
                                        </Option>
                                    ))}

                                </Select>
                            </div>
                        </div>
                    </div>

                    <TableComponent dataSource={educatorTableData} columns={columns}
                        tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={tableTotalCount}
                        currentPage={paginationData + 1} limit={limit} loadingStatus={isLoading}
                        />

                </div>
                <Drawer title={currentTitle} onClose={() => setVisible(false)} width="518px" closable={false} closeIcon={false} placement="right" open={visible} footer={(
                    <div className='footer'>
                        <div className='footer-button coachmi-footer'>
                            <Button className='primary-cancel-button coachmi-primary-cancel-button' onClick={() => setVisible(false)} >Cancel</Button >

                            {
                                currentId ?
                                    <Button type="primary"                                                
                                         className={` 
                                        ${!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} coachmi-primary-submit-button`}
                                         onClick={showUpdateConfirmation}>Update</Button>
                                    :
                                    <Button type='primary'
                                    className={` 
                                        ${!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} coachmi-primary-submit-button`}
                                         disabled={(!formik.dirty || !formik.isValid)} onClick={showSubmitConfirmation}>Save</Button>
                            }
                        </div>
                    </div>
                )}>
                    <div className='basic-form'>
                        <>
                            <Form>
                                
                                <div className='form-control'>
                                    <label>Educator Name<span style={{ color: 'red' }}> *</span></label>
                                    <Input maxLength={50} id='name' name='name' placeholder="Enter a Educator Name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
                                    {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
                                </div>

                                <div className='form-control'>
                                    <label htmlFor='email'>Email
                                        <span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <Input maxLength={100} type='text' id='email' placeholder="Enter a Email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                                    {formik.touched.email && formik.errors.email ? <div className='error' style={{ color: 'red' }}>{formik.errors.email}</div> : null}
                                </div>


                                <div className='form-control' style={{ display: "flex", justifyContent: "space-between", columnGap: "16px" }}>
                                    <div className='form-control' style={{ width: '50%' }}>
                                        <label>Date of Birth<span style={{ color: 'red' }}> *</span></label>
                                        <Input type='date' placeholder='Select a Date of Birth' id='dateOfBirth' name='dateOfBirth' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.dateOfBirth} max={getCurrentDate()}></Input>
                                        {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? <div className='error'>{formik.errors.dateOfBirth}</div> : null}
                                    </div>
                                    <div className='form-control' style={{ width: '50%' }}>
                                        <label>Mobile Number<span style={{ color: 'red' }}> *</span></label>
                                        <Input type='number' maxLength={10} id='mobileNumber' name='mobileNumber' placeholder='Enter a Mobile Number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNumber}></Input>
                                        {formik.touched.mobileNumber && formik.errors.mobileNumber ? <div className='error'>{formik.errors.mobileNumber}</div> : null}
                                    </div>
                                </div>

                                <div className='form-control'>
                                    <label htmlFor='roleId'>Cluster Name
                                        <span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <div>
                                        <Select
                                            mode="multiple"
                                            id="clusterMapping"
                                            placeholder="Select Cluster Name"
                                            name="clusterMapping"
                                            style={{ width: "100%" }}
                                            maxTagCount="responsive"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            onChange={onClusterName}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.clusterMapping}
                                        >
                                            {getClusterNameData.map((data) => (
                                                <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>
                                            ))}
                                        </Select>

                                    </div>
                                    {formik.touched.clusterMapping && formik.errors.clusterMapping ? <div className='error' style={{ color: 'red' }}>{formik.errors.clusterMapping}</div> : null}
                                </div>

                                <div className="form-control file-input-btn attachment-upload">
                                    <label className='label-name'>Profile Photo
                                        <span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <div className='attachment-upload'>
                                        <FileUpload
                                            filehandleChange={filehandleChange}
                                            handleFileRemove={handleFileRemove}
                                            file={uploadFile}
                                            allowCount={1}
                                            customButton={
                                                <Button className="attachment-upload-button">
                                                    Drop files to attach or <span className="browse-and-upload">browse and upload</span>
                                                </Button>
                                            }
                                        />
                                    </div>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                                    <Switch
                                        onChange={handleActiveChange}
                                        checked={inActive}
                                        style={{ margin: 0 }}
                                    />
                                    <p style={{ marginRight: '12px', fontSize: '13px', color: '#000000', fontWeight: '500', marginLeft: '12px' }}>
                                      { !inActive ? 'Inactive'  : 'Active' } 
                                    </p>
                                </div>
                            </Form>
                        </>
                    </div>
                </Drawer>
                <Modal title='Deletion' open={isModalVisible} onCancel={onModalClose} footer={
                    <div>
                        <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
                        <Button className='primary-submit-button' type='default' onClick={() => confirmDelete()}>OK</Button>
                    </div>
                }>
                    <p>Are you sure you want to delete <span className='highlight-text'>{currentRecord.groupName} </span>  ?</p>
                </Modal>
                {/* update Confirmation */}

                <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
                    <div>
                        <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
                        <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
                    </div>
                }>
                    <p>Are you sure you want to update?</p>
                </Modal>


                {/* submit confirmation */}
                <Modal title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
                    <div>
                        <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel </Button>
                        <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}>Submit</Button>
                    </div>
                } >
                    <p>Are you sure you want to submit?</p>
                </Modal>

            </div>
        </>


    );

}
export default EducatorMaster;