import React, { useState, useEffect } from "react";
import { Input, DatePicker, Button } from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { getLeadGeneration, downloadLeadGenerationExcel } from "../components/reducers/LeadGenerationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import TableComponent from "./Table/tableComponent";
import Loading from "./Loading";
import moment from "moment";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const { RangePicker } = DatePicker;

const LeadGeneration = () => {
    const dispatch = useDispatch();
    const { LeadGenerationDataCount, isLoading, LeadGenerationData } = useSelector((state) => state.LeadGeneration);
    const [paginationData, setPaginationData] = useState(0);
    const [filterData, setFilterData] = useState("");
    const [limit, setLimit] = useState(50);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);

    useEffect(() => {
        fetchData();
    }, [paginationData, filterData, dateRange]);

    const fetchData = () => {
        let obj = {
            pagination: paginationData,
            searchValue: filterData,
            limit: limit,
            fromDate: dateRange[0] ? dateRange[0].startOf('day').toISOString() : null,
            toDate: dateRange[1] ? dateRange[1].endOf('day').toISOString() : null,
        };
        dispatch(getLeadGeneration(obj));
    };

    const onSelectChange = (selectData) => {
        setSelectedRowKeys(selectData);
    };

    const onSearch = (value) => {
        setFilterData(value);
        fetchData();
    };

    const debounced = useDebouncedCallback((obj) => {
        dispatch(getLeadGeneration(obj));
    }, 1000);

    const onDateChange = (dates) => {
        setDateRange(dates);
    };

    const downloadExcel = () => {
        console.log("download questions count");
        let obj = {
            pagination: paginationData,
            searchValue: filterData,
            limit: limit,
            fromDate: dateRange[0] ? dateRange[0].startOf('day').toISOString() : null,
            toDate: dateRange[1] ? dateRange[1].endOf('day').toISOString() : null,
        };
        dispatch(downloadLeadGenerationExcel(obj))
            .unwrap()
            .then((res) => {
                const buffer = res.data.data
                const arrayBuffer = new Uint8Array(buffer).buffer;
                const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
                const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelFile], { type: 'application/octet-stream' });
                saveAs(blob, 'Exam Questions Count.xlsx');
            });
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const tableOnChange = (pagination, filters, sort, extra) => {
        setPaginationData(pagination?.current - 1);
    };

    const columns = [
        {
            title: "S.No",
            dataIndex: "index",
            key: "index",
            width: "6%",
            render: (_, record, i) => {
                return (
                    <div className="index-id">
                        {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
                    </div>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (_, record) => {
                return (
                    <div>
                        <p>{record.name}</p>
                    </div>
                );
            },
        },
        {
            title: "Mobile Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            render: (_, record) => {
                return (
                    <div>
                        <p>{record.mobileNumber}</p>
                    </div>
                );
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (_, record) => {
                return (
                    <div>
                        <p>{record.email}</p>
                    </div>
                );
            },
        },
        {
            title: "Course Interested",
            dataIndex: "courseName",
            key: "courseName",
            render: (_, record) => {
                return (
                    <div>
                        <p>{record.courseName}</p>
                    </div>
                );
            },
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => {
                return (
                    <div>
                        <span>{moment(record.createdAt).format('DD MMM YYYY')}</span>
                    </div>
                )
            }
        },
    ];

    return (
        <>
            <div className="all-table-component" style={{ padding: 0, margin: 0 }}>
                <div className="adminHeader">
                    <h3 className="primary-header">Lead Generation</h3>
                    <div className="lead-globalSearch" style={{ display: "flex", justifyContent: "space-between", paddingTop: '35px' }} >
                        <Input
                            placeholder="Search"
                            prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
                            style={{ width: 262, height: 32, borderRadius: '4px' }}
                            onChange={(event) => onSearch(event.target.value)}
                        />
                        <div className="date-picker">
                            <RangePicker
                                style={{ marginLeft: 10, height: 32, borderRadius: '4px' }}
                                onChange={onDateChange}
                                value={dateRange}
                            />
                            <Button
                                type="primary"
                                className="primary-submit-button"
                                onClick={() => downloadExcel()}
                            >
                                {" "}
                                Download Excel
                            </Button>
                        </div>
                    </div>
                    <TableComponent
                        dataSource={LeadGenerationData}
                        columns={columns}
                        rowSelection={rowSelection}
                        tableOnChange={tableOnChange}
                        tableHeight={320}
                        totalData={LeadGenerationDataCount}
                        currentPage={paginationData + 1}
                        loadingStatus={isLoading}
                        limit={limit}
                    />
                </div>
            </div>
        </>
    );
};

export default LeadGeneration;
