import { Button, Checkbox, Col, Divider, Drawer, Image, Input, Modal, Row, Select } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import translate from '../asset/image/translate.svg';
import clock from '../asset/image/Alarmclock_light.svg';
import batch from '../asset/image/User_fill.svg';
import { getAllAssignTest, getAssignTestById, updatePublishTest } from './reducers/institutionMasterSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';
import centumLogo from '../asset/image/centumLogo.svg'
import moment from 'moment';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Loading from './Loading';

const PublishedTest = () => {
  const { getAssignTest, isLoading } = useSelector((state) => (state.InstitutionSlice))
  const dispatch = useDispatch()
  const institutionId = localStorage.getItem("institutionId")
  const [currentTitle, setCurrentTitle] = useState("")
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [drawerValues, setDrawerValues] = useState({})
  const navigate = useNavigate();
  const [formDate, setFormDate] = useState(moment());
  const { Option } = Select;
  const [currentId, setCurrentId] = useState(null)

  useEffect(() => {

    dispatch(getAllAssignTest(institutionId));
  }, [])
  const openDrawer = (values) => {
    setVisible(true)
    setDrawerValues(values);
    setCurrentId(values.assignId)
    console.log('values', values.batchId);
    formik.setFieldValue('testDate', moment(values.startedDate).format('YYYY-MM-DD'));
    formik.setFieldValue('isActive', true);
    formik.setFieldValue('assignToCourse', values.assignId);
    formik.setFieldValue('batch', values.batchId);
    formik.setFieldValue('testTime', values.startTime)
    setCurrentTitle("Assign Test")

    console.log('values', formik);

    // dispatch(getAssignTestById(values.inspurtestId)).unwrap().then((res) => {
    //   formik.setFieldValue(res)
    // })
  }

  const viewResultTest = (data) => {
    navigate(`/batchTestResult/${data.courseId}/${data.batchId}/test/${data.assignTestId}/assign/${data.assignId}`)
    localStorage.setItem('publishTest', true)
  }

  const onModalClose = () => {
    setIsModalVisible(false);
  }
  const onModalOpen = () => {
    setIsModalVisible(true);
  }
  const initialValues = {
    testDate: '',
    testTime: '',
    isActive: ''
  }

  let validationSchema = yup.object({
    assignToCourse: yup.string().required("Course is Required"),
    batch: yup.string().required("Batch is Required"),
    testDate: yup.string().required("Test valid Date is Required"),
    testTime: yup.string().required("Test Start Time is Required"),
  })
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })
  function onSubmit(values) {
    console.log('values', formik.isValid);
    setVisible(false)
    setIsModalVisible(false);
    let data = {
      id: currentId,
      testDate: values.testDate,
      startTime: values.testTime,
      shareViaEmail: values.isActive,

    }
    console.log('data', data,);
    dispatch(updatePublishTest(data)).unwrap().then((res) => {
      console.log('res', res)
      if (res.success == true) {
        dispatch(getAllAssignTest(institutionId));
      }
    })

  }
  function onClose(values) {
    setVisible(false);
  }
  const onChangeSelect = (e, fieldHelpers) => {
    fieldHelpers.setValue(e)
  }
  return (
    <>
      {
        isLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> :
          <div className='exam-master-page'>
            <div>
              <div className='exam-header'>
                <div><h3 className='primary-header course-header'>Published Test </h3></div>
              </div>
              <div className='publishedTest-container'>
                <div className='publishedTest-wrapper'>
                  <Row>
                    <>
                      {
                        getAssignTest?.map((data, index) => {
                          return (
                            <Col xs={12} sm={12} md={8} lg={8}>
                              <div className='published-container'>

                                <p className='test-name'><span>group</span></p>
                                <p className='test-group-name'><span>{data.testName}</span></p>
                                <div className='split-container'>
                                  <p className='total-questions'>{data.numberOfQuestions} Questions</p>
                                  <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                                  <p className='total-questions'>{data.totalMark} Marks</p>
                                  <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                                  <p className='total-questions'>{data.timeLimit} Mins</p>

                                </div>
                            
                                <div className='test-details-container'>
                                  <div className='language-content-published'>
                                    <div className='language-image'><Image src={translate} preview={false}></Image></div>
                                    <p className='language-text'><span>English,Tamil</span></p>
                                  </div>
                                  <div className='language-content-published'>
                                    <div className='language-image'><Image src={clock} preview={false}></Image></div>
                                    <p className='language-text'><span>Started Date {moment(data.startedDate).format('DD/MM/YYYY')}</span></p>
                                  </div>
                                  <div className='language-content-published'>
                                    <div className='language-image'><Image src={batch} preview={false}></Image></div>
                                    <p className='language-text'><span>{data.batchName}</span></p>
                                  </div>
                                </div>
                                {
                                  moment(data.startedDate).isBefore(moment()) ? <div className='share-footer'>
                                    <Button className='primary-submit-button view-test-result-button' onClick={() => viewResultTest(data)}>Result</Button>
                                  </div> : <div className='share-footer'>
                                    <Button className='primary-submit-button share-button' onClick={() => openDrawer(data)}>Edit</Button>
                                  </div>
                                }


                              </div>
                            </Col>
                          )
                        })
                      }

                    </>
                  </Row>
                </div>
              </div>
            </div>
            <Drawer closable={false} title={currentTitle} placement="right" onClose={() => onClose(formik.values)} open={visible} footer={(
              <div className='footer'>
                <div className='footer-button'>
                  <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose(formik.values)}>Cancel</Button>
                  <Button type='primary'
                    // className={formik.isValid || formik.dirty ? 'disabled-button' : 'primary-submit-button'} 
                    disabled={!formik.isValid}
                    onClick={() => onModalOpen()} >Publish</Button>

                </div>
              </div>
            )}>
              <div>
                <div className='published-test-container'>
                  <div className='published-test-body'>
                    <p className='published-test-name'><span>{drawerValues.testName}</span></p>
                    <p className='published-test-group-name'><span>(<span>Tamil Medium</span>)</span></p>
                    <div className='split-container'>
                      <p className='total-questions'>{drawerValues.numberOfQuestions} Questions</p>
                      <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                      <p className='total-questions'>{drawerValues.totalMark} Marks</p>
                      <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                      <p className='total-questions'>{drawerValues.timeLimit} Mins</p>
                    </div>
                    <p>Test Remains : <span>{drawerValues.unAssignCount} / {drawerValues.assignCount}</span></p>
                  </div>
                  <div className='published-image-body'>
                    <Image src={centumLogo}></Image>
                  </div>
                </div>
                <div>
                  <form>
                    <div>
                      <div className='assign-date-container'>
                        <div style={{ width: '50%' }}>
                          <div className='form-control'>
                            <label>Test Date <span style={{ color: 'red' }}>*</span></label>
                            <Input type='date' maxLength={50} placeholder='Select a Test Date' id='testDate' name='testDate' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.testDate}></Input>
                            {formik.touched.testDate && formik.errors.testDate ? <div className='error'>{formik.errors.testDate}</div> : null}
                          </div>
                        </div>
                        <div>
                          <div className='form-control test-valid-content'>
                            <label>Start Time <span style={{ color: 'red' }}>*</span></label>
                            <Input type='time' maxLength={50} id='testTime' name='testTime' placeholder='Enter a Start Time' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.testTime}></Input>
                            {formik.touched.testTime && formik.errors.testTime ? <div className='error'>{formik.errors.testTime}</div> : null}
                          </div>
                        </div>
                      </div>
                      <div className='form-control'>
                        <Checkbox onChange={formik.handleChange} checked={formik.values.isActive} name="isActive" >Share via email to students</Checkbox>
                      </div>
                    </div>
                  </form>
                </div>
              </div>


            </Drawer>
            <Modal title='Confirmation' open={isModalVisible} onCancel={onModalClose} footer={
              <div>
                <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
                <Button className='primary-submit-button' type='default' onClick={() => onSubmit(formik.values)}>Confirm</Button>
              </div>
            }>
              <p>Are you sure do you want to Publish the test <span className='highlight-text'> </span>  ?</p>
            </Modal>
          </div>
      }
    </>
  )
}
export default PublishedTest;