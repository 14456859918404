import { Button, Image, Input, Select, Tooltip, Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useDebouncedCallback } from 'use-debounce'
import { Option } from 'antd/lib/mentions';
import { useDispatch, useSelector } from 'react-redux'
import { getClusterName } from './reducers/ClusterGroupMasterSlice.js';
import { getClusterType } from './reducers/clusterCourseSlice.js';
import { insertOtherVideo, updateOtherVideos, deleteOtherVideo, getAllOtherVideo, getOtherVideoDetailsById, getAllEducators, getUniqueOtherVideoCreatedDate } from './reducers/OtherVideoSlice';
import { getTestSeriesExamFilter, getTestSeriesExam } from './reducers/CurriculumSlice';
import TableComponent from './Table/tableComponent'
import { IconButton } from '@material-ui/core'
import DeleteImage from '../asset/image/deleteIcon.svg'
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons'
import '../components/styles/otherVideo.scss';
import { handleToast, filehandleChangeHelper, handleFileRemoveHelper, extractVideoDurationHelper } from '../utils/HelperFunction';
import { videoFilter, videoFilterMessage, VIDEO_STREAM_MAX_SIZE, videoSizeErrorMessage, EFieldStatus, MaxFileCount, ChunkSize } from "../utils/Variable.js";
import { createMultipartUpload, resetState, setTotalParts } from './reducers/MultipartUploadSlice.js';
import { uploadFilePartsHelper, completeFileUploadHelper, updateFileLocationHelper, removePrefix, extractFileLocations } from '../utils/MultipartUploadHelper.js';
import DrawerForm from "./Table/VideoContentDrawer";

const OtherVideo = () => {

    const dispatch = useDispatch();

    const [isDrawerVisible, setDrawerVisible] = useState(false);
    const [filterData, setFilterData] = useState("");
    const [paginationData, setPaginationData] = useState(0);
    const [limit, setLimit] = useState(50);
    let [uploadFile, setFile] = useState([]);
    const roleId = localStorage.getItem("roleId");
    const [currentId, setCurrentId] = useState(null);
    const [currentTitle, setCurrentTitle] = useState("");
    const { isLoading, otherVideoTableData, tableTotalCount, educatorDetails, clusterCreatedAt } = useSelector((state) => state.OtherVideoSlice);
    const { parts, uploadId, uploading, progress } = useSelector(state => state.MultipartUpload);
    const { getTestSeriesExamData } = useSelector((state) => state.CurriculumSlice);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
    const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
    const [commentsOpen, setCommentsOpen] = useState(false);
    const [communityFree, setCommunityFree] = useState(false);
    const [uploadPartCompleted, setUploadPartCompleted] = useState(false);
    const [selectedFile, setSelectedFile] = useState('')
    const [tempObjectKey, setObjectKey] = useState('');
    const [deletedAttachment, setDeletedAttachment] = useState([]);
    const [createdAtFilterData, setCreatedAtFilterData] = useState("");
    const [testMiData, setTestMiData] = useState([
        // Sample test data
        {
            id: 1,
            type: 'Full test',
            exam: "TNPSC",
            group: 'Group 4',
            name: 'CT 1: General Tamil',
            questions: 50,
            marks: 75,
            time: 45
        },
        {
            id: 2,
            type: 'Full test',
            exam: "TNPSC",
            group: 'Group 1',
            name: 'CT 1: General English',
            questions: 50,
            marks: 75,
            time: 45
        },
        {
            id: 3,
            type: 'Full test',
            exam: "TNPSC",
            group: 'Group 1',
            name: 'CT 1: General Maths',
            questions: 200,
            marks: 100,
            time: 60
        },
        {
            id: 4,
            type: 'Full test',
            exam: "TNPSC",
            group: 'Group 2',
            name: 'CT 1: General Tamil',
            questions: 50,
            marks: 75,
            time: 30
        },
        {
            id: 5,
            type: 'Full test',
            exam: "TNPSC",
            group: 'Group 1',
            name: 'CT 1: General English',
            questions: 50,
            marks: 75,
            time: 45
        },
        {
            id: 6,
            type: 'Full test',
            exam: "TNPSC",
            group: 'Group 3',
            name: 'CT 1: General Tamil',
            questions: 50,
            marks: 75,
            time: 45
        },
        {
            id: 7,
            type: 'Full test',
            exam: "TNPSC",
            group: 'Group 4',
            name: 'CT 1: General Tamil',
            questions: 50,
            marks: 75,
            time: 45
        },
        {
            id: 8,
            type: 'Full test',
            exam: "DFO",
            group: 'Group 5',
            name: 'CT 1: General Tamil',
            questions: 50,
            marks: 500,
            time: 45
        },
    ]);
    const [selectedCards, setSelectedCards] = useState([]);
    const [youtubeLinkMapping, setYoutubeLinkMapping] = useState([]);
    const [duration, setDuration] = useState(null);

    const tableHeight = 320;

    const handleCommentsChange = (checked) => {
        setCommentsOpen(checked);
    };

    const handleCommunityFreeChange = (checked) => {
        setCommunityFree(checked);
    };

    useEffect(() => {
        dispatch(getAllOtherVideo()).unwrap().then((res) => { });
        dispatch(getAllEducators());
        dispatch(getUniqueOtherVideoCreatedDate());
        dispatch(getTestSeriesExamFilter());
        dispatch(getTestSeriesExam()).unwrap().then((data)=> {
          setTestMiData(data?.data);
        });
        dispatch(getClusterName());
    }, []);

    useEffect(() => {
        if (uploadPartCompleted && uploadId && tempObjectKey && parts?.length) {
            completeUpload(tempObjectKey, uploadId, selectedFile, parts);
        }
    }, [parts, uploadPartCompleted]);

    const columns = [
        {
            title: 'Other Videos',
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                },
                multiple: 3,
            },
            render: (_, record) => {
                return (
                    <div>
                        <span style={{ textTransform: 'capitalize' }}>{record.name}</span>
                    </div>
                )
            }
        },
        {
            title: 'Cluster Name',
            dataIndex: 'clusterName',
            key: 'clusterName',
            render: (text) => text && text.replace(/\b\w/g, (c) => c.toUpperCase()),
            sorter: {
                compare: (a, b) => {
                    if (a.clusterName < b.clusterName) return -1;
                    if (a.clusterName > b.clusterName) return 1;
                    return 0;
                },
                multiple: 3,
            },
        },
        {
            title: ' Created Date',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: {
                compare: (a, b) => {
                    if (a.updatedAt < b.updatedAt) return -1;
                    if (a.updatedAt > b.updatedAt) return 1;
                    return 0;
                },
                multiple: 3,
            },
            render: (_, record) => {
                return (
                    <div>
                        <span>{moment(record.updatedAt).format('DD MMM YYYY')}</span>
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <>
                        {
                            roleId == 1 || roleId == 3 ?
                                <div className='table-action-link'>
                                    <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
                                    {
                                        roleId == 3 ?
                                            <Tooltip title="Delete" style={{ padding: 0 }}>
                                                <IconButton>
                                                    <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                                                </IconButton>
                                            </Tooltip>
                                            : null
                                    }

                                </div>
                                : <div>
                                    Not Authorized
                                </div>
                        }

                    </>

                )
            },
        },
    ];

    const handleOtherVideoContent = async (values) => {

        const { clusterType, orderNumber, videoName, videoDescription, videoLink, testmiMapping, educatorMapping } = values;

        let linkMapping = [
            { link: videoLink },
            ...youtubeLinkMapping
        ];

        linkMapping = linkMapping?.map(item => {

            let newItem = { ...item };

            if (newItem.fieldStatus === EFieldStatus.NEW) {
                // delete newItem?.pricingId;
            };

            return newItem;
        });

        let fileMapping = extractFileLocations(uploadFile);

        let data = {
            clusterGroupMappingId: clusterType,
            orderId: orderNumber,
            videoName,
            videoDescription,
            videoLinkMapping: linkMapping,
            testmiMapping,
            educatorMapping,
            allComments: commentsOpen,
            freeForCommunity: communityFree
        }

        if (fileMapping?.length) {
            data = {
                ...data,
                files: fileMapping
            }
        };

        let datas = {
            data,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
            }
        };

        return datas;
    };

    const initialValues = {
        clusterName: '',
        clusterType: '',
        orderNumber: '',
        videoName: '',
        videoDescription: '',
        videoLink: '',
        attachment: [],
        testmiMapping: [],
        educatorMapping: []
    };

    const validationSchema = yup.object({
        clusterName: yup.string()
        .required("Cluster Name is Required"),
        clusterType: yup.string()
        .required("Cluster Type is Required"),
        orderNumber: yup.number()
        .required("Order Number is Required"),
        videoName: yup
            .string()
            .required("Video name is required")
            .min(2, "Video name be at least 2 characters long")
            .max(255, "Video name be at most 255 characters long"),
        videoDescription: yup
            .string()
            .required("Description is required")
            .min(2, "Description must be at least 2 characters long"),
        // .max(100, "Description must be at most 100 characters long"),
        // attachment: yup
        //     .array()
        //     .of(yup.string().required("Each attachment is required"))
        //     .min(1, "At least one attachment is required"),
        videoLink: yup
            .string()
            .required("Video link is required"),
        testmiMapping: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number().required("ID is required"),
                    examId: yup.number().nullable(),
                    examGroupId: yup.number().nullable()
                })
            )
            .min(1, "At least one testmi mapping is required"),
        educatorMapping: yup
            .array()
            .of(yup.string().required("Each educator mapping is required"))
            .min(1, "At least one educator mapping is required"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setDrawerVisible(false);
            const datas = await handleOtherVideoContent(values);
            setSubmitModalVisible(false);
            dispatch(insertOtherVideo(datas)).unwrap()
                .then((res) => {
                    handleToast(res);
                    !res?.resSuccess ? setDrawerVisible(true) : setDrawerVisible(false);
                })
                .catch((error) => {
                    setDrawerVisible(true);
                });
        }
    });

    const handleCheckboxChange = (event, checked) => {

        const { examGroupId = null, id, examId = null } = event || {};

        setSelectedCards((prevSelectedCards) => {
            const updatedSelectedCards = checked
              ? [...prevSelectedCards, { id, examId, examGroupId }]
              : prevSelectedCards.filter((i) => i?.id !== id);
      
            formik.setFieldValue('testmiMapping', updatedSelectedCards);
      
            return updatedSelectedCards;
          });
    };

    const editData = (_data) => {

        setCommentsOpen(false);
        setCommunityFree(false);
        setFile([]);
        setYoutubeLinkMapping([]);
        setDeletedAttachment([]);
        setSelectedCards([]);
        setCurrentId(_data.id)
        formik.resetForm({ values: initialValues });
        dispatch(getOtherVideoDetailsById(_data.id)).unwrap().then((res) => {

            if (res?.success) {

                let { allComments, attachmentMapping, description, educatorMapping, freeForCommunity, name, testmiMapping, videoLinkMapping } = res?.data;


                let attachment = attachmentMapping || [];

                setFile(attachment);
                educatorMapping = educatorMapping || [];
                testmiMapping = testmiMapping || [];
                videoLinkMapping = videoLinkMapping || [];

                formik.setValues({
                    videoName: name,
                    videoDescription: description,
                    attachment,
                    educatorMapping,
                    testmiMapping,
                });

                handleYoutubeLink(videoLinkMapping);
                setSelectedCards([...testmiMapping])
                setCommentsOpen(allComments === 1);
                setCommunityFree(freeForCommunity === 1);

                setCurrentTitle("Edit Other Video Content");
                setDrawerVisible(true);
            };
        });
        formik.validateForm();
    };

    const deleteData = (_data) => {
        setCurrentId(_data.id);
        setIsModalVisible(true);
    };

    const openDrawer = () => {
        setCommentsOpen(false);
        setCommunityFree(false);
        setFile([]);
        setYoutubeLinkMapping([]);
        setSelectedCards([]);
        setDeletedAttachment([]);
        setCurrentId(null);
        setDrawerVisible(true);
        setCurrentTitle("New Other Video Content")
        formik.resetForm({ values: initialValues })
    };

    const closeDrawer = () => setDrawerVisible(false);

    const onSearch = (value) => {
        setFilterData(value);
        let obj = {
            offSet: paginationData,
            searchInput: filterData,
            limit: limit,
            createdAtFilter: createdAtFilterData,
        };
        debounced(obj);
    };

    const debounced = useDebouncedCallback((obj) => {
        dispatch(getAllOtherVideo(obj));
    }, 1000);

    const handleCreatedAtChange = (e, value) => {
        setCreatedAtFilterData(e);
        let obj = {
            offSet: paginationData,
            limit: limit,
            searchInput: filterData,
            createdAtFilter: e,
        };
        dispatch(getAllOtherVideo(obj));
    };

    const showUpdateConfirmation = () => {
        setUpdateModalVisible(true);
    };

    const showSubmitConfirmation = (values) => {
        setSubmitModalVisible(true);
    };

    const onModalClose = () => {
        setIsModalVisible(false);
    };

    const confirmDelete = () => {
        setIsModalVisible(false)
        let data = {
            id: currentId,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
            }
        };
        dispatch(deleteOtherVideo(data)).unwrap().then((res) => {
            handleToast(res);
        })
    };

    const tableOnChange = (pagination, filters, sort, extra) => {
        setPaginationData(pagination?.current - 1)

        let obj = {
            offSet: pagination?.current - 1,
            searchInput: filterData,
            limit: limit,
        };

        dispatch(getAllOtherVideo(obj));
    };

    const closeUpdateModal = () => {
        setUpdateModalVisible(false);
    };

    const closeSubmitModal = () => {
        setSubmitModalVisible(false);
    };

    const updateData = async () => {

        const datas = await handleOtherVideoContent(formik?.values);

        datas['id'] = currentId;

        if (deletedAttachment?.length) {
            datas['data'].deletedAttachment = deletedAttachment;
        };

        setUpdateModalVisible(false);
        setDrawerVisible(false);

        dispatch(updateOtherVideos(datas))
            .unwrap()
            .then((res) => {
                try {
                    handleToast(res);
                    !res?.resSuccess ? setDrawerVisible(true) : setDrawerVisible(false);
                } catch (error) {
                    setDrawerVisible(true);
                }
            });
    };

    const handleAttachmentChange = async ({ file, fileList }) => {
        setSelectedFile('');
        setUploadPartCompleted(false);
        dispatch(resetState());
        const fileResponse = await filehandleChangeHelper(videoFilter, videoFilterMessage, file, uploadFile, setFile, formik, 'attachment', MaxFileCount.otherVideoAttachment, true, VIDEO_STREAM_MAX_SIZE, videoSizeErrorMessage);
        if (fileResponse) {
           const fileDuration = await extractVideoDurationHelper(file);
           setDuration(fileDuration);
           await startUpload(file);
        }
    };

    const handleAttachmentFileRemove = async (file) => {
        await handleFileRemoveHelper(file, setDeletedAttachment, deletedAttachment, setFile, formik, 'attachment');
    };

    const addYoutubeLink = (values) => {
        const { videoLink = '-' } = values;
        setYoutubeLinkMapping([...youtubeLinkMapping, { link: videoLink }]);
        formik.setFieldValue('videoLink', '')
    };

    const deleteYoutubeLink = (values, index) => {

        const updatedLink = youtubeLinkMapping?.filter((_, i) => i !== index);
        setYoutubeLinkMapping(updatedLink);

        // if (values?.fieldStatus !== EFieldStatus.NEW) {
        //   setDeletedYoutubeLink([]);
        // };
    };

    const handleYoutubeLink = async (links) => {

        if (links?.length) {

            const { link = '' } = links?.[0];

            formik.setFieldValue('videoLink', link);

            const remainingLinks = links?.slice(1)?.map?.((item) => item);

            setYoutubeLinkMapping(remainingLinks);
        }
    };

    const startUpload = async (selectedFile) => {

        const objectname = selectedFile.name;
        setSelectedFile(selectedFile);

        try {
            const response = await dispatch(createMultipartUpload(objectname)).unwrap();
            let { uploadId, objectKey } = response;
            objectKey = removePrefix(objectKey);
            setObjectKey(objectKey);
            const partSize = ChunkSize;
            const totalParts = Math.ceil(selectedFile.size / partSize);
            await dispatch(setTotalParts({ value: totalParts }));
            await uploadFilePartsHelper(selectedFile, objectKey, uploadId, totalParts, partSize, dispatch);
            setUploadPartCompleted(true);
        } catch (error) { }
    };

    const completeUpload = async (objectKey, uploadId, selectedFile, parts) => {
        try {
            let response = await completeFileUploadHelper(objectKey, uploadId, parts, dispatch);
            const { data, success } = response;
            if (success) {
                const updatedAttachment = await getAttachmentFiles();
                await updateFileLocationHelper(selectedFile, updatedAttachment, setFile, data, formik, 'attachment');

                const fileIndex = updatedAttachment?.findIndex(f => f?.uid === selectedFile?.uid);

                if (fileIndex !== -1) {

                    const updatedFile = {
                        uid: updatedAttachment[fileIndex]?.uid,
                        name: updatedAttachment[fileIndex]?.name,
                        size: updatedAttachment[fileIndex]?.size,
                        type: updatedAttachment[fileIndex]?.type,
                        fileLocation: { ...data, duration }
                    };

                    await new Promise(resolve => {
                        setFile(prevFiles => {
                            const updatedFiles = [...prevFiles];
                            updatedFiles[fileIndex] = updatedFile;
                            resolve(updatedFiles);
                            return updatedFiles;
                        });
                    });

                    const getAttachment = await getAttachmentFiles();
                    await formik.setFieldValue('attachment', getAttachment);
                };
            };
            dispatch(resetState());
            setSelectedFile('');
            setUploadPartCompleted(false);
            setDuration(null);
        } catch (error) {
        };
    };

    const getAttachmentFiles = async () => {
        return await new Promise(resolve => {
            setFile(prevFiles => {
                resolve(prevFiles);
                return prevFiles;
            });
        });
    };

    const onChangeCluster = (value) => {
        formik.setFieldValue("clusterName", value);
        formik.setFieldValue("clusterType", '');
        dispatch(getClusterType(value))
    };

    return (

        <>
            <div className='exam-group-container'>
                <div className='header'>
                    <h3 className='primary-header'>Courses</h3>
                    <div className='add-exam'>
                        {
                            roleId == 3 ?
                                <span className='add-new-exam'><Button type='primary' className='primary-submit-button video-content-btn' onClick={() => openDrawer()}>New Other Video Content</Button></span>
                                : null
                        }
                    </div>
                </div>
                <div className='all-table-component'>

                    <div className="table-header">
                        <div className="golbalSearch" >
                            <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
                        </div>
                        <div className="cluster-normalPage">
                            <div className="form-control">
                                <label>
                                    <span style={{ color: "#646C7A", fontSize: "13px", fontWeight: "500" }}>Sort by</span>
                                </label>
                            </div>
                            <div className='form-control'>
                                <div>
                                    <Select
                                        id="createdAtFilter"
                                        name="createdAtFilter"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        placeholder="Date Created"
                                        onChange={handleCreatedAtChange}
                                        allowClear
                                    >
                                        {clusterCreatedAt?.map((date, index) => (
                                            <Option key={index} value={date.date}>
                                                {date.date}
                                            </Option>
                                        ))}

                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <TableComponent dataSource={otherVideoTableData} columns={columns} limit={limit}
                        tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={tableTotalCount}
                        currentPage={paginationData + 1} loadingStatus={isLoading} />

                </div>

                <DrawerForm
                    title={currentTitle}
                    formik={formik}
                    visible={isDrawerVisible}
                    isOtherVideoContent={true}
                    commentsOpen={commentsOpen}
                    communityFree={communityFree}
                    attachmentFile={uploadFile}
                    testMiData={testMiData}
                    educatorDetails={educatorDetails}
                    selectedCards={selectedCards}
                    youtubeLinkMapping={youtubeLinkMapping}
                    currentId={currentId}
                    uploading={uploading}
                    progress={progress}
                    attachmentFileCount={MaxFileCount.otherVideoAttachment}
                    handleCommunity={handleCommunityFreeChange}
                    handleComments={handleCommentsChange}
                    onClose={closeDrawer}
                    submit={showSubmitConfirmation}
                    update={showUpdateConfirmation}
                    testmiTextBoxChanges={handleCheckboxChange}
                    handleAttachmentChange={handleAttachmentChange}
                    handleAttachmentFileRemove={handleAttachmentFileRemove}
                    addYoutubeLink={addYoutubeLink}
                    deleteYoutubeLink={deleteYoutubeLink}
                    onChangeCluster={onChangeCluster}
                />

                {/* delete Confirmation */}

                <Modal title='Deletion' open={isModalVisible} onCancel={onModalClose} footer={
                    <div>
                        <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
                        <Button className='primary-submit-button' type='default' onClick={() => confirmDelete()}>OK</Button>
                    </div>
                }>
                    <p>Are you sure you want to delete </p>
                </Modal>

                {/* update Confirmation */}

                <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
                    <div>
                        <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
                        <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
                    </div>
                }>
                    <p>Are you sure you want to update?</p>
                </Modal>

                {/* submit confirmation */}
                <Modal title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
                    <div>
                        <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel </Button>
                        <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}>Submit</Button>
                    </div>
                } >
                    <p>Are you sure you want to submit?</p>
                </Modal>
            </div>
        </>
    );
};

export default OtherVideo;