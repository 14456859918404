import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import TableComponent from "./Table/tableComponent";
import Loading from "./Loading";
import moment from 'moment';
import { getCareerDetails } from "../components/reducers/CareerSlice";

const CareerDetails = () => {
  useEffect(() => {
    let obj = {
        pagination: paginationData,
        searchValue: '',
        limit: limit,
      };
    dispatch(getCareerDetails(obj));
  }, []);

  const dispatch = useDispatch();
  let tableHeight = 320;
  const { CareerDetailsDataCount, isLoading, CareerDetailsData } = useSelector(
    (state) => state.CareerSlice
  );
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData);
  };

  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getCareerDetails(obj));
  }, 1000);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
    };

    dispatch(getCareerDetails(obj));
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "6%",
      render: (_, record, i) => {
        return (
          <div className="index-id">
            {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return (
          <div>
            <p>{record.name}</p>
          </div>
        );
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (_, record) => {
        return (
          <div>
            <p>{record.mobileNumber}</p>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => {
        return (
          <div>
            <p>{record.email}</p>
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, record) => {
        return (
          <div>
            <p>{record.description}</p>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        return (
          <div>
            <p>{moment(record.createdAt).format('DD MMM YYYY')}</p>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="all-table-component" style={{ padding: 0, margin: 0 }}>
        <div className="adminHeader">
          <h3 className="primary-header">Career Details</h3>
          <div className="golbalSearch">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
              style={{ width: 262, height: 32 }}
              onChange={(event) => onSearch(event.target.value)}
            />
          </div>
          <TableComponent
            dataSource={CareerDetailsData}
            columns={columns}
            rowSelection={rowSelection}
            tableOnChange={tableOnChange}
            tableHeight={tableHeight}
            totalData={CareerDetailsDataCount}
            currentPage={paginationData + 1}
            loadingStatus={isLoading}
            limit={limit}
          />
        </div>
      </div>
    </>
  );
};

export default CareerDetails;
