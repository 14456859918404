import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

export const createLeads = createAsyncThunk("leads/createLeads",async (payload,thunk) => {
    try {
      let leads = await axios.post(`leads/createLeads`, payload.data)
        .then(async (res) => {
          if (res.data.status) {
            message.success(res.data.message)
            await thunk.dispatch(getAllLeads(payload.obj))

            return res.data;
          }
        });
      return leads;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);
export const getLeadsById = createAsyncThunk("leads/getLeadsById",async (payload) => {
    try {
      let leads = await axios.get(`leads/getLeadsById/${payload}`).then((res) => {
          if (res) {
            return res.data;
          }
        });
      return leads;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getAllLeads = createAsyncThunk("leads/getAllLeads",async (payload) => {
    try {
            let leads = await axios.post(`leads/getAllLeads`, payload).then((res) => {
          if (res.data.success) {
            return res.data;
          }
        });
      return leads;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const updateLeads = createAsyncThunk("leads/updateLeads", async (payload, thunk) => {
  try {
    let leads = await axios.put(`leads/updateLeads/${payload.id}`, payload.data).then(async (res) => {
      if (res.status) {
        message.success(res.data.message)
      await thunk.dispatch(getAllLeads(payload.obj))        
      return res.data
      }
    })
    return leads
  } catch (error) {
    message.error(error.response.data.message)

  }
})

export const deleteLeads = createAsyncThunk('leads/deleteLeads', async (payload, thunk) => {
  try{
      let leads = await axios.delete(`leads/deleteLeads/${payload.id}`).then(async (res) => {
          if (res.data.success) {
            message.success(res.data.data.message)
            await thunk.dispatch(getAllLeads(payload.obj))   
            return res.data     
        }
        })
        return leads
  }
    catch(error){
      message.error(error.response.data.message)
       }
      })




const initialState = {
    leadData:[],
    isLoading: false,
    totalDataCount:0
};

const LeadsSlice = createSlice({
    name: "LeadsSlice",
    initialState,
    extraReducers: {
      [getAllLeads.pending]:(state)=>{
          state.isLoading=true
      },
      [getAllLeads.fulfilled]:(state,actions)=>{
        state.leadData = actions.payload.data.getLeadData
        state.totalDataCount = actions.payload.data.getLeadCount.leadCount
          state.isLoading=false
      },
      [getAllLeads.rejected]:(state)=>{
          state.isLoading=false
      },
      [createLeads.pending]:(state)=>{
        state.isLoading=true
    },
    [createLeads.fulfilled]:(state,actions)=>{
        state.isLoading=false
    },
    [createLeads.rejected]:(state)=>{
        state.isLoading=false
    },
    [getLeadsById.pending]:(state) =>{
      state.isLoading=true
    },
    [getLeadsById.fulfilled]:(state) =>{
      state.isLoading=false
    },
    [getLeadsById.rejected]:(state) =>{
      state.isLoading=false
    },
    [updateLeads.pending]:(state) =>{
      state.isLoading=true
  },
  [updateLeads.fulfilled]:(state,actions) =>{
      state.isLoading=false
  },
  [updateLeads.rejected]:(state) =>{
      state.isLoading=false
  },
  [deleteLeads.pending]:(state) =>{
      state.isLoading=true
  },
  [deleteLeads.fulfilled]:(state,actions) =>{
      state.isLoading=false
  },
  [deleteLeads.rejected]:(state) =>{
      state.isLoading=false
  },
    }
})

    export default LeadsSlice.reducer