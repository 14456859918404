
import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Drawer, Form, Image, Input, message, Modal, Select, Tag, Tooltip, Upload } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import * as yup from 'yup';
import { Option } from 'antd/lib/mentions';
import { useDispatch, useSelector } from 'react-redux'
import TableComponent from './Table/tableComponent'
import { IconButton } from '@material-ui/core'
import DeleteImage from '../asset/image/deleteIcon.svg'
import moment from 'moment'
import Loading from './Loading'
import { getAllNotificationData, addNotification, updateNotificationData, deleteNotificationData, getNotificationById } from "../components/reducers/WebsiteNotificationSlice";



const WebsiteNotification = () => {

    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const [filterData, setFilterData] = useState("")
    const [paginationData, setPaginationData] = useState(0);
    const [limit, setLimit] = useState(50);
    const [file, setFile] = useState([]);
    const roleId = localStorage.getItem("roleId")
    const [currentId, setCurrentId] = useState(null)
    const [currentRecord, setCurrentRecord] = useState({})
    const [currentTitle, setCurrentTitle] = useState("")
    const { socialMediaMasterData } = useSelector((state) => state.Community)
    const { currentData, isLoading, currentDataCount, NotificationData } = useSelector(
        (state) => state.WebsiteNotificationSlice
    );
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
    const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const tableHeight = 320

    const columns = [
        {
            title: "S.No",
            dataIndex: "index",
            key: "index",
            width: '6%',
            render: (_, record, i) => {
                return (
                    <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
                    </div>
                )
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => {
                return (
                    <div>
                        <span style={{ textTransform: 'capitalize' }}>{record.title}</span>
                    </div>
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (_, record) => {
                return (
                    <div>
                        <span>{moment(record.updatedAt).format('DD MMM YYYY')}</span>
                    </div>
                )
            }
        },
        {
            title: 'Active / InActive',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => {
                return (
                    <div>
                        <span> {record.isActive ?
                            <Tag color="green">Active</Tag> :
                            <Tag color="red">InActive</Tag>
                        }</span>
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <>
                        {
                            roleId == 1 || roleId == 3 ?
                                <div className='table-action-link'>
                                    <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
                                    {
                                        roleId == 3 ?
                                            <Tooltip title="Delete" style={{ padding: 0 }}>
                                                <IconButton>
                                                    <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                                                </IconButton>
                                            </Tooltip>
                                            : null
                                    }
                                </div>
                                : <div>
                                    Not Authorized
                                </div>
                        }
                    </>
                )
            },
        },
    ]

    const initialValues = { title: '', notificationLink: '', uploadNotification: [], isActive: false, }

    let validationSchema = yup.object({
        title: yup
            .string()
            .required("title Name is Required")
            .min(2, "title Name Needed At Least 2 characters")
            .max(100, "title Name not more than 100 characters"),
        //   uploadNotification: yup.array().min(1, "Logo is Required").required("Logo is Required").nullable(),
    })
    const closeSubmitModal = () => {
        setSubmitModalVisible(false);
    };

    const showSubmitConfirmation = (values) => {
        setSubmittedData(values);
        setSubmitModalVisible(true);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            setVisible(false);
    
            let data = new FormData();
            setSubmitModalVisible(false);
            if (file.length > 0 && file[0].originFileObj) {
                data.append("uploadNotification", file[0].originFileObj);
            }
            if (values.notificationLink && values.notificationLink.trim().length > 0) {
                data.append("notificationLink", values.notificationLink.trim());
            }
            data.append("title", values.title);
            data.append("isActive", values.isActive ? 1 : 0);
    
            console.log(data);
            let datas = {
                data,
                obj: {
                    pagination: paginationData,
                    searchValue: filterData,
                    limit: limit
                }
            };

            dispatch(addNotification(datas));
        }
    });
 

    const onSearch = (value) => {
        setFilterData(value)
        let obj = {
            pagination: paginationData,
            searchValue: value,
            limit: limit
        }
        debounced(obj)
    }
    const debounced = useDebouncedCallback((obj) => {
        dispatch(getAllNotificationData(obj))
    }, 1000)

    const filehandleChange = (file) => {
        if (file.fileList.length) {
            if (file.file.type == "application/pdf") {
                setFile(file.fileList);
                formik.values.uploadNotification = file.fileList;
            } else {
                message.error("please upload .pdf formats only ");
            }
        } else {
            setFile(file.fileList);
            formik.values.uploadNotification = file.fileList;
            formik.validateForm();
        }
    };

    const openDrawer = () => {
        setFile([])
        setCurrentId(null)
        setVisible(true)
        setCurrentTitle("Create Notification")
        formik.resetForm({ values: initialValues })
    }

    const editData = (_data) => {
        setCurrentId(_data.notificationId)
        dispatch(getNotificationById(_data.notificationId)).unwrap().then((res) => {
            if (res.status) {
                setFile([{ url: res?.data?.fileUrl }])
                let tempValues = JSON.parse(JSON.stringify(res.data))
                formik.values['uploadNotification'] = tempValues['fileUrl']
                formik.values.notificationLink = tempValues.notificationLink
                formik.values.title = tempValues.title
                formik.values.isActive = tempValues.isActive
            }
        })
        setVisible(true)
        setCurrentTitle("Edit Notification")
    }
    const deleteData = (_data) => {
        setCurrentRecord(_data)
        setIsModalVisible(true)
        setCurrentId(_data.notificationId)
    }

    const closeUpdateModal = () => {
        setUpdateModalVisible(false);
    };

    const showUpdateConfirmation = () => {
        setUpdateModalVisible(true);
    };

    const updateData = () => {
        let data = new FormData();
        data.append("uploadNotification", formik.values?.uploadNotification[0]?.originFileObj)
        data.append("notificationLink", formik.values.notificationLink.trim())
        data.append("title", formik.values.title)
        data.append("isActive", formik.values.isActive == true ? 1 : 0)
        let datas = {
            id: currentId,
            data,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit

            }
        }
        setUpdateModalVisible(false);
        setVisible(false)
        dispatch(updateNotificationData(datas)).unwrap().then((res) => {
            try {
                if (res.status) {
                    setVisible(false)
                }
            }
            catch (error) {
                setVisible(true)
            }
        })
    }

    const onModalClose = () => {
        setIsModalVisible(false);
    }

    const confirmDelete = () => {
        setIsModalVisible(false)
        let data = {
            id: currentId,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
            }
        }
        dispatch(deleteNotificationData(data)).unwrap().then((res) => {
        })
    }

    const tableOnChange = (pagination, filters, sort, extra) => {
        setPaginationData(pagination?.current - 1)
        let obj = {
            pagination: pagination?.current - 1,
            searchValue: filterData,
            limit: limit
        }
        dispatch(getAllNotificationData(obj));
    }

    useEffect(() => {
        let obj = {
            pagination: paginationData,
            searchValue: filterData,
            limit: limit
        }
        dispatch(getAllNotificationData(obj))
    }, [])


    return (
        <>
            <div className='community-container'>
                <div className='header'>
                    <h3 className='primary-header'>Website Notification</h3>
                    <div className='add-exam'>
                        {
                            roleId == 3 ?
                                <span className='add-new-exam'><Button type='primary' className='primary-submit-button' onClick={() => openDrawer()}>Add Notification</Button></span>
                                : null
                        }
                    </div>
                </div>
                <div className='all-table-component'>
                    <div className="golbalSearch" >
                        <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
                    </div>
                    <TableComponent
                        dataSource={currentData}
                        columns={columns}
                        tableOnChange={tableOnChange}
                        tableHeight={tableHeight}
                        totalData={currentDataCount}
                        currentPage={paginationData + 1}
                        loadingStatus={isLoading}
                        limit={limit}
                    />
                </div>

                <Drawer title={currentTitle} onClose={() => setVisible(false)} width="500px" closable={false} closeIcon={false} placement="right" open={visible} footer={(
                    <div className='footer'>
                        <div className='footer-button'>
                            <Button className='primary-cancel-button' onClick={() => setVisible(false)} >Cancel</Button >
                            {
                                currentId ?
                                    <Button type="primary" className={(!formik.isValid || !formik.dirty) ? 'disabled-button' : 'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showUpdateConfirmation}>Update</Button>
                                    :
                                    <Button type='primary' className={(!formik.isValid || !formik.dirty) ? 'disabled-button' : 'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showSubmitConfirmation}>Submit</Button>
                            }
                        </div>
                    </div>
                )}>
                    <div className='basic-form'>
                        <>
                            <Form>
                                <div className='form-control'>
                                    <label>Title<span style={{ color: "red" }}> *</span></label>
                                    <Input maxLength={50} id='title' name='title' placeholder="Enter a Title Name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.title}></Input>
                                    {formik.touched.title && formik.errors.title ? <div className='error'>{formik.errors.title}</div> : null}
                                </div>
                                <div className="form-control">
                                    <label htmlFor="notificationLink">
                                        Notification Link
                                    </label>
                                    <Input
                                        type="text"
                                        id="notificationLink"
                                        placeholder="Enter a Notification Link"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.notificationLink}
                                    />
                                </div>
                                <div className='exam-upload-file'>
                                    <label htmlFor="uploadNotification">
                                        Upload PDF
                                    </label>
                                    <div className='form-control'>
                                        <Upload
                                            className='FileUpload'
                                            listType="picture-card"
                                            onChange={(file) => filehandleChange(file)}
                                            beforeUpload={() => false}
                                            fileList={file}
                                            maxCount={1}
                                        >
                                            <Button className='exam-image-upload-button' name="uploadNotification" onChange={formik.handleChange} onBlur={formik.handleBlur} icon={<UploadOutlined />}>Choose file</Button>
                                        </Upload>
                                        {/* {formik.touched.uploadNotification && formik.errors.uploadNotification ? <div className='error' >{formik.errors.uploadNotification}</div> : null} */}
                                    </div>
                                </div>
                                <div className='form-control'>
                                    <Checkbox onChange={formik.handleChange} checked={formik.values.isActive} name="isActive" >Active</Checkbox>
                                </div>
                            </Form>
                        </>
                    </div>
                </Drawer>
                <Modal title='Deletion' open={isModalVisible} onCancel={onModalClose} footer={
                    <div>
                        <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
                        <Button className='primary-submit-button' type='default' onClick={() => confirmDelete()}>OK</Button>
                    </div>
                }>
                    <p>Are you sure you want to delete <span className='highlight-text'>{currentRecord.communityName} </span>  ?</p>
                </Modal>
                {/* update Confirmation */}

                <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
                    <div>
                        <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
                        <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
                    </div>
                }>
                    <p>Are you sure you want to update?</p>
                </Modal>


                {/* submit confirmation */}
                <Modal title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
                    <div>
                        <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel </Button>
                        <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}>Submit </Button>
                    </div>
                } >
                    <p>Are you sure you want to submit?</p>
                </Modal>
            </div>
            {
                isLoading ? <div className='loading-container'>
                    <Loading></Loading>
                </div> : null
            }
        </>
    )
}

export default WebsiteNotification