import React, { useEffect, useState } from 'react';
import TableComponent from './Table/tableComponent';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate, useParams } from 'react-router-dom';
import { getExamByExamGroupId } from './reducers/masterSlice';

function SubjectMaster() {

  const dispatch = useDispatch()
  const params = useParams()
  const { examDataByGroup, isLoading } = useSelector((state) => state.MasterSlice)
  const navigate = useNavigate();
  let tableHeight = 320
  const [filterData, setFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    }
    let data = {
      examGroupId: +params?.examGroupId,
      obj
    }
    dispatch(getExamByExamGroupId(data))
  }, [])

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Exam Name',
      dataIndex: 'examName',
      key: 'examName',
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate(`/SingleTestExamExam/${record.examId}`);
          },
        };
      },
    },
    {
      title: 'Exam Group Name',
      dataIndex: 'examGroupName',
      key: 'examGroupName',
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate(`/SingleTestExamExam/${record.examId}`);
          },
        };
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => {
        return (
          <div>
            <span>{moment(record.updatedAt).format('DD MMM YYYY')}</span>
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate(`/SingleTestExamExam/${record.examId}`);
          },
        };
      },
    }
  ]

  const search = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    }
    let data = {
      examGroupId: +params?.examGroupId,
      obj
    }
    debounced(data)
  }
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getExamByExamGroupId(obj))
  }, 400)

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)
  }

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <>
      <div className='subject-master-page'>
        <div>
          <div className='subject-header'>
            <div className='subject-header-wrapper'>
              <div>
                <h3 className='primary-header'>Exam Master</h3>
              </div>
            </div>
          </div>
          <div className="all-table-component">
            {
              !params?.statusName ?
                <div className="golbalSearch" >
                  <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => search(event.target.value)} />
                </div>
                : null
            }
          </div>

        </div>
        <div>
          {
            <TableComponent dataSource={examDataByGroup} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={examDataByGroup.length} currentPage={paginationData + 1} loadingStatus={isLoading} limit={limit} />
          }
        </div>
      </div>
      {
        isLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> : null
      }
    </>
  )
}

export default SubjectMaster;
