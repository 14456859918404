import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

export const createStats = createAsyncThunk("Stats/createStats",async (payload,thunk) => {
    try {
      let stats = await axios.post(`stats/createStats`, payload.data)
        .then(async (res) => {
          if (res.data.status) {
            message.success(res.data.message)
            await thunk.dispatch(getAllStats(payload.obj))

            return res.data;
          }
        });
      return stats;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getStatsById = createAsyncThunk("Stats/getStatsById",async (payload) => {
    try {
      let stats = await axios.get(`stats/getStatsById/${payload.id}`).then((res) => {
          if (res) {
            return res.data;
          }
        });
      return stats;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getAllStats = createAsyncThunk("Stats/getAllStats",async (payload) => {
    try {
            let stats = await axios.post(`stats/getAllStats`, payload).then((res) => {
          if (res.data.success) {
            return res.data;
          }
        });
      return stats;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);


export const updateStats = createAsyncThunk("Stats/updateStats", async (payload, thunk) => {
    try {
      let stats = await axios.put(`stats/updateStats/${payload.id}`, payload.data).then(async (res) => {
        if (res.status) {
          message.success(res.data.message)
        await thunk.dispatch(getAllStats(payload.obj))        
        return res.data
        }
      })
      return stats
    } catch (error) {
      message.error(error.response.data.message)
  
    }
  })

  export const deleteStats = createAsyncThunk('Stats/deleteStats', async (payload, thunk) => {
    try{
        let stats = await axios.delete(`stats/deleteStats/${payload.id}`).then(async (res) => {
            if (res.data.success) {
              message.success(res.data.data.message)
              await thunk.dispatch(getAllStats(payload.obj))   
              return res.data     
          }
          })
          return stats
    }
      catch(error){
        message.error(error.response.data.message)
         }
        })
    
  

const initialState = {
    statsData:[],
    isLoading: false,
    totalDataCount:0
};

const StatsSlice = createSlice({
  name: "StatsSlice",
  initialState,
  extraReducers: {
    [createStats.pending]:(state)=>{
        state.isLoading=true
    },
    [createStats.fulfilled]:(state,actions)=>{
        state.isLoading=false
    },
    [createStats.rejected]:(state)=>{
        state.isLoading=false
    },
    [getAllStats.pending]:(state) =>{
        state.isLoading=true
    },
    [getAllStats.fulfilled]:(state,actions) =>{
      state.statsData=actions.payload.data.getStatsData
      state.totalDataCount=actions.payload.data.getStatsCount.StatsCount
      state.isLoading=false
    },
    [getAllStats.rejected]:(state) =>{
        state.isLoading=false
    },
    [getStatsById.pending]:(state) =>{
      state.isLoading=true
    },
    [getStatsById.fulfilled]:(state) =>{
      state.isLoading=false
    },
    [getStatsById.rejected]:(state) =>{
      state.isLoading=false
    },
    [updateStats.pending]:(state) =>{
        state.isLoading=true
    },
    [updateStats.fulfilled]:(state,actions) =>{
        state.isLoading=false
    },
    [updateStats.rejected]:(state) =>{
        state.isLoading=false
    },
    [deleteStats.pending]:(state) =>{
        state.isLoading=true
    },
    [deleteStats.fulfilled]:(state,actions) =>{
        state.isLoading=false
    },
    [deleteStats.rejected]:(state) =>{
        state.isLoading=false
    },
    
  },
});

export default StatsSlice.reducer;
