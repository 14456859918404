/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Image,
  Tabs,
  message,
} from "antd";
import axios from "axios";
import { FieldArray, Field, Formik, getIn, useFormik } from "formik";
import { DeleteOutlined } from "@ant-design/icons";
import BuildEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const DynamicQuesionArray = (props) => {
    let {formik ,index , onCheckArr} = props
    const questionQuill = useRef();
    const solutionQuill = useRef();
    const arrayOfQuestion = useRef([]);
  const arrayOfSolution = useRef([]);
  const [arrayAnswer, setArrayAnswer] = useState([]);
  const [answer, setAnswer] = useState(false);
  const editorContainerRef = useRef();
  const [currentEquation,setCurrentEquation] = useState(arrayOfQuestion);

let  realEditor ;
let listOfAnswer= [];
  const [isQuestionFormula, setIsQuestionFormula] = useState(false);
  const [isOpenMath,setIsOpenMath]=useState(false)

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image','formula'
      ]
    const container = [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image','formula'],
        ['clean']
      ]

      const questionTextImage=()=>{ 
        const input = document.createElement('input');  
        input.setAttribute('type', 'file');  
        input.setAttribute('accept', 'image/*'); 
        input.addEventListener('change', async(e)=>{
        const formData =  new FormData()
        formData.append('file',e.target.files[0])
        insertUploadImage(arrayOfQuestion,formData)
        });
        input.click(); 
      }
      const solutionImage=()=>{ 
        const input = document.createElement('input');  
        input.setAttribute('type', 'file');  
        input.setAttribute('accept', 'image/*'); 
        input.addEventListener('change', async(e)=>{
        const formData =  new FormData()
        formData.append('file',e.target.files[0])
        insertUploadImage(arrayOfSolution,formData)
        });
        input.click(); 
      }
      const questionEquation = ()=>{
          setIsQuestionFormula(true)
          setCurrentEquation(arrayOfQuestion)
          setIsOpenMath(true)
      
      }
      const solutionEquation = ()=>{
        setIsOpenMath(true)
        setIsQuestionFormula(true)
        setCurrentEquation(arrayOfSolution)
        
      }

      const questionModule = useMemo(() => (
      {
      toolbar: {
        container,
        handlers: {
          image: questionTextImage,
          formula:questionEquation,
      }
      }
      }
      ),[]) 
      const solutionModule = useMemo(() => (
        {
        toolbar: {
          container,
          handlers: {
            image: solutionImage,
          formula:solutionEquation,
      
          }
        }
        }
        ),[]) 
    const insertUploadImage = async(quill,formData) =>{
    await axios.post('/masters/customFileUpload',formData).then((res)=>{
        if(res.data.status){
            const editor = quill.current[index].getEditor();
            var range = editor.getSelection();
            if(res.data.status){
                range = !range ? editor.getLength() : range.index
                editor.insertEmbed(range, 'image', res.data.data.url);
            }
        }
    }).catch((error)=>{
    })
    }
    const arrayOfQuestionOnChange = (value ,indexArg)=>{
        // console.log("changes  in ",value ,indexArg)
        let tempValues = JSON.parse(JSON.stringify(formik.values))
        tempValues.questions[indexArg]['questionText'] =  value
        formik.setValues(tempValues)
      
      }
      const arrayOfSolutionOnChange = (value ,indexArg)=>{
        let tempValues = JSON.parse(JSON.stringify(formik.values))
        tempValues.questions[indexArg]['solution'] =  value
        formik.setValues(tempValues)
      
      }
      const addDynamicNewRow = (indexArg) => {
        let tempValues = JSON.parse(JSON.stringify(formik.values))
        tempValues?.questions[indexArg]['questionOption'].push({ questionOption: '', id: null, value: false })
        formik.setValues(tempValues)
      }
    //   console.log("arrayAnswer ",arrayAnswer)

      const optionsDynamicOnChecked = (indexArg,i, e) => {
        // console.log("optionsDynamicOnChecked == ",indexArg,i,e)
        let tempValues = JSON.parse(JSON.stringify(formik.values))
        tempValues.questions[indexArg].questionOption.forEach((option, indexArg) => {
          if (e.target.checked) {
            if (indexArg != i) {
              option['value'] = false
            }
          }
        })
        tempValues.questions[indexArg]['questionOption'][i]['value'] = e.target.checked
        // if(e.target.checked){
          // setAnswer(true)
        //   console.log("e.target.checked",e.target.checked)
          setArrayAnswer(prevArray =>{
            const newArray = [...prevArray]; // create a copy of the previous array
            newArray[indexArg] = e.target.checked ? e.target.checked : false; // update the value at the specified index
            return newArray; // r

        });
        // listOfAnswer = JSON.parse(JSON.stringify(arrayAnswer))
        // console.log("arrayAnswer ",e.target.checked )
        onCheckArr(e.target.checked ,indexArg,i)


        // }
        // else{
        //   setAnswer(false)
    
        // }
    
        formik.setValues(tempValues)
      }
      const optionsDynamicOnChange = (indexArg,i, e) => {
        let tempValues = JSON.parse(JSON.stringify(formik.values))
        tempValues.questions[indexArg]['questionOption'][i]['questionOption'] = e.target.value
        formik.setValues(tempValues)
      }
      const dynamicRemove = (indexArg,i) => {
        let tempValues = JSON.parse(JSON.stringify(formik.values))
        if(tempValues.questions[indexArg]['questionOption'][i]['value'] == true){
          setAnswer(false)
        }
        tempValues.questions[indexArg]['questionOption'].splice(i, 1)
        formik.setValues(tempValues)
    
      }
      const onQuestionCancel = () => {
        setIsQuestionFormula(false)
    
      }
      const onQuestionOk = () => {
        setIsQuestionFormula(false)
    
      }
      // const showData = async() =>{
      //   setIsQuestionFormula(false)
      //   setIsOpenMath(false)  
      
      //   await axios.post('question/equationUpload',{data : realEditor.getMathML()}).then((res)=>{
      //       const questionEditor = currentEquation.current[index].getEditor();
      //       var range = questionEditor.getSelection();
      //       if(res.data.status){
      //           range = !range ? questionEditor.getLength() : range.index
      //           questionEditor.insertEmbed(range, 'image', res.data.url);
      //       }
      //   })
      // }
      useEffect(() => {
        const wirisEditor = com.wiris.jsEditor.JsEditor.newInstance({'language': 'en','toolbar':'chemistry'});
        if(editorContainerRef?.current){
          wirisEditor.insertInto(editorContainerRef?.current);
        }
         realEditor =  wirisEditor
      },[isOpenMath]);

      const checkSelectedAnswer = () =>{
        let data = formik.values?.questions[index].questionOption
        if(data.length){
          for(let i=0 ;i<data.length;i++){
            if(data[i].value){
              return true
            }
          }
        }
        return false
      }

      function uploadAdapter(loader) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              loader.file.then((file) => {
                formData.append("file", file);
                axios
                  .post("/masters/customFileUpload", formData)
                  .then((res) => {
                    const imageUrl = res.data.data.url;
                    // setUploadImage(imageUrl);/
                    resolve({ default: imageUrl });
                  })
                  .catch((error) => {
                    // console.log(error);
                    reject(error);
                  });
              });
            });
          },
        };
      }
      
      function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
      }
      const addDynamicEquation  = ()=>{
        setIsQuestionFormula(true)
        // setCurrentEquation(questionQuill)
        setIsOpenMath(true)
      
      }
      const showData = async(index) =>{
        setIsQuestionFormula(false)
        setIsOpenMath(false)  
        await axios.post('question/equationUpload',{data : realEditor.getMathML()}).then((res)=>{
            if(res.data.status){
                const imageUrl = res.data.url;
                const editor = currentEquation?.current?.editor ? currentEquation.current.editor : currentEquation.editor ;
                editor.execute( 'insertImage', { source: imageUrl } );
            }
        })
      }
  return(
    <div>
     <div className='form-control'>
        <label htmlFor={`questionText.${index}`}>
        Question Text  :
        </label>
        <CKEditor
                    editor={ BuildEditor }
                    name="questionText"
                    id='questionText'
                    // ref={comprehensionQuill}
                    ref={el => arrayOfQuestion.current[index] = el}
                    config={{
                     removePlugins: [ 'CKFinder' ],  
                      extraPlugins: [uploadPlugin],
                    
                    }} 
                          
                    onReady={ editor => {
                    } }
                    
                    data={formik.values?.questions[index].questionText}
                    onChange={(event, editor) =>{
                      const questionDataText = editor.getData();
                      arrayOfQuestionOnChange(questionDataText,index)
                }
                } 

                onFocus={ ( event, editor ) => {
                  setCurrentEquation(arrayOfQuestion.current[index])
              } }
                />
                <div className="add-equation-wrapper">
                <Button type="primary" className='primary-submit-button' onClick={() => addDynamicEquation()}>Add Equation </Button>
                {/* <Button type="primary" className='primary-submit-button' onClick={() => checkButton()}>Check Button </Button> */}
                </div>
        {/* <ReactQuill name="questionText" ref={el => arrayOfQuestion.current[index] = el} theme="snow" id='questionText' modules={questionModule} formats={formats} placeholder="Question Text" onChange={(value) => arrayOfQuestionOnChange(value,index)} value={formik.values?.questions[index].questionText} /> */}
        <Modal title="Equation"  className='save-equation' open={isQuestionFormula} onCancel={onQuestionCancel} onOk={onQuestionOk}  >
                <div ref={editorContainerRef} />    
                <Button type="primary" className='primary-submit-button' onClick={() => showData(index)}>Save Equation</Button>

              </Modal>
        </div>
<div>
    {
         !checkSelectedAnswer() ? 
        
        <span className='answer-option-error-message'> Answer Must be select in the below option</span> 

        : null
    }

    {
    formik.values && formik.values?.questions[index].questionOption ?
        formik.values?.questions[index].questionOption.map((option, i) => {
        const questionOption = `questionOption[${index}][${i}]`;
        const touchedQuestion = getIn(formik.touched, questionOption);
        const errorQuestion = getIn(formik.errors, `questions[${index}].questionOption[${i}].questionOption`);
        return (
            <div className='question-option-wrapper' key={i}>

            <div className="formik-question-option">
                <Checkbox style={{ marginLeft: 10, marginRight: 10}} onChange={(e) => { optionsDynamicOnChecked(index,i, e) }} checked={formik.values?.questions[index].questionOption[i]['value']}  ></Checkbox>
                <div className='formik-input-field'>
                <Input type="text" id={`questionOption.${index}.${i}`} name={`questionOption.${index}.${i}`} onChange={(e) => { optionsDynamicOnChange(index ,i, e) }} onBlur={formik.handleBlur} placeholder="Options" value={formik.values?.questions[index].questionOption[i]['questionOption']} ></Input>
                </div>
                <DeleteOutlined style={{ marginLeft: 10, marginRight: 10 }} onClick={() => dynamicRemove(index,i)} />
            </div>
            {touchedQuestion && errorQuestion ? <div className='errorMessage answer-option-error-message' style={{ color: 'red' }}>{errorQuestion}</div> : null}

            </div>
        )
        })
        : ''
    }
    <div>
    <span className='add-option' onClick={() => { addDynamicNewRow(index) }}>+ Add Option</span>
    </div>
</div>
    <div className='form-control solution'>
    <label htmlFor={`solution.${index}`}>
    Solution  :
    </label>
    <CKEditor
                    editor={ BuildEditor }
                    name="solution"
                    id='solution'
                    // ref={comprehensionQuill}
                    ref={el => arrayOfSolution.current[index] = el}
                    config={{
                     removePlugins: [ 'CKFinder' ],  
                      extraPlugins: [uploadPlugin],
                    
                    }} 
                          
                    onReady={ editor => {
                    } }
                    
                    data={formik.values?.questions[index].solution}
                    onChange={(event, editor) =>{
                      const solutionDataText = editor.getData();
                      arrayOfSolutionOnChange(solutionDataText,index)
                }
                } 

                onFocus={ ( event, editor ) => {
                  setCurrentEquation(arrayOfSolution.current[index])
              } }
                />
    
        {/* <ReactQuill name="solution" ref={el => arrayOfSolution.current[index] = el} theme="snow" id='solution' modules={solutionModule} formats={formats} placeholder="Solution Text" onChange={(value) => arrayOfSolutionOnChange(value,index)} value={formik.values?.questions[index].solution} /> */}
    
    </div>
    </div>
  )
};

export default DynamicQuesionArray;
