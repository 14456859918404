import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";


export const getLeadGeneration = createAsyncThunk('products/getLeadGeneration', async (payload) => {
    try {
        let getAllData = await axios.post('/products/getLeadGeneration',payload).then((res) => {
            if (res.data) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
        console.log(error, 'err');
    }
})


export const downloadLeadGenerationExcel = createAsyncThunk(
    'products/downloadLeadGenerationExcel',async (payload)=>{
      try{
        let download = await axios.post('/products/downloadLeadGenerationExcel',payload)
        .then((res) => {
          message.success(res.data.message)
         return res.data;
        })
        return download
      }
      catch(error){
  
      }
    }
  )


const initialState = {
    isLoading: false,
    LeadGenerationData: [],
    LeadGenerationDataCount: 0
};
const LeadGenerationSlice = createSlice({
    name: "LeadGeneration",
    initialState,
    extraReducers: {
        [getLeadGeneration.pending]: (state) => {
            state.isLoading = true

        },
        [getLeadGeneration.fulfilled]: (state, actions) => {
            state.isLoading = false
            state.LeadGenerationData = actions.payload.getleadGenData
            state.LeadGenerationDataCount = actions.payload.getleadGenCount.leadGenCount
        },
        [getLeadGeneration.rejected]: (state) => {
            state.isLoading = false

        },
        [downloadLeadGenerationExcel.pending]: (state) => {
            state.isLoading = true;
        },
        [downloadLeadGenerationExcel.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [downloadLeadGenerationExcel.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
})

export default LeadGenerationSlice.reducer;
