import React, { useState,useEffect } from 'react';
import { Image, Button, Drawer,Input,Select,Modal,Spin } from 'antd';
import importImage from '../asset/image/Import.svg';
import { DownOutlined,LoadingOutlined } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import TableComponent from './Table/tableComponent';
import { Formik, isInteger, useFormik} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DeleteImage from '../asset/image/deleteIcon.svg';
import * as yup from 'yup';
import { getSubscriptionMasterTable,insertSubscriptionMasterTable,editSubscriptionMasterTable,deleteSubscriptionMasterTable} from './reducers/SubscriptionMasterSlice';
import { useDebouncedCallback } from 'use-debounce';
const { Option } = Select;


function SubscriptionMaster(){
  
  const dispatch=useDispatch();
  const antIcon = (<LoadingOutlined style={{fontSize: 24,}}spin/>);
  const [filterData, setFilterData] = useState("");
  const [visible, setVisible] = useState(false);
  const [editVisible,setEditVisible]=useState(false);
  const [isModalVisible,setIsModalVisible]=useState(false)
  const [disabledButton, setDisabled] = useState(false);
  const [SubscriptionDetails,setSubscriptionDetails]=useState('');
  const [editId,setEditId]=useState();
  const [record,setRecord]=useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(10);

  let tableHeight = 320
  const{SubscriptionMasterTableData,isLoading,totalDataCount}=useSelector((state)=>state.SubscriptionMaster)
  useEffect(()=>{
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit:limit
    }
    dispatch(getSubscriptionMasterTable(obj))
  },[])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'No of test',
      dataIndex: 'noOfTest',
      key: 'subjectName',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'No of Custom Test',
      dataIndex: 'noOfCustomTest',
      key: 'subjectName',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
    }, 
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'subjectName',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <div className='table-action-link'>
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
            <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
          </div>
        )
      },
    },
  ]

  const editData = (record) => {
    setEditVisible(true)
    formik.values.name=record.name;
    formik.values.noOfTest=record.noOfTest;
    formik.values.price=record.price;
    formik.values.noOfCustomTest=record.noOfCustomTest
    setEditId(record.id)
  }

  function  deleteData (record)  {
    setRecord(record)
    setIsModalVisible(true)
  }
  function confirmDelete(){
    setIsModalVisible(false)
    let id=record.id
    dispatch(deleteSubscriptionMasterTable(id))
  }
  function onModalClose(){
    setIsModalVisible(false);
  }


  const onSubmit = () => {
    setVisible(false);
    dispatch(insertSubscriptionMasterTable(SubscriptionDetails))
    formik.values.name='';
    formik.values.noOfTest='';
    formik.values.price='';
    formik.values.noOfCustomTest=''
  }

  const validate = (values) => {
    let errors = {}
    if (!values.name) {
      errors.name = "Name is required"
      setDisabled(false);
    }
    if(!values.price){
      errors.price="Price is required";
      setDisabled(false);
    }
    if(!values.noOfCustomTest){
      errors.noOfCustomTest="Customize test is required";
      setDisabled(false);
    }
    if(!values.noOfTest){
      errors.noOfTest="Test is required";
      setDisabled(false);
    }
    else if(errors.price==null && errors.name==null && errors.noOfCustomTest==null && errors.noOfTest==null){
      setDisabled(true);
      setSubscriptionDetails(values)
    }
    return errors;
  }
  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit:limit

    }
    debounced(obj)
  }
  const debounced = useDebouncedCallback((obj) =>{
    dispatch(getSubscriptionMasterTable(obj))

  },1000)
  const initialValues = { name: '',noOfTest:'',noOfCustomTest:'',price:'' }

  function onClose() {
    setVisible(false);
    setEditVisible(false);
    setDisabled(false);
    formik.values.name='';
    formik.values.noOfTest='';
    formik.values.price='';
    formik.values.noOfCustomTest='';
    formik.errors.name=null;
    formik.errors.noOfTest='';
    formik.errors.price='';
    formik.errors.noOfCustomTest='';
    formik.touched.name=null
    formik.touched.noOfTest=null;
    formik.touched.price=null;
    formik.touched.noOfCustomTest=null;

  }

  function onEditSubmit(){
    let editingData={
      name:formik.values.name,
      noOfTest:formik.values.noOfTest,
      noOfCustomTest:formik.values.noOfCustomTest,
      price:formik.values.price,
      id:editId
    }
    dispatch(editSubscriptionMasterTable(editingData));
    setEditVisible(false)
    formik.values.name='';
    formik.values.noOfTest='';
    formik.values.price='';
    formik.values.noOfCustomTest='';
    setDisabled(false);

  }
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }



  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit:limit

    }

    dispatch(getSubscriptionMasterTable(obj))
  }

const subcriptionMasterSchema=yup.object({
  noOfTest: yup.number().required('No of Test is Required').typeError('No of Test must be a number'),
  noOfCustomTest: yup.number().required('No of Custom Test is Required').typeError('No of Custom Test must be a number'),
  price: yup.number().required('Price is Required').typeError('Price must be a number'),

})
const formik = useFormik({
  initialValues,
  validationSchema:subcriptionMasterSchema,
  onSubmit,
  validate,
})

  return(
    <div className='subscription-master-page'>
    <div>
      <div className='subscription-header'>
        <div><h3 style={{fontWeight:600,fontSize:18,color:'#1B2228'}}>Subscription Master</h3></div>
        <div className='add-subscription'>
        <span className='add-new-subscription'><Button type='primary' className='primary-submit-button' onClick={() => (setVisible(true))}> New subscription</Button></span>
      </div>
      </div>
      <div className="golbalSearch" >
          <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event)=>onSearch(event.target.value)} />
        </div>

      {/* Add subscription drawer */}

      <Drawer closable={false}  title="New subscription" placement="right" onClose={onClose} open={visible} footer={(
        <div className='footer'>
        <div className='footer-button'>
        <Button className='primary-cancel-button' style={{marginRight:42.5}} onClick={() => onClose()}>Cancel</Button>
        <Button disabled={disabledButton == false ? true : false} type='primary' onClick={() => onSubmit()}>Submit</Button>
        </div>
      </div>
      )}>
        <div className='drawer-content'>
          <form onSubmit={formik.handleSubmit}>
            <div className='drawer-content-subject'>Name</div>
            <Input  id='name' name='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
            {formik.touched.name && formik.errors.name ? <span className='error'>{formik.errors.name}</span> : null}
            <div style={{marginBottom:16,marginTop:16,display:'flex',justifyContent:'space-between'}}>
              <div style={{width:'48%'}}>
              <div><label>No. of test</label></div>
              <Input type="number" maxLength={2} id='noOfTest' name='noOfTest' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noOfTest}></Input>
              {formik.touched.noOfTest && formik.errors.noOfTest ? <span className='error'>{formik.errors.noOfTest}</span> : null}
              </div>
              <div style={{width:'48%'}}>
                <div><label>No. of Customize Test</label></div>
              <Input type="number" maxLength={2}  id='noOfCustomTest' name='noOfCustomTest' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noOfCustomTest}></Input>
              {formik.touched.noOfCustomTest && formik.errors.noOfCustomTest ? <span className='error'>{formik.errors.noOfCustomTest}</span> : null}
              </div>
            </div>
            <label>Price</label>
            <Input type="number" id='price' name='price' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.price}></Input>
            {formik.touched.price && formik.errors.price ? <span className='error'>{formik.errors.price}</span> : null}
          </form>
        </div>
        <div className='drawer-footer'>
        </div>
      </Drawer>

      {/* Edit subscription master drawer*/}

      <Drawer closable={false}  title="Edit subscription" placement="right" onClose={onClose} open={editVisible} footer={(
         <div className='footer'>
         <div className='footer-button'>
         <a className='footer-cancel' onClick={() => onClose()}>Cancel</a>
         <Button disabled={disabledButton == false ? true : false} type='primary' onClick={() => onEditSubmit()}>Update</Button>
         </div>
       </div>
      )}>
        <div className='drawer-content'>
          <form onSubmit={formik.handleSubmit}>
            <div className='drawer-content-subject'>Name</div>
            <Input id='name' name='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
            {formik.touched.name && formik.errors.name ? <span className='error'>{formik.errors.name}</span> : null}
            <div style={{marginBottom:16,marginTop:16,display:'flex',justifyContent:'space-between'}}>
              <div style={{width:'48%'}}>
              <div><label>No. of test</label></div>
              <Input id='noOfTest'type="number" name='noOfTest' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noOfTest}></Input>
              {formik.touched.noOfTest && formik.errors.noOfTest ? <span className='error'>{formik.errors.noOfTest}</span> : null}
              </div>
              <div style={{width:'48%'}}>
                <div><label>No. of Customize Test</label></div>
              <Input id='noOfCustomTest' type="number" name='noOfCustomTest' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noOfCustomTest}></Input>
              {formik.touched.noOfCustomTest && formik.errors.noOfCustomTest ? <span className='error'>{formik.errors.noOfCustomTest}</span> : null}
              </div>
            </div>
            <label>Price</label>
            <Input id='price' type="number" name='price' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.price}></Input>
            {formik.touched.price && formik.errors.price ? <span className='error'>{formik.errors.price}</span> : null}
          </form>
        </div>
      </Drawer>
    </div>
      {/* delete subscription modal */}

      <Modal title="Deletion" open={isModalVisible} onOk={()=>confirmDelete()} onCancel={()=>onModalClose()}>
        <p>Are you sure want to delete?</p>
        </Modal>

        <div>
        <TableComponent dataSource={SubscriptionMasterTableData}  columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight}  totalData={totalDataCount} currentPage={paginationData + 1} loadingStatus={isLoading} />
      </div>
    </div>
  )
}

export default SubscriptionMaster;