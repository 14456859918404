import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import axios from "axios";
import { getAllTypeData } from "./dashboardSlice";


//  get All Question Bank Page
export const getAllQuestion = createAsyncThunk("QuestionBank/getAllData", async (payload, thunk) => {
  try {
    if (payload?.questionTypeId == 2) {
      let getComprehenQuestion = await axios.post(`previousYearQuestions/getPreviousComphrensiveQuestion/${payload?.previousYearExamId}`, payload.obj).then((res) => {
        if (res.data.success) {
          return res.data.data
        }
      })
      return getComprehenQuestion
    } else {
      let url = `/previousYearQuestions/getQuestion/${payload?.questionTypeId}/${payload?.previousYearExamId}`
      let getAll = await axios.post(url, payload.obj).then((res) => {
        if (res.data.success) {
                    return res.data.data
        }
      })
      return getAll
    }
  } catch (error) {
    console.log('error', error)
  }
})



// Add Question Master Data -- sep 21
export const addQuestionData = createAsyncThunk('previousYearQuestion/addPreviousQuestion', async (payload, thunk) => {
  try {
    let payloadData = []
    let questionData = new FormData();
    if (payload.payload.questionTypeId == 1) {
      questionData.append("file", payload.payload.file)
      questionData.append("questionText", payload.payload.questionText)
      questionData.append("solution", payload.payload.solution)
      questionData.append("questionOption", JSON.stringify(payload.payload.questionOption))
      questionData.append("questionTypeId", payload.payload.questionTypeId)
      questionData.append("previousYearExamId", payload.payload.previousYearExamId)
    } else {
      questionData.append("file", payload.payload.file)
      questionData.append("questionTypeId", payload.payload.questionTypeId)
      questionData.append("questionParagraphText", payload.payload.questionParagraphText)
      questionData.append("questions", JSON.stringify(payload.payload.questions))
      questionData.append("previousYearExamId", payload.payload.previousYearExamId)
    }
    await axios.post('/previousYearQuestions/createQuestion', questionData).then(async (res) => {
      if (res.data.data.status) {
        await thunk.dispatch(getAllQuestion(payload.obj))
        message.success(res.data.data.message)
      } else {
        message.error(res.data.createAdminUser.message)
      }
    })
    return payloadData
  }
  catch (error) {
  }
})

// get All Question Bank  get By Id Page
export const getQuestionById = createAsyncThunk("previousYearQuestions/getById", async (payload) => {
  try {
    let getById = await axios.get('previousYearQuestions/getPreviousQuestion/' + payload).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getById

  } catch (error) {
  }
})

export const getPreviousYearExamById = createAsyncThunk(
  'PreviousYearExam/getPreviousYearExamById', async (payload) => {
    let tabledata = []
    let tableData = await axios.get(`previousYearExam/getPreviousYearExam/${payload}`)
    .then((res) => {
      tabledata = res.data.data;
    })
    .catch((error) => {
    })
  return tabledata;
  }
)




//update.............

export const updateQuestionData = createAsyncThunk(
  'previousYearQuestions/updateQuestionData', async (payload, thunk) => {
    let tabledata = []
    if (payload.obj.questionTypeId == 1) {
      let updateData = await axios.put(`previousYearQuestions/updateQuestionData/${payload.previousYearExamId}/${payload.id}`, payload.data)
        .then(async (res) => {
          message.success(res.data.data.message)
          if (res.data.data.status) {
            await thunk.dispatch(getAllQuestion(payload.obj))
          }
          tabledata = res.data.updateExam;
        })
        .catch((error) => {
          let insertError = error.response.data.updateExam
          message.error(insertError.message);
        })
      return tabledata;
    } else {
      let updateData = await axios.put(`previousYearQuestions/updateComprehensiveQuestion/${payload.id}`, payload.data)
        .then(async (res) => {
          message.success(res.data.data.message)
          if (res.data.data.status) {
            await thunk.dispatch(getAllQuestion(payload.obj))
          }
          tabledata = res.data.updateExam;
        })
        .catch((error) => {
          let insertError = error.response.data.updateExam
          message.error(insertError.message);
        })
      return tabledata;
    }
  }
)



//DELETE
export const deleteQuestionData = createAsyncThunk('QuestionBank/deleteData', async (payload, thunk) => {
  try {
    let payloadId = payload.currentId
    const deleteData = await axios.delete(`/previousYearQuestions/deleteQuestionData/${payload.previousYearExamId}/${payload.id}`, payload).then(async (res) => {
      if (res.data.data.status) {
        await thunk.dispatch(getAllQuestion(payload))
        message.success(res.data.data.message)

        // return res.data.data.message
      } else {
        message.error(res.data.data.message)
      }
    })
    return payloadId
  } catch (error) {
  }


})
// Add Question selection Master Data
export const addQuestionSelectionData = createAsyncThunk('QuestionSelection/addData', async (payload, thunk) => {
  try {
    const insertData = await axios.post('/testMaster/createTestQuestionSelection', payload).then(async (res) => {
      if (res.data.data.status) {
        message.success(res.data.data.message)
        return res.data.data.data
      } else {
        message.error(res.data.data.message)
      }
    })
    return insertData
  } catch (error) {
  }


})

// get Question selection by test id Master Data
export const getQuestionSelectionByTestId = createAsyncThunk('QuestionSelection/GetByTestId', async (payload, thunk) => {
  try {
    const getData = await axios.get('testMaster/getQuestionSelectionByTestId/' + payload).then(async (res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getData
  } catch (error) {
  }


})


// question bank bulk upload

export const excelUpload = createAsyncThunk(
  'AdminMaster/excelUpload', async (payload, thunk) => {
    try {
      let excel = await axios.put(`question/questionExcel/${payload.examId}/${payload.subjectId}/${payload.chapterId}`, payload.excelFile).then(async (res) => {

        if (res?.data?.success) {

          message.success(res.data.message)
          let data = {
            obj: {
              pagination: 0,
              searchValue: "",
              limit: 10

            },
            examId: payload.examId,
            chapterId: payload.chapterId,
            subjectId: payload.subjectId,
            status: payload.status
          }
          await thunk.dispatch(getAllQuestion(data))
        }




      })
    } catch (error) {
    }


  }
)


// get Question selection by test id Master Data 
export const ApprovalQuestionStatus = createAsyncThunk('QuestionSelection/ApprovalQuestionStatus', async (payload, thunk) => {
  try {
    let questionTypeUrl = payload.questionTypeId == 2 ? 'approvedStatusComprehensiveQuestion' : 'approvedStatusQuestion';
    const getData = await axios.put(`previousYearQuestions/${questionTypeUrl}/${payload.questionId}/${payload.statusId}`, payload.data).then(async (res) => {
      if (res.data.success) {
        await thunk.dispatch(getAllQuestion(payload.getAllData))
        return res.data

      }
    })
    return getData
  } catch (error) {
  }


})


export const getPreYearCompreQuestionById = createAsyncThunk("PreviousYearQuestionBank/getPreYearCompreQuestionById", async (payload) => {
  try {
    let getComprehenQuestionById = await axios.get(`previousYearQuestions/getPreviousComphrensiveQuestion/${payload}`).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getComprehenQuestionById
  }
  catch (error) {
    console.log('error', error)
    // message.error(res.data.data.message)
  }
})


//  get All Question Bank  get By Id Page
export const getComprehensionById = createAsyncThunk("QuestionBank/getComprehensionById", async (payload) => {
  try {
    let getById = await axios.get('question/getComprehenQuestion/' + payload).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getById

  } catch (error) {
  }
})


//DELETE
export const deleteComprehensionData = createAsyncThunk('QuestionBank/deleteComprehensionData', async (payload, thunk) => {
  try {
    let payloadId = payload.currentId
    const deleteData = await axios.delete(`/previousYearQuestions/deleteQuestionDataComp/${payload.previousYearExamId}/${payload.id}`, payload).then(async (res) => {
      if (res.data.data.status) {
        await thunk.dispatch(getAllQuestion(payload))
        message.success(res.data.data.message)
      } else {
        message.error(res.data.data.message)
      }
    })
    return payloadId
  } catch (error) {
  }


})

// export const getAllExamList = createAsyncThunk('getAllExamList', async (payload, thunk) => {
//   try {
//     let getAllData = await axios.get(`masters/getAllExamList`).then((res) => {
//       if (res.data.status) {
//         return res.data.data
//       }
//     })
//     return getAllData

//   } catch (error) {

//   }
// })
export const getAllSubjectList = createAsyncThunk('getAllSubjectList', async (payload, thunk) => {
  try {
    let getAllData = await axios.get(`masters/getAllSubjectList/${payload}`).then((res) => {
      if (res.data.status) {
        return res.data.data
      }
    })
    return getAllData


  } catch (error) {

  }
})
export const getAllChapterList = createAsyncThunk('getAllChapterList', async (payload, thunk) => {
  try {
    let getAllData = await axios.get(`masters/getAllChapterList/${payload}`).then((res) => {
      if (res.data.status) {
        return res.data.data
      }
    })
    return getAllData


  } catch (error) {

  }
})

export const movingQuestion = createAsyncThunk('QuestionMovement', async (payload, thunk) => {
  try {
    const moveData = await axios.post(`question/movingQuestion`, payload.data).then(async (res) => {
      // console.log("res data == ",res.data)

      if (res.data.status) {
        message.success(res.data.message)
        let data = payload.getAllData
        await thunk.dispatch(getAllQuestion(data))
      }
    })
    return

  } catch (error) {

  }
})
export const copyAndPasteQuestion = createAsyncThunk('copyAndPasteQuestion', async (payload, thunk) => {
  try {
    const moveData = await axios.post(`question/copyAndPasteQuestion`, payload.data).then(async (res) => {
      // console.log("res data == ",res.data)

      if (res.data.status) {
        message.success(res.data.message)
        let data = payload.getAllData
        await thunk.dispatch(getAllQuestion(data))
      }
    })
    return

  } catch (error) {

  }
})



const initialState = {
  questionData: [],
  isQuestionLoading: false,
  currentQuestion: {},
  questionSelection: {
    questionSelection: []
  },
  totalDataCount: 0,
  breadCrumbData: {},
  getAllExamListData: [],
  getAllSubjectListData: [],
  getAllChapterListData: [],

}

const PreviousYearQuestionsSlice = createSlice({
  name: 'AdminMaster',
  initialState,
  reducers: {
    removeQuestionData: (state, action) => {
      state.questionData = [];

    },
    removeQuestionSelection: (state, action) => {
      state.questionSelection.questionSelection = [];

    },
  },
  extraReducers: {
    [getAllQuestion.pending]: (state, action) => {
      state.isQuestionLoading = true
      state.questionData = [];
      state.totalDataCount = 0
    },
    [getAllQuestion.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.questionData = action.payload?.getQuestion
      state.totalDataCount = +action.payload?.getQuestionCount?.questionCount
      state.breadCrumbData = action.payload?.breadcrumbs
    }, [getAllQuestion.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [addQuestionData.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [addQuestionData.fulfilled]: (state, action) => {
      state.isQuestionLoading = false

    }, [addQuestionData.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [getQuestionById.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [getQuestionById.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.currentQuestion = action.payload

    }, [getQuestionById.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },

    [getQuestionSelectionByTestId.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [getQuestionSelectionByTestId.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      action.payload.forEach((e) => {
        if (e.chapterId == null) {
          e['chapterId'] = -1
        }
      })
      state.questionSelection.questionSelection = action.payload
    },
    [getQuestionSelectionByTestId.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [addQuestionSelectionData.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [addQuestionSelectionData.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
    },
    [addQuestionSelectionData.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [excelUpload.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [excelUpload.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
    },
    [excelUpload.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [getComprehensionById.pending]: (state, action) => {
      state.isQuestionLoading = true
      state.currentQuestion = {}
    },
    [getComprehensionById.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.currentQuestion = action.payload

    }, [getComprehensionById.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [updateQuestionData.pending]: (state) => {
      state.isQuestionLoading = true
    },
    [updateQuestionData.fulfilled]: (state, actions) => {
      let success = actions.payload.success
      state.isQuestionLoading = false
    },
    [updateQuestionData.rejected]: (state) => {
      state.isQuestionLoading = false
    },
    [deleteComprehensionData.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [deleteComprehensionData.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.questionData = state.questionData.filter((e) => e.id !== action.payload)

    }, [deleteComprehensionData.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },

    [getAllSubjectList.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [getAllSubjectList.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.getAllSubjectListData = action.payload
    },
    [getAllSubjectList.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [getAllChapterList.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [getAllChapterList.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.getAllChapterListData = action.payload
    },
    [getAllChapterList.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [movingQuestion.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [movingQuestion.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
    },
    [movingQuestion.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [copyAndPasteQuestion.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [copyAndPasteQuestion.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
    },
    [copyAndPasteQuestion.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },

    [getPreYearCompreQuestionById.pending]: (state, action) => {
      state.isQuestionLoading = true
      state.currentQuestion = {}
    },
    [getPreYearCompreQuestionById.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.currentQuestion = action.payload

    }, [getPreYearCompreQuestionById.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },

  }
})
export const { removeQuestionData, removeQuestionSelection } = PreviousYearQuestionsSlice.actions

export default PreviousYearQuestionsSlice.reducer