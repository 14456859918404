import { configureStore } from "@reduxjs/toolkit";
import Loginmaster from "../reducers/loginSlice";
import SubjectMaster from "../reducers/subjectSlice";
import ChapterMaster from "../reducers/chapterSlice";
import ExamMaster from "../reducers/ExamMasterSlice";
import ClusterMasterSlice from "../reducers/ClusterMasterSlice";
import ClusterGroupMasterSlice from "../reducers/ClusterGroupMasterSlice";
import AdminMaster from "../reducers/adminSlice";
import StudentMaster from "../reducers/studentSlice";
import MasterSlice from "../reducers/masterSlice";
import SubscriptionMasterSlice from "../reducers/SubscriptionMasterSlice";
import TestMaster from "../reducers/TestMasterSlice";
import QuestionSlice from "../reducers/questionSlice";
import TestBundle from "../reducers/TestBundleSlice";
import CouponSlice from "../reducers/couponSlice";
import dashboardSlice from "../reducers/dashboardSlice";
import InstitutionSlice from "../reducers/institutionMasterSlice";
import CourseSlice from "../reducers/CourseMasterSlice";
import BatchSlice from "../reducers/BatchMasterSlice";
import PreviousYearQuestionsSlice from "../reducers/PreviousYearQuestionsSlice";
import PreviousYearExamSlice from "../reducers/PreviousYearExamSlice";
import CommunitySlice from "../reducers/CommunitySlice";
import StatsSlice from "../reducers/StatsSlice";
import InstitutionContactRegisterSlice from "../reducers/InstitutionContactRegisterSlice";
import KeyFeaturesSlice from "../reducers/KeyFeaturesSlice";

import CurrentAffairsSlice from "../reducers/CurrentAffairsSlice";
import AnnouncementSlice from "../reducers/AnnouncementSlice";
import TutorialSlice from "../reducers/TutorialSlice";
import LeadsSlice from "../reducers/LeadsSlice";
import ProductsSlice from "../reducers/ProductsSlice";
import testimonialsSlice from "../reducers/TestimonialsSlice";
import NotificationSlice from "../reducers/NotificationSlice";
import ClusterCourseSlice from "../reducers/clusterCourseSlice";
import OtherVideoSlice from "../reducers/OtherVideoSlice";
import EducatorMasterSlice from "../reducers/EducatorMasterSlice";
import PricingSlice from "../reducers/PricingSlice";
import LeadGenerationSlice from '../reducers/LeadGenerationSlice'
import CareerSlice from "../reducers/CareerSlice";
import WebsiteNotificationSlice from "../reducers/WebsiteNotificationSlice";
import CurriculumSlice from "../reducers/CurriculumSlice";
import MultipartUploadReducer from "../reducers/MultipartUploadSlice";

export const store = configureStore({
  reducer: {
    SubjectMaster: SubjectMaster,
    ChapterMaster: ChapterMaster,
    AdminMaster: AdminMaster,
    StudentMaster: StudentMaster,
    Loginmaster: Loginmaster,
    ExamMaster: ExamMaster,
    MasterSlice: MasterSlice,
    SubscriptionMaster: SubscriptionMasterSlice,
    TestMaster: TestMaster,
    QuestionSlice: QuestionSlice,
    TestBundle: TestBundle,
    Coupon: CouponSlice,
    DashboardSlice: dashboardSlice,
    InstitutionSlice: InstitutionSlice,
    CourseSlice: CourseSlice,
    BatchSlice: BatchSlice,
    PreviousYearQuestionsSlice: PreviousYearQuestionsSlice,
    PreviousYearExamSlice: PreviousYearExamSlice,
    Community: CommunitySlice,
    Stats: StatsSlice,
    InstitutionContactRegister: InstitutionContactRegisterSlice,
    KeyFeatures: KeyFeaturesSlice,
    CurrentAffairsSlice: CurrentAffairsSlice,
    Announcement: AnnouncementSlice,
    Tutorial: TutorialSlice,
    Leads: LeadsSlice,
    Products: ProductsSlice,
    Testimonials: testimonialsSlice,
    NotificationSlice: NotificationSlice,
    ClusterMasterSlice: ClusterMasterSlice,
    ClusterGroupMasterSlice: ClusterGroupMasterSlice,
    ClusterCourseSlice: ClusterCourseSlice,
    OtherVideoSlice: OtherVideoSlice,
    EducatorMasterSlice: EducatorMasterSlice,
    PricingSlice : PricingSlice,
    LeadGeneration:LeadGenerationSlice,
    CareerSlice:CareerSlice,
    WebsiteNotificationSlice:WebsiteNotificationSlice,
    CurriculumSlice:CurriculumSlice,
    MultipartUpload: MultipartUploadReducer
  }
})

