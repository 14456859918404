import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams,useNavigate } from 'react-router-dom';
import { Checkbox, Radio, Input,Button,Spin,Image } from 'antd';
import { getSubscription} from './reducers/TestMasterSlice';
import {testBundlePricing,getBundlePricing} from './reducers/TestBundleSlice'
import { useFormik } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import BackImage from '../asset/image/leftArrow.svg';



function TestBundlePricing() {

  const dispatch = useDispatch();

  const params=useParams();

  const navigate=useNavigate();

  const [checkedValues, setCheckedValues] = useState([])

  const antIcon = (<LoadingOutlined style={{ fontSize: 24, }} spin />);

  const { subscriptionList,isTestMasterLoading } = useSelector((state) => state.TestMaster)

  const {isLoading}=useSelector((state)=>state.TestBundle)


  useEffect(() => {
    var subscriptionsLength=0;
    var pricingLength=0
    dispatch(getSubscription()).unwrap().then((res)=>{
      subscriptionsLength=res.length
    })
    dispatch(getBundlePricing(params.id)).unwrap().then((res)=>{
      newPricing.setValues(res.data);
      pricingLength=res.data.subscriptions.length

      if(subscriptionsLength==pricingLength){
        newPricing.setFieldValue('subscriptionRadio',1)
      }
      else{
        newPricing.setFieldValue('subscriptionRadio',2)

      }
    })
  }, [])


  const newPricingInitialValues = {
    pricing: '',
    bundlePrice: '',
    subscriptions: [],
    subscriptionRadio: ''
  }

  const newPricingSchema = yup.object({
    bundlePrice: yup.string().required("Test price is required.").matches(/^[0-9\b]+$/, "Please Don't Enter Any Special Character").trim(),
    subscriptions: yup.array().min(1, "Subscriptions is required."),
    pricing: yup.string().required("Pricing is required.")
  })

  const newPricing = useFormik({
    initialValues: newPricingInitialValues,
    validationSchema: newPricingSchema,
  })


  let subscription = []
  subscriptionList.map((item) => {
    subscription.push(item.value)
  })

  const onChange = (checkedValues) => {
    newPricing.setFieldValue('subscriptions', checkedValues)
    setCheckedValues(checkedValues)
  }

  const priceSubmit = () => {
    if (newPricing.values.subscriptionRadio == 1) {
      let subscriptions = []
      subscriptionList.map((item) => {
          subscriptions.push(item.value)
      })
      let data = {
          pricing: newPricing.values.pricing,
          testPrice: newPricing.values.bundlePrice,
          subscriptions: newPricing.subscriptions,
          testBundleId: params.id
      }
      dispatch(testBundlePricing(data)).unwrap().then((res)=>{
        if(res.data.success){
          navigate('/TestBundle')
        }
      })
      newPricing.resetForm(newPricingInitialValues)

  }
  if (newPricing.values.subscriptionRadio == 2) {
      let data={
        pricing:newPricing.values.pricing,
        subscriptionRadio:newPricing.values.subscriptionRadio,
        subscriptions:newPricing.values.subscriptions,
        testPrice:newPricing.values.bundlePrice,
        testBundleId:params.id
      }
      dispatch(testBundlePricing(data)).unwrap().then((res)=>{
        if(res.data.success){
          navigate('/TestBundle')
        }
      })
      newPricing.resetForm(newPricingInitialValues)
  }
}

function onBackClick(){
  navigate('/TestBundle');
}

// loader

if (isLoading) {
  return (
    <div className='loading'>
      <Spin tip='Loading...' indicator={antIcon} />
    </div>
  )
}
  return (
    <div style={{position:'relative',height:'100%'}}>
      <div style={{paddingBottom:10}}>
      <div className='new-bundle-header' style={{paddingBottom:10}}>
      <div><Image src={BackImage} preview={false} onClick={()=>onBackClick()}></Image></div>
      <div className='bundle-heading'>Create a Bundle</div>
      </div>
      </div>
      <div className='pricing'>
        <Radio.Group id='pricing' onBlur={newPricing.handleBlur} onChange={(e) => newPricing.setFieldValue('pricing', e.target.value)} value={newPricing.values.pricing}>
          <div className='pricing-free'>
            <Radio value={false}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Free</p>
              </div>
              <p>Allow access to your content free of charge</p>
            </Radio>
          </div>
          <div className='pricing-payment'>
            <Radio value={true}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Payment</p>
              </div>
              <p> Learner can access the course by making payment</p>
            </Radio>
          </div>
        </Radio.Group>
        {newPricing.touched.pricing && newPricing.errors.pricing ? <div className='errorMessage' style={{ color: 'red', paddingLeft: 25, position: 'absolute' }}>{newPricing.errors.pricing}</div> : null}
      </div>
      {newPricing.values.pricing == true ?
        <div>
          <div className='pricing-details'>
            <div className='pricing-details-header'>Pricing Details</div>
            <div className='test-price'>
              <p>Bundle Price</p>
              <Input id='bundlePrice' type='number' name='bundlePrice' onBlur={newPricing.handleBlur} onChange={newPricing.handleChange} value={newPricing.values.bundlePrice}></Input>
              {newPricing.touched.bundlePrice && newPricing.errors.bundlePrice ? <div className='errorMessage' style={{ color: 'red', position: 'absolute', bottom: -20 }}>{newPricing.errors.bundlePrice}</div> : null}
            </div>
          </div>
          <div className='subscription-details'>
            <div className='subscription-details-header' style={{ fontWeight: 500, fontSize: 15, color: '#2D81F7', paddingTop: 28, paddingBottom: 16, paddingLeft: 24 }}>Subscription Details</div>
            <div>
              <div className='subscription-radio' style={{ paddingLeft: 24 }}>
                <Radio.Group id='subscriptionRadio' onBlur={newPricing.handleBlur} onChange={(e) => { newPricing.setFieldValue('subscriptionRadio', e.target.value)}} value={newPricing.values.subscriptionRadio}>
                  <Radio value={1}>Apply for all</Radio>
                  <Radio value={2}>Assign list of Subscription</Radio>
                </Radio.Group>
              </div>
              <div className='subscription-checkbox' style={{ padding: 26 }}>
                {newPricing.values.subscriptionRadio == 2 ?
                  <Checkbox.Group options={subscriptionList} id='subscriptions' name='subscriptions' onBlur={newPricing.handleBlur} onChange={(checkedValues) => onChange(checkedValues)} value={newPricing.values.subscriptions} />
                  : ''}
              </div>
              {newPricing.touched.subscriptions && newPricing.errors.subscriptions ? <div className='errorMessage' style={{ color: 'red', paddingTop: 10, paddingLeft: 24 }}>{newPricing.errors.subscriptions}</div> : null}
            </div>
          </div>
        </div>
        : null}
      

      <div style={{position:'absolute',bottom:'10%',right:0}}>
        <div className='submit-buttons'>
        <Button style={{ marginRight: 44, width: 74 }} type='primary' onClick={()=>priceSubmit()}>Save</Button>
        <Button style={{ marginRight: 49 }}>Cancel</Button>
      </div>
      </div>
    </div>
  )
}

export default TestBundlePricing;