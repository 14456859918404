import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const insertClusterDetails = createAsyncThunk(
  'ClusterMaster/insertClusterDetails', async (payload, thunk) => {
    let resSuccess = false;
    let resMessage = '';
    await axios.post('coachmi/addClusterDetails', payload.data)
      .then(async (res) => {
        const { success, message } = res?.data;
        if (success) {
          resMessage = message;
          resSuccess = success;
          await thunk.dispatch(getClusterMasterTable(payload.obj));
          await thunk.dispatch(getClusterCreatedDateFilter());
        };
      })
      .catch((error) => {
        resMessage = error?.response?.data?.message
      });
    return { resSuccess, resMessage };
  }
);

export const updateClusterDetails = createAsyncThunk(
  'ClusterMaster/updateClusterDetails', async (payload, thunk) => {
    let resSuccess = false;
    let resMessage = '';
    await axios.put('coachmi/updateClusterDetails/' + payload.id, payload.data)
      .then(async (res) => {
        const { success, message } = res?.data;
        if (success) {
          resMessage = message;
          resSuccess = success;
          await thunk.dispatch(getClusterMasterTable(payload.obj));
          await thunk.dispatch(getClusterCreatedDateFilter());
        };
      })
      .catch((error) => {
        resMessage = error?.response?.data?.message
      })
    return { resSuccess, resMessage };
  }
);

export const deleteCluster = createAsyncThunk(
  'cluster/deleteCluster', async (payload, thunk) => {
    let resSuccess = false;
    let resMessage = '';
    await axios.put('coachmi/deleteCluster/' + payload.id)
      .then(async (res) => {
        const { success, message } = res?.data;
        resSuccess = success;
        resMessage = message;
        if (success) {
          await thunk.dispatch(getClusterMasterTable(payload.obj));
          await thunk.dispatch(getClusterCreatedDateFilter());
        };
      })
      .catch((error) => {
        resMessage = error?.response?.data?.message
      })
    return { resSuccess, resMessage };
  }
);

export const getClusterMasterTable = createAsyncThunk(
  'ClusterMaster/getClusterMasterTable',
  async (payload, thunk) => {
    try {
      let tableData = [];
      let tableTotalCount;
      let resSuccess = false;
      let resMessage = '';
      await axios.post('coachmi/getAllClusterDetails', payload)
        .then(async (res) => {
          const { success, data, message } = res.data;
          const { listData, dataCount } = data;
          resSuccess = success
          resMessage = message;
          tableData = listData;
          tableTotalCount = dataCount;
        })
        .catch((error) => {
          const { message } = error.error;
          resMessage = message
        })
      return { tableData, tableTotalCount, resSuccess, resMessage };
    } catch (error) {
      throw error;
    };
  }
);

export const getClusterDetailsById = createAsyncThunk(
  "coachmi/getClusterDetailsById", async (payload) => {
    try {
      let clusterDetails = await axios.get(`/coachmi/getClusterDetailsById/${payload}`)
        .then((res) => {
          if (res?.data?.status) {
            return res?.data
          }
        })
        .catch((error) => {
        })
      return clusterDetails
    } catch (error) {
    }
  }
);

export const getClusterFilter = createAsyncThunk(
  'cluster/getClusterFilter', async (payload, thunk) => {
    try {
      let response = [];
      await axios.get('coachmi/getUniqueClusterNames')
        .then(async (res) => {
          const { status, data } = res.data;
          response = data;
        })
        .catch((error) => {
        })
      return response;
    } catch (error) {
    }
  }
);

export const getClusterCreatedDateFilter = createAsyncThunk(
  'cluster/getClusterCreatedDateFilter', async (payload, thunk) => {
    try {
      let response = [];
      const publicData = await axios.get('coachmi/getUniqueClusterCreatedDate')
        .then(async (res) => {
          const { status, data } = res.data;
          if (status) {
          };
          response = data;
        })
        .catch((error) => {
        })
      return response;
    } catch (error) {
    }
  }
);

const initialState = {
  clusterMasterTableData: [],
  isLoading: false,
  tableTotalCount: 0,
  clusterNameFilter: [],
  clusterCreatedAt: []
};

const ClusterMaster = createSlice({
  name: 'ExamMaster',
  initialState,
  extraReducers: {
    [insertClusterDetails.pending]: (state) => {
      state.isLoading = true
    },
    [insertClusterDetails.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [insertClusterDetails.rejected]: (state) => {
      state.isLoading = false
    },
    [updateClusterDetails.pending]: (state) => {
      state.isLoading = true
    },
    [updateClusterDetails.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [updateClusterDetails.rejected]: (state) => {
      state.isLoading = false
    },
    [deleteCluster.pending]: (state) => {
      state.isLoading = true
    },
    [deleteCluster.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [deleteCluster.rejected]: (state) => {
      state.isLoading = false
    },
    [getClusterMasterTable.pending]: (state) => {
      state.isLoading = true
    },
    [getClusterMasterTable.fulfilled]: (state, actions) => {
      state.isLoading = false
      const { tableData, tableTotalCount } = actions.payload;
      state.clusterMasterTableData = tableData;
      state.tableTotalCount = tableTotalCount;
    },
    [getClusterMasterTable.rejected]: (state) => {
      state.isLoading = false
    },
    [getClusterDetailsById.pending]: (state, action) => {
      state.isLoading = true
    },
    [getClusterDetailsById.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [getClusterDetailsById.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getClusterFilter.pending]: (state) => {
      state.isLoading = true
    },
    [getClusterFilter.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.clusterNameFilter = actions?.payload
    },
    [getClusterFilter.rejected]: (state) => {
      state.isLoading = false
    },
    [getClusterCreatedDateFilter.pending]: (state) => {
      state.isLoading = true
    },
    [getClusterCreatedDateFilter.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.clusterCreatedAt = actions?.payload
    },
    [getClusterCreatedDateFilter.rejected]: (state) => {
      state.isLoading = false
    },
  }
 }
);

export default ClusterMaster.reducer;


