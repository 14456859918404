import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Drawer,
  Modal,
  Tag,
  Image,
  DatePicker,
  Form,
  Checkbox,
  message,
  Upload,
} from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import {
  getAllAffairsData,
  addCurrentAffairs,
  getAffairsById,
  updateCurrentAffairs,
  deleteCurrrentAffiars,
  uploadCurrentAffairsPdf,
} from "../components/reducers/CurrentAffairsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikConsumer, useFormik } from "formik";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { useDebouncedCallback } from "use-debounce";
import TableComponent from "./Table/tableComponent";
import moment, { ISO_8601, utc } from "moment";
import Loading from "./Loading";



const CurrentAffairs = () => {
  const dispatch = useDispatch();
  let tableHeight = 320;
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentTitlePdf, setCurrentTitlePdf] = useState("");
  const [visible, setVisible] = useState(false);
  const [visiblePdf, setVisiblePdf] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isSubmitModalVisiblePdf, setSubmitModalVisiblePdf] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [formDate, setFormDate] = useState(moment());
  const [record, setRecord] = useState({});
  const [file, setFile] = useState([]);

  const { currentData, isLoading, currentDataCount } = useSelector(
    (state) => state.CurrentAffairsSlice
  );
  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    };
    dispatch(getAllAffairsData(obj));
  }, []);

  const dateFormat = "YYYY-MM-DD";
  const initialValues = {
    title: "",
    subTitle: "",
    content: "",
    isActive: false,
    orderNo: "",
    date: "",
  };
  const initialValuesPDF = { currentAffairsPdf: [] };

  const validationSchema = yup.object({
    title: yup
      .string()
      .required("Title is Required")
      .min(2, "Title Needed At Least Two characters")
      .max(100, "Title not more than 100 characters"),

    subTitle: yup
      .string()
      .required("SubTitle is Required")
      .min(2, "SubTitle Needed At Least Two characters")
      .max(100, "SubTitle not more than 100 characters"),

    content: yup.string().required("Content is Required"),

    orderNo: yup
      .string()
      .required("Order Number is Required")
      .matches(/^[0-9\b]+$/, "Please Don't Enter Any Special Character"),

    date: yup.string().required("Valid time is required"),
  });

  const validationSchemaPdf = yup.object({
    currentAffairsPdf: yup
      .array()
      .min(1, "Pdf is Required")
      .required("Pdf is Required"),
  });

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "6%",
      render: (_, record, i) => {
        return (
          <div className="index-id">
            {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "SubTitle",
      dataIndex: "subTitle",
      key: "subTitle",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Order No",
      dataIndex: "orderNo",
      key: "orderNo",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (_, record) => {
        return (
          <div>
            <span>
              {record.isActive == 1 ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">Inactive</Tag>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="table-action-link">
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)}>
              Edit
            </a>
            <Tooltip style={{ padding: 0 }} title="Delete">
              <IconButton>
                <Image
                  src={DeleteImage}
                  preview={false}
                  onClick={() => deleteData(record)}
                ></Image>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onCalenderChange = (date, dateString) => {
    formik.setFieldValue("date", dateString);
    setFormDate(dateString);
  };

  const editData = (record) => {
    setCurrentId(record.id);
    setCurrentTitle("Edit CurrentAffairs");
    dispatch(getAffairsById(record.id))
      .unwrap()
      .then((res) => {
        setVisible(true);
        if (res.data.isActive == 1) {
          res.data.isActive = true;
        }
        formik.setValues(res.data);
      });
  };

  const deleteData = (record) => {
    setRecord(record);
    setIsModalVisible(true);
  };

  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getAllAffairsData(obj));
  }, 1000);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
    };

    dispatch(getAllAffairsData(obj));
  };

  const openDrawer = () => {
    setCurrentId(null);
    formik.resetForm({ values: initialValues });
    setVisible(true);
    setCurrentTitle("Create CurrentAffairs");
  };

  const openDrawerCurrentAffairsUpload = () => {
    formikPdf.resetForm({ values: initialValuesPDF });
    setVisiblePdf(true);
    setCurrentTitlePdf("Upload current affairs pdf");
  };

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const closeSubmitModalPdf = () => {
    setSubmitModalVisiblePdf(false);
  };

  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const showSubmitConfirmationPdf = (values) => {
    setSubmitModalVisiblePdf(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false);
      let payload = {
        data: values,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        },
      };
      setSubmitModalVisible(false);
      dispatch(addCurrentAffairs(payload));
    },
  });

  const formikPdf = useFormik({
    initialValuesPDF,
    validationSchemaPdf,
    onSubmit: (values) => {
      let data = new FormData();
      data.append(
        "currentAffairsPdf",
        values.currentAffairsPdf[0]?.originFileObj
      );
      setVisiblePdf(false);
      setSubmitModalVisiblePdf(false);
      dispatch(uploadCurrentAffairsPdf(data));
    },
  });

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
    dispatch(updateCurrentAffairs(formik.values))
      .unwrap()
      .then((res) => {
        let obj = {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        };
        dispatch(getAllAffairsData(obj))
          .unwrap()
          .then((res) => {});
      });
    setUpdateModalVisible(false);
    setVisible(false);
  };

  const modalOnCancel = () => {
    setIsModalVisible(false);
  };
  const modalOnOk = () => {
    setIsModalVisible(false);
    dispatch(deleteCurrrentAffiars(record.id))
      .unwrap()
      .then((res) => {
        let obj = {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        };
        dispatch(getAllAffairsData(obj));
      });
  };
  const drawerCloser = () => {
    setVisible(false);
    formik.resetForm();
  };
  const drawerCloserPdf = () => {
    setVisiblePdf(false);
    formikPdf.resetForm();
  };

  useEffect(() => {
    formik.validateForm();
    formikPdf.validateForm();
  }, []);

  const filehandleChange = (file) => {
    if (file.fileList.length) {
      if (file.file.type == "application/pdf") {
        setFile(file.fileList);
        formikPdf.values.currentAffairsPdf = file.fileList;
      } else {
        message.error("please upload .pdf formats only ");
      }
    } else {
      setFile(file.fileList);
      formikPdf.values.currentAffairsPdf = file.fileList;
      formikPdf.validateForm();
    }
  };

  return (
    <div className="admin-page">
      <div className="adminHeader">
        <h3 className="primary-header">Current Affairs</h3>
        <div className="admin-button">
          <Button
            className="primary-submit-button"
            type="primary"
            onClick={openDrawer}
          >
            Add CurrentAffairs
          </Button>
          <Button
            className="primary-submit-button"
            type="primary"
            onClick={openDrawerCurrentAffairsUpload}
          >
            Upload Current Affairs PDF
          </Button>
        </div>
      </div>
      <div className="all-table-component" style={{ padding: 0, margin: 0 }}>
        <div className="golbalSearch">
          <Input
            placeholder="Search"
            prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
            style={{ width: 262, height: 32 }}
            onChange={(event) => onSearch(event.target.value)}
          />
        </div>
        <TableComponent
          dataSource={currentData}
          columns={columns}
          rowSelection={rowSelection}
          tableOnChange={tableOnChange}
          tableHeight={tableHeight}
          totalData={currentDataCount}
          currentPage={paginationData + 1}
          loadingStatus={isLoading}
          limit={limit}
        />
      </div>
      <Drawer
        title={currentTitle}
        onClose={() => drawerCloser()}
        width="518px"
        closable={false}
        closeIcon={false}
        placement="right"
        open={visible}
        footer={
          <div className="footer">
            <div className="footer-button">
              <Button
                className="cancel-Button primary-cancel-button"
                onClick={drawerCloser}
              >
                Cancel
              </Button>
              {currentId == null ? (
                <Button
                  className={
                    !formik.isValid || !formik.dirty
                      ? "disabled-button"
                      : "primary-submit-button"
                  }
                  type="primary"
                  disabled={!formik.dirty || !formik.isValid}
                  onClick={showSubmitConfirmation}
                >
                  Submit{" "}
                </Button>
              ) : (
                <Button
                  className={
                    !formik.isValid
                      ? "disabled-button"
                      : "primary-submit-button"
                  }
                  type="primary"
                  disabled={!formik.isValid}
                  onClick={showUpdateConfirmation}
                >
                  Update{" "}
                </Button>
              )}
            </div>
          </div>
        }
      >
        <Formik validateOnChange={false} validateOnBlur={false}>
          <Form>
            <div className="form-control">
              <label htmlFor="title">
                Title<span style={{ color: "red" }}> *</span>
              </label>
              <Input
                type="text"
                id="title"
                placeholder="Enter a title"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="errorMessage" style={{ color: "red" }}>
                  {formik.errors.title}
                </div>
              ) : null}
            </div>
            <div className="form-control">
              <label htmlFor="subtitle">
                Sub Title<span style={{ color: "red" }}> *</span>
              </label>
              <Input
                type="text"
                id="subTitle"
                placeholder="Enter a subtitle"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.subTitle}
              />
              {formik.touched.subTitle && formik.errors.subTitle ? (
                <div className="errorMessage" style={{ color: "red" }}>
                  {formik.errors.subTitle}
                </div>
              ) : null}
            </div>
            <div className="form-control">
              <label htmlFor="content">
                Content
                <span style={{ color: "red" }}> *</span>
              </label>
              <Input
                type="text"
                id="content"
                placeholder="Enter a content"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.content}
              />
              {formik.touched.content && formik.errors.content ? (
                <div className="errorMessage" style={{ color: "red" }}>
                  {formik.errors.content}
                </div>
              ) : null}
            </div>
            <div className="form-control">
              <label htmlFor="orderNo">
                Order No<span style={{ color: "red" }}> *</span>
              </label>
              <Input
                type="text"
                id="orderNo"
                placeholder="Enter a orderNo"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.orderNo}
              />
              {formik.touched.orderNo && formik.errors.orderNo ? (
                <div className="errorMessage" style={{ color: "red" }}>
                  {formik.errors.orderNo}
                </div>
              ) : null}
            </div>
            <div className="date-pick">
              <label htmlFor="date-pick">
                Date <span style={{ color: "red" }}>*</span>
              </label>
              <DatePicker
                format={dateFormat}
                id="date"
                name="date"
                onChange={(date, dateString) =>
                  onCalenderChange(date, dateString)
                }
                value={
                  formik.values.date
                    ? moment(formDate, "YYYY-MM-DDTHH:mm:ss.sssZ")
                    : undefined
                }
              />
              {formik.touched.date && formik.errors.date ? (
                <div className="errorMessage" style={{ color: "red" }}>
                  {formik.errors.date}
                </div>
              ) : null}
            </div>

            <div className="form-control">
              <Checkbox
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                value={formik.values.isActive}
              >
                Active <span className="required">*</span>
              </Checkbox>
            </div>
          </Form>
        </Formik>
      </Drawer>
      <Modal
        title="Deletion"
        open={isModalVisible}
        onCancel={modalOnCancel}
        onOk={modalOnOk}
        footer={
          <div>
            <Button
              className="primary-cancel-button"
              onClick={() => modalOnCancel()}
            >
              Cancel
            </Button>
            <Button
              className="primary-submit-button"
              type="default"
              onClick={() => modalOnOk()}
            >
              OK
            </Button>
          </div>
        }
      >
        <p>Are you sure want to delete ?</p>
      </Modal>

      {/* update model */}
      <Modal
        title="Update Confirmation"
        open={isUpdateModalVisible}
        onCancel={closeUpdateModal}
        footer={
          <div>
            <Button
              className="primary-cancel-button"
              onClick={closeUpdateModal}
            >
              Cancel
            </Button>
            <Button
              className="primary-submit-button"
              type="default"
              onClick={() => updateData(formik.values)}
            >
              Update
            </Button>
          </div>
        }
      >
        <p>Are you sure you want to update?</p>
      </Modal>

      {/* submit modal */}

      <Modal
        title="Submit Confirmation"
        open={isSubmitModalVisible}
        onCancel={closeSubmitModal}
        footer={
          <div>
            <Button
              className="primary-cancel-button"
              onClick={closeSubmitModal}
            >
              Cancel{" "}
            </Button>
            <Button
              className="primary-submit-button"
              type="default"
              onClick={formik.handleSubmit}
            >
              {" "}
              Submit{" "}
            </Button>
          </div>
        }
      >
        <p>Are you sure you want to Submit?</p>
      </Modal>

      <Modal
        title="Submit Confirmation"
        open={isSubmitModalVisiblePdf}
        onCancel={closeSubmitModalPdf}
        footer={
          <div>
            <Button
              className="primary-cancel-button"
              onClick={closeSubmitModalPdf}
            >
              Cancel
            </Button>
            <Button
              className="primary-submit-button"
              type="default"
              onClick={formikPdf.handleSubmit}
            >
              Submit
            </Button>
          </div>
        }
      >
        <p>Are you sure you want to Submit?</p>
      </Modal>

      {/* pdf file upload */}
      <Drawer
        title={currentTitlePdf}
        onClose={() => drawerCloserPdf()}
        width="518px"
        closable={false}
        closeIcon={false}
        placement="right"
        open={visiblePdf}
        footer={
          <div className="footer">
            <div className="footer-button">
              <Button
                className="cancel-Button primary-cancel-button"
                onClick={drawerCloserPdf}
              >
                Cancel
              </Button>
              <Button
                // className={
                //   !formikPdf.isValid || !formikPdf.dirty
                //     ? "disabled-button"
                //     : "primary-submit-button"
                // }
                type="primary"
                // disabled={!formikPdf.dirty || !formikPdf.isValid}
                onClick={() => showSubmitConfirmationPdf(formikPdf.values)}
              >
                Submit
              </Button>
            </div>
          </div>
        }
      >
        <Formik validateOnChange={false} validateOnBlur={false}>
          <Form>
            <div className="exam-upload-file">
            <label htmlFor="title">
                Current Affairs PDF<span style={{ color: "red" }}> *</span>
              </label>
              <div className="form-control">
                <Upload
                  className="ImageUpload"
                  listType="picture"
                  onChange={(file) => filehandleChange(file)}
                  beforeUpload={() => false}
                  fileList={file}
                  maxCount={1}
                >
                  <Button
                    className="exam-image-upload-button"
                    name="currentAffairsPdf"
                    onChange={formikPdf.handleChange}
                    onBlur={formikPdf.handleBlur}
                    icon={<UploadOutlined />}
                  >
                    Choose file
                  </Button>
                </Upload>
                {formikPdf.touched.currentAffairsPdf &&
                formikPdf.errors.currentAffairsPdf ? (
                  <div className="error">
                    {formikPdf.errors.currentAffairsPdf}
                  </div>
                ) : null}
              </div>
            </div>
          </Form>
        </Formik>
      </Drawer>

      {
        isLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> : null
}
    </div>
  );
};

export default CurrentAffairs;
