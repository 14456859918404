import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from "moment";
import { getChapterBySubject } from "./masterSlice";
import { getAllTypeData } from "./dashboardSlice";
export const getChapterMasterTable=createAsyncThunk(
  'ChapterMaster/getChapterMasterTable',async (payload)=>{
    let chapterTableData=await axios.post('masters/getChapter',payload)
    .then((res)=>{
      let tabledata=res.data.data;
      return tabledata;
    })
    .catch((error)=>{
    })
    return chapterTableData;
  }
)

// Insert Chapter details

export const insertChapterMasterTable=createAsyncThunk(
  'ChapterMaster/insertChapterMasterTable',async (payload,thunk)=>{
    let chapterTable=[]
    let success='';
    let InsertMessage=''
    let insertData=await axios.post('masters/createChapter',payload.payload)
    .then(async(res)=>{
      if(res?.data?.createChapter?.status){

        let url = {
          examId:payload.payload.examId,
          subId:payload.payload.subjectId,
        }

            let data =  {
              url:url,
              obj:payload.obj
            }
        await thunk.dispatch(getChapterBySubject(data))

      }
      chapterTable=res.data.createChapter
      success=chapterTable.success
      InsertMessage=chapterTable.message
    })
    .catch((error)=>{
      let err=error.response.data.createChapter
      message.error(err.message)
    })
    return {
      chapterTable:chapterTable,
      success:success,
      InsertMessage:InsertMessage
    };
  }
)

// edit chapter

export const editChapterMasterTable=createAsyncThunk(
  'ChapterMaster/editChapterMasterTable',async (payload,thunk)=>{
    let edit=[]
    let editMessage=''
    let editData=await axios.put('masters/updateChapter/'+payload.payload.id,payload.payload)
    .then(async(res)=>{

      if(res?.data?.updateChapter?.status){

        let url = {
          examId:payload.payload.examId,
          subId:payload.payload.subjectId,
        }

            let data =  {
              url:url,
              obj:payload.obj
            }
            if(payload.payload?.statusName){
              let typePayload={
                filterType: payload.payload?.statusName,
                type:'chapter'
              }
              await thunk.dispatch(getAllTypeData(typePayload))
            }else{

              await thunk.dispatch(getChapterBySubject(data))
            }

      }

      edit=res.data.updateChapter
      editMessage=res.data.message

    })
    .catch((error)=>{
      let err=error.response.data.updateChapter
      message.error(err.message)
    })
    return edit;
  }
)

export const deleteChapterMasterTable=createAsyncThunk(
  'ChapterMaster/deleteChapterMasterTable',async (payload,thunk)=>{
    let deleted=[]
    let deleteData=await axios.delete('masters/deleteChapter/'+payload.payload.id)
    .then(async(res)=>{

      if(res?.data?.data?.status){

        let url = {
          examId:payload.payload.examId,
          subId:payload.payload.subjectId,
        }

            let data =  {
              url:url,
              obj:payload.obj
            }
        await thunk.dispatch(getChapterBySubject(data))

      }

      deleted=res.data.data
    })
    .catch((error)=>{
    })
    return {
      id:payload,
      status:deleted.status
    };
  }
)

// approve chapter
export const approveChapter=createAsyncThunk(
  'ChapterMaster/approveChapter',async(payload,thunk)=>{
    try{
      let data={
        chapterId:payload.getData.chapterId,
        remarks:payload?.getData?.remarks
      }
      let approvedChapter=await axios.put(`/masters/approvedStatusChapter/${payload.getData.chapterId}/${payload.getData.statusId}`,payload.getData.data)
      .then(async(res)=>{
        let getData = {
          obj:payload.obj,
          url:payload.url
        }
        if(payload.url?.statusName){
          let typePayload={
            filterType:payload.url?.statusName,
            type:'chapter'
          }
          await thunk.dispatch(getAllTypeData(typePayload))
        }else{
          await thunk.dispatch(getChapterBySubject(getData))
        }
        return res.data;
      })
      return approvedChapter;
    }
    catch(error){
    }
  }
)

// bulk chapter approve
export const bulkChapterApprove=createAsyncThunk(
  'ExamMaster/bulkChapterApprove',async(payload)=>{
    try{
      let data={
        chapterId:payload.chapterId,
      }
      let approvedChapterBulk=await axios.put('/masters/chapterStatusBulkUpdate/'+payload.statusId,data)
      .then((res)=>{
        return res.data;
      })
      return approvedChapterBulk;
    }
    catch(error){
    }
  }
)


export const downloadChapterQuestionsCount = createAsyncThunk("Masters/downloadChapterQuestionsCount", async (payload) => {
  try {
    let getQuestionsCount = await axios.get(`/masters/downloadChapterQuestionsCountXl/${payload}`).then((res) => {
      if (res) {
        message.success(res.data.message)
        return res.data
      }
    })
    return getQuestionsCount
  } catch (error) {
  }
})

const initialState={
  chapterMasterTableData:[],
  isChapterLoading:false,
  chapterDataCount:0,
  breadCrumbData:{}

}

 const ChapterSlice=createSlice({
    name:'ChapterMaster',
    initialState,
    extraReducers:{
      [getChapterMasterTable.pending]:(state)=>{
        state.isChapterLoading=true
      },
      [getChapterMasterTable.fulfilled]:(state,actions)=>{
       state.isChapterLoading = false
        state.chapterMasterTableData=actions.payload.getChapterData
        state.chapterMasterTableData.map((item)=>{
          item.createdAt=moment(item.createdAt).format('DD MMM YYYY')
        })
        state.chapterDataCount =  +actions.payload?.getChapterCount?.chapterCount
      state.breadCrumbData =  actions.payload?.breadcrumbs

      },
      [getChapterMasterTable.rejected]:(state)=>{
        state.isChapterLoading=false
      },
      [insertChapterMasterTable.pending]:(state)=>{
        state.isChapterLoading=true
      },
      [insertChapterMasterTable.fulfilled]:(state,actions)=>{
        let tableData=actions.payload.chapterTable.data
        let success=actions.payload.success
        let InsertMessage=actions.payload.InsertMessage
        if(success==true){
          tableData.createdAt=moment(tableData.createdAt).format('DD MMM YYYY')
          state.chapterMasterTableData=[tableData,...state.chapterMasterTableData,]
          message.success(InsertMessage)
        }
        state.isChapterLoading=false
      },
      [insertChapterMasterTable.rejected]:(state)=>{
        state.isChapterLoading=false
      },
      [editChapterMasterTable.pending]:(state)=>{
        state.isChapterLoading=true
      },
      [editChapterMasterTable.fulfilled]:(state,actions)=>{
        let editdata=actions.payload.data;
        let success=actions.payload.success;
        let editMessage=actions.payload.message
        if(success==true){
          state.chapterMasterTableData.map((item,i)=>{
            if(item.id==editdata.id){
              item.name=editdata.name;
              item.subjectName=editdata.subjectName
            }
          })
          message.success(editMessage)
        }
        state.isChapterLoading=false
      },
      [editChapterMasterTable.rejected]:(state)=>{
        state.isChapterLoading=false
      },
      [deleteChapterMasterTable.pending]:(state)=>{
        state.isChapterLoading=true
      },
      [deleteChapterMasterTable.fulfilled]:(state,actions)=>{
        let deleteData=actions.payload
        if(deleteData.status==true){
         let examTableData=state.chapterMasterTableData
         state.chapterMasterTableData=examTableData.filter((item)=>item.id!=deleteData.id)
         message.success("Chapter deleted successfully !")
        }
        state.isChapterLoading=false
      },
      [deleteChapterMasterTable.rejected]:(state)=>{
        state.isChapterLoading=false;
      },
      [approveChapter.pending]:(state)=>{
        state.isChapterLoading=true
      },
      [approveChapter.fulfilled]:(state,action)=>{
        state.isChapterLoading=false
      },
      [approveChapter.rejected]:(state)=>{
        state.isChapterLoading=false
      },
      [bulkChapterApprove.pending]:(state)=>{
        state.isChapterLoading=true
      },
      [bulkChapterApprove.fulfilled]:(state,action)=>{
        state.isChapterLoading=false;
      },
      [bulkChapterApprove.rejected]:(state)=>{
        state.isChapterLoading=false
      },
      [downloadChapterQuestionsCount.pending]:(state)=>{
        state.isChapterLoading=true
      },
      [downloadChapterQuestionsCount.fulfilled]:(state,action)=>{
        state.isChapterLoading=false;
      },
      [downloadChapterQuestionsCount.rejected]:(state)=>{
        state.isChapterLoading=false
      },
    }

})

export default ChapterSlice.reducer;