import { Button, Col, Image, Input, message, Row } from 'antd'
import { useFormik } from 'formik'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Loading from './Loading';
import { forgotPassword } from './reducers/institutionMasterSlice';
import eduTechImage from '../asset/image/eduTech.svg'
import groupone from "../asset/image/groupimg.svg"; 

const InstitutionForgotPassword = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const {isLoading} = useSelector((state)=>state.InstitutionSlice)

    const formik = useFormik({
        initialValues:{email:''},
        validationSchema:yup.object({
        email: yup.string().email('Invalid Email Id').required("email is Required"),
        }),
        onSubmit:((values)=>{
            dispatch(forgotPassword(values)).unwrap().then((res)=>{
                navigate('/login')
                if(res.status){
                     message.success("Check Your Mail And Verify It")
                }
                else{
                    message.error("Error in Forgot Password")
                }
            })
        })
    }) 

  return (
    <>
        <div className='forgotPassword-container'>
        <Row>
            <Col span={12}>
            <div className='logo-container'>
            <div className="logoimage">
              <div className="image-container">
                <Image preview={false} src={eduTechImage}></Image>
                <Image preview={false} src={groupone} />
              </div>
            </div>
            </div>
            </Col>
            <Col span={12}>
                <div className='forgot-content-wrapper'>
                    <div className='form-container'>
                        <div className='form-wrapper'>
                        <div>
                            <h2 className='header'>Enter Email To set Password</h2>
                        </div>
                        <div className='form-control'>
                        <div>
                        <label htmlFor='email'>
                            Email
                        </label>
                        </div>
                        <div>
                            <Input id='email' type="text" placeholder="Enter a Email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                            {formik.touched.email && formik.errors.email ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.email}</div> : null}
                        </div>
                        </div>
                        <div className='form-control'>
                        {/* <Button className='primary-submit-button remove-margin' type="primary" disabled={!formik.dirty || !formik.isValid} onClick={formik.handleSubmit} >Submit </Button>  */}
                        {/* <Button className={!formik.isValid || !formik.dirty ?'disabled-button':'primary-submit-button'} type="primary" disabled={!formik.dirty || !formik.isValid} onClick={formik.handleSubmit} >Submit </Button>  */}
                        <Button  type="primary" className={ !formik.isValid ?'disabled-button remove-margin':'primary-submit-button remove-margin'} disabled={!formik.isValid} onClick={formik.handleSubmit} >Submit </Button> 
                        </div>
                        <div>
                   <p  className='navigate'> Back To<a onClick={()=>navigate("/login")}> Login </a></p>
                   </div>
                        </div>
                       

                    </div>

                </div>
            </Col>

        </Row>
    </div>
    {
            isLoading ? <div className='loading-container'>
                <Loading></Loading>
                 </div> : null
            }
    </>

  )
}

export default InstitutionForgotPassword