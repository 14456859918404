import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { constructFormData } from "../../utils/MultipartUploadHelper.js";

let initialState = {
    uploadId: '',
    totalParts: 0,
    parts: [],
    uploading: false,
    progress: 0,
    error: null,
    completionStarted: false,
};

export const createMultipartUpload = createAsyncThunk(
    'multipartUpload/createMultipartUpload',
    async (objectKey, { rejectWithValue }, thunkApi) => {
        try {
            // await thunkApi.dispatch(resetState());
            const response = await axios.post('/multipartUpload/createMultipartUpload', { objectKey });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const uploadPart = createAsyncThunk(
    'multipartUpload/uploadPart',
    async ({ file, objectKey, uploadId, partNumber }, { rejectWithValue }) => {
        try {

            const formData = await constructFormData({ file, objectKey, uploadId, partNumber });

            const response = await axios.post('/multipartUpload/uploadPart', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return { ETag: response.data.data.ETag, PartNumber: partNumber };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const completeMultipartUpload = createAsyncThunk(
    'multipartUpload/completeMultipartUpload',
    async ({ objectKey, uploadId, parts }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/multipartUpload/completeMultipartUpload', {
                objectKey,
                uploadId,
                parts,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const uploadObject = createAsyncThunk(
    'multipartUpload/uploadObject',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await axios.post('/multipartUpload/uploadObject', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const MultipartUploadSlice = createSlice({
    name: 'MultipartUpload',
    initialState: initialState,
    reducers: {
        resetState: (state) => {
            Object.assign(state, initialState);
        },
        setTotalParts(state, action) {
            const { value } = action.payload;
            state.totalParts = value;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createMultipartUpload.pending, (state) => {
                state.uploading = true;
                state.progress = 0;
                state.error = null;
            })
            .addCase(createMultipartUpload.fulfilled, (state, action) => {
                state.uploadId = action.payload.uploadId;
            })
            .addCase(createMultipartUpload.rejected, (state, action) => {
                state.error = action.payload;
                state.uploading = false;
            })
            .addCase(uploadPart.pending, (state) => {
                state.uploading = true;
                state.error = null;
            })
            .addCase(uploadPart.fulfilled, (state, action) => {
                state.parts.push(action.payload);
                state.progress = (state?.parts?.length / state.totalParts) * 100;
            })
            .addCase(uploadPart.rejected, (state, action) => {
                state.error = action.payload;
                state.uploading = false;
            })
            .addCase(completeMultipartUpload.pending, (state) => {
                state.completionStarted = true;
            })
            .addCase(completeMultipartUpload.fulfilled, (state) => {
                state.uploading = false;
                state.totalParts = 0;
                state.progress = 0;
                state.completionStarted = false;
            })
            .addCase(completeMultipartUpload.rejected, (state, action) => {
                state.error = action.payload;
                state.completionStarted = false;
            })
            .addCase(uploadObject.pending, (state) => {
            })
            .addCase(uploadObject.fulfilled, (state) => {
            })
            .addCase(uploadObject.rejected, (state, action) => {
            });
    },
});

export const { resetState } = MultipartUploadSlice.actions;
export const { setTotalParts } = MultipartUploadSlice.actions;


export default MultipartUploadSlice.reducer;
