import React, { useState } from 'react';
import TableComponent from './Table/tableComponent';
import { SearchOutlined, DeleteOutlined, DownloadOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import { Button, Form, Input, Drawer, Checkbox, Select, InputNumber, Spin, Alert, Modal,Image, Row, Col } from 'antd'
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { Option } from 'antd/lib/mentions';
import { useDispatch, useSelector } from 'react-redux';
import { addAdminData, deleteAdminDataById, getAdminDataById, getAllAdminData, updateAdminDataById } from './reducers/adminSlice';
import {getExamMasterTable} from './reducers/ExamMasterSlice';
// import {getPreviousYrExamTable} from './reducers/PreviousYearExamSlice';
import {getSubjectByExamId} from './reducers/masterSlice'
import { useEffect } from 'react';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { getAllRole } from './reducers/masterSlice';
import DeleteImage from '../asset/image/deleteIcon.svg'
import { useDebouncedCallback } from 'use-debounce';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

function Admin() {
  const { adminMaster, isLoading, totalDataCount } = useSelector((state) => state.AdminMaster)
  const {examMasterTableData}=useSelector((state)=>state.ExamMaster);
  const { roleMaster ,objExamBySubject } = useSelector((state) => state.MasterSlice)
  const [currentId, setCurrentId] = useState(null)
  const [currentTitle, setCurrentTitle] = useState("")
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("")
  const [limit, setLimit] = useState(50);
  const [role,setRole]=useState(0);
  const [subjectError,setSubjectError]=useState(false);
  const [isAdmin ,setIsAdmin] = useState(false)
  const [isSubmitModalVisible,setSubmitModalVisible]=useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
   const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit:limit
    }
    let obj2= {
      pagination: -1,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getExamMasterTable(obj2));
    // dispatch(getPreviousYrExamTable(obj2))
    dispatch(getAllAdminData(obj))
    dispatch(getAllRole())
  }, [])
  const init={
    name: '',
    email: '',
    mobileNumber: null,
    sendEmail: true,
    roleId: '',
    userAccessMap:[],
    isActive:false
  }

  const[initialValues ,setInitialValues] = useState(init)
  const validationSchema = yup.object({
    name: yup.string()
      .required(" Name is Required")
      .min(2, " Name Needed At Least Two characters")
      .max(100, " Name not more than 100 characters"),
    // .matches(/^\s*[A-Za-z0-9]+\s*( \s*[A-Za-z0-9]+\s*)*$/, "Name should only contain letters, numbers, and spaces with leading/trailing spaces"),

    email: yup.string().email('Invalid Email Id').required("Email is Required"),
  mobileNumber: yup
  .string()
  .matches(/^[0-9\b]+$/, "Please Don't Enter Any Special Character")
  .trim()
  .min(10, 'Mobile Number Must Be Exactly 10')
  .max(10, 'Mobile Number Must Be Exactly 10')
  .required('Mobile Number is Required')
  .nullable(),
  

    roleId: yup.string().required("Role is Required"),
    userAccessMap: subjectError || isAdmin ? yup.array().of(
      yup.object().shape({
          examId:  yup.string().required("Exam is required").nullable(),
          subjectIds: subjectError ?  yup.array().min(1,"Subject is required") :null
      })
  ) : null
  
  }) 

  const dispatch = useDispatch()
  let tableHeight = 320;
  const [visible, setVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
                return (
          <div className='index-id'>{(i+1)+(paginationData>0?(paginationData)*limit:0)}
          </div>
        )
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        return (
          <div>
            <span>{moment(record.createdAt).format('DD MMM YYYY')}</span>
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <div className='table-action-link'>
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
            <Tooltip style={{padding:0}} title="Delete">
              <IconButton>
              <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
              </IconButton>
            </Tooltip>
          </div>
        )
      },
    },
  ]
  const editData = (record) => {
    dispatch(getAdminDataById(record.id)).unwrap().then((res)=>{
      res['sendEmail'] = record?.sendEmail ? true : false
      for(let i =0 ; i<res.userAccessMap.length;i++){
        let obj = {
          pagination: -1,
          searchValue: "",
          limit: 10,
          status:{statusId:-1}
      }
      let data = {
          examId: res.userAccessMap[i]['examId'],
          obj
      }
        dispatch(getSubjectByExamId(data))
      }
      formik.setValues(res)
    })
    setCurrentId(record.id)
    setCurrentTitle("Edit Admin")
    let currentUser = adminMaster.filter((e) => e.id == record.id)
    formik.values.name = currentUser[0].name
    formik.values.email = currentUser[0].email
    formik.values.mobileNumber = currentUser[0].mobileNumber
    formik.values.roleId = currentUser[0].roleId
    if(currentUser[0].roleId == 1){
      setIsAdmin(true)
      setSubjectError(false)

    }else if(currentUser[0].roleId == 4 ||currentUser[0].roleId == 5 ){

      setIsAdmin(true)
      setSubjectError(true)

    }else{
      setIsAdmin(false)
      setSubjectError(false)
    }
    setVisible(true)
  }
  const deleteData = (record) => {
    setCurrentId(record.id)
    setIsModalVisible(true)

  }
  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit:limit

    }
    debounced(obj)

  }
  const debounced = useDebouncedCallback((obj) =>{
    dispatch(getAllAdminData(obj))

  },1000)

  const handleChange = (event) => {
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }
  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit:limit

    }

    dispatch(getAllAdminData(obj))
  }
  const openDrawer = () => {
    setCurrentId(null)
  formik.resetForm({values:init})

    setVisible(true)
    setCurrentTitle("Create Admin")
  }

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

 
  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false)
      let data = {
        payload:values,
        obj:{
          pagination: paginationData,
          searchValue: filterData,
          limit:limit
        }
      }
      setSubmitModalVisible(false);
      dispatch(addAdminData(data))

    }
  })
  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };


  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
        formik.values['mobileNumber'] = +formik.values['mobileNumber']
    let payload = {
      id: currentId,
      data: formik.values
    }
    let data = {
      payload:payload,
      obj:{
        pagination: paginationData,
        searchValue: filterData,
        limit:limit
      }
    }
    setUpdateModalVisible(false);
    setVisible(false)
    dispatch(updateAdminDataById(data))

  }
  const modalOnCancel = () => {
    setIsModalVisible(false)
  }
  const modalOnOk = () => {
    setIsModalVisible(false)
    dispatch(deleteAdminDataById(currentId))

  }
  const drawerCloser = () =>{
    setVisible(false)
    formik.resetForm()
  }

  const onExamChange=(value)=>{
    formik.setFieldValue('exam',value)
    let obj2= {
      pagination: 0,
      searchValue: filterData,
      limit: limit
    }
    let data={
      examId:value,
      obj:obj2
    }
    dispatch(getSubjectByExamId(data))
  }

  const onRoleChange=(value)=>{
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    if(value == 4 || value == 5){
      setIsAdmin(true)
      setSubjectError(true)
      tempValues['roleId'] = value
    }
    else if(value == 1){
      setIsAdmin(true)
      setSubjectError(false)
      tempValues['roleId'] = value

    }
   else if(value==2){
      setSubjectError(false)
      setIsAdmin(false)
      tempValues['roleId'] = value

    }
    else{
      setSubjectError(false)
      setIsAdmin(false)
      tempValues['roleId'] = value

    }
    formik.setValues(tempValues)


  }
  useEffect(()=>{
    formik.validateForm();
  },[subjectError,isAdmin])

  const addSection = () => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['userAccessMap'].push({ keyId: Date.now() , examId: "", subjectIds: []})
    formik.setValues(tempValues)
}
const remove = (index) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['userAccessMap'].splice(index, 1)
    formik.setValues(tempValues)

}

const onSelectExam = (event,index,name)=>{
  let tempValues = JSON.parse(JSON.stringify(formik.values))
  tempValues['userAccessMap'][index][name] = event
  tempValues['userAccessMap'][index]['subjectIds'] = []

  formik.setValues(tempValues)

  let obj = {
    pagination: -1,
    searchValue: "",
    limit: 10,
    status:{statusId:-1}
}
let data = {
    examId: event,
    obj
}
  dispatch(getSubjectByExamId(data))
}
const onSelectSubject = (event,index,name) =>{
  let tempValues = JSON.parse(JSON.stringify(formik.values))
  tempValues['userAccessMap'][index][name] = event
  formik.setValues(tempValues)
}
    return (
      <div className='admin-page'>
        <div className='adminHeader'>
          <h3 className='primary-header'>Admin Management</h3>
          <div className='admin-button' >
            <Button className='primary-submit-button' type="primary" onClick={openDrawer}>Add Admin </Button>
          </div>
        </div>
        <div className='all-table-component' style={{ padding: 0, margin: 0 }}>
          <div className="golbalSearch" >
            <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event)=>onSearch(event.target.value)} />
          </div>
          <TableComponent dataSource={adminMaster} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight}  totalData={totalDataCount} currentPage={paginationData + 1} loadingStatus={isLoading}  limit={limit} />
        </div>
        <Drawer title={currentTitle} onClose={() =>drawerCloser()} width="518px" closable={false} closeIcon={false} placement="right" open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='cancel-Button primary-cancel-button' onClick={drawerCloser} >Cancel</Button>
              {
                currentId == null ?
                  <Button className={!formik.isValid || !formik.dirty ?'disabled-button':'primary-submit-button'} type="primary" disabled={!formik.dirty || !formik.isValid} onClick={showSubmitConfirmation} >Submit </Button> :
                  <Button className={!formik.isValid ?'disabled-button':'primary-submit-button'} type="primary" disabled={!formik.isValid} onClick={showUpdateConfirmation}>Update </Button>
              }
            </div>
          </div>
        )}>
          <Formik validateOnChange={false}
            validateOnBlur={false}  >
            <Form>
              <div className='form-control'>
                <label htmlFor='name'>Name<span style={{ color: 'red' }}> *</span>
                </label>
                <Input type='text' id='name' placeholder="Enter a Name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.name} />
                {formik.touched.name && formik.errors.name ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.name}</div> : null}
              </div>
              <div className='form-control'>
                <label htmlFor='email'>Email
                <span style={{ color: 'red' }}> *</span>
                </label>
                <Input type='text' id='email' placeholder="Enter a Email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                {formik.touched.email && formik.errors.email ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.email}</div> : null}
              </div>
                    <div className='form-control'>
                <label htmlFor='mobileNumber'>Mobile No
                <span style={{ color: 'red' }}> *</span>
                </label>
                <div>
                  <Input id='mobileNumber' type="text" style={{ width: "100%" }} placeholder="Enter a Mobile Number" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mobileNumber} />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.mobileNumber}</div> : null}
                </div>
              </div>
              <div className='form-control' >
                <label htmlFor='roleId'>Role
                <span style={{ color: 'red' }}> *</span>
                </label>
                <div>
                <Select id="roleId" placeholder="Select a Role" name="roleId" style={{ width: "100%" }} ShowSearch onChange={(value) => onRoleChange(value)} onBlur={formik.handleBlur} value={formik.values.roleId} >
                    { roleMaster.map((data,index)=>(
                      <Option key={data.id}  value={data.id}>{data.name}</Option>

                    ))}
                  </Select>
                </div>
                {formik.touched.roleId && formik.errors.roleId ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.roleId}</div> : null}
              </div>
              {/* <div className='form-control'>
                <Checkbox onChange={formik.handleChange} checked={formik.values.sendEmail}   name="sendEmail" >Send Email to User</Checkbox>
              </div> */}
              {
                formik.values.roleId == 4 || formik.values.roleId == 5  || formik.values.roleId == 1 ?

                <div >
                  <div className='form-control' style={{marginTop:10}} >
                    <Button className='primary-submit-button' type="default" onClick={()=>addSection()} >Add Exam </Button> 
                  </div>
                {
                     formik.values && formik.values.userAccessMap ?
                     formik.values.userAccessMap.map((option, i) => {
                      return(

                      <div key={option.keyId}>
                        <Row>
                            <Col span={10}>
                                <div className='form-control' >
                                    <label htmlFor={`examId.${i}`}>
                                        Exam
                                    </label>
                                    <div>
                                        <Select id={`examId.${i}`} name={`examId.${i}`} style={{ width: "100%" }} placeholder="Select Option" onChange={(e) => { onSelectExam(e, i, 'examId') }} onBlur={formik.handleBlur}  value={formik.values.userAccessMap[i]['examId']}  >
                                            {examMasterTableData?.length ?
                                                examMasterTableData?.map((data, index) => (
                                                    <Option value={data.id} key={data.id} >{data.name}</Option>
                                                ))
                                                 : null} 
                                        </Select>
                                        {formik.touched['examId'] && formik.touched['examId'][i] && formik.errors.userAccessMap && formik.errors.userAccessMap[i]?.examId ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.userAccessMap[i]['examId']}</div> : null}

                                    </div>
                                </div>
                            </Col>
                            <Col span={1}>
                            </Col>
                            {
                              subjectError ? 
                              <Col span={10}>

                              <div className='form-control' >

                                  <label htmlFor={`subjectIds.${i}`}>
                                      Subject 
                                  </label>
                                  <div>
                                      <Select id={`subjectIds.${i}`}  mode='multiple' name={`subjectIds.${i}`} style={{ width: "100%" }} placeholder="Select Option" onChange={(e) => { onSelectSubject(e, i, 'subjectIds') }} onBlur={formik.handleBlur}  value={formik.values.userAccessMap[i]['subjectIds']}  >
                                      { objExamBySubject[formik.values.userAccessMap[i]['examId']]?.getSubjectData?.map((data, index) => (
                                        <Option value={data.id} key={data.id} >{data.name}</Option>
                                     ))}
                                      </Select>
                                      {formik.touched['subjectIds'] && formik.touched['subjectIds'][i] && formik.errors.userAccessMap && formik.errors.userAccessMap[i]?.subjectIds ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.userAccessMap[i]['subjectIds']}</div> : null}

                                  </div>
                              </div>
                          </Col> : null
                            }

                            <Col>
                            <div onClick={()=>remove(i)} className="userRoleExamCancel">
                              <p> X</p>
                            </div>
                            </Col>
                            </Row>

                      </div>
                      )
                     })
                     : null
                }
                </div>
                : null
              }

            </Form>
          </Formik>
        </Drawer>
        <Modal title="Deletion" open={isModalVisible} onCancel={modalOnCancel} onOk={modalOnOk} footer={
          <div>
          <Button className='primary-cancel-button' onClick={() => modalOnCancel()}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() =>modalOnOk()}>OK</Button>
        </div>
        } >
          <p>Are you sure want to delete ?</p>
        </Modal>

        
        {/* update model */}
        <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
          <div>
          <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() => updateData(formik.values)} >Update</Button>
        </div>
        }>
          <p>Are you sure you want to update?</p>
        </Modal>


        {/* submit modal */}

        <Modal
        title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
          <div>
            <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
            <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}> Submit </Button>
          </div>
        }
      >
        <p>Are you sure you want to Submit?</p>
      </Modal>
      </div>
    );

}
export default Admin;
