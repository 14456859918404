import { useSelector } from 'react-redux';
import { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Image, Input,Modal,Spin } from 'antd';
import { SearchOutlined ,LoadingOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getTestBundle,getEditData,deleteTestBundle } from './reducers/TestBundleSlice';
import './styles/TestBundle.scss'
import TableComponent from './Table/tableComponent';
import HomeImage from '../asset/image/createBundle.svg';
import DeleteImage from '../asset/image/dustbin.svg'
import { useDebouncedCallback } from 'use-debounce';

function TestBundle() {
  const navigate=useNavigate();

  const dispatch=useDispatch();

  const antIcon = (<LoadingOutlined style={{fontSize: 24,}}spin/>);
  const [filterData, setFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(10);


  
  useEffect(()=>{
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit:limit
    }
      dispatch(getTestBundle(obj))
  },[])

  let tableHeight = 320


  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { testBundleMasterTableData,isLoading, totalDataCount } = useSelector((state) => state.TestBundle)


  const [isModalVisible,setIsModalVisible]=useState(false);

  const [record,setRecord]=useState();


  const data=[
    {
      name:"TNPSC Group II & II A (TM)",
      status:"TNPSC",
      noOfTest:10,
      createdAt:'9 Aug 2021',
      noOfBuys:50
    },
    {
      name:"TNPSC Group II & II A (TM)",
      status:"TNPSC",
      noOfTest:10,
      createdAt:'9 Aug 2021',
      noOfBuys:50
    }
  ]

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'subjectName',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'No of test',
      dataIndex: 'noOfTest',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt', 
    },
    {
      title: 'No of Buys',
      dataIndex: 'noOfBuys',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <div>
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
            <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
          </div>
        )
      },
    },
  ]

  // editData function

  function editData(record) {
    dispatch(getEditData(record.id)).unwrap().then((data)=>{
      if(data.success==true){
        navigate('/EditTestBundle/'+data.resData.id)
      }
    })
  }

  // delete data function

  function deleteData(record){
    setIsModalVisible(true)
    setRecord(record)
  }

  // Delete confirmation function

  function deleteConfirm(){
    setIsModalVisible(false)
    dispatch(deleteTestBundle(record.id))
  }

  function handleCancel(){
    setIsModalVisible(false)
  }

  // new chapter function

  function newChapter() {
    navigate('/NewTestBundle')
  }

  // onSearch function

  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit:limit
    }
    debounced(obj)

  }
  const debounced = useDebouncedCallback((obj) =>{
    dispatch(getTestBundle(obj))

  },1000)

  // onSelect change function

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }


  // row selection function

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }


  // onTable change function

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit :limit
    }

    dispatch(getTestBundle(obj))
  }

  return (
    <div style={{height:'100%'}}>
      { !testBundleMasterTableData.length && filterData == "" ?   
      <div style={{height:'100%'}}>
        <div className='overall-homepage'>
        <div className='home-page'>
          <div><Image preview={false} src={HomeImage}></Image></div>
          <p className='homepage-header'>Create Your First Test</p>
          <p className='homepage-content'>Bundles on centumapp allow you to package your Test products together to sell to your students.</p>
          <Button onClick={() => newChapter()} type='primary'>New Bundle</Button>
        </div>
      </div>
      </div> 
      :
      
      <div>
        <div className='test-bundle-header'>
          <div><h3 style={{fontWeight: 600,fontSize:18,color: '#1B2228'}}>Test Bundle</h3></div>
          <div className='add-test-bundle'>
            <span className='add-new-test-bundle'><Button type='primary' onClick={() => newChapter()}> New Bundle</Button></span>
          </div>
        </div>
        <div className="golbalSearch" style={{ paddingBottom: 16, paddingTop: 16, paddingLeft: 16, background: '#fff', justifyContent: 'center' }}>
          <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event)=>onSearch(event.target.value)} />
        </div>
        <div>
          <TableComponent dataSource={testBundleMasterTableData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight}   totalData={totalDataCount} currentPage={paginationData + 1} loadingStatus={isLoading} />
        </div>

        {/* delete modal */}

        <Modal title="Delete Confirmation" open={isModalVisible} onOk={deleteConfirm} onCancel={handleCancel}>
        <p>Are you sure to delete ?</p>
        </Modal>

      </div> 



      }
    </div>
  )
}

export default TestBundle;