import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";

export const getloginData = createAsyncThunk(
  "login/loginData",
  async (payload, thunk) => {
    let loginData = await axios
      .post("user/admin/login", payload)
      .then((res) => {
        let loginData = {
          userId: res.data.userId,
          token: res.data.token,
          roleId: res.data.roleId,
          roleName: res.data.roleName,
          name: res.data.name
        };


        localStorage.setItem('authToken', loginData['token'])
        localStorage.setItem('roleId', loginData['roleId'])
        localStorage.setItem('roleName', loginData['roleName'])
        localStorage.setItem('institutionAdmin', false)
        localStorage.setItem('name', loginData['name'])
        return loginData;
      })
      .catch((error) => {
        return error
      });
    return loginData;
  }
);
export const getSavedata = createAsyncThunk(
  "question/getSavedata",
  async (payload) => {
    let loginData = await axios
      .get("/question", payload)
      .then((res) => {
        let loginData = {
          userId: res.data.userId,
          token: res.data.token,
        };
        return loginData;
      })
      .catch((error) => {
      });
    return loginData;
  }
);

export const setPassword = createAsyncThunk("login/resetPassword", async (payload) => {
  try {
    let resetPassword = await axios.post(`user/admin/createPassword/${payload.token}`, payload.data).then((res) => {
      if (res.data.success) {
        return res.data
      }
    })
    return resetPassword
  } catch (error) {
    message.error(error.response.data.message)

  }

})

export const verifyToken = createAsyncThunk("login/VerifyToken", async (payload) => {
  try {
    let verify = await axios.put(`user/admin/verifyToken/${payload}`).then((res) => {
      if (res.data.success) {
        return res.data
      }

    })
    return verify

  } catch (error) {
    message.error(error.response.data.message)
  }

})

export const forgotPasswordVerifyToken = createAsyncThunk("login/ForgotPasswordVerifyToken", async (payload) => {
  try {
    let verify = await axios.put(`user/admin/forgotPasswordVerifyToken/${payload}`).then((res) => {
      if (res.data.success) {
        return res.data
      }

    })
    return verify

  } catch (error) {
    message.error(error.response.data.message)
  }

})




export const forgotPassword = createAsyncThunk("login/forgotPassword", async (payload) => {
  try {
    let forgotPassword = await axios.post(`user/admin/forgotPassword`, payload).then((res) => {
      if (res.data.status) {
        return res.data
      }

    })
    return forgotPassword

  } catch (error) {
    message.error(error.response.data.message)
  }

})


const initialState = {
  isLoading: false,
  loginData: {
    userId: "",
    token: "",
  },
};
const loginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: {
    [getloginData.pending]: (state) => {
      state.isLoading = true

    },
    [getloginData.fulfilled]: (state, actions) => {
      state.isLoading = false

    },
    [getloginData.rejected]: (state) => {
      state.isLoading = false

    },
    [verifyToken.pending]: (state) => {
      state.isLoading = true
    },
    [verifyToken.fulfilled]: (state) => {
      state.isLoading = false
    },
    [verifyToken.rejected]: (state) => {
      state.isLoading = false
    },
    [forgotPasswordVerifyToken.pending]: (state) => {
      state.isLoading = true
    },
    [forgotPasswordVerifyToken.fulfilled]: (state) => {
      state.isLoading = false
    },
    [forgotPasswordVerifyToken.rejected]: (state) => {
      state.isLoading = false
    },
    [forgotPassword.pending]: (state) => {
      state.isLoading = true
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isLoading = false
    },
    [forgotPassword.rejected]: (state) => {
      state.isLoading = false
    },
    [setPassword.pending]: (state) => {
      state.isLoading = true
    },
    [setPassword.fulfilled]: (state) => {
      state.isLoading = false
    },
    [setPassword.rejected]: (state) => {
      state.isLoading = false
    },
  },
});

export default loginSlice.reducer;
