import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from "moment";


export const addNotification = createAsyncThunk("websiteNotification/addNotification", async (payload, thunk) => {
    try {
        let insertData = await axios.post(`notification/createNotification`, payload.data)
            .then(async (res) => {
                if (res.data.data.status) {
                    message.success(res.data.data.message)
                    await thunk.dispatch(getAllNotificationData(payload.obj))
                    return res.data;
                }
            });
        return insertData;
    } catch (error) {
        message.error(error.response.data.message);
    }
}
);

export const getNotificationById = createAsyncThunk("websiteNotification/getNotificationById", async (payload) => {
    try {
        let notification = await axios.get(`notification/getNotificationById/${payload}`).then((res) => {
            if (res.data.status) {
                return res.data;
            }
        });
        return notification;
    } catch (error) {
        message.error(error.response.data.message);
    }
}
);

export const getAllNotificationData = createAsyncThunk(
    "websiteNotification/getAffairs",
    async (payload) => {
        try {
            let getAllData = await axios
                .post("notification/getAllNotification", payload)
                .then((res) => {
                    if (res.data.success) {
                        return res.data.data;
                    }
                });
            return getAllData;
        } catch (error) {
            message.error();
        }
    }
);

export const updateNotificationData = createAsyncThunk("websiteNotification/updateNotificationData", async (payload, thunk) => {
    try {
        let community = await axios.put(`notification/updateNotification/${payload.id}`, payload.data).then(async (res) => {
            if (res.data.data.status) {
                message.success(res.data.data.message)
                await thunk.dispatch(getAllNotificationData(payload.obj))
                return res.data.data
            }
        })
        return community
    } catch (error) {
        message.error(error.response.data.message)

    }
})



export const deleteNotificationData = createAsyncThunk('websiteNotification/deleteNotificationData', async (payload, thunk) => {
    try {
        let community = await axios.delete(`notification/deleteNotification/${payload.id}`).then(async (res) => {
            if (res.data.data.status) {
                message.success(res.data.data.message)
                await thunk.dispatch(getAllNotificationData(payload.obj))
                return res.data
            }
        })
        return community
    }
    catch (error) {
        message.error(error.response.data.message)
    }
})


const initialState = {
    NotificationData: [],
    isLoading: false,
    currentDataCount: 0,
    currentData: "",
    NotificationDataById: ""
};

const WebsiteNotificationSlice = createSlice({
    name: "WebsiteNotificationSlice",
    initialState,
    extraReducers: {
        [addNotification.pending]: (state) => {
            state.isLoading = true;
        },
        [addNotification.fulfilled]: (state, actions) => {
            state.isLoading = false;
        },
        [addNotification.rejected]: (state) => {
            state.isLoading = false;
        },
        [getAllNotificationData.pending]: (state) => {
            state.isLoading = true;
        },
        [getAllNotificationData.fulfilled]: (state, actions) => {
            state.isLoading = false;
            state.currentData = actions.payload?.getNotificationData;
            state.currentDataCount = actions.payload.getNotificationCount.notificationCount;

        },
        [getAllNotificationData.rejected]: (state) => {
            state.isLoading = false;
        },
        [updateNotificationData.pending]: (state) => {
            state.isLoading = true;
        },
        [updateNotificationData.fulfilled]: (state, actions) => {
            state.isLoading = false;
        },
        [updateNotificationData.rejected]: (state) => {
            state.isLoading = false;
        },
        [deleteNotificationData.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteNotificationData.fulfilled]: (state, actions) => {
            state.isLoading = false;
        },
        [deleteNotificationData.rejected]: (state) => {
            state.isLoading = false;
        },
        [getNotificationById.pending]: (state) => {
            state.isLoading = true
        },
        [getNotificationById.fulfilled]: (state, actions) => {
            state.isLoading = false
            state.NotificationDataById = actions.payload.data
        },
        [getNotificationById.rejected]: (state) => {
            state.isLoading = false
        },
    },
});
export default WebsiteNotificationSlice.reducer;
