import { useState, useEffect } from 'react';
import { Button, Input, Image, Modal, Drawer, Select, Tabs } from 'antd';
// import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup'
// import TableComponent from './Table/tableComponent';
// import deleteImage from '../asset/image/deleteIcon.svg';
// import exportImage from '../asset/image/Import.svg';
// import importImage from '../asset/image/export.svg';
import './styles/InstitutionMaster.scss';
// import IconButton from "@material-ui/core/IconButton";
// import Tooltip from "@material-ui/core/Tooltip";
import { getInstitutionById, getAllTest, addNewPlan } from './reducers/institutionMasterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPurchaseTest } from './reducers/institutionMasterSlice'


const IndividualInstitute = () => {

  const [filterData, setFilterData] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [record, setRecord] = useState({});
  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const { Option } = Select;
  const { TabPane } = Tabs;
  const [tabStatus, setTabStatus] = useState("1")
  const [singleTestPaper, setSingleTestPaper] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();

  const { instituteTableData, isLoading, instituteDataById, getTest } = useSelector((state) => (state.InstitutionSlice))
  const params = useParams()
  const dispatch = useDispatch()
  const onTabChange = (data) => {
    setTabStatus(data)
    if (data == 1) {
      navigate(`/IndividualInstitute/${params.id}/getIndividualInstituteDetails`)
    }
    else {
      navigate(`/IndividualInstitute/${params.id}/getIndividualInstitutePurchaseList`)
    }
  }


  useEffect(() => {
    if (location.pathname.split('/')[location.pathname.split('/').length - 1] == 'getIndividualInstituteDetails') {
      onTabChange("1")
    } else if (location.pathname.split('/')[location.pathname.split('/').length - 1] == 'getIndividualInstitutePurchaseList') {
      onTabChange("2")
    }
    dispatch(getInstitutionById(params.id));
  }, [])

  // on select change
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }

  // row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }



  // delete data
  const deleteData = (record) => {
    setRecord(record)
    setIsModalVisible(true)
  }

  // search
  const search = (value) => {

    setFilterData(value)
    // debounced(data)

  }

  // add student function
  const addTestNewPlan = () => {
    individualInstituteFormik.resetForm()
    dispatch(getAllTest()).unwrap().then((res) => {
      setVisible(true)
      setCurrentId(null)
      setCurrentTitle("New Subscription")
    })
  }



  const onChangeSelect = (e, option, fieldHelpers) => {
    fieldHelpers.setValue(e)
    if (option.type == "normalTest") {
      setSingleTestPaper(false)
    } else {
      setSingleTestPaper(true)
    }
  }
  // on modal close
  const onModalClose = () => {
    setIsModalVisible(false);
  }

  // confirm delete
  const confirmDelete = () => {
    setIsModalVisible(false)
  }

  // update data

  const updateData = () => {
    setVisible(false)
    individualInstituteFormik.setValues(initialValues)
    individualInstituteFormik.resetForm()
  }

  // onclose
  const onClose = () => {
    setVisible(false);
    individualInstituteFormik.setValues(initialValues)
    individualInstituteFormik.resetForm()
  }

  // individual institution formik

  const initialValues = {
    chooseTest: '',
    numberOfTests: '',
    testValid: '',
    amount: 0,
    price: '',
    isSingleTestPaper: 0
  }
  const validationSchema = yup.object({
    chooseTest: yup.string().required("Test is Required"),
    numberOfTests: yup.number().required("Number of Tests is Required")
      .positive('numberOfTest must be a positive integer')
      .integer('numberOfTest must be an integer'),
    testValid: yup.string().required("Test valid Date is Required")
    // .matches(/^\d{2}-\d{2}-\d{4}$/, 'Invalid date format (DD-MM-YYYY)')
    ,
    // amount: yup.string().required("Amount is Required"),
    price: yup.number().required("Price is Required")
      .positive('Price must be a positive integer')
      .integer('Price must be an integer')

  })



  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };


  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const individualInstituteFormik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false)
      console.log('values', values)
      let datas = {
        data: {
          testId: +values.chooseTest,
          numberOfTest: +values.numberOfTests,
          testValidityDate: values.testValid,
          amount: +values.amount,
          price: +values.price,
          isSingleTestPaper: singleTestPaper
        },
        institutionId: +params.id
      }
      setSubmitModalVisible(false);
      dispatch(addNewPlan(datas)).unwrap().then((res) => {
        let institutionGetDetails = {
          obj: {
            pagination: 0,
            searchValue: '',
            limit: 100
          },
          institutionId: +params.id
        }

        dispatch(getAllPurchaseTest(institutionGetDetails));
      })

    }


  })
  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };


  return (
    <div className="individual-institution">
      <div className="institution-header">
        <p className="header-name">{instituteDataById.name}</p>
        <div className="header-wrapper">
          <div className="create-student"><Button className='create-button' onClick={() => addTestNewPlan()}>Add New Plan</Button></div>
        </div>
      </div>
      <div>
        <div className='individual-instution-content'>
          <Tabs activeKey={tabStatus} onChange={onTabChange} >
            <TabPane tab="Institution Details " key="1">
              <div className='individual-instution-tab'> <Outlet></Outlet></div>
            </TabPane>
            <TabPane tab="Purchase list" key="2">
              <div className='individual-instution-tab'> <Outlet></Outlet></div>
            </TabPane>
          </Tabs>

        </div>


        {/* drawer */}

        <Drawer closable={false} title={currentTitle} placement="right" onClose={() => onClose()} open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose()}>Cancel</Button>
              {
                currentId == null ?
                  <Button type='primary' className={!individualInstituteFormik.isValid || !individualInstituteFormik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!individualInstituteFormik.dirty || !individualInstituteFormik.isValid} onClick={showSubmitConfirmation}>Save</Button>
                  :
                  <Button className={!individualInstituteFormik.dirty || !individualInstituteFormik.isValid ? 'disabled-button' : 'primary-submit-button'} disabled={!individualInstituteFormik.isValid} type='primary' onClick={() => updateData()} >Update</Button>
              }
            </div>
          </div>
        )}>
          <form>
            <div>
              <div className='form-control'>
                <label>Choose Test <span style={{ color: 'red' }}>*</span></label>

                <Select showSearch style={{ width: "100%" }} placeholder="Select a Test" optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(e, option) => { onChangeSelect(e, option, individualInstituteFormik.getFieldHelpers('chooseTest')) }}
                  onBlur={individualInstituteFormik.handleBlur}
                  value={individualInstituteFormik.values.chooseTest}>
                  {getTest.getTestMasterData?.map((data, index) => (
                    <Option key={index} value={data.id} type={data.type}>{data.name}</Option>
                  ))}
                </Select>
                {!individualInstituteFormik.touched['chooseTest'] && individualInstituteFormik.errors.chooseTest ? <div className='error' style={{ color: 'red' }}>{individualInstituteFormik.errors.chooseTest}</div> : null}


              </div>
              <div className='form-control'>
                <label>Number of tests <span style={{ color: 'red' }}>*</span></label>
                <Input type='number' maxLength={50} id='numberOfTests' name='numberOfTests' placeholder="Enter Number of Test" onChange={individualInstituteFormik.handleChange} onBlur={individualInstituteFormik.handleBlur} value={individualInstituteFormik.values.numberOfTests}></Input>
                {individualInstituteFormik.touched.numberOfTests && individualInstituteFormik.errors.numberOfTests ? <div className='error'>{individualInstituteFormik.errors.numberOfTests}</div> : null}
              </div>
              <div className='form-control test-valid-content'>
                <label>Price <span style={{ color: 'red' }}>*</span></label>
                <Input type='number' maxLength={50} id='price' name='price' placeholder="Choose Price" onChange={individualInstituteFormik.handleChange} onBlur={individualInstituteFormik.handleBlur} value={individualInstituteFormik.values.price}></Input>
                {individualInstituteFormik.touched.price && individualInstituteFormik.errors.price ? <div className='error'>{individualInstituteFormik.errors.price}</div> : null}
              </div>

              <div className='form-control test-valid-content'>
                <label>Validity to <span style={{ color: 'red' }}>*</span></label>
                <Input
                  type='date'
                  maxLength={50}
                  placeholder='Select Date'
                  id='testValid'
                  name='testValid'
                  onChange={individualInstituteFormik.handleChange}
                  onBlur={individualInstituteFormik.handleBlur}
                  value={individualInstituteFormik.values.testValid}
                  min={new Date().toISOString().split('T')[0]} ></Input>
                {individualInstituteFormik.touched.testValid && individualInstituteFormik.errors.testValid ? (
                  <div className='error'>{individualInstituteFormik.errors.testValid}</div>
                ) : null}
              </div>

              <div className='form-control test-valid-content'>
                <label>Amount</label>
                <Input type='number' maxLength={50} id='amount' name='amount' placeholder='Rs.000,00' onChange={individualInstituteFormik.handleChange} onBlur={individualInstituteFormik.handleBlur} value={individualInstituteFormik.values.amount = individualInstituteFormik.values.numberOfTests * individualInstituteFormik.values.price} disabled></Input>
                {/* {individualInstituteFormik.touched.amount && individualInstituteFormik.errors.amount ? <div className='error'>{individualInstituteFormik.errors.amount}</div> : null} */}
              </div>
            </div>
          </form>
        </Drawer>

        {/* delete modal */}

        <Modal title="Deletion" open={isModalVisible} onOk={() => confirmDelete()} onCancel={() => onModalClose()}>
          <p>Are you sure want to delete ?</p>
        </Modal>
        {/* save model */}
        <Modal
          title="Save Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
            <div>
              <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
              <Button className="primary-submit-button" type="default" onClick={individualInstituteFormik.handleSubmit}> Save </Button>
            </div>
          }
        >
          <p>Are you sure you want to Save?</p>
        </Modal>
      </div>
    </div>
  )
}

export default IndividualInstitute;