import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

export const createCommunity = createAsyncThunk("Community/createCommunity", async (payload, thunk) => {
  try {
    let community = await axios.post(`community/createCommunity`, payload.data)
      .then(async (res) => {
        if (res.data.data.status) {
          message.success(res.data.data.message)
          await thunk.dispatch(getAllCommunity(payload.obj))
          return res.data;
        }
      });
    return community;
  } catch (error) {
    message.error(error.response.data.message);
  }
}
);

export const getCommunityById = createAsyncThunk("Community/getCommunityById", async (payload) => {
  try {
    let community = await axios.get(`community/getCommunityById/${payload}`).then((res) => {
      if (res.data.status) {
        return res.data;
      }
    });
    return community;
  } catch (error) {
    message.error(error.response.data.message);
  }
}
);

export const getAllCommunity = createAsyncThunk("Community/getAllCommunity", async (payload) => {
  try {
    let community = await axios.post(`community/getAllCommunity`, payload).then((res) => {
      if (res.data.success) {
        return res.data;
      }
    });
    return community;
  } catch (error) {
    message.error(error.response.data.message);
  }
}
);


export const updateCommunity = createAsyncThunk("Community/updateCommunity", async (payload, thunk) => {
  try {
    let community = await axios.put(`community/updateCommunity/${payload.id}`, payload.data).then(async (res) => {
      if (res.data.data.status) {
        message.success(res.data.data.message)
        await thunk.dispatch(getAllCommunity(payload.obj))
        return res.data.data
      }
    })
    return community
  } catch (error) {
    message.error(error.response.data.message)

  }
})

export const deleteCommunity = createAsyncThunk('Community/deleteCommunity', async (payload, thunk) => {
  try {
    let community = await axios.delete(`community/deleteCommunity/${payload.id}`).then(async (res) => {
      if (res.data.data.status) {
        message.success(res.data.data.message)
        await thunk.dispatch(getAllCommunity(payload.obj))
        return res.data
      }
    })
    return community
  }
  catch (error) {
    message.error(error.response.data.message)
  }
})


export const getSocialMedia = createAsyncThunk('Community/getSocialMedia',async() =>{
  try{
    let socialMedia = await axios.get(`community/getSocialMedia`).then(async (res) =>{
      if(res){
        return res.data
      }
    })
    return socialMedia
  }
  catch(error){
    message.error(error.response.data.message)
  }
})




const initialState = {
  communityData: [],
  isLoading: false,
  communityDataCount: 0,
  communityDataById: {}
};

const CommunitySlice = createSlice({
  name: "CommunityMaster",
  initialState,
  extraReducers: {
    [createCommunity.pending]: (state) => {
      state.isLoading = true
    },
    [createCommunity.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [createCommunity.rejected]: (state) => {
      state.isLoading = false
    },
    [getAllCommunity.pending]: (state) => {
      state.isLoading = true
    },
    [getAllCommunity.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.communityDataCount = actions.payload.data.getCommunityCount.communityCount
      state.communityData = actions.payload.data.getCommunityData
    },
    [getAllCommunity.rejected]: (state) => {
      state.isLoading = false
    },
    [getCommunityById.pending]: (state) => {
      state.isLoading = true
    },
    [getCommunityById.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.communityDataById = actions.payload.data
    },
    [getCommunityById.rejected]: (state) => {
      state.isLoading = false
    },
    [updateCommunity.pending]: (state) => {
      state.isLoading = true
    },
    [updateCommunity.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [updateCommunity.rejected]: (state) => {
      state.isLoading = false
    },
    [deleteCommunity.pending]: (state) => {
      state.isLoading = true
    },
    [deleteCommunity.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [deleteCommunity.rejected]: (state) => {
      state.isLoading = false
    },
    [getSocialMedia.pending]: (state) => {
      state.isLoading = true
    },
    [getSocialMedia.fulfilled]: (state,actions) => {
      state.socialMediaMasterData = actions.payload.data
      state.isLoading = false
    },
    [getSocialMedia.rejected]: (state) => {
      state.isLoading = true
    },

  },
});

export default CommunitySlice.reducer;
