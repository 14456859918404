import React, { useState, useEffect, useRef } from "react";
import { Button, Image, Input, Modal, Select, Drawer, Switch } from 'antd'
import { Option } from 'antd/lib/mentions';
import { useFormik } from "formik";
import { SearchOutlined, LoadingOutlined, } from "@ant-design/icons";
import TableComponent from "./Table/tableComponent.js";
import { useDispatch, useSelector } from "react-redux";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { insertGroupClusterTable, getGroupClusterTable, getClusGroupDateFilter, updateClusterGroupDetails, deleteGroupClusterTable, getFrequency, getClusterName, getClusterGroupById, getClusterGroupFilter } from "./reducers/ClusterGroupMasterSlice.js";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BreadCrumb from "./BreadCrumb.js";

import { handleToast, filehandleChangeHelper, handleFileRemoveHelper, hasErrorsInPriceAndFrequencyHelper, handlePricingDataHelper, constructFrequencyHelper } from "../utils/HelperFunction.js";
import { imageFilter, imageFilterMessage, DefaultImageDimensions, DefaultImageAspectRatio } from "../utils/Variable.js";
import FileUpload from "./Table/FileUpload.js";
import PricingList from "../components/Table/PricingList.js";


function ClusterGroupMaster() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [currentRecord, setCurrentRecord] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
    const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const hiddenFile = useRef(null);
    const [paginationData, setPaginationData] = useState(0);
    const [filterData, setFilterData] = useState("");
    const [limit, setLimit] = useState(50);
    const [currentTitle, setCurrentTitle] = useState("");
    const [currentId, setCurrentId] = useState(null);
    const [mappingId, setMappingId] = useState(null);
    let [uploadFile, setFile] = useState([]);
    const [buttonState, setButtonState] = useState(1);
    const [approveId, setApproveId] = useState(null);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const { TextArea } = Input;
    const [isApproval, setIsApproval] = useState(false);
    const [isSubjectShow, setIsSubjectShow] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false);
    const [clusterFilterData, setClusterFilterData] = useState("");
    const [createdAtFilterData, setCreatedAtFilterData] = useState("");
    const [open, setOpen] = useState(false);
    const [deletedAttachment, setDeletedAttachment] = useState([]);
    const [frequencyMap, setFrequencyMap] = useState({});
    const [isEditAction, setIsEditAction] = useState(false);
    const [prices, setPrices] = useState([]);

    const selectedFrequencyIds = prices.map(item => item.frequencyId);

    const onChange = (checked) => {
        setOpen(checked);
    };

    const [selectedDate, setSelectedDate] = useState(null);

    const { clusterGroupMasterTableData, isLoading, examDataCount, frequencyData, getClusterNameData, clusterGroupNameFilter, clusterGroupCreatedAt } = useSelector(
        (state) => state.ClusterGroupMasterSlice
    );

    const roleId = localStorage.getItem("roleId");

    useEffect(() => {
        let obj = {
            offSet: paginationData,
            searchInput: filterData,
            limit: limit,
            clusterFilter: clusterFilterData,
            createdAtFilter: createdAtFilterData,
        };
        dispatch(getGroupClusterTable(obj))
            .unwrap().then((res) => {
                try {
                    // handleToast(res);
                } catch (error) {
                    // handleToast(res);
                }
            })
        dispatch(getFrequency())
            .unwrap()
            .then((data) => {
                constructFrequencyHelper(data, setFrequencyMap)
            })
            .catch((error) => { });
        dispatch(getClusterGroupFilter())
        dispatch(getClusGroupDateFilter())
        dispatch(getClusterName())

    }, []);
    // useEffect(() => {


    // }, []);

    const handleDateChange = (value) => {
        setSelectedDate(value); // Update selected date state
    };

    let tableHeight = 320;

    const [visible, setVisible] = useState(false);
    const [disabledButton, setDisabled] = useState(false);

    const [chapterDetails, setChapterDetails] = useState("");
    const uniqueDates = [...new Set(clusterGroupMasterTableData.map(record => moment(record.updatedAt).format('DD MMM YYYY')))];


    const columns = [

        {
            title: 'Cluster Name',
            dataIndex: "clusterName",
            key: "clusterName",
            sorter: {
                compare: (a, b) => {
                    if (a.clusterName < b.clusterName) return -1;
                    if (a.clusterName > b.clusterName) return 1;
                    return 0;
                },
                multiple: 3,
            }
        },
        {
            title: 'Cluster Group',
            dataIndex: "groupName",
            key: "groupName",
            sorter: {
                compare: (a, b) => {
                    if (a.groupName < b.groupName) return -1;
                    if (a.groupName > b.groupName) return 1;
                    return 0;
                },
                multiple: 3,
            }
        },
        {
            title: "Created Date",
            dataIndex: "updatedAt",
            key: "updatedAt",
            sorter: {
                compare: (a, b) => {
                    if (a.updatedAt < b.updatedAt) return -1;
                    if (a.updatedAt > b.updatedAt) return 1;
                    return 0;
                },
                multiple: 3,
            },
            render: (_, record) => {
                return (
                    <div>
                        <span>{moment(record.updatedAt).format("DD MMM YYYY")}</span>
                    </div>
                );
            }
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => {
                return (
                    <>
                        {roleId == 1 || roleId == 3 ? (
                            <div className="table-action-link">
                                <a
                                    style={{ paddingRight: 10 }}
                                    onClick={() => editData(record)}
                                >
                                    Edit
                                </a>
                                {roleId == 3 ? (
                                    <Tooltip title="Delete" style={{ padding: 0 }}>
                                        <IconButton>
                                            <Image
                                                src={DeleteImage}
                                                preview={false}
                                                onClick={() => deleteData(record)}
                                            ></Image>
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </div>
                        ) : (
                            <div>Not Authorized</div>
                        )}
                    </>
                );
            },
        },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const editData = async (_data) => {
        setCurrentId(_data?.id);
        setMappingId(_data?.mappingId);
        setIsEditAction(false);
        setOpen(false);
        setPrices([]);
        dispatch(getClusterGroupById(_data?.id)).unwrap().then(async (res) => {
            if (res?.status) {
                const { clusterId, clusterGroup, planPricing, freeTrial, attachmentUrl, name } = res?.data;
                attachmentUrl && name ? setFile([{ url: attachmentUrl, name, id: _data?.id }]) : setFile([]);
                await handlePricingDataHelper(formik, setPrices, planPricing);
                formik.setFieldValue("name", clusterId);
                formik.setFieldValue("group", clusterGroup);
                setOpen(freeTrial === 1);
                setVisible(true);
            };
        })
        setCurrentTitle("Edit Cluster Group");
    };

    const updateData = async () => {

        let datas = await handleClusterGroup(formik?.values);
        datas['id'] = currentId;
        datas['mappingId'] = mappingId;

        setUpdateModalVisible(false);
        setVisible(false);
        dispatch(updateClusterGroupDetails(datas))
            .unwrap()
            .then((res) => {
                try {
                    handleToast(res);
                    !res.resSuccess ? setVisible(true) : setVisible(false);
                } catch (error) {
                    setVisible(true);
                    // handleToast(res);
                }
            });
    };

    function onClose(values) {
        setVisible(false);
    }

    const tableOnChange = (pagination, filters, sort, extra) => {
        setPaginationData(pagination?.current - 1);

        let obj = {
            offSet: paginationData,
            searchInput: filterData,
            limit: limit,
            clusterFilter: clusterFilterData,
            createdAtFilter: createdAtFilterData,
        };

        dispatch(getGroupClusterTable(obj));
    };

    const onSearch = (value) => {
        setFilterData(value);
        let obj = {
            offSet: paginationData,
            searchInput: filterData,
            limit: limit,
            clusterFilter: clusterFilterData,
            createdAtFilter: createdAtFilterData,
        };
        debounced(obj);
    };

    const debounced = useDebouncedCallback((obj) => {
        dispatch(getGroupClusterTable(obj));
    }, 1000);

    const handleClusterGroupChange = (e, value) => {
        setClusterFilterData(e);
        let obj = {
            offSet: paginationData,
            limit: limit,
            searchInput: filterData,
            clusterFilter: e,
            createdAtFilter: createdAtFilterData
        };

        dispatch(getGroupClusterTable(obj));
    };

    const handleCreatedAtChange = (e, value) => {
        setCreatedAtFilterData(e);
        let obj = {
            offSet: paginationData,
            limit: limit,
            searchInput: filterData,
            createdAtFilter: e,
            clusterFilter: clusterFilterData
        };
        dispatch(getGroupClusterTable(obj));
    };


    const onSelectChange = (selectData) => {
        setSelectedRowKeys(selectData);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const onFrequencyChange = (value) => {
        formik.setFieldValue("frequencyId", value);
    };
    const onChangeCluster = (value) => {
        formik.setFieldValue("name", value);
    };

    const closeSubmitModal = () => {
        setSubmitModalVisible(false);
    };

    const showSubmitConfirmation = (values) => {
        setSubmittedData(values);
        setSubmitModalVisible(true);
    };

    const handleClusterGroup = async (values) => {

        const { name, group, actualPrice, price, frequencyId } = values;

        let data = new FormData();

        let pricingPlan = [
            {
                actualPrice,
                price,
                frequencyId,
            },
            ...prices
        ];

        data.append("clusterId", name);
        data.append("name", group);
        data.append("pricingPlan",
            JSON.stringify(pricingPlan)
        );

        data.append("freeTrial", open);

        const tempFile = uploadFile?.[0] || {};
        if (tempFile) {
            data.append(`file`, tempFile);
        };

        let datas = {
            data,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
            }
        };

        return datas;
    };

    async function onSubmit(values) {

        let datas = await handleClusterGroup(values);

        setSubmitModalVisible(false);
        setVisible(false);
        dispatch(insertGroupClusterTable(datas)).unwrap().then((res) => {
            try {
                handleToast(res);
                !res.resSuccess ? setVisible(true) : setVisible(false);
            }
            catch (error) {
                setVisible(true);
                // handleToast(res);
            }
        });
    };

    const filteredData = selectedDate
        ? clusterGroupMasterTableData.filter(record => moment(record.updatedAt).format('DD MMM YYYY') === selectedDate)
        : clusterGroupMasterTableData;


    const handleFormValidation = (isValid) => {
        setIsFormValid(isValid);
    };

    const deleteData = (data) => {
        setCurrentRecord(data);
        setIsModalVisible(true);
        setCurrentId(data?.id);
        setMappingId(data?.mappingId);
    };

    const confirmDelete = () => {
        setIsModalVisible(false)
        let data = {
            id: currentId,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
            }
        }
        dispatch(deleteGroupClusterTable(data)).unwrap().then((res) => {
            try {
                handleToast(res);
            } catch (error) {
                // handleToast(res);
            }
        })
    }
    function onModalClose() {
        setIsModalVisible(false);
        setIsRejectModalVisible(false);
        remarkFormik.resetForm();
    };

    const initialValues = {
        name: "",
        group: "",
        actualPrice: "",
        price: "",
        frequencyId: "",
        attachment: ""
    };

    const validationSchema = yup.object({
        name: yup.string().required("Cluster Name is Required"),
        group: yup.string()
            .required("Cluster Group Name is Required")
            .min(2, "Cluster Group Name Needed At Least Two characters")
            .max(100, "Cluster Group Name not more than 100 characters"),
        actualPrice: yup.number()
            .required("Actual price is Required")
            .positive('Actual price must be a positive integer')
            .integer('Actual price must be an integer'),
        price: yup.number()
            .required("Price is Required")
            .positive('Price must be a positive integer')
            .integer('Price must be an integer')
            .when('actualPrice', (actualPrice, schema) =>
                schema.max(actualPrice, 'Price must be less than or equal to the actual price')
            ),
        frequencyId: yup.string().required("Frequency is Required"),
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const remarkInitialValues = { remarks: "" };
    const remarkValidationSchema = yup.object({
        remarks: yup.string().required("Remarks is required"),
    });

    const remarkFormik = useFormik({
        initialValues: remarkInitialValues,
        validationSchema: remarkValidationSchema,
    });
    const openDrawer = () => {
        setIsApproval(false);
        setIsEditAction(false);
        setOpen(false);
        setFile([]);
        setCurrentId(null);
        setMappingId(null);
        setVisible(true);
        setButtonState(1);
        setPrices([]);
        setCurrentTitle("New Cluster Group");
        formik.resetForm({ values: initialValues });
    };
    const closeUpdateModal = () => {
        setUpdateModalVisible(false);
    };

    const showUpdateConfirmation = () => {
        setUpdateModalVisible(true);
    };

    const onTypeofTestChange = (e, data) => {
        setIsAvailable(false)

        formik.setFieldValue("typeOfTestId", e)
        formik.setTouched({
            typeOfTestId: false
        })

        if (e != 1) {
            setIsSubjectShow(true)
            let id = editData.subjectId
            formik.setFieldValue("subjectId", id)
        }
        else {
            setIsSubjectShow(false)
            formik.setFieldValue("subjectId", null)
        }
    };

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    };

    const breadCrumbData = {};


    const filehandleChange = async ({ file, fileList }) => {
        await filehandleChangeHelper(imageFilter, imageFilterMessage, file, uploadFile, setFile, formik, 'attachment', 1, false, 0, '', { });
    };

    const handleFileRemove = async (file) => {
        await handleFileRemoveHelper(file, setDeletedAttachment, deletedAttachment, setFile, formik, 'attachment');
    };

    const deletePricing = (values, index) => {
        const updatedPrices = prices?.filter((_, i) => i !== index);
        setPrices(updatedPrices);
    };

    const addPrice = (values) => {
        const { name, attachment, group, ...rest } = values;
        const newPrice = { ...rest };
        setPrices([...prices, newPrice]);
        formik.setFieldValue("price", "");
        formik.setFieldValue("frequencyId", "");
        formik.setFieldValue("actualPrice", "");
    };

    return (
        <div className="exam-master-page">
            <div>

                <div className="breadCrumb-Data">
                    <BreadCrumb data={breadCrumbData} loading={isLoading}></BreadCrumb>
                </div>

                <div className="exam-header">
                    <div>
                        <h3 className="primary-header">Cluster Group Master</h3>
                    </div>


                    <div className="download-new-exam-btn">
                        <div className="add-exam">
                            {roleId == 3 && !params.statusName ? (
                                <span className="add-new-exam">
                                    <Button
                                        type="primary"
                                        className="primary-submit-button"
                                        onClick={() => openDrawer()}
                                    >
                                        {" "}
                                        New Cluster Group
                                    </Button>
                                </span>
                            ) : null}
                        </div>



                    </div>



                </div>
                <div className="table-header">
                    <div>
                        <div className="golbalSearch">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
                                style={{ width: 262, height: 32 }}
                                onChange={(event) => onSearch(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="cluster-normalPage">
                        <div className="form-control">
                            <label>
                                <span style={{ color: "#646C7A", fontSize: "13px", fontWeight: "500" }}>Sort by</span>
                            </label>
                        </div>
                        <div className='form-control'>
                            <div className="clusterFilter" style={{ width: "134px" }}>
                                <Select
                                    id="clusterFilter"
                                    name="clusterFilter"
                                    placeholder="Cluster"
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    onChange={handleClusterGroupChange}
                                    allowClear
                                >
                                    {clusterGroupNameFilter.map((clusterName, index) => (
                                        <Option key={index + 'clusterFilter'} value={clusterName?.clusterName}>
                                            {clusterName?.clusterName}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className='form-control'>
                            <div>
                                <Select
                                    id="typeOfTestId"
                                    name="typeOfTestId"
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    placeholder="Date Created"
                                    onChange={handleCreatedAtChange}
                                    allowClear
                                >
                                    {clusterGroupCreatedAt?.map((date, index) => (
                                        <Option key={index + 'createdAtFilter'} value={date.date}>
                                            {date.date}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>




                {/* Add exam drawer */}

                <Drawer
                    closable={false}
                    title={currentTitle}
                    placement="right"
                    onClose={() => onClose(formik.values)}
                    open={visible}
                    footer={
                        <div className="footer">
                            <div className="footer-button coachmi-footer">
                                <Button
                                    className="primary-cancel-button coachmi-primary-cancel-button"
                                    onClick={() => onClose(formik.values)}
                                >
                                    Cancel
                                </Button>
                                <>
                                    {currentId ? (
                                        <Button
                                            type="primary"
                                            className={` 
                                                ${!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} coachmi-primary-submit-button`}
                                            disabled={!formik.isValid || !formik.dirty}
                                            onClick={showUpdateConfirmation}
                                        >
                                            Update{" "}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            className={` 
                                                ${!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} coachmi-primary-submit-button`}
                                            disabled={!formik.dirty || !formik.isValid}
                                            onClick={() => showSubmitConfirmation(formik.values)}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </>
                            </div>
                        </div>
                    }
                >
                    <div className="basic-form">
                        <form onKeyDown={onKeyDown}>
                            <div>
                                <div className='form-control'>
                                    <label htmlFor='name'>Cluster Name
                                        <span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <div>
                                        <Select
                                            id="name"
                                            placeholder="Select Cluster Name"
                                            name="name"
                                            style={{ width: "100%" }}
                                            onChange={onChangeCluster}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                        >
                                            {getClusterNameData.map((data) => (
                                                <Select.Option key={data.id + 'clusterName'} value={data.id}>{data.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>

                                    {formik.touched.name && formik.errors.name && (
                                        <div className='error' style={{ color: 'red' }}>
                                            {formik.errors.name}
                                        </div>
                                    )}
                                </div>

                                <div className="form-control">
                                    <label>
                                        Cluster Group Name<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Input
                                        maxLength={100}
                                        id="group"
                                        name="group"
                                        placeholder="Enter Cluster group"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.group}
                                    ></Input>
                                    {formik.touched.group && formik.errors.group ? (
                                        <div className="error">{formik.errors.group}</div>
                                    ) : null}
                                </div>

                                <div className="form-control">
                                    <label>
                                        <span style={{ color: "#2A569E", fontSize: "14px", fontWeight: "500" }}>Subscription Plan</span>
                                    </label>
                                </div>

                                <div className='form-control'>
                                    <label>Actual Price <span style={{ color: 'red' }}>*</span></label>
                                    <Input
                                        type='number'
                                        id="actualPrice"
                                        name="actualPrice"
                                        maxLength={50}
                                        placeholder="Enter a Actual price"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.actualPrice || ''}
                                    />
                                    {formik.touched.actualPrice && formik.errors.actualPrice ?
                                        (
                                            <div className='error'>{formik.errors.actualPrice}</div>
                                        ) : null}
                                </div>

                                <div className='form-control test-valid-content'>
                                    <label>Price <span style={{ color: 'red' }}>*</span></label>
                                    <Input type='number' maxLength={50} id='price' name='price' placeholder="Enter a price" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.price}></Input>
                                    {formik.touched.price && formik.errors.price ? <div className='error'>{formik.errors.price}</div> : null}
                                </div>

                                <div className='form-control'>
                                    <label htmlFor='frequencyId'>Frequency
                                        <span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <div>
                                        <Select
                                            id="frequencyId"
                                            placeholder="Select a Frequency"
                                            name="frequencyId"
                                            style={{ width: "100%" }}
                                            onChange={onFrequencyChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.frequencyId}
                                        >
                                            {frequencyData.map((data) => (
                                                <Select.Option key={data.id + 'frequency'} value={data.id} disabled={selectedFrequencyIds?.includes(data.id)}>{data.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    {formik.touched.frequencyId && formik.errors.frequencyId && (
                                        <div className='error' style={{ color: 'red' }}>
                                            {formik.errors.frequencyId}
                                        </div>
                                    )}
                                </div>

                                <p>
                                    <span
                                        className="add-pricing"
                                        onClick={() => !hasErrorsInPriceAndFrequencyHelper(isEditAction, formik) && addPrice(formik.values)}
                                        style={{ cursor: hasErrorsInPriceAndFrequencyHelper(isEditAction, formik) ? 'not-allowed' : 'pointer' }}
                                    >
                                        Add Pricing
                                    </span>
                                </p>

                                <div className="form-control file-input-btn attachment-upload">
                                    <label className='label-name'>Exam Thumbnail
                                        {/* <span style={{ color: 'red' }}> *</span> */}
                                    </label>
                                    <div className='attachment-upload'>
                                        <FileUpload
                                            filehandleChange={filehandleChange}
                                            handleFileRemove={handleFileRemove}
                                            file={uploadFile}
                                            allowCount={1}
                                            customButton={
                                                <Button className="attachment-upload-button">
                                                    Drop files to attach or <span className="browse-and-upload">browse and upload</span>
                                                </Button>
                                            }
                                        />
                                    </div>
                                </div>

                                <div style={{ display: "flex", alignItems: "center", marginTop: "30px" }}>

                                    <Switch
                                        onChange={onChange}
                                        checked={open}
                                        style={{
                                            margin: 0, // Remove margin to keep it aligned
                                        }}
                                    />
                                    <p style={{ marginRight: "12px", fontSize: "13px", color: "#000000", fontWeight: "500", marginLeft: "12px" }}>Include a free trial</p>
                                </div>

                                <div>
                                    <PricingList
                                        prices={prices}
                                        deletePricing={deletePricing}
                                        frequencyMap={frequencyMap}
                                    />
                                </div>

                                {/* <div style={{ border: "1px solid #E1E7EA", marginTop: '16px' }}></div> */}
                            </div>

                        </form>
                    </div>
                </Drawer>
                {/* delete modal */}

                <Modal
                    title="Deletion"
                    open={isModalVisible}
                    onCancel={onModalClose}
                    footer={
                        <div>
                            <Button
                                className="primary-cancel-button"
                                onClick={() => onModalClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="primary-submit-button"
                                type="default"
                                onClick={() => confirmDelete()}
                            >
                                OK
                            </Button>
                        </div>
                    }
                >
                    <p>Are you sure you want to delete?</p>
                </Modal>

                {/* update confirmation */}

                <Modal
                    title="Update Confirmation"
                    open={isUpdateModalVisible}
                    onCancel={closeUpdateModal}
                    footer={
                        <div>
                            <Button
                                className="primary-cancel-button"
                                onClick={closeUpdateModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="primary-submit-button"
                                type="default"
                                onClick={updateData}
                            >
                                Update
                            </Button>
                        </div>
                    }
                >
                    <p>Are you sure you want to update?</p>
                </Modal>

                {/* submit Confirmation */}

                <Modal
                    title="Submit Confirmation"
                    open={isSubmitModalVisible}
                    onCancel={closeSubmitModal}
                    footer={
                        <div>
                            <Button
                                className="primary-cancel-button"
                                onClick={closeSubmitModal}
                            >
                                Cancel{" "}
                            </Button>
                            <Button
                                className="primary-submit-button"
                                type="default"
                                onClick={() => onSubmit(formik.values)}
                            >
                                {" "}
                                Submit{" "}
                            </Button>
                        </div>
                    }
                >
                    <p>Are you sure you want to submit?</p>
                </Modal>
            </div>

            <div>
                <TableComponent
                    dataSource={clusterGroupMasterTableData}
                    columns={columns}
                    rowSelection={rowSelection}
                    tableOnChange={tableOnChange}
                    tableHeight={tableHeight}
                    totalData={examDataCount}
                    currentPage={paginationData + 1}
                    loadingStatus={isLoading}
                    limit={limit}
                />
            </div>
        </div>
    );
}

export default ClusterGroupMaster;
