import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RequiredLoginAuth = ({ children }) => {
 const navigate = useNavigate()
 let token  = localStorage.getItem('authToken') ? true : false
 useEffect(()=>{
  if (token) {
   return navigate('/')
  }
 },[])

 return children
}

export default RequiredLoginAuth