import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Image, Input, Drawer, Select, Modal, DatePicker, Checkbox, message,Tag } from 'antd';
import DeleteImage from '../asset/image/deleteIcon.svg';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import TableComponent from './Table/tableComponent';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';
import emptyImage from '../asset/image/createBundle.svg';
import './styles/couponMaster.scss';
import { getCouponType, createCoupon, getCouponDetails, getEditData, deleteCouponDetails, updateCouponDetails } from './reducers/couponSlice';
import moment, { ISO_8601, utc } from 'moment';
import Loading from './Loading';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useDebouncedCallback } from 'use-debounce';






const CouponMaster = () => {

  const dispatch = useDispatch()

  const { Option } = Select;

  const { couponType, couponDetails, isCouponLoading } = useSelector((state) => state.Coupon)

  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [record, setRecord] = useState({});
  const [filterData, setFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dateFormat = 'YYYY-MM-DD';
  const [formDate, setFormDate] = useState(moment());
  const [defaultValue, setDefaultValue] = useState()


  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getCouponType(obj)).unwrap().then((res) => {
    })

    dispatch(getCouponDetails(obj)).unwrap().then((res) => {
    })

  }, [])

  let tableHeight = 320



  const columns = [
    {
      title: 'Coupon Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Discount details',
      dataIndex: 'details',
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (_, record) => {
        return (
          <div><span> {record.isActive ?
            <Tag color="green">Active</Tag> :
            <Tag color="red">Inactive</Tag>

          }</span></div>
        )
      }
    },
    {
      title: 'Level',
      dataIndex: 'isPublic',
      render: (_, record) => {
        return (
          <div><span>{record.isPublic == 1 ? 'Public' : 'Private'}</span></div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <div className='table-action-link'>
            <a onClick={() => editData(record)}>Edit</a>
            <Tooltip title="Delete" style={{ padding: 0 }}>
              <IconButton>
                <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
              </IconButton>
            </Tooltip>
          </div>
        )
      },
    },
  ]



  // edit data

  const editData = (record) => {
    setCurrentTitle("Edit Coupon")
    setCurrentId(record.id)
    dispatch(getEditData(record.id)).unwrap().then((res) => {
      setVisible(true)
      setFormDate(res.data.validDateTime)
      res.data.isActive == 1 ? res.data.isActive = true : res.data.isActive = false
      res.data.isPublic == 1 ? res.data.isPublic = true : res.data.isPublic = false
      couponFormik.setValues(res.data)
    })
  }

  // delete data
  const deleteData = (record) => {
    setRecord(record)
    setIsModalVisible(true)
  }

  // open drawer
  const openDrawer = () => {
    setCurrentId(null)
    setVisible(true)
    setCurrentTitle("New Coupon")
    couponFormik.resetForm()
  }

  const search = (value) => {

  
    setFilterData(value)
    let data = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    }
    debounced(data)
    // debounced(data)

  }
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getCouponDetails(obj))
  }, 400)

  // onClose
  const onClose = () => {
    setVisible(false);
    couponFormik.setValues(initialValues)
    couponFormik.resetForm()
  }

  // update data

  const updateData = () => {
    setVisible(false);
    dispatch(updateCouponDetails(couponFormik.values)).unwrap().then((res) => {
      let obj = {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
      dispatch(getCouponDetails(obj)).unwrap().then((res) => {
      })
      message.success(res.message)
    })
    couponFormik.setValues(initialValues)
    couponFormik.resetForm()
  }

  // onChange select

  const onChangeSelect = (e, fieldHelpers) => {
    fieldHelpers.setValue(e)
  }

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }


  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit

    }

    dispatch(getCouponDetails(obj))
  }

  // confirm delete
  const confirmDelete = () => {
    setIsModalVisible(false)
    dispatch(deleteCouponDetails(record.id)).unwrap().then((res) => {
      let obj = {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
      dispatch(getCouponDetails(obj))
      message.success(res.message)
    })
  }


  // on modal close
  const onModalClose = () => {
    setIsModalVisible(false);
  }

  // Datepicker

  const onCalenderChange = (date, dateString) => {
    couponFormik.setFieldValue('validDateTime', dateString)
    setFormDate(dateString)
  }

  // Coupons formik

  const initialValues = {
    name: "",
    couponCode: "",
    details: "",
    couponTypeId: null,
    amount: null,
    discountPercentage: null,
    discountMaxValue: null,
    minimumCartValue: null,
    isPublic: false,
    validDateTime: '',
    isActive: false,
    count: null
  }

  const validationSchema = yup.object({
    name: yup.string().required("Coupon name is required"),
    couponCode: yup.string().required("Coupon code is required"),
    details: yup.string().required("Coupon details is required"),
    couponTypeId: yup.number().required("Coupon type is required").nullable(),
    amount: yup.number().required("Amount is required").nullable(),
    discountPercentage: yup.number().required("Discount percentage is required").nullable(),
    discountMaxValue: yup.number().required("Discount maximum is required").nullable(),
    minimumCartValue: yup.number().required("Minimum cart value is required").nullable(),
    validDateTime: yup.string().required("Valid time is required"),
    count: yup.number().required("Count is required").nullable()
  })

  const couponFormik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false)
      dispatch(createCoupon(values)).unwrap().then((res) => {
        message.success(res.message)
        let obj = {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
        dispatch(getCouponDetails(obj))
      })
      couponFormik.setValues(initialValues)
    }
  })


  const onCheckboxChange = (e) => {
  }

  return (
    <div className="coupon-master-page">
      <div className="coupon-header">
        <p className="header-name">Coupon Master</p>
        <div className="create-coupon"><Button className='create-button' onClick={() => openDrawer()}>Create coupon</Button></div>
      </div>
      <div className="coupon-content">
        {couponDetails.length == 0  && !filterData ?
          <div className="coupon-empty">
            <div>
              <div className="coupon-empty-image"><Image preview={false} src={emptyImage}></Image></div>
              <p className="coupon-empty-header">Create coupon</p>
              <p className="coupon-empty-content">You’re moments away from Create Chapter Master</p>
              <Button className='coupon-empty-button'>+Create coupon</Button>
            </div>
          </div> :
          <div>
            <div className="golbalSearch" >
              <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(e) => search(e.target.value)} value={filterData} />
            </div>
            <div className="table-component">
              <TableComponent columns={columns} dataSource={couponDetails} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={couponDetails} currentPage={paginationData + 1} loadingStatus={isCouponLoading} limit={limit} />
            </div>
          </div>
        }



        {/* drawer */}

        <Drawer closable={false} title={currentTitle} placement="right" onClose={() => onClose()} open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose()}>Cancel</Button>


              {
                currentId == null ?
                  <Button className={!couponFormik.isValid || !couponFormik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!couponFormik.dirty || !couponFormik.isValid} type='primary' onClick={() => couponFormik.handleSubmit()}>Submit</Button>
                  :
                  <Button className={!couponFormik.isValid || !couponFormik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!couponFormik.isValid} type='primary' onClick={() => updateData()} >Update</Button>

              }

            </div>
          </div>
        )}>
          <form>
            <div className='form-control'>
              <label htmlFor='name'>
                Coupon Name <span className='required'>*</span>
              </label>
              <div>
                <Input type='text' id='name' onBlur={couponFormik.handleBlur} onChange={couponFormik.handleChange} value={couponFormik.values.name} sty />
                {couponFormik.touched.name && couponFormik.errors.name ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.name}</div> : null}
              </div>
              <div className="discount-wrapper">
                <div className="discount-percent">
                  <label htmlFor='couponCode'>
                    Coupon code <span className='required'>*</span>
                  </label>
                  <div>
                    <Input type='text' id='couponCode' onBlur={couponFormik.handleBlur} onChange={couponFormik.handleChange} value={couponFormik.values.couponCode} />
                    {couponFormik.touched.couponCode && couponFormik.errors.couponCode ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.couponCode}</div> : null}
                  </div>
                </div>
                <div className="discount-value">
                  <label htmlFor='couponType'>
                    Coupon type <span className='required'>*</span>
                  </label>
                  <div>
                    <Select id="couponTypeId" name="couponTypeId" style={{ width: "100%" }} placeholder="Choose Option" onChange={(e) => { onChangeSelect(e, couponFormik.getFieldHelpers('couponTypeId')) }} onBlur={couponFormik.handleBlur} value={couponFormik.values.couponTypeId} >
                      {couponType ? couponType.map((item) =>
                        <Option value={item.id}>{item.name}</Option>
                      ) : ''}
                    </Select>
                    {couponFormik.touched.couponTypeId && couponFormik.errors.couponTypeId ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.couponTypeId}</div> : null}
                  </div>
                </div>
              </div>
              <div className="discount-wrapper">
                <div className="discount-percent">
                  <label htmlFor='discountPercentage'>
                    Discount Percent <span className='required'>*</span>
                  </label>
                  <div>
                    <Input type='number' id='discountPercentage' onBlur={couponFormik.handleBlur} onChange={couponFormik.handleChange} value={couponFormik.values.discountPercentage} />
                    {couponFormik.touched.discountPercentage && couponFormik.errors.discountPercentage ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.discountPercentage}</div> : null}
                  </div>
                </div>
                <div className="discount-value">
                  <label htmlFor='discountMaxValue'>
                    Maximum Discount Value <span className='required'>*</span>
                  </label>
                  <div>
                    <Input type='number' id='discountMaxValue' onBlur={couponFormik.handleBlur} onChange={couponFormik.handleChange} value={couponFormik.values.discountMaxValue} />
                    {couponFormik.touched.discountMaxValue && couponFormik.errors.discountMaxValue ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.discountMaxValue}</div> : null}
                  </div>
                </div>
              </div>
              <div className="discount-wrapper">
                <div className="discount-percent">
                  <label htmlFor='minimumCartValue'>
                    Minimum Cart Value <span className='required'>*</span>
                  </label>
                  <div>
                    <Input type='number' id='minimumCartValue' onBlur={couponFormik.handleBlur} onChange={couponFormik.handleChange} value={couponFormik.values.minimumCartValue} />
                    {couponFormik.touched.minimumCartValue && couponFormik.errors.minimumCartValue ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.minimumCartValue}</div> : null}
                  </div>
                </div>
                <div className="discount-value">
                  <label htmlFor='name'>
                    Amount <span className='required'>*</span>
                  </label>
                  <div>
                    <Input type='number' id='amount' onBlur={couponFormik.handleBlur} onChange={couponFormik.handleChange} value={couponFormik.values.amount} sty />
                    {couponFormik.touched.amount && couponFormik.errors.amount ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.amount}</div> : null}
                  </div>
                </div>
              </div>
              <div className="count">
                <label htmlFor='count'>
                  Count <span className='required'>*</span>
                </label>
                <div>
                  <Input type='number' id='count' onBlur={couponFormik.handleBlur} onChange={couponFormik.handleChange} value={couponFormik.values.count} sty />
                  {couponFormik.touched.count && couponFormik.errors.count ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.count}</div> : null}
                </div>
              </div>

              <div className='date-pick'>
                <label htmlFor='date-pick'>
                  Valid Till <span className='required'>*</span>
                </label>
                <DatePicker format={dateFormat} id='validDateTime' name='validDateTime' onChange={(date, dateString) => onCalenderChange(date, dateString)} value={couponFormik.values.validDateTime ? moment(formDate, 'YYYY-MM-DDTHH:mm:ss.sssZ') : undefined} />
                {couponFormik.touched.validDateTime && couponFormik.errors.validDateTime ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.validDateTime}</div> : null}
              </div>
              <label htmlFor='details'>
                Details <span className='required'>*</span>
              </label>
              <div>
                <Input type='text' id='details' onBlur={couponFormik.handleBlur} onChange={couponFormik.handleChange} value={couponFormik.values.details} />
                {couponFormik.touched.details && couponFormik.errors.details ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.details}</div> : null}
              </div>
              <div className="discount-wrapper">
                <div className="discount-percent">
                  <Checkbox id='isPublic' name='isPublic' checked={couponFormik.values.isPublic} onChange={couponFormik.handleChange} value={couponFormik.values.isPublic}>Public <span className='required'>*</span></Checkbox>
                  <Checkbox id='isActive' name='isActive' checked={couponFormik.values.isActive} onChange={couponFormik.handleChange} value={couponFormik.values.isActive}>Active <span className='required'>*</span></Checkbox>
                </div>
                <div className="discount-value">
                </div>
              </div>
            </div>
          </form>
        </Drawer>
        {/* delete modal */}

        <Modal title="Deletion" open={isModalVisible} onOk={() => confirmDelete()} onCancel={() => onModalClose()}>
          <p>Are you sure want to delete ?</p>
        </Modal>

      </div>
      <div>
        {isCouponLoading ? <Loading></Loading> : null}
      </div>
    </div>
  )
}

export default CouponMaster;