import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector} from 'react-redux';
import {Button, Image, Input,Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import BackImage from '../asset/image/leftArrow.svg';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import {insertTestBundle,getTestBundle,getEditData,updateTestBundle} from './reducers/TestBundleSlice'
import './styles/NewTestBundle.scss';
const {TextArea}=Input;

function NewTestBundle(){

  const dispatch=useDispatch()

  const params=useParams();

  const navigate=useNavigate();

  const antIcon = (<LoadingOutlined style={{fontSize: 24,}}spin/>);

  const {testBundleEditData,isLoading}=useSelector((state)=>state.TestBundle)

  const [disableButton,setDisabledButton ]=useState(false);

  const [bundleDetails,setBundleDetails]=useState();

  const newTestBundleValues={
    name:'',
    description:''
  }

  useEffect(()=>{
    if(params.id){
      dispatch(getEditData(params.id))
    }
  },[])
  useEffect(()=>{
    if(params.id){
      newTestBundleFormik.setValues(testBundleEditData);
    }
  },[testBundleEditData])

 function onSubmit(){
   if(!params.id){
     dispatch(insertTestBundle(bundleDetails)).unwrap().then((res)=>{
       let id=res.data[0].id
       navigate('/TestBundleTest/'+id)
     });
     newTestBundleFormik.resetForm(newTestBundleValues)
   }
   if(params.id){
     dispatch(updateTestBundle(bundleDetails)).unwrap().then(()=>{
      navigate('/TestBundleTest/'+params.id);
      newTestBundleFormik.resetForm(newTestBundleValues)
     })
   }
 }

 function onBackClick(){
    navigate('/TestBundle');
 }


 const validate=(values)=>{

    let errors={}
    if(!values.name){
      errors.name="Bundle name is required"
      setDisabledButton(false);
    }
    if(!values.description){
      errors.description="Description is required"
      setDisabledButton(false)
    } 
    else if(errors.name==null && errors.description==null){
      setDisabledButton(true);
      setBundleDetails(values)
    }
    return errors;
}

  const newTestBundleFormik=useFormik({
    initialValues:newTestBundleValues,
    onSubmit,
    validate,
  })

  if(isLoading){
    return(
      <div className='loading'>
        <Spin tip='Loading...' indicator={antIcon} />
      </div>
    )
  }



  return(
    <div >
      <div className='new-bundle-header'>
      <div><Image src={BackImage} preview={false} onClick={()=>onBackClick()}></Image></div>
      <div className='bundle-heading'>Create a Bundle</div>
      </div>
      <div className='new-bundle-content'>
      <div style={{width:'60%'}}>
        <p>Name your test bundle</p>
        <p><label className='new-bundle-label'>Bundle Name</label></p>
        <div style={{position: "relative"}}>
        <Input id='name' name='name' onBlur={newTestBundleFormik.handleBlur} onChange={newTestBundleFormik.handleChange} value={newTestBundleFormik.values.name}></Input>
        {newTestBundleFormik.touched.name && newTestBundleFormik.errors.name ? <div className='errorMessage' style={{ color: 'red', position: "absolute"}}>{newTestBundleFormik.errors.name}</div> : null}
        <p style={{marginTop:25}}><label className='new-bundle-label'>Desciption</label></p>
        </div>
        <div>
        <TextArea id='description' name='description' onBlur={newTestBundleFormik.handleBlur} onChange={newTestBundleFormik.handleChange} value={newTestBundleFormik.values.description}></TextArea>
        {newTestBundleFormik.touched.description && newTestBundleFormik.errors.description ? <div className='errorMessage' style={{ color: 'red', position: "absolute"}}>{newTestBundleFormik.errors.description}</div> : null}
        <Button disabled={disableButton==false ? true:false} type='primary' onClick={()=>onSubmit()}>Next</Button>
        </div>
      </div>
      </div>
    </div>
  )
}

export default NewTestBundle;