import React, { useState,useEffect } from 'react';
import { Button, Input, Select, Drawer, Switch, Form, Image, Tooltip } from 'antd';
import { Option } from 'antd/lib/mentions';
import TestmiIcon from "../../asset/image/testmi_icon.svg";
import PriceplanIcon from "../../asset/image/price_plan_icon.svg";
import '../../components/styles/curriculam.scss';
import TestMiModal from "./TestmiModel";
import CustomFileUpload from './CustomFileUpload';
import PricePlanModal from './PricePlanModal';
import ProgressBar from './ProgressBar';
import EducatorIcon from '../../asset/image/Educator_profile.svg'
import closeIcon from '../../asset/image/close.svg';
import alternativeCloseIcon from '../../asset/image/close20-20.svg';
import contentImg from '../../asset/image/Drawer-content.svg';
import vectorImg from '../../asset/image/Vector.svg'
import { useDispatch,useSelector } from 'react-redux';
import { getClusterType } from "../reducers/clusterCourseSlice";
import { getClusterName } from '../reducers/ClusterGroupMasterSlice';

const { TextArea } = Input;

const DrawerForm = ({ title, formik, pricingFormik = '', visible, isOtherVideoContent = false, onClose, commentsOpen, communityFree, attachmentFile, fileNotesOthers, testMiData = [], educatorDetails, selectedCards, currentId = null, drawerIsEdit = false, frequencyData, prices, youtubeLinkMapping, frequencyMap, uploading = false, progress = 0, attachmentFileCount, otherNotesFileCount = 0, testmiTextBoxChanges, handleCommunity, handleComments, submit, update, handleAttachmentChange, handleAttachmentFileRemove, handleFilesNotesChange, handleFilesNotesRemove, addPrice, pricingSwitchChange, deletePricing, addYoutubeLink, deleteYoutubeLink, onChangeCluster }) => {

    const [isModalOpenTestMi, setModalOpenTestMi] = useState(false);
    const [isModalOpenPricePlan, setModalOpenPricePlan] = useState(false);
    const dispatch = useDispatch()
    const showModalTestMi = () => setModalOpenTestMi(true);
    const handleOkTestMi = () => setModalOpenTestMi(false);
    const handleCancelTestMi = () => setModalOpenTestMi(false);
    const showModalPricePlan = () => setModalOpenPricePlan(true);
    const handleOkPricePlan = () => setModalOpenPricePlan(false);
    const handleCancelPricePlan = () => setModalOpenPricePlan(false);

    const handleChangeTestMiExam = (value) => { };

    const handleChangeTestMiExamGroup = (value) => { };

    const handleChangeEducator = (value) => {
        formik.setFieldValue("educatorMapping", value);
    };

    const { getClusterNameData } = useSelector(
        (state) => state.ClusterGroupMasterSlice
    );

    const { getClusterTypeData } = useSelector(
        (state) => state.ClusterCourseSlice
    );

    const onTypeCluster = (value) => {
        formik.setFieldValue("clusterType", value);
    };

    const isButtonDisabled = !formik.dirty || !formik.isValid || (pricingFormik && !pricingFormik.isValid);

    const renderYoutubeLink = () => {
        return (
            (Array.isArray(youtubeLinkMapping) ? youtubeLinkMapping : []).map((item, index) => (
                <div className='base-remove-container youtube-link-remove' key={index?.toString()}>
                    <div className='link-container'>
                        <div className='cotent-icon-wrapper'>
                            <Image src={contentImg} preview={false} />
                        </div>
                        <Tooltip placement="top" title={item?.link}>
                            <p className='youtube-link ellipsis-text'>
                                <span>{item?.link}</span>
                            </p>
                        </Tooltip>
                    </div>
                    <div className='remove-icon-wrapper'>
                        <Image src={closeIcon} preview={false} onClick={() => deleteYoutubeLink(item, index)} />
                    </div>
                </div>
            ))
        );
    };

    return (
        <>
            <Drawer
                closable={false}
                title={title}
                placement="right"
                onClose={() => onClose(formik.values)}
                open={visible}
                footer={(
                    <div className='footer'>
                        <div className='footer-button coachmi-footer'>
                            <Button className='primary-cancel-button coachmi-primary-cancel-button' onClick={() => onClose()} >Cancel</Button >

                            {
                                drawerIsEdit || currentId ?
                                    <Button type="primary"
                                        className={` 
                                        ${isButtonDisabled ? 'disabled-button' : 'primary-submit-button'} coachmi-primary-submit-button`}
                                        onClick={() => update(formik.values, pricingFormik?.values)}
                                        disabled={isButtonDisabled}
                                    >Update</Button>
                                    :
                                    <Button type='primary'
                                        className={` 
                                        ${isButtonDisabled ? 'disabled-button' : 'primary-submit-button'} coachmi-primary-submit-button`}
                                        onClick={() => submit(formik.values, pricingFormik?.values)}
                                        disabled={isButtonDisabled}
                                    >Save</Button>
                            }
                        </div>
                    </div>
                )}
            >
                <Form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                    <div>

                        {isOtherVideoContent && (
                         <>
                                
                        <div className='form-control'>
                            <label htmlFor='clusterName'>Cluster Name
                                <span style={{ color: 'red' }}> *</span>
                            </label>
                            <div>
                                <Select
                                    id="clusterName"
                                    placeholder="Enter Cluster Name"
                                    name="clusterName"
                                    style={{ width: "100%" }}
                                    onChange={onChangeCluster}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.clusterName}
                                >
                                    {getClusterNameData?.map((data, index) => (
                                        <Select.Option key={`${data?.id}-${index}`} value={data?.id}>{data?.name}</Select.Option>
                                    ))}
                                </Select>
                            </div>
                            {formik.touched?.clusterName && formik.errors?.clusterName && (
                                <div className='error' style={{ color: 'red' }}>
                                    {formik.errors?.clusterName}
                                </div>
                            )}
                        </div>
                            
                        <div className='form-control'>
                            <label htmlFor='clusterType'>
                                Cluster Type<span style={{ color: 'red' }}> *</span>
                            </label>
                            <div>
                                <Select
                                    id="clusterType"
                                    placeholder="Enter Cluster Type"
                                    name="clusterType"
                                    style={{ width: "100%" }}
                                    onChange={onTypeCluster}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.clusterType}
                                >
                                    {getClusterTypeData?.map((data, index) => (
                                        <Select.Option key={`${data?.id}-${index}`} value={data?.id}>{data?.name}</Select.Option>
                                    ))}
                                </Select>
                                {formik.touched?.clusterType && formik.errors?.clusterType ? <div className='error' style={{ color: 'red' }}>{formik.errors?.clusterType}</div> : null}
                            </div>
                        </div>

                        <div className="form-control">
                            <label>
                                Order <span style={{ color: "red" }}> *</span>
                            </label>
                            <Input
                                maxLength={255}
                                id="orderNumber"
                                className='other-video-content-input'
                                name="orderNumber"
                                placeholder="Enter Order Number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.orderNumber}
                            />
                            {formik.touched?.orderNumber && formik.errors?.orderNumber ? (
                                <div className="error">{formik.errors?.orderNumber}</div>
                            ) : null}
                        </div>
                                 
                         </>
                        )}

                        <div className="form-control">
                            <label>
                                Video Name<span style={{ color: "red" }}> *</span>
                            </label>
                            <Input
                                maxLength={255}
                                id="videoName"
                                className='other-video-content-input'
                                name="videoName"
                                placeholder="Enter the Video Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.videoName}
                            />
                            {formik.touched.videoName && formik.errors.videoName ? (
                                <div className="error">{formik.errors.videoName}</div>
                            ) : null}
                        </div>

                        <div className="form-control">
                            <label>Description for video</label>
                            <TextArea
                                rows={5}
                                id="videoDescription"
                                className='other-video-content-description'
                                name="videoDescription"
                                placeholder="Enter the Description for video"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.videoDescription}
                            />
                            {formik.touched.videoDescription && formik.errors.videoDescription ? <div className='error'>{formik.errors.videoDescription}</div> : null}
                        </div>

                        <div className="form-control mb-6">
                            <label>Youtube Video Link</label>
                            <div>
                                <Input
                                    id="videoLink"
                                    className='other-video-content-input'
                                    name="videoLink"
                                    placeholder="Paste your video link here"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.videoLink}
                                />
                                {formik?.touched?.videoLink && formik?.errors?.videoLink &&
                                    formik?.errors?.videoLink ? <div className='error'>
                                    {formik?.errors?.videoLink}
                                </div> : null}
                            </div>
                            <p><span className='formarray-action-button' onClick={() => addYoutubeLink(formik?.values)}>Add Video Link</span></p>
                            {renderYoutubeLink()}
                        </div>

                        <div className="form-control file-input-btn attachment-upload mb-6">
                            <label className='label-name'>Video Attachment</label>
                            <div className='attachment-upload-form'>
                                <CustomFileUpload
                                    filehandleChange={handleAttachmentChange}
                                    handleFileRemove={handleAttachmentFileRemove}
                                    file={attachmentFile}
                                    allowCount={attachmentFileCount}
                                    customButton={
                                        <Button className="attachment-upload-button">
                                            Drop files to attach or <span className="browse-and-upload">browse and upload</span>
                                        </Button>
                                    }
                                />

                                {uploading && (
                                    <ProgressBar progress={progress} />
                                )}

                            </div>
                        </div>

                        {
                            !isOtherVideoContent && (
                                <div className="form-control file-input-btn attachment-upload mb-6">
                                    <label className='label-name'>Notes and Others</label>
                                    <div className='attachment-upload-form'>
                                        <CustomFileUpload
                                            filehandleChange={handleFilesNotesChange}
                                            handleFileRemove={handleFilesNotesRemove}
                                            file={fileNotesOthers}
                                            allowCount={otherNotesFileCount}
                                            customButton={
                                                <Button className="attachment-upload-button">
                                                    Drop files to attach or <span className="browse-and-upload">browse and upload</span>
                                                </Button>
                                            }
                                        />
                                    </div>
                                </div>

                            )
                        }

                        <div className="form-control recomented-control mb-6">
                            <label>Testmi</label>
                            <div className="form-control-btn">
                                <div className='testmi-remove-container'>
                                    {selectedCards?.map((cardId) => {
                                        const testMi = testMiData.find(item => item?.id === cardId?.id);
                                        if (!testMi) return null;

                                        return (
                                            <div className='base-remove-container test-remove' key={testMi.id}>
                                                <div className='vector-container'>
                                                    <div className='cotent-icon-wrapper'>
                                                        <Image src={vectorImg} preview={false} />
                                                    </div>
                                                    <Tooltip placement="top" className='capitalize' title={testMi?.testName}>
                                                        <p className='testmi-name ellipsis-text'>
                                                            <span>{testMi?.testName}</span>
                                                        </p>
                                                    </Tooltip>
                                                </div>
                                                <div className='remove-icon-wrapper'>
                                                    <Image src={alternativeCloseIcon} preview={false} onClick={() => testmiTextBoxChanges(cardId, false)} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <Button
                                    className="attach-upload-button"
                                    name="examImage"
                                    onClick={showModalTestMi}
                                    icon={<img src={TestmiIcon} alt="Testmi Icon" />}
                                >
                                    Add Recommended Test
                                </Button>
                            </div>
                        </div>

                        <div className="form-control recomented-control mb-6">
                            <label className='label-name'>Educator</label>
                            <div className="form-control-btn form-control-btn-educator">
                                <Select
                                    id="educatorMapping"
                                    name="educatorMapping"
                                    style={{
                                        width: 120,
                                    }}
                                    placeholder="Select Educator"
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.educatorMapping}
                                    onChange={handleChangeEducator}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children[1]?.props.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {(Array.isArray(educatorDetails) ? educatorDetails : [])?.map((data) => (
                                        <Option key={data?.id} value={data?.id}>
                                            {data?.attachmentUrl ? (
                                                <Image key={data?.id} preview={false} className='educator-profile' src={data?.attachmentUrl} />
                                            ) : (
                                                <Image key={data?.id} preview={false} className='educator-profile' src={EducatorIcon} />
                                            )}
                                            <span className='educator-name'>{data?.name}</span>
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            {formik.touched.educatorMapping && formik.errors.educatorMapping ? <div className='error'>{formik.errors.educatorMapping}</div> : null}
                        </div>

                        {
                            !isOtherVideoContent && (
                                <div className="form-control recomented-control mb-6">
                                    <label className='label-name'>Pricing Plan</label>
                                    <div className="form-control-btn">
                                        <Button
                                            className="attach-upload-button"
                                            name="examImage"
                                            onClick={showModalPricePlan}
                                            icon={<img src={PriceplanIcon} alt="Priceplan Icon" />}
                                        >
                                            Add Pricing
                                        </Button>
                                    </div>
                                </div>
                            )
                        }

                        <div className="form-control recomented-control mb-6">
                            <label className='label-name'>General Configurations</label>
                            <div className="form-control-btn">
                                <div className='form-switch-btn'>
                                    <Switch
                                        checked={commentsOpen}
                                        onChange={handleComments}
                                    />
                                    <p>All Comments</p>
                                </div>
                                <div className='form-switch-btn'>
                                    <Switch
                                        checked={communityFree}
                                        onChange={handleCommunity}
                                    />
                                    <p>Free for community</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Drawer>

            <TestMiModal
                visible={isModalOpenTestMi}
                onOk={handleOkTestMi}
                onCancel={handleCancelTestMi}
                testMiData={testMiData}
                handleChangeTestMiExam={handleChangeTestMiExam}
                handleChangeTestMiExamGroup={handleChangeTestMiExamGroup}
                handleCheckboxChange={testmiTextBoxChanges}
                selectedCards={selectedCards}
            />

            <PricePlanModal
                formik={pricingFormik}
                videoName={formik?.values?.videoName}
                visible={isModalOpenPricePlan}
                onOk={handleOkPricePlan}
                onCancel={handleCancelPricePlan}
                frequencyData={frequencyData}
                prices={prices}
                frequencyMap={frequencyMap}
                addPrice={addPrice}
                pricingSwitchChange={pricingSwitchChange}
                deletePricing={deletePricing}
            />
        </>
    )
};

export default DrawerForm;