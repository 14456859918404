import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./components/store/store";
import axios from "axios";
import { BrowserRouter, useNavigate, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
let history = createBrowserHistory();

// axios.defaults.baseURL = "http://127.0.0.1:5000/api/";
// axios.defaults.baseURL = "http://localhost:5000/api/";
// axios.defaults.baseURL = "http://43.205.106.109:3000/api/"; 
// axios.defaults.baseURL = "https://admin.dcrew.co.in/api/"
axios.defaults.baseURL = "https://staging-admin.centum.academy/api/"


axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401) {
        localStorage.clear();
        history.replace("/login");
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <HistoryRouter history={history}>
      <App />
    </HistoryRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
