import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Divider, Drawer, Image, Input, Row, Tabs,Modal } from 'antd';
import { Outlet } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';
import { insertBatchMasterTable } from './reducers/BatchMasterSlice'
import {  getCourseById } from './reducers/CourseMasterSlice'
import Loading from './Loading';

const { TabPane } = Tabs;
const CoursePage = () => {

  const dispatch = useDispatch();
  const [tabStatus, setTabStatus] = useState("1")
  const navigate = useNavigate()
  const roleId = localStorage.getItem("roleId")
  const [currentTitle, setCurrentTitle] = useState("")
  const [visible, setVisible] = useState(false);
  const institutionId = localStorage.getItem("institutionId")
  const params = useParams()
  const { courseId, isLoading } = useSelector((state) => (state.CourseSlice))
  const [isSubmitModalVisible,setSubmitModalVisible]=useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  useEffect(() => {
   
    dispatch(getCourseById(+params.courseId));
    // dispatch(addBreadCrumbData({}))
  }, [])
  const onTabChange = (data) => {
    setTabStatus(data)
    if (data == 1) {
      navigate('/CoursePage/getBatchDetails')
    }
    else if (data == 2) { }
    else {
      navigate('/CoursePage/getAllTestResults')
    }
  }
  const openDrawer = () => {
    setVisible(true)
    setCurrentTitle("Add Batch")
    formik.resetForm({ values: initialValues })

  }
  function onClose(values) {
    setVisible(false);
  }
  const initialValues = { name: '' ,batchCode:''}
  let validationSchema = yup.object({
 
    name: yup.string().required("Batch Name is Required").min(2, "Batch Name Needed Atleast Two characters").max(100, "Batch Name not more than 100 characters"),
    // .matches(/^[A-Za-z0-9_@ ]*$/, 'Name can only contain alphabetic characters, numbers, "@" symbol, and underscore'),
    batchCode: yup.string().required("Batch code is Required").trim()
    .matches(/^[^\s@\-_][\w\s@\-_]*$/, {
      message: 'Invalid format. should not contain first letter in special characters ,only allow @ or _  ',
      excludeEmptyString: true,
    })


  });
  
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

 
  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };
  function onSubmit(values) {
    console.log('values', values);
    setVisible(false);
    let data = {
      name: values.name,
      batchCode:values.batchCode,
      institutionDetailId: +institutionId,
      courseId: +params.courseId
    }
    setSubmitModalVisible(false);
    dispatch(insertBatchMasterTable(data))
  }
  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };
  return (
    <>{
      isLoading ? <div className='loading-container'>
      <Loading></Loading>
    </div> :
     <div className='exam-master-page'>
     <div>
       <div className='exam-header'>
         <div><h3 className='primary-header course-header'>{courseId?.name} </h3></div>
         <div className='add-exam'>
           <span className='add-new-exam'><Button type='primary' className='primary-submit-button' onClick={() => openDrawer()}> Add Batch</Button></span>
         </div>
       </div>

     </div>
     <div><Outlet></Outlet></div>

     <Drawer closable={false} title={currentTitle} placement="right" onClose={() => onClose(formik.values)} open={visible} footer={(
       <div className='footer'>
         <div className='footer-button'>
           <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose(formik.values)}>Cancel</Button>
           <Button className={!formik.isValid || !formik.dirty ?'disabled-button':'primary-submit-button'} type="primary" disabled={!formik.dirty || !formik.isValid} onClick={showSubmitConfirmation} >Add </Button> 

           {/* <Button type='primary' className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} onClick={() => onSubmit(formik.values)}>Add</Button> */}

         </div>
       </div>
     )}>
       <form>
         <div>

           <div className='form-control'>
             <label>Batch Name <span style={{ color: 'red' }}>*</span></label>
             <Input maxLength={50} id='name' name='name' placeholder='Enter Batch Name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
             {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
           </div>
           <div className='form-control'>
             <label>Batch Code <span style={{ color: 'red' }}>*</span></label>
             <Input maxLength={50} id='batchCode' name='batchCode' placeholder='Enter Batch Code' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.batchCode}></Input>
             {formik.touched.batchCode && formik.errors.batchCode ? <div className='error'>{formik.errors.batchCode}</div> : null}
           </div>
         </div>
       </form>
     </Drawer>

      {/* add modal */}

 <Modal
        title="Add Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
          <div>
            <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
            <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}> Add </Button>
          </div>
        }
      >
        <p>Are you sure you want to Add?</p>
      </Modal>
   </div>
    }
     
    </>
  )
}
export default CoursePage;



