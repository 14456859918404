import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from 'moment'
import { error } from "jquery";
import { act } from "@testing-library/react";


export const createInstituteData = createAsyncThunk(
  'institutional/create', async (payload, thunk) => {
    let tabledata = []
    let Insertmessage = '';
    let success = '';
    let insertData = await axios.post('institutional/create', payload.data)
      .then(async (res) => {
        if (res.data.status) {
          await thunk.dispatch(getinstitutionMasterTable(payload.obj))
        }
        tabledata = res.data;
        message.success(res.data.message)
      })
      .catch((error) => {
        let insertError = error.response.data
        message.error(insertError.message);
      })
    return {
      tabledata: tabledata,
      Insertmessage: Insertmessage,
      success: success
    };
  }
)

export const getinstitutionMasterTable = createAsyncThunk(
  'institutionMaster/getinstitutionMasterTable', async (payload) => {
    let tabledata = []
    let tableData = await axios.post('institutional/getInstitution', payload)
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)

export const verifyInstituteToken = createAsyncThunk("login/verifyInstituteToken", async (payload) => {
  try {
    console.log('token', payload);
    let verify = await axios.put(`institutional/verifyToken/${payload}`).then((res) => {
      if (res.data.success) {
        return res.data
      }

    })
    return verify

  } catch (error) {
    message.error(error.response.data.message)
  }

})

export const setPassword = createAsyncThunk("login/resetPassword", async (payload) => {
  try {
    let resetPassword = await axios.post(`institutional/admin/setPassword/${payload.token}`, payload.data).then((res) => {
      if (res.data.success) {
        return res.data
      }
    })
    return resetPassword
  } catch (error) {
    message.error(error.response.data.message)

  }

})

export const getInstituteLoginData = createAsyncThunk(
  "login/getInstituteLoginData",
  async (payload, thunk) => {
    let loginData = await axios
      .post("institutional/admin/login", payload)
      .then((res) => {
        let loginData = {
          userId: res.data?.userId,
          token: res.data?.token,
          institutionAdmin: res.data?.institutionAdmin,
          name: res.data?.name
        };


        localStorage.setItem('authToken', loginData?.['token'])
        localStorage.setItem('institutionId', res.data?.userId)
        localStorage.setItem('institutionAdmin', true)
        localStorage.setItem('name', loginData?.['name'])
        return loginData;
      })
      .catch((error) => {
        return error
      });
    return loginData;
  }
);

export const forgotPassword = createAsyncThunk("login/forgotPassword", async (payload) => {
  try {
    let forgotPassword = await axios.post(`institutional/admin/forgotPassword`, payload).then((res) => {
      if (res.data.status) {
        return res.data
      }

    })
    return forgotPassword

  } catch (error) {
    message.error(error.response.data.message)
  }

})

export const getInstitutionById = createAsyncThunk("Masters/getInstitutionById", async (payload) => {
  try {
    let getUser = await axios.get(`/institutional/${payload}`).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    console.log('getuser', getUser);
    return getUser
  } catch (error) {
  }
})

export const updateInstitutionMail = createAsyncThunk("Masters/updateVerifyMail", async (payload, thunk) => {
  try {
    console.log(payload, 'payload');
    let updateUserMail = await axios.put(`institutional/updateIntitution/${payload.datas.id}`, payload.datas.data).then(async (res) => {
      if (res.status) {
        if (res.status) {
          if (payload?.resendMail) {
            message.success('Institution verify mail send successfully')
          }
          await thunk.dispatch(getinstitutionMasterTable(payload.datas.obj))
          return res.data
        }
      }
    })
    console.log(updateUserMail, 'updateUserMail');
    return updateUserMail
  } catch (error) {
    message.error(error.response.data.message)
  }
})

export const updateInstitution = createAsyncThunk("Masters/updateInstitution", async (payload, thunk) => {
  try {
    let updateUser = await axios.put(`/institutional/${payload.datas.id}`, payload.datas.data).then(async (res) => {

      if (res.data.status) {
        if (payload.updateInstite) {
          message.success('Institution update successfully')
        }
        await thunk.dispatch(getinstitutionMasterTable(payload.datas.obj))
        return res.data

      }
    })
    return updateUser
  } catch (error) {
    message.error(error.response.data.message)

  }
})

export const deleteInstitutionData = createAsyncThunk("Masters/deleteInstitutionData", async (payload, thunk) => {
  try {
    let updateUser = await axios.delete(`/institutional/${payload.id}`).then(async (res) => {
      console.log('detele resss', res);

      if (res.data.success) {
        message.success(res.data.message)

        await thunk.dispatch(getinstitutionMasterTable(payload.obj))
        return res.data
      }
    })
    return updateUser
  } catch (error) {
    message.error(error.response.data.message)

  }
})

export const getAllTest = createAsyncThunk(
  'TestMaster/getAllTest', async () => {
    let tabledata = []
    let tableData = await axios.get('testMaster/getBothTestDetails')
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)

export const addNewPlan = createAsyncThunk(
  'institutional/addNewPlan', async (payload, thunk) => {
    let tabledata = []
    let Insertmessage = '';
    let success = '';
    let insertData = await axios.post(`institutional/${payload.institutionId}/purchaseTest/add`, payload.data)
      .then(async (res) => {
        console.log('res data', res);

        // if (res.data.status) {
        //   await thunk.dispatch(getinstitutionMasterTable(payload.obj))
        // }
        tabledata = res.data;
        message.success(res.data.message)
      })
      .catch((error) => {
        let insertError = error.response.data
        message.error(insertError.message);
      })
    return {
      tabledata: tabledata,
      Insertmessage: Insertmessage,
      success: success
    };
  }
)
// get test questions for print
export const getTestQuestions = createAsyncThunk('institutionMaster/getTestQuestions', async (payload) => {
  let downloadPdf = []
  await axios.post(`institutional/questions/${payload?.data?.inspurtestId}`, payload?.data)
    .then((res) => {
      downloadPdf = res.data
      message.success(res.data.message)
    })
    .catch((error) => {
      let insertError = error.response.data
      message.error(insertError.message);
    })
  return downloadPdf
})

export const getTestQuestionsOnly = createAsyncThunk('institutionMaster/getTestQuestionsOnly', async (payload) => {
  let downloadPdf = []
  await axios.post(`institutional/questionsonly/${payload?.data?.inspurtestId}`, payload?.data)
    .then((res) => {
      downloadPdf = res.data
      message.success(res.data.message)
    })
    .catch((error) => {
      let insertError = error.response.data
      message.error(insertError.message);
    })
  return downloadPdf
})

//get all purchase test by institution id
export const getAllPurchaseTest = createAsyncThunk(
  'institutionMaster/getAllPurchaseTest', async (payload) => {
    let tabledata = []
    await axios.post(`institutional/${payload.institutionId}/purchaseTest`, payload.obj)
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)

export const getAllUnAssignTest = createAsyncThunk(
  'institutionMaster/getAllUnAssignTest', async (payload) => {
    let tabledata = []
    await axios.get(`institutional/${payload}/unAssignTestList`)
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)
//get unassign test by id
export const getAssignTestById = createAsyncThunk("Masters/getAssignTestById", async (payload) => {
  try {
    let getUser = await axios.get(`/institutional/testAssign/test/${payload}`).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    console.log('getuser', getUser);
    return getUser
  } catch (error) {
  }
})

// get all assign test list (published test)
export const getAllAssignTest = createAsyncThunk(
  'institutionMaster/getAllAssignTest', async (payload) => {
    let tabledata = []
    let tableData = await axios.get(`institutional/${payload}/assignTestList`)
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)

// assign test 
export const assignunPublishedTest = createAsyncThunk(
  'institutional/assignunPublishedTest', async (payload, thunk) => {
    console.log('assignunPublishedTest payload', payload);
    let tabledata = []
    let Insertmessage = '';
    let success = '';
    let insertData = await axios.post(`institutional/${payload.institutionId}/test/${payload.testId}/${payload.id}`, payload.data)
      .then(async (res) => {
        console.log('assignunPublishedTest', res);
        // if (res.data.status) {
        //   await thunk.dispatch(getinstitutionMasterTable(payload.obj))
        // }
        tabledata = res.data;
        message.success(res.data.data.message)
      })
      .catch((error) => {
        let insertError = error.response.data
        message.error(insertError.message);
      })
    return {
      tabledata: tabledata,
      Insertmessage: Insertmessage,
      success: success
    };
  }
)

export const assignunPublishedTestPerticularStudent = createAsyncThunk(
  'institutional/assignunPublishedTestPerticularStudent', async (payload, thunk) => {
    let tabledata = []
    let Insertmessage = '';
    let success = '';
    await axios.post(`institutional/${payload.institutionId}/test/${payload.testId}/${payload.id}/perticularStudent`, payload.data)
      .then(async (res) => {
        tabledata = res.data;
        message.success(res.data.data.message)
      })
      .catch((error) => {
        let insertError = error.response.data
        message.error(insertError.message);
      })
    return {
      tabledata: tabledata,
      Insertmessage: Insertmessage,
      success: success
    };
  }
)

/* get institution batch test list */
export const getInstitutionBatchTest = createAsyncThunk(
  'institutional/getInstitutionBatchTest', async (payload, thunk) => {
    console.log('getInstitutionBatchTest payload', payload.data);
    let batchTestList
    await axios.post(`institutional/getTestListByCourseByBatch/${payload.instituteId}/batch/${payload.batchId}/course/${payload.courseId}`, payload.data)
      .then(async (res) => {
        console.log('test batch list from institution', res.data)
        batchTestList = res.data;
      })
      .catch((error) => {
        let insertError = error.response.data
        message.error(insertError.message);
      })
    return batchTestList
  }
)

/* get institution batch test rank result */
export const getInstitutionBatchTestResultRank = createAsyncThunk(
  'institutional/getInstitutionBatchTestResultRank', async (payload, thunk) => {
    let batchTestResult
    await axios.get(`institutional/batch/${payload.batchId}/test/${payload.testId}/assign/${payload.assignId}/batchRank`)
      .then(async (res) => {
        batchTestResult = res.data;
      })
      .catch((error) => {
        let insertError = error.response.data
        message.error(insertError.message);
      })
    return batchTestResult
  }
)

/* get institution batch test details and mark details */
export const getInstitutionBatchTestMarkDetails = createAsyncThunk(
  'institutional/getInstitutionBatchTestMarkDetails', async (payload, thunk) => {
    let batchTestMarkDetails
    await axios.get(`institutional/batch/test/assign/${payload.assignId}/testMarkDetails`)
      .then(async (res) => {
        batchTestMarkDetails = res.data;
      })
      .catch((error) => {
        let insertError = error.response.data
        message.error(insertError.message);
      })
    return batchTestMarkDetails
  }
)

// update publish test
export const updatePublishTest = createAsyncThunk(
  'institutional/updatePublishTest', async (payload, thunk) => {
    console.log('update publish test payload', payload);
    let updatedData;
    let data = {
      id: payload.id,
      shareViaEmail: payload.shareViaEmail,
      startTime: payload.startTime,
      testDate: payload.testDate,
    }
    await axios.put(`institutional/testAssign/test/${payload.id}`, data)
      .then(async (res) => {
        console.log('update publish test', res);
        updatedData = res.data;
        message.success(res.data.data.message)
      })
      .catch((error) => {
        let insertError = error.response.data
        message.error(insertError.message);
      })
    return updatedData;
  }
)

/* get overall rank details by institute */
export const getInstitutionOverallRankdetails = createAsyncThunk(
  'institutional/getInstitutionOverallRankdetails', async (payload, thunk) => {
    let overallRankDetails
    await axios.post(`institutional/getInstitutionOverallRankdetails/${payload.instituteId}`, payload.data)
      .then(async (res) => {
        overallRankDetails = res.data;
      })
      .catch((error) => {
        let insertError = error.response.data
        message.error(insertError.message);
      })
    return overallRankDetails
  }
)

const initialState = {
  instituteTableData: [],
  isLoading: false,
  institutionDataCount: 0,
  loginData: {
    userId: "",
    token: "",
  },
  instituteDataById: {},
  getTest: {},
  getAllPurchasetest: [],
  getUnAssignTest: [],
  getassigntestById: {},
  getAssignTest: [],
  getInstitutionBatchTestList: [],
  studentResultRank: [],
  isRankTableLoading: true,
  getInstitutionBatchTestMark: {},
  firstThreeRank: [],
  otherRankAll: [],
  overallRankDetails: [],
  totalDataCount: 0,
  getInstitutionBatchTestRe: []
}

const InstitutionSlice = createSlice({
  name: 'InstitutionSlice',
  initialState,
  extraReducers: {
    [createInstituteData.pending]: (state) => {
      state.isLoading = true
    },
    [createInstituteData.fulfilled]: (state, actions) => {
      let tableData = actions.payload.tabledata.data
      let success = actions.payload.success
      let Insertmessage = actions.payload.Insertmessage
      state.isLoading = false
    },
    [createInstituteData.rejected]: (state) => {
      state.isLoading = false
    },
    [getinstitutionMasterTable.pending]: (state) => {
      state.isLoading = true
    },
    [getinstitutionMasterTable.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.instituteTableData = actions.payload.getInstitutionDetails;

      state.institutionDataCount = actions.payload?.getQuestionCount?.questionCount;
    },
    [getinstitutionMasterTable.rejected]: (state) => {
      state.isLoading = false
    },
    [verifyInstituteToken.pending]: (state) => {
      state.isLoading = true
    },
    [verifyInstituteToken.fulfilled]: (state) => {
      state.isLoading = false
    },
    [verifyInstituteToken.rejected]: (state) => {
      state.isLoading = false
    },
    [setPassword.pending]: (state) => {
      state.isLoading = true
    },
    [setPassword.fulfilled]: (state) => {
      state.isLoading = false
    },
    [setPassword.rejected]: (state) => {
      state.isLoading = false
    },
    [getInstituteLoginData.pending]: (state) => {
      state.isLoading = true

    },
    [getInstituteLoginData.fulfilled]: (state, actions) => {
      state.isLoading = false

    },
    [getInstituteLoginData.rejected]: (state) => {
      state.isLoading = false

    },
    [forgotPassword.pending]: (state) => {
      state.isLoading = true
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isLoading = false
    },
    [forgotPassword.rejected]: (state) => {
      state.isLoading = false
    },
    [getInstitutionById.pending]: (state, action) => {
      state.instituteDataById = {}
      state.isLoading = true
    },
    [getInstitutionById.fulfilled]: (state, action) => {
      console.log('action', action.payload);
      state.instituteDataById = action.payload
      state.isLoading = false

    },
    [getInstitutionById.rejected]: (state, action) => {
      state.isLoading = false
    },
    [updateInstitution.pending]: (state, action) => {
      state.isLoading = true
    },
    [updateInstitution.fulfilled]: (state, action) => {
      state.isLoading = false

    },
    [updateInstitution.rejected]: (state, action) => {
      state.isLoading = false
    },
    [deleteInstitutionData.pending]: (state, action) => {
      state.isLoading = true
    },
    [deleteInstitutionData.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [deleteInstitutionData.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getAllTest.pending]: (state, action) => {
      state.getTest = {}
      state.isLoading = true
    },
    [getAllTest.fulfilled]: (state, action) => {
      state.getTest = action.payload
      state.isLoading = false

    },
    [getAllTest.rejected]: (state, action) => {
      state.isLoading = false
    },
    [addNewPlan.pending]: (state) => {
      state.isLoading = true
    },
    [addNewPlan.fulfilled]: (state, actions) => {
      let tableData = actions.payload.tabledata.data
      let success = actions.payload.success
      let Insertmessage = actions.payload.Insertmessage
      if (success == true) {

        message.success(Insertmessage)
      }
      state.isLoading = false
    },
    [addNewPlan.rejected]: (state) => {
      state.isLoading = false
    },
    [getAllPurchaseTest.pending]: (state) => {
      state.isLoading = true
    },
    [getAllPurchaseTest.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.getAllPurchasetest = actions.payload;

      // state.institutionDataCount = actions.payload?.getExamCount?.examCount;
    },
    [getAllPurchaseTest.rejected]: (state) => {
      state.isLoading = false
    },
    [getAllUnAssignTest.pending]: (state) => {
      state.getUnAssignTest = []
      state.isLoading = true
    },
    [getAllUnAssignTest.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.getUnAssignTest = actions.payload;

    },
    [getAllUnAssignTest.rejected]: (state) => {
      state.isLoading = false
    },
    [getAssignTestById.pending]: (state, action) => {
      state.getassigntestById = {}
      state.isLoading = true
    },
    [getAssignTestById.fulfilled]: (state, action) => {
      console.log('action', action.payload);
      state.getassigntestById = action.payload
      state.isLoading = false

    },
    [getAssignTestById.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getAllAssignTest.pending]: (state) => {
      state.getAssignTest = []
      state.isLoading = true
    },
    [getAllAssignTest.fulfilled]: (state, actions) => {
      state.getAssignTest = actions.payload;
      state.isLoading = false
    },
    [getAllAssignTest.rejected]: (state) => {
      state.isLoading = false
    },
    [assignunPublishedTest.pending]: (state) => {
      state.isLoading = true
    },
    [assignunPublishedTest.fulfilled]: (state, actions) => {
      let tableData = actions.payload.tabledata.data
      let success = actions.payload.success
      let Insertmessage = actions.payload.Insertmessage
      if (success == true) {

        message.success(Insertmessage)
      }
      state.isLoading = false
    },
    [assignunPublishedTest.rejected]: (state) => {
      state.isLoading = false
    },

    /* ---------------- */
    [assignunPublishedTestPerticularStudent.pending]: (state) => {
      state.isLoading = true
    },
    [assignunPublishedTestPerticularStudent.fulfilled]: (state, actions) => {
      let tableData = actions.payload.tabledata.data
      let success = actions.payload.success
      let Insertmessage = actions.payload.Insertmessage
      if (success == true) {
        message.success(Insertmessage)
      }
      state.isLoading = false
    },
    [assignunPublishedTestPerticularStudent.rejected]: (state) => {
      state.isLoading = false
    },

    /* get institution batch test list */

    [getInstitutionBatchTest.pending]: (state) => {
      state.isLoading = true
    },
    [getInstitutionBatchTest.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.getInstitutionBatchTestList = actions.payload.data.getTestListByCourseByBatch
      state.totalDataCount = actions.payload.data.getTestListByCourseByBatchCount
      state.getInstitutionBatchTestList.map((item) => {
        item.startedDate = moment(item.startedDate).format('DD MMM YYYY');
      })
      // console.log("into actions",actions.payload.data)
    },
    [getInstitutionBatchTest.rejected]: (state) => {
      state.isLoading = false
    },

    /* get institution batch test rank details */

    // [getInstitutionBatchTestResultRank.pending]: (state) => {
    //   state.isLoading = true
    // },
    // [getInstitutionBatchTestResultRank.fulfilled]: (state, actions) => {
    //   console.log(actions,'bsvsvvsvs');
    //   state.isRankTableLoading = false
    //   state.studentResultRank = actions.data.rankDetails
    //   state.studentResultRank.map((e, i) => {
    //     e['rank'] = i + 1
    //     return e
    //   })
    //   state.firstThreeRank = state.studentResultRank.slice(0, 3);
    //   state.otherRankAll = state.studentResultRank.slice(3);
    // },
    // [getInstitutionBatchTestResultRank.rejected]: (state) => {
    //   state.isLoading = false
    // },

    [getInstitutionBatchTestResultRank.pending]: (state) => {
      state.isLoading = true
    },
    [getInstitutionBatchTestResultRank.fulfilled]: (state, actions) => {
      state.isRankTableLoading = false
      state.getInstitutionBatchTestRe = actions.payload
      state.studentResultRank = actions.payload.data.rankDetails
      state.studentResultRank.map((e, i) => {
        e['rank'] = i + 1
        return e
      })
      state.firstThreeRank = state.studentResultRank.slice(0, 3);
      state.otherRankAll = state.studentResultRank.slice(3);
    },
    [getInstitutionBatchTestResultRank.rejected]: (state) => {
      state.isLoading = false
    },

    /* get institution batch test mark details */
    [getInstitutionBatchTestMarkDetails.pending]: (state) => {
      state.isLoading = true
    },
    [getInstitutionBatchTestMarkDetails.fulfilled]: (state, actions) => {
      state.isRankTableLoading = false
      state.getInstitutionBatchTestMark = actions.payload.data[0]
    },
    [getInstitutionBatchTestMarkDetails.rejected]: (state) => {
      state.isLoading = false
    },
    [updatePublishTest.pending]: (state) => {
      state.isLoading = true
    },
    [updatePublishTest.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [updatePublishTest.rejected]: (state) => {
      state.isLoading = false
    },
    [getInstitutionOverallRankdetails.pending]: (state) => {
      state.isLoading = true
    },
    [getInstitutionOverallRankdetails.fulfilled]: (state, actions) => {
      console.log("actions ------- ", actions.payload)
      state.isLoading = false
      if (actions.payload.success == true) {
        state.overallRankDetails = actions.payload.data.dataResult
        state.firstThreeRank = actions.payload.data.firstThreeRank
        actions.payload.data.dataResult.forEach(e => {
          actions.payload.data.firstThreeRank.forEach((ele, i) => {
            if (ele.userId == e.userId) {
              e['index'] = i
            }
          })
        })
        state.overallRankDetails = actions.payload.data.dataResult
        state.firstThreeRank = actions.payload.data.firstThreeRank
      }
    },
    [getInstitutionOverallRankdetails.rejected]: (state) => {
      state.isLoading = false
    },
    [getTestQuestions.rejected]: (state) => {
      state.isLoading = false
    },
    [getTestQuestions.pending]: (state) => {
      state.isLoading = true
    },
    [getTestQuestions.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.getTestQuestions = actions.payload
    },
    [getTestQuestionsOnly.pending]: (state) => {
      state.isLoading = true
    },
    [getTestQuestionsOnly.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [getTestQuestionsOnly.rejected]: (state) => {
      state.isLoading = false
    },

    // /* get question  */
    // [getAllTest.pending]: (state, action) => {
    //   state.getTest = {}
    //   state.isLoading = true
    // },
    // [getAllTest.fulfilled]: (state, action) => {
    //   state.getTest = action.payload
    //   state.isLoading = false

    // },
    // [getAllTest.rejected]: (state, action) => {
    //   state.isLoading = false
    // },
  },
})


// export const {createInstitute} = InstitutionSlice.actions
export default InstitutionSlice.reducer