import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Drawer,
  Modal,
  Table,
  Image,
  Form,
  Checkbox,
  Tag,
} from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import {
  getAllStats,
  createStats,
  getStatsById,
  updateStats,
  deleteStats,
} from "../components/reducers/StatsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { useDebouncedCallback } from "use-debounce";
import TableComponent from "./Table/tableComponent";
import Loading from "./Loading";

const Stats = () => {
  const dispatch = useDispatch();
  let tableHeight = 320;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchText, setSearchText] = useState("");

  const { statsData, isLoading, totalDataCount } = useSelector(
    (state) => state.Stats
  );
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    };
    dispatch(getAllStats(obj));
  }, []);

  const initialValues = {
    title: "",
    count: "",
    orderNo: "",
    isActive: false,
  };

  const validationSchema = yup.object({
    title: yup
      .string()
      .required("Title is Required")
      .min(2, "Title Needed At Least Two characters")
      .max(100, "Title not more than 100 characters"),

    count: yup
      .string()
      .required("Count is Required")
      .matches(/^[0-9\b]+$/, "Count must be an numbers"),

    orderNo: yup
      .string()
      .required("Order Number is Required")
      .matches(/^[1-9][0-9]*$/, "Order Number must be a number and cannot consist only of 0")
    });

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "6%",
      render: (_, record, i) => {
        return (
          <div className="index-id">
            {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => {
        return (
          <div>
            <p>{record.title}</p>
          </div>
        );
      },
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      render: (_, record) => {
        return (
          <div>
            <p>{record.count}</p>
          </div>
        );
      },
    },
    {
      title: "Order No",
      dataIndex: "orderNo",
      key: "orderNo",
      render: (_, record) => {
        return (
          <div>
            <p>{record.orderNo}</p>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (_, record) => {
        return (
          <div>
            <span>
              {" "}
              {record.isActive ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">inActive</Tag>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="table-action-link">
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)}>
              Edit
            </a>
            <Tooltip style={{ padding: 0 }} title="Delete">
              <IconButton>
                <Image
                  src={DeleteImage}
                  preview={false}
                  onClick={() => deleteData(record)}
                ></Image>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const editData = (data) => {
    console.log(!formik.dirty,!formik.isValid, "////////////////");
    setCurrentId(data.id);
    setVisible(true);
    dispatch(getStatsById(data))
      .unwrap()
      .then((res) => {
        let tempValues = res.data[0];
        formik.setValues(tempValues);
      });
    setCurrentTitle("Edit Stats");
  };

  const deleteData = (record) => {
    setCurrentId(record.id);
    setIsModalVisible(true);
  };

  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getAllStats(obj));
  }, 1000);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
    };

    dispatch(getAllStats(obj));
  };

  const openDrawer = () => {
    setCurrentId(null);
    formik.resetForm({ values: initialValues });

    setVisible(true);
    setCurrentTitle("Create Stats");
  };

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false);
      let payload = {
        data: values,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        },
      };
      setSubmitModalVisible(false);
      dispatch(createStats(payload));
    },
  });

  // const handleFormValidation = (isValid) => {
  //   setIsFormValid(isValid);
  // };

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
    console.log(formik, "/////////////up//////////////");
    let datas = {
      id: currentId,
      data: formik.values,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };

    setUpdateModalVisible(false);
    setVisible(false);
    dispatch(updateStats(datas));
    formik.resetForm();
  };

  const modalOnCancel = () => {
    setIsModalVisible(false);
  };
  const modalOnOk = () => {
    setIsModalVisible(false);
    setIsModalVisible(false);
    let id = currentId;
    let data = {
      id: id,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };
    dispatch(deleteStats(data));
  };
  const drawerCloser = () => {
    setVisible(false);
    formik.resetForm();
  };

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <>
      <div className="admin-page">
        <div className="adminHeader">
          <h3 className="primary-header">Stats</h3>
          <div className="admin-button">
            <Button
              className="primary-submit-button"
              type="primary"
              onClick={openDrawer}
            >
              Add Stats{" "}
            </Button>
          </div>
        </div>
        <div className="all-table-component" style={{ padding: 0, margin: 0 }}>
          <div className="golbalSearch">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
              style={{ width: 262, height: 32 }}
              onChange={(event) => onSearch(event.target.value)}
            />
          </div>
          <TableComponent
            dataSource={statsData}
            columns={columns}
            rowSelection={rowSelection}
            tableOnChange={tableOnChange}
            tableHeight={tableHeight}
            totalData={totalDataCount}
            currentPage={paginationData + 1}
            loadingStatus={isLoading}
            limit={limit}
          />
        </div>
        <Drawer
          title={currentTitle}
          onClose={() => drawerCloser()}
          width="518px"
          closable={false}
          closeIcon={false}
          placement="right"
          open={visible}
          footer={
            <div className="footer">
              <div className="footer-button">
                <Button
                  className="cancel-Button primary-cancel-button"
                  onClick={drawerCloser}
                >
                  Cancel
                </Button>
                {currentId == null ? (
                  <Button
                    className={
                      !formik.isValid || !formik.dirty
                        ? "disabled-button"
                        : "primary-submit-button"
                    }
                    type="primary"
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={showSubmitConfirmation}
                  >
                    Submit{" "}
                  </Button>
                ) : (
                    <Button
                      className={
                        !formik.isValid || !formik.dirty
                          ? "disabled-button"
                          : "primary-submit-button"
                      }
                      type="primary"
                      disabled={!formik.dirty || !formik.isValid}
                      onClick={showUpdateConfirmation}
                    >
                      Update{" "}
                    </Button>
                )}
              </div>
            </div>
          }
        >
          <Formik validateOnChange={false} validateOnBlur={false}>
            <Form>
              <div className="form-control">
                <label htmlFor="title">
                  Title<span style={{ color: "red" }}> *</span>
                </label>
                <Input
                  type="text"
                  id="title"
                  placeholder="Enter a Title"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="errorMessage" style={{ color: "red" }}>
                    {formik.errors.title}
                  </div>
                ) : null}
              </div>
              <div className="form-control">
                <label htmlFor="orderNo">
                  Order No<span style={{ color: "red" }}> *</span>
                </label>
                <Input
                  type="number"
                  id="orderNo"
                  placeholder="Enter a Order No"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.orderNo}
                />
                {formik.touched.orderNo && formik.errors.orderNo ? (
                  <div className="errorMessage" style={{ color: "red" }}>
                    {formik.errors.orderNo}
                  </div>
                ) : null}
              </div>

              <div className="form-control">
                <label htmlFor="count">
                  Count<span style={{ color: "red" }}> *</span>
                </label>
                <Input
                  type="text"
                  id="count"
                  placeholder="Enter a Count"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.count}
                />
                {formik.touched.count && formik.errors.count ? (
                  <div className="errorMessage" style={{ color: "red" }}>
                    {formik.errors.count}
                  </div>
                ) : null}
              </div>
              <div className="form-control">
                <Checkbox
                  id="isActive"
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  // value={formik.values.isActive}
                >
                  Active
                </Checkbox>
              </div>
            </Form>
          </Formik>
        </Drawer>
        <Modal
          title="Deletion"
          open={isModalVisible}
          onCancel={modalOnCancel}
          onOk={modalOnOk}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={() => modalOnCancel()}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => modalOnOk()}
              >
                OK
              </Button>
            </div>
          }
        >
          <p>Are you sure want to delete ?</p>
        </Modal>

        {/* update model */}
        <Modal
          title="Update Confirmation"
          open={isUpdateModalVisible}
          onCancel={closeUpdateModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeUpdateModal}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => updateData(formik.values)}
              >
                Update
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to update?</p>
        </Modal>

        {/* submit modal */}

        <Modal
          title="Submit Confirmation"
          open={isSubmitModalVisible}
          onCancel={closeSubmitModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeSubmitModal}
              >
                Cancel{" "}
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={formik.handleSubmit}
              >
                {" "}
                Submit{" "}
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to Submit?</p>
        </Modal>
      </div>

      {isLoading ? (
        <div className="loading-container">
          <Loading></Loading>
        </div>
      ) : null}
    </>
  );
};

export default Stats;
