import { message } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from './Loading'
import { verifyToken } from './reducers/loginSlice'

const VerifyTokenPage = () => {

    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {isLoading} = useSelector((state)=>state.Loginmaster)

    useEffect(()=>{
        localStorage.clear()
        dispatch(verifyToken(params.token)).unwrap().then((res)=>{
            if(res?.success){
                message.success(res.message)
                navigate("/setPassword/"+res.data.token)
            }
            else{
                navigate("/login")
            }
        })

    },[])


  return (
    <>
        {
            isLoading ? <div className='loading-container'>
                <Loading></Loading>
                 </div> : null
            }
    </>

  )
}

export default VerifyTokenPage