import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import axios from "axios";


// Add Admin Master Data
export const addStudentData = createAsyncThunk('StudentMaster/addData',async(data,thunk)=>{
 try {
  let payloadData = []
  const insertData = await axios.post('user/student/create',data.payload).then(async(res)=>{
   if(res.data.createStudentUser.status){
    await thunk.dispatch(getAllStudentData(data.obj))
    message.success(res.data.createStudentUser.message)
    return res.data.createStudentUser.data
   }else{
    message.error(res.data.createStudentUser.message)
   }
  })
  return payloadData
 } catch (error) {
  message.error(error.response.data.createStudentUser.message)
 }


})

// Get All Student Data
export const getAllStudentData = createAsyncThunk('StudentMaster/getAllData',async(payload)=>{
 try{ 
  let getAllData = await axios.post('/user/student/getUserAll',payload).then((res)=>{
    if(res.data.success){
     return res.data.data
    }
  })
  return getAllData
  
 }catch(error){
 }
})

// Update By id 
export const updateStudentDataById = createAsyncThunk('StudentMaster/updateStudentDataById', async (data,thunk) => {
 try {

  let updateData = await axios.put('/user/student/updateUser/' + data.payload.id, data.payload.data).then(async(res) => {
      if (res.data.studentUserUpdate.status) {
         await thunk.dispatch(getAllStudentData(data.obj))
        message.success(res.data.studentUserUpdate.message)
        return res.data.studentUserUpdate.data
  
     }
     else{
      message.error(res.data.studentUserUpdate.message)

     }
  })
  return updateData

 } catch (error) {
 }
})

// delete By id 
export const deleteStudentDataById = createAsyncThunk('StudentMaster/deleteStudentDataById', async (id,thunk) => {
 try {
let updatedId = id
  let updateData = await axios.delete('/user/student/deleteUser/'+id).then((res) => {
   if (res.data.success) {
    message.success(res.data.message)
    return res.data.data

   }
   else{
    message.error(res.data.message)

   }
  })
  return updatedId

 } catch (error) {
 }
})


const initialState = {
 studentMaster:[],
 isLoading:false,
 totalDataCount:0,
}

 const StudentSlice =  createSlice({
  name:'StudentMaster',
  initialState,
  reducers:{},
  extraReducers:{
   [addStudentData.pending]:(state)=>{
    state.isLoading = true
   },
   [addStudentData.fulfilled]:(state,action)=>{
    state.isLoading = false
   },
   [addStudentData.rejected]:(state)=>{
    state.isLoading = false
   },
   [getAllStudentData.pending]:(state)=>{
    state.isLoading = true
   },
   [getAllStudentData.fulfilled]:(state,action)=>{
    state.isLoading = false
    state.studentMaster = action.payload.getUserData
    state.totalDataCount = action.payload?.getUserCount?.userCount
   },
   [getAllStudentData.rejected]:(state)=>{
    state.isLoading = false
   },
   [updateStudentDataById.pending]: (state) => {
    state.isLoading = true
 
   },
   [updateStudentDataById.fulfilled]: (state, action) => {
    state.isLoading =  false;

   },
   [updateStudentDataById.rejected]: (state) => {
    state.isLoading = false;
 
   },
   [deleteStudentDataById.pending]: (state) => {
    state.isLoading = true
   },
   [deleteStudentDataById.fulfilled]: (state, action) => {
    state.isLoading = false;
    state.studentMaster =  state.studentMaster.filter((e)=> e.id !== action.payload)
   },
   [deleteStudentDataById.rejected]: (state) => {
    state.isLoading = false;
 
   },
  }
 })

 export default StudentSlice.reducer