import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";


export const sendPublicPushNotification = createAsyncThunk('Notification/sendPublicPushNotification', async (payload, thunk) => {
  try {
    const publicData = await axios.post('user/sendPublicPushNotification', payload.data).then(async (res) => {
      if (res.data.status) {
        await thunk.dispatch(getNotificationDetails(payload.obj))
        message.success(res.data.message)
        return res.data
      }
    })
    return publicData
  } catch (error) {
    message.error(error.response.data.message)
  }
})

export const sendPrivatePushNotification = createAsyncThunk('Notification/sendPrivatePushNotification', async (payload, thunk) => {
  try {
    const privateData = await axios.post('user/sendPrivatePushNotification', payload.data).then(async (res) => {
      if (res.data.status) {
        await thunk.dispatch(getNotificationDetails(payload.obj))
        message.success(res.data.message)
        return res.data
      }
    })
    return privateData
  } catch (error) {
    message.error(error.response.data.createAdminUser.message)
  }
})

export const getNotificationMethod = createAsyncThunk('Notification/getNotificationMethod', async (data, thunk) => {
  try {
    const getNotificationMethod = await axios.post('user/GetnotificationMethod', data).then(async (res) => {
      if (res.data.status) {
        return res.data
      }
    })
    return getNotificationMethod
  } catch (error) {
    message.error(error.response.data.message)
  }
})

export const getNotificationType = createAsyncThunk('Notification/getNotificationType', async (data, thunk) => {
  try {
    const getNotificationType = await axios.post('user/GetnotificationType', data).then(async (res) => {
      if (res.data.status) {
        return res.data
      }
    })
    return getNotificationType
  } catch (error) {
    message.error(error.response.data.message)
  }
})

export const getActiveStudents = createAsyncThunk('Notification/getActiveStudents', async (data, thunk) => {
  try {
    const activeStudent = await axios.post('user/activeUserFcm').then(async (res) => {
      if (res.data.status) {
        return res.data
      }
    })
    return activeStudent
  } catch (error) {
    message.error(error.response.data.createAdminUser.message)
  }
})

export const getNotificationDetails = createAsyncThunk('Notification/getNotificationDetails', async (data, thunk) => {
  try {
    const notificationDetails = await axios.post('user/GetPushNotificationDetails', data).then(async (res) => {
      if (res.data.status) {
        return res.data
      }
    })
    return notificationDetails
  } catch (error) {
    message.error(error.response.data.message)
  }
})

const initialState = {
  isLoading: false,
  notificationMethodData: [],
  notificationTypeData: [],
  activestudentData: []
}
const NotificationSlice = createSlice({
  name: 'NotificationSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [sendPublicPushNotification.pending]: (state) => {
      state.isLoading = true
    },
    [sendPublicPushNotification.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [sendPublicPushNotification.rejected]: (state) => {
      state.isLoading = false
    },
    [sendPrivatePushNotification.pending]: (state) => {
      state.isLoading = true
    },
    [sendPrivatePushNotification.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [sendPrivatePushNotification.rejected]: (state) => {
      state.isLoading = false
    },
    [getNotificationMethod.pending]: (state) => {
      state.isLoading = true
    },
    [getNotificationMethod.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.notificationMethodData = actions?.payload?.data || []
    },
    [getNotificationMethod.rejected]: (state) => {
      state.isLoading = false
    },
    [getNotificationType.pending]: (state) => {
      state.isLoading = true
    },
    [getNotificationType.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.notificationTypeData = actions?.payload?.data || []
    },
    [getNotificationType.rejected]: (state) => {
      state.isLoading = false
    },
    [getNotificationDetails.pending]: (state) => {
      state.isLoading = true
    },
    [getNotificationDetails.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.notificationDetailsData = actions?.payload?.data.GetPushNotificationDetails|| []
      state.notificationDetailsCount = actions?.payload?.data.GetPushNotificationCount.count || []
    },
    [getNotificationDetails.rejected]: (state) => {
      state.isLoading = false
    },
    [getActiveStudents.pending]: (state) => {
      state.isLoading = true
    },
    [getActiveStudents.fulfilled]: (state, actions) => {
      state.isLoading = false
      console.log(actions, 'actions');
      state.activestudentData = actions?.payload?.data.data
    },
    [getActiveStudents.rejected]: (state) => {
      state.isLoading = false
    }
  }
})

export default NotificationSlice.reducer
