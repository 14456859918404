import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from 'moment';

export const insertBatchMasterTable = createAsyncThunk(
  'batchMaster/insertBatchMasterTable', async (payload, thunk) => {
    let tabledata = []
    let Insertmessage = '';
    let success = ''
    let insertData = await axios.post('masters/batch/create', payload)
      .then(async (res) => {
        console.log('response of batch master', res)
        if (res.data.status) {

          await thunk.dispatch(getBatchMasterTable(payload.courseId))
        }
        message.success(res.data.message);
      })
      .catch((error) => {
        let insertError = error.response.data.createExam
        message.error(insertError.message);
      })
    return {
      tabledata: tabledata,
      Insertmessage: Insertmessage,
      success: success
    };
  }
)
export const getBatchMasterTable = createAsyncThunk(
  'BatchMaster/getBatchMasterTable', async (id) => {
    let tabledata = []
    let tableData = await axios.get(`masters/batch/getByCourse/${id}`)
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)

export const getBatchById = createAsyncThunk("Masters/getBatchById", async (payload) => {
  try {
    let getbatchById = await axios.get(`/masters/batch/${payload}`).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    console.log('getBatchById', getbatchById);
    return getbatchById
  } catch (error) {
  }
})

/* add the student from the batch */
export const insertStudentByBatch = createAsyncThunk(
  'batchMaster/insertStudentByBatch', async (payload, thunk) => {
    let tabledata = []
    let Insertmessage = '';
    let success = ''
    await axios.post(`institutional/${payload.institutionId}/student/add`, payload.data)
      .then(async (res) => {
        if (res.status) {
          let obj = {
            pagination: -1,
            searchValue: '',
            limit: 50
          }
          await thunk.dispatch(getStudentByBatch({ data: obj, institutionId: +payload.institutionId, courseId: +payload.data.courseId, batchId: +payload.data.batchId }))
        }
        message.success(res.data.message);
      })
      .catch((error) => {
        // let insertError = error.response.data.createExam
        message.error(error.message);
      })
    return {
      tabledata: tabledata,
      Insertmessage: Insertmessage,
      success: success
    };
  }
)

export const getStudentByBatch = createAsyncThunk(
  'Master/getStudentByBatch', async (payload) => {
    let tabledata = []
    let tableData = await axios.post(`institutional/${payload.institutionId}/course/${payload.courseId}/batch/${payload.batchId}`, payload.data)
      .then((res) => {
        tabledata = res.data.data;

      })
      .catch((error) => {
      })
    return tabledata;
  }
)

export const getInstitutionStudentById = createAsyncThunk("institutional/getInstitutionStudentById", async (payload) => {
  try {
    let getStudent = await axios.get(`/institutional/${payload.institutionDetailId}/course/${payload.courseId}/batch/${payload.batchId}/student/${payload.id}`).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    console.log('getStudent', getStudent);
    return getStudent
  } catch (error) {
  }
})

export const updateStudentByBatch = createAsyncThunk("institutional/updateStudentByBatch", async (payload, thunk) => {
  try {
    console.log('update', payload);
    let updateUser = await axios.put(`/institutional/student/${payload.id}`, payload.data).then(async (res) => {
      console.log('ressss', res);

      if (res.data.status) {
        message.success(res.data.message)
        let obj = {
          pagination: -1,
          searchValue: '',
          limit: 50
        }
        await thunk.dispatch(getStudentByBatch({ institutionId: payload.data.institutionDetailId, courseId: payload.data.courseId, batchId: payload.data.batchId, data: obj }))
        return res.data


      }
    })
    return updateUser
  } catch (error) {
    message.error(error.response.data.message)

  }
})

export const deleteStudentByBatch = createAsyncThunk("institutional/deleteStudentByBatch", async (payload, thunk) => {
  try {
    let deleteUser = await axios.delete(`/institutional/student/${payload.id}`).then(async (res) => {
      if (res.data.status) {
        message.success(res.data.message)
        let obj = {
          pagination: -1,
          searchValue: '',
          limit: 50
        }
        await thunk.dispatch(getStudentByBatch({ institutionId: payload.data.institutionDetailId, courseId: payload.data.courseId, batchId: payload.data.batchId, data: obj }))
        return res.data
      }
    })
    return deleteUser
  } catch (error) {
    message.error(error.response.data.message)

  }
})

// bulk student upload
export const excelBulkUpload = createAsyncThunk(
  'institutional/excelBulkUpload', async (payload, thunk) => {
    try {
      let excel = await axios.put(`institutional/bulkipload/${payload.institutionId}/course/${payload.courseId}/batch/${payload.batchId}/student`, payload.excelFile).then(async (res) => {
        console.log('res builk', res)
        if (res?.data?.status) {

          message.success(res.data.message)
          let obj = {
            data: {
              pagination: 0,
              searchValue: "",
              limit: 50
            },
            institutionId: payload.institutionId,
            courseId: payload.courseId,
            batchId: payload.batchId,
          }
          await thunk.dispatch(getStudentByBatch(obj))
        }
      })
    } catch (error) {
      let responseMessage = ''
      for (let i = 0; i < error.response.data.data.length; i++) {
        responseMessage += `${i + 1}. Row number : ${error.response.data.data[i].rowId} Column name : ${error.response.data.data[i].columnName} message : ${error.response.data.data[i].message}. `
      }
      message.error(responseMessage)
    }


  }
)

export const updateBatch = createAsyncThunk("Masters/updateBatch", async (payload, thunk) => {
  try {
    console.log('update', payload);
    let updateUser = await axios.put(`/masters/batch/${payload.id}`, payload.data).then(async (res) => {
      console.log('ressss', res);

      if (res.data.status) {
        message.success(res.data.message)

        await thunk.dispatch(getBatchMasterTable(payload.data.courseId))
        return res.data


      }
    })
    return updateUser
  } catch (error) {
    message.error(error.response.data.message)

  }
})
export const deleteBatch = createAsyncThunk("Masters/deleteBatch", async (payload, thunk) => {
  try {
    let deletebatch = await axios.delete(`/masters/batch/${payload.id}`).then(async (res) => {
      console.log('detele resss', res);
      if (res.data.success) {
        message.success(res.data.message)

        await thunk.dispatch(getBatchMasterTable(payload.courseId))
        return res.data
      }
    })
    return deletebatch
  } catch (error) {
    message.error(error.response.data.message)

  }
})

export const resetPassInstitutionStudent = createAsyncThunk("Institutional/resetPassInstitutionStudent", async (payload, thunk) => {
  try {
    let resetPassInsStudMail = await axios.put(`/institutional/resetPassInstitutionStudent/${payload}`, {}).then(async (res) => {
      if (res.data.success) {
        message.success(res.data.message)
        return res.data
      }
    })
    return resetPassInsStudMail
  } catch (error) {
    message.error(error.response.data.message)
  }
})


const initialState = {
  batchMasterData: [],
  studentDetails: [],
  isLoading: false,
  batchDataCount: 0,
  batchById: {},
  isLoadingById: false,
  totalDataCount: 0
}
const BatchSlice = createSlice({
  name: 'BatchSlice',
  initialState,
  extraReducers: {
    [insertBatchMasterTable.pending]: (state) => {
      state.isLoading = true
    },
    [insertBatchMasterTable.fulfilled]: (state, actions) => {
      let tableData = actions.payload.tabledata.data
      let success = actions.payload.success
      let Insertmessage = actions.payload.Insertmessage
      if (success == true) {

        message.success(Insertmessage)
      }
      state.isLoading = false
    },
    [insertBatchMasterTable.rejected]: (state) => {
      state.isLoading = false
    },
    [getBatchMasterTable.pending]: (state) => {
      state.isLoading = true
    },
    [getBatchMasterTable.fulfilled]: (state, actions) => {
      console.log('actions payloaddd', actions);
      state.isLoading = false
      state.batchMasterData = actions.payload;
      // state.batchMasterData.map((item)=>{
      //   item.createdAt=moment(item.createdAt).format('DD MMM YYYY')
      // })
      state.batchDataCount = actions.payload?.getExamCount?.examCount;
    },
    [getBatchMasterTable.rejected]: (state) => {
      state.isLoading = false
    },

    /* add student by batch */
    [insertStudentByBatch.pending]: (state) => {
      state.isLoading = true
    },
    [insertStudentByBatch.fulfilled]: (state, actions) => {
      state.isLoading = false
      // state.studentDetails = actions.payload;
    },
    [insertStudentByBatch.rejected]: (state) => {
      state.isLoading = false
    },

    [getStudentByBatch.pending]: (state) => {
      state.isLoading = true
    },
    [getStudentByBatch.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.studentDetails = actions.payload.getInstitutionStudentDetails;
      state.totalDataCount = actions.payload.instituionalStudentCount
      state.studentDetails = state.studentDetails.map((e) => {
        e.updatedAt = moment(e.updatedAt).format('DD MMM YYYY')
        return e
      })
    },
    [getStudentByBatch.rejected]: (state) => {
      state.isLoading = false
    },
    [getBatchById.pending]: (state, action) => {
      state.isLoadingById = true
    },
    [getBatchById.fulfilled]: (state, action) => {
      state.batchById = action.payload
      state.isLoadingById = false

    },
    [getBatchById.rejected]: (state, action) => {
      state.isLoadingById = false
    },
    [updateBatch.pending]: (state, action) => {
      state.isLoading = true
    },
    [updateBatch.fulfilled]: (state, action) => {
      state.isLoading = false

    },
    [updateBatch.rejected]: (state, action) => {
      state.isLoading = false
    },
    [deleteBatch.pending]: (state, action) => {
      state.isLoading = true
    },
    [deleteBatch.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [deleteBatch.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getInstitutionStudentById.pending]: (state, action) => {
      state.isLoading = true
    },
    [getInstitutionStudentById.fulfilled]: (state, action) => {
      state.examGroupById = action.payload.data
      state.isLoading = false

    },
    [getInstitutionStudentById.rejected]: (state, action) => {
      state.isLoading = false
    },
    [updateStudentByBatch.pending]: (state, action) => {
      state.isLoading = true
    },
    [updateStudentByBatch.fulfilled]: (state, action) => {
      state.isLoading = false

    },
    [updateStudentByBatch.rejected]: (state, action) => {
      state.isLoading = false
    },
    [deleteStudentByBatch.pending]: (state, action) => {
      state.isLoading = true
    },
    [deleteStudentByBatch.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [deleteStudentByBatch.rejected]: (state, action) => {
      state.isLoading = false
    },
    [excelBulkUpload.pending]: (state, action) => {
      state.isLoading = true
    },
    [excelBulkUpload.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [excelBulkUpload.rejected]: (state, action) => {
      state.isLoading = false
    },
    [resetPassInstitutionStudent.pending]: (state, action) => {
      state.isLoading = true
    },
    [resetPassInstitutionStudent.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [resetPassInstitutionStudent.rejected]: (state, action) => {
      state.isLoading = false
    },
  }
})
export default BatchSlice.reducer;
