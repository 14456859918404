import { Breadcrumb } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { addBreadCrumbData } from './reducers/masterSlice'

const BreadCrumb = ({data,loading}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {isLoading,currentBreadCrumbData} = useSelector((state) => state.MasterSlice)
    const {  isQuestionLoading } = useSelector((state) => state.QuestionSlice)
    const location = useLocation();
    const onStaticExamClick=()=>{
        navigate(`/ExamMaster`)
        }
  return (
    <div className='BreadCrumb-container'>
    {
        location.pathname.split("/")[1] == "ExamMaster" ?
        <>
                {
            !isLoading   ?
                <>
                    <Breadcrumb separator=">">
                            <Breadcrumb.Item onClick={onStaticExamClick}> <span className='content' >{currentBreadCrumbData?.exam}</span> </Breadcrumb.Item>
                        { currentBreadCrumbData?.examId ?
                            <Breadcrumb.Item onClick={()=> navigate(`/ExamMaster/${currentBreadCrumbData.examId}`)} > <span className='content' >  {currentBreadCrumbData.examName} </span></Breadcrumb.Item>
                            :null
                        }
                        { currentBreadCrumbData?.subId ?
                            <Breadcrumb.Item  onClick={()=> navigate(`/ExamMaster/${currentBreadCrumbData.examId}/${currentBreadCrumbData.subId}`)} ><span className='content' > {currentBreadCrumbData.subjectName} </span></Breadcrumb.Item>
                            :null
                        }
                        { currentBreadCrumbData?.chapterId ?
                            <Breadcrumb.Item  onClick={()=> navigate(`/ExamMaster/${currentBreadCrumbData.examId}/${currentBreadCrumbData.subId}/${currentBreadCrumbData.chapterId}`)} > <span className='content' > {currentBreadCrumbData.chapterName}</span> </Breadcrumb.Item>
                            :null
                        }
                    </Breadcrumb>
                </>
            : null
        }
        </>
        : null
    }
    </div>
  )
}

export default BreadCrumb