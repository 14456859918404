import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { message } from "antd";

// insert test bundle
export const insertTestBundle=createAsyncThunk(
  'TestBundle/insertTestBundle',async (payload)=>{
    let insert=[]
    let success=''
    let insertMessage=''
    let insertData=await axios.post('testMaster/createTestBundle',payload)
    .then((res)=>{
      success=res.data.data.success
      insertMessage=res.data.data.message
      insert=res.data.data.data
    })
    .catch((error)=>{
      let err=error.response.data.createTestBundle
      message.error(err.message)
    })
    return {
      data:insert,
      insertMessage:insertMessage,
      success:success
    }
  }
)

// get test bundle

export const getTestBundle=createAsyncThunk(
  'TestBundle/getTestBundle',async (payload)=>{

    try {
      let getData=await axios.post('testMaster/getTestBundle',payload)
      .then((res)=>{
        if(res.data.success){
          return res.data.data;
        }
      })
      return  getData
    
     } catch (error) {
     }
    })


// get edit data

export const getEditData=createAsyncThunk(
  'TestBundle/getEditData',async (payload)=>{
    let resData={};
    let success="";
    let insertData=await axios.get('testMaster/getTestBundle/'+payload)
    .then((res)=>{
      resData=res.data.data[0];
      success=res.data.success
    })
    .catch((error)=>{
    })
    return {
      resData:resData,
      success:success
    }
  }
)

// update test bundle data

export const updateTestBundle=createAsyncThunk(
  'TestBundle/updateTestBundle',async (payload)=>{
    let update=[]
    let success=''
    let updateMessage=''
    let data={
      name:payload.name,
      description:payload.description
    }
    let updateData=await axios.put('testMaster/updateTestBundle/'+payload.id,data)
    .then((res)=>{
      update=res.data.data.data;
      success=res.data.data.success;
      updateMessage=res.data.data.message;
    })
    .catch((error)=>{
    })
    return {
      data:update,
      success:success,
      updateMessage:updateMessage
    }
  }
)

// delete test bundle

export const deleteTestBundle=createAsyncThunk(
  'TestBundle/deleteTestBundle',async (payload)=>{
    let success='';
    let deleteMessage=''
    let deleteData=await axios.delete('testMaster/deleteTestBundle/'+payload)
    .then((res)=>{
      success=res.data.deleteTestBundle.status;
      deleteMessage=res.data.deleteTestBundle.message
    })
    .catch((error)=>{
    })
    return {
      deleteId:payload,
      success:success,
      deleteMessage:deleteMessage
    };
  }
)

// add test for test bundles

export const insertTestBundleTest=createAsyncThunk(
  'TestBundle/insertTestBundletest',async (payload,thunk)=>{
    let insert=[]
    let success=''
    let insertMessage=''
    let data = {
      testBundleSelection:payload.selectedRowKeys
    }
    let insertTestData=await axios.put('testMaster/testBundleTestSelection/'+payload.bundleId, data )
    .then(async(res)=>{
      success=res.data.data.success
      insertMessage=res.data.data.message
      insert=res.data.data.data
      if(success==true){
       await  thunk.dispatch(getTestBundleTest(payload.bundleId))
      }
    })
    .catch((error)=>{
    })
    return {
      data:insert,
      insertMessage:insertMessage,
      success:success,
      id:payload
    }
  }
)

// remove tests from test bundle

export const removeTestBundleTest=createAsyncThunk(
  'TestBundle/removeTestBundleTest',async (payload,thunk)=>{
    let remove=[]
    let success=''
    let removeMessage=''
    let data = {
      testBundleSelectionIds:payload.selectedRowKeys
    }
    let removeTestData=await axios.post('testMaster/deleteTestBundleSelection',data)
    .then((res)=>{
      success=res.data.data.success
      removeMessage=res.data.data.message
      remove=res.data.data.data
      thunk.dispatch(getTestBundleTest(payload.bundleId))
    })
    .catch((error)=>{
    })
    return {
      data:remove,
      removeMessage:removeMessage,
      success:success
    }
  }
)

// get test bundle questions data

export const getTestBundleTest=createAsyncThunk(
  'TestBundle/getTestBundleTest',async (data)=>{
    let resData={};
    let success="";
    let Data=await axios.put('testMaster/getBundleTest/'+data.id,data.obj)
    .then((res)=>{
      resData=res.data.data;
      success=res.data.success
    })
    .catch((error)=>{
    })
    return {
      resData:resData,
      success:success
    }
  }
)

// test bundle pricing

export const testBundlePricing=createAsyncThunk(
  'TestBundle/testBundlePricing',async (payload)=>{
    let pricing=[]
  
    let data={
      pricingPlan:payload.pricing,
      price:Number(payload.testPrice),
      testBundleId:Number(payload.testBundleId),
      subscriptionId:payload.subscriptions
    }
    let bundlePricing=await axios.post('testMaster/createTestBundlePricing', data )
    .then((res)=>{
      pricing=res.data.data
    })
    .catch((error)=>{
    })
    return {
      data:pricing,
    }
  }
)

// get test bundle pricing by id

export const getBundlePricing=createAsyncThunk(
  'TestBundle/getBundlePricing',async (payload)=>{
    let pricing=[]
    let data={}

    let getBundlePricing=await axios.get('testMaster/getTestBundlePricingPlan/'+payload)
    .then((res)=>{
      pricing=res.data.data
      let subscriptions=[]
      pricing.subscriptionId.map((item)=>{
        subscriptions.push(item.subscriptionId)
      })
       data={
        pricing:pricing.pricingPlan==1 ? true:false,
        bundlePrice:pricing.price,
        subscriptions:subscriptions,
      }
    })
    .catch((error)=>{
    })
    return {
      data:data,
    }
  }
)



const initialState={
  testBundleMasterTableData:[],
  testBundleTestTData:[],
  testBundleEditData:{},
  isLoading:true,
  totalDataCountTestBundle:0
}
const TestBundleSlice=createSlice({
  name:'TestBundle',
  initialState,
  extraReducers:{
    [insertTestBundle.pending]:(state)=>{
      state.isLoading=true
    },
    [insertTestBundle.fulfilled]:(state,actions)=>{
      if(actions.payload.success==true){
        actions.payload.data[0].createdAt=moment(actions.payload.data[0].createdAt).format('DD MMM YYYY')
        state.testBundleMasterTableData=[...actions.payload.data,...state.testBundleMasterTableData]
        message.success(actions.payload.insertMessage)
      }
      state.isLoading=false
    },
    [insertTestBundle.rejected]:(state)=>{
      state.isLoading=false
    },
    [getTestBundle.pending]:(state)=>{
      state.isLoading=true
    },
    [getTestBundle.fulfilled]:(state,actions)=>{
      state.isLoading=false
        state.testBundleMasterTableData=actions.payload?.getTestBundle
        state.testBundleMasterTableData.map((item)=>{
          item.createdAt=moment(item.createdAt).format("DD MMM YYYY")
        })
      state.totalDataCount =  +actions.payload?.getTestBundleCount?.testBundleCount
    },
    [getTestBundle.rejected]:(state)=>{
      state.isLoading=false
    },
    [getEditData.pending]:(state)=>{
      state.isLoading=true
    },
    [getEditData.fulfilled]:(state,actions)=>{
      if(actions.payload.success==true){
          state.testBundleEditData=actions.payload.resData
      }
      state.isLoading=false
    },
    [getEditData.rejected]:(state)=>{
      state.isLoading=false
    },
    [updateTestBundle.pending]:(state)=>{
      state.isLoading=true
    },
    [updateTestBundle.fulfilled]:(state,actions)=>{
      let payload=actions.payload.data[0]
      let totalData=state.testBundleMasterTableData
      if(actions.payload.success==true){
        totalData.map((item)=>{
          if(item.id==payload.id){
            item.name=payload.name;
            item.description=payload.description
            message.success(actions.payload.updateMessage)
            state.testBundleEditData={}
          }
        })
      }
      state.isLoading=false
    },
    [updateTestBundle.rejected]:(state)=>{
      state.isLoading=false
    },
    [deleteTestBundle.pending]:(state)=>{
      state.isLoading=true
    },
    [deleteTestBundle.fulfilled]:(state,actions)=>{
      let totalData=state.testBundleMasterTableData
      if(actions.payload.success==true){
        state.testBundleMasterTableData=totalData.filter((item)=>item.id!=actions.payload.deleteId)
        message.success(actions.payload.deleteMessage)
      }
      state.isLoading=false
    },
    [deleteTestBundle.rejected]:(state)=>{
      state.isLoading=false
    },
    [getTestBundleTest.pending]:(state)=>{
      state.isLoading=true
    },
    [getTestBundleTest.fulfilled]:(state,actions)=>{
      state.isLoading=false
      if(actions.payload.success==true){
        state.testBundleTestTData=actions.payload.resData
      }
    },
    [getTestBundleTest.rejected]:(state)=>{
      state.isLoading=false
    },
    [insertTestBundleTest.pending]:(state)=>{
      state.isLoading=true;
      state.testBundleTestTData=[]
    },
    [insertTestBundleTest.fulfilled]:(state,actions,thunk)=>{
      state.isLoading=false
      let data=actions.payload.data
      if(actions.payload.success==true){
        message.success(actions.payload.insertMessage)
      }
    },
    [insertTestBundleTest.rejected]:(state)=>{
      state.isLoading=false
    },
    [removeTestBundleTest.pending]:(state)=>{
      state.isLoading=true
    },
    [removeTestBundleTest.fulfilled]:(state,actions,)=>{
     let removedData=actions.payload.data
      state.isLoading=false;

    },
    [removeTestBundleTest.rejected]:(state)=>{
      state.isLoading=false
    },
    [testBundlePricing.pending]:(state)=>{
      state.isLoading=true
    },
    [testBundlePricing.fulfilled]:(state,actions)=>{
      state.isLoading=false
      if(actions.payload.data.success==true){
        message.success(actions.payload.data.message)
      }
    },
    [testBundlePricing.rejected]:(state)=>{
      state.isLoading=false
    },
    [getBundlePricing.pending]:(state)=>{
      state.isLoading=true
    },
    [getBundlePricing.fulfilled]:(state,actions)=>{
      state.isLoading=false
    },
    [getBundlePricing.rejected]:(state)=>{
      state.isLoading=false
    },
  }
})

export default TestBundleSlice.reducer;