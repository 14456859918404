export const imageFilter = [
    'image/jpeg', 'image/jpg', 'image/png'
];

export const imageFilterPng = [
    'image/png'
];

export const pdfFilter = [
    'application/pdf'
];

export const videoFilter = [
    'video/mp4'
];

export const imageAndPdfFilter = [
    ...imageFilter,
    ...pdfFilter
];

export const VIDEO_STREAM_MAX_SIZE = 5 * 1024 * 1024 * 1024 * 1024; // 5 TB in bytes
export const videoSizeErrorMessage = 'File size exceeds the 5 TB limit!';


export const imageFilterMessage = "Please upload jpeg, jpg, and png formats only.";
export const imageFilterPngMessage = "Please upload png formats only.";
export const pdfFilterMessage = "Please upload pdf formats only";
export const videoFilterMessage = "Please upload mp4 formats only";
export const imageAndPdfFilterMessage = "Please upload jpeg, jpg, png and pdf formats only.";

export const EQuickActions = {
    edit: 1,
    paymentConfig: 2,
    publish: 3,
    deleteContent: 4,
    unPublish: 5
};

export const EQuickActionsType = {
    topic: 1,
    subTopic: 2,
    class: 3,
    content: 4,
    course: 5
};

export const EFieldStatus = {
    NONE: 'NONE',
    EDITED: 'EDITED',
    NEW: 'NEW'
};

export const ChunkSize = 5 * 1024 * 1024; // 5 MB

export const MaxFileCount = {
    otherVideoAttachment: 5,
    curriAttachment: 5,
    curriculumOtherNotes: 5
};

export const DefaultPublishStatus = false;

export const DefaultImageDimensions = {
    validateDimensions: true, 
    expectedWidth: 700, 
    expectedHeight: 369
};

export const DefaultImageAspectRatio = {
    validateAspectRatio: true, 
    expectedAspectRatio: 16 / 9,
    aspectRatioToString: '16 / 9'
};
