import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

export const createProduct = createAsyncThunk("products/addProduct",async (payload,thunk) => {
    try {
      let products = await axios.post(`products/addProduct`, payload.data)
        .then(async (res) => {
          if (res.data.status) {
            message.success(res.data.message)
            await thunk.dispatch(getAllProduct(payload.obj))

            return res.data;
          }
        });
      return products;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);


export const getproductsById = createAsyncThunk("products/getproductsById",async (payload) => {
    try {
      let products = await axios.get(`products/getProduct/${payload}`).then((res) => {
          if (res) {
            return res;
          }
        });
      return products;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getAllProduct = createAsyncThunk("products/getAllProduct",async (payload) => {
    try {
            let products = await axios.post(`products/getAllProduct`, payload).then((res) => {
          if (res.data.success) {
            return res.data;
          }
        });
      return products;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const updateProduct = createAsyncThunk("products/updateProduct", async (payload, thunk) => {
  try {
    let products = await axios.put(`products/updateProduct/${payload.id}`, payload.data).then(async (res) => {
      if (res.status) {
        message.success(res.data.message)
      await thunk.dispatch(getAllProduct(payload.obj))        
      return res.data
      }
    })
    return products
  } catch (error) {
    message.error(error.response.data.message)

  }
})


export const deleteProduct = createAsyncThunk('products/deleteProduct', async (payload, thunk) => {
  try{
      let products = await axios.delete(`products/product/${payload.id}`).then(async (res) => {
          if (res.data.success) {
            message.success(res.data.message)
            await thunk.dispatch(getAllProduct(payload.obj))   
            return res.data     
        }
        })
        return products
  }
    catch(error){
      message.error(error.response.data.message)
       }
      })






const initialState = {
    productData:[],
    isLoading: false,
    totalDataCount:0
};

const ProductsSlice = createSlice({
    name: "ProductsSlice",
    initialState,
    extraReducers: {
      [createProduct.pending]:(state)=>{
          state.isLoading=true
      },
      [createProduct.fulfilled]:(state,actions)=>{
          state.isLoading=false
      },
      [createProduct.rejected]:(state)=>{
          state.isLoading=false
      },
      [getAllProduct.pending]:(state)=>{
        state.isLoading=true
    },
    [getAllProduct.fulfilled]:(state,actions)=>{
        state.productData = actions.payload.data.getProductData
        state.totalDataCount = actions.payload.data.getProductCount.productCount
        state.isLoading=false
    },
    [getAllProduct.rejected]:(state)=>{
        state.isLoading=false
    },
    [updateProduct.pending]:(state) =>{
      state.isLoading=true
  },
  [updateProduct.fulfilled]:(state,actions) =>{
      state.isLoading=false
  },
  [updateProduct.rejected]:(state) =>{
      state.isLoading=false
  },
  [deleteProduct.pending]:(state) =>{
    state.isLoading=true
},
[deleteProduct.fulfilled]:(state,actions) =>{
    state.isLoading=false
},
[deleteProduct.rejected]:(state) =>{
    state.isLoading=false
},
[getproductsById.pending]:(state) =>{
  state.isLoading=true
},
[getproductsById.fulfilled]:(state,actions) =>{
  state.isLoading=false
},
[getproductsById.rejected]:(state) =>{
  state.isLoading=false
},
    
}
})


export default ProductsSlice.reducer