import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const insertEducatorsDetails = createAsyncThunk(
    'EducatorMaster/insertEducatorsDetails', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.post('coachmi/addEducator', payload.data)
            .then(async (res) => {
                const { success, message } = res?.data;
                if (success) {
                    resMessage = message;
                    resSuccess = success;
                    await thunk.dispatch(getEducatorsTable(payload.obj));
                    await thunk.dispatch(getClusterFilter());
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            });
            return { resSuccess, resMessage };
    }
);

export const updateEducatorDetails = createAsyncThunk(
    'EducatorMaster/updateEducatorDetails', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.put('coachmi/updateEducator/' + payload.id, payload.data)
            .then(async (res) => {
                const { success, message } = res?.data;
                if (success) {
                    resMessage = message;
                    resSuccess = success;
                    await thunk.dispatch(getEducatorsTable(payload.obj));
                    await thunk.dispatch(getClusterFilter());
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            })
        return { resSuccess, resMessage };
    }
);

export const deleteEducator = createAsyncThunk(
    'EducatorMaster/deleteEducator', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.put('coachmi/deleteEducator/' + payload.id)
            .then(async (res) => {
                const { success, message } = res?.data;
                resSuccess = success;
                resMessage = message
                if (success) {
                    await thunk.dispatch(getEducatorsTable(payload.obj));
                    await thunk.dispatch(getClusterFilter());
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            });
            return { resSuccess, resMessage };
    }
);

export const getEducatorsTable = createAsyncThunk(
    'EducatorMaster/getEducatorsTable',
    async (payload) => {
        try {
            let tableData = [];
            let tableTotalCount;
            let resSuccess = false;
            let resMessage = '';
            await axios.post('coachmi/getAllEducatorDetails', payload)
                .then(async (res) => {
                    const { success, message, data } = res.data;
                    const { listData, dataCount } = data;
                    resSuccess = success
                    resMessage = message;
                    tableData = listData;
                    tableTotalCount = dataCount;;
                })
                .catch((error) => {
                    resMessage = error?.response?.data?.message
                })
            return { tableData, tableTotalCount, resSuccess, resMessage };
        } catch (error) {
            throw error;
        };
    }
);

export const getEducatorDetailsById = createAsyncThunk(
    "EducatorMaster/getEducatorDetailsById", async (payload) => {
        try {
            let clusterDetails = await axios.get(`/coachmi/getEducatorDetailsById/${payload}`)
                .then((res) => {
                    if (res?.data?.status) {
                        return res?.data
                    }
                })
                .catch((error) => {

                })
            return clusterDetails
        } catch (error) {
        }
    }
);

export const getClusterFilter = createAsyncThunk(
    'EducatorMaster/getClusterFilter', async (payload, thunk) => {
        try {
            let response = [];
            await axios.get('coachmi/getUniqueEducatorClusterNames')
                .then(async (res) => {
                    const { status, data } = res.data;
                    response = data;
                })
                .catch((error) => {
                })
            return response;
        } catch (error) {
        }
    }
);

const initialState = {
    educatorTableData: [],
    tableTotalCount: 0,
    isLoading: false,
    clusterNameFilter: [],
};

const EducatorMasterSlice = createSlice({
    name: 'EducatorMaster',
    initialState,
    reducers: {
    },
    extraReducers: {
        [insertEducatorsDetails.pending]: (state, action) => {
            state.isLoading = true
        },
        [insertEducatorsDetails.fulfilled]: (state, action) => {
            state.isLoading = false
        },
        [insertEducatorsDetails.rejected]: (state, action) => {
            state.isLoading = false
        },
        [updateEducatorDetails.pending]: (state, action) => {
            state.isLoading = true
        },
        [updateEducatorDetails.fulfilled]: (state, action) => {
            state.isLoading = false
        },
        [updateEducatorDetails.rejected]: (state, action) => {
            state.isLoading = false
        },
        [deleteEducator.pending]: (state, action) => {
            state.isLoading = true
        },
        [deleteEducator.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [deleteEducator.rejected]: (state, action) => {
            state.isLoading = false
        },
        [getEducatorsTable.pending]: (state) => {
            state.isLoading = true
        },
        [getEducatorsTable.fulfilled]: (state, actions) => {
            state.isLoading = false
            const { tableData, tableTotalCount } = actions.payload;
            state.educatorTableData = tableData;
            state.tableTotalCount = tableTotalCount;
        },
        [getEducatorsTable.rejected]: (state) => {
            state.isLoading = false
        },
        [getEducatorDetailsById.pending]: (state, action) => {
            state.isLoading = true
        },
        [getEducatorDetailsById.fulfilled]: (state, action) => {
            state.isLoading = false
        },
        [getEducatorDetailsById.rejected]: (state, action) => {
            state.isLoading = false
        },
        [getClusterFilter.pending]: (state) => {
            state.isLoading = true
        },
        [getClusterFilter.fulfilled]: (state, actions) => {
            state.isLoading = false
            state.clusterNameFilter = actions?.payload
        },
        [getClusterFilter.rejected]: (state) => {
            state.isLoading = false
        },
    }
 }
);

export default EducatorMasterSlice.reducer;