import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from 'moment'


// insert exam

export const insertExamMasterTable=createAsyncThunk(
  'ExamMaster/insertExamMasterTable',async (payload,thunk)=>{
    let tabledata=[]
    let Insertmessage='';
    let success=false;
    let insertData=await axios.post('masters/createExam',payload.data)
    .then(async(res)=>{
      if(res.data.createExam.status){
        await thunk.dispatch(getExamMasterTable(payload.obj))
      }
      tabledata=res.data.createExam;
      Insertmessage=tabledata.message;
      success=tabledata.success;
    })
    .catch((error)=>{
      let insertError=error.response.data.createExam
      message.error(insertError.message);
    })
    return {
      tabledata:tabledata,
      Insertmessage:Insertmessage,
      success:success
    };
  }
)

// get exam master

export const getExamMasterTable=createAsyncThunk(
  'SubjectMaster/getExamMasterTable',async (payload)=>{
    let tabledata=[]
    let tableData=await axios.post('masters/getExam',payload)
    .then((res)=>{
      tabledata=res.data.data;
    })
    .catch((error)=>{
    })
    return tabledata;
  }
)

// update exam master


export const updateExamMasterTable=createAsyncThunk(
  'ExamMaster/updateExamMasterTable',async (payload,thunk)=>{
    let tabledata=[]
    let updateData=await axios.put('masters/updateExam/'+payload.id,payload.data)
    .then(async(res)=>{
      if(res.data.updateExam.status){
        await thunk.dispatch(getExamMasterTable(payload.obj))
      }
      tabledata=res.data.updateExam;
    })
    .catch((error)=>{
      let insertError=error.response.data.updateExam
      message.error(insertError.message);
    })
    return tabledata;
  }
)

export const deleteExamMasterTable=createAsyncThunk(
  'SubjectMaster/deleteExamMasterTable',async (payload,thunk)=>{
    let deleted=[]
    let deleteMessage=''
    let deleteData=await axios.delete('masters/deleteExam/'+payload.id)
    .then(async(res)=>{
      if(res.data.data.status){
        await thunk.dispatch(getExamMasterTable(payload.obj))
      }
      deleteMessage=res.data.message;
      deleted=res.data.data
    })
    .catch((error)=>{
    })
    return {
      deleteMessage:deleteMessage,
      payload:payload,
      status:deleted.status
    };
  }
)

// approve exam
export const approveExam=createAsyncThunk(
  'ExamMaster/approveExam',async(payload)=>{
    try{
      let data={
        examId:payload.examId,
        remarks:payload.remarks
      }
      let approvedExam=await axios.put('/masters/statusupdate/'+payload.statusId,data)
      .then((res)=>{
        return res.data;
      })
      return approvedExam;
    }
    catch(error){
    }
  }
)

// bulk exam approve
export const bulkExamApprove=createAsyncThunk(
  'ExamMaster/bulkExamApprove',async(payload)=>{
    try{
      let data={
        examId:payload.examId,
      }
      let approvedExamBulk=await axios.put('/masters/examStatusBulkUpdate/'+payload.statusId,data)
      .then((res)=>{
        return res.data;
      })
      return approvedExamBulk;
    }
    catch(error){
    }
  }
)

export const downloadExamQuestionsCount = createAsyncThunk(
  'ExamMaster/downloadQuestionsCount',async (payload)=>{
    try{
      let questionCount = await axios.get('/masters/downloadExamQuestionsCountXl')
      .then((res) => {
        message.success(res.data.message)
        console.log(res,"OOOOOOOOOOOOOOOOOOOOOOOOOo");
       return res.data;
      })
      return questionCount
    }
    catch(error){

    }
  }
)


const initialState={
  examMasterTableData:[],
  isLoading:false,
  examDataCount:0
}
const ExamSlice=createSlice({
  name:'ExamMaster',
  initialState,
  extraReducers:{
    [insertExamMasterTable.pending]:(state)=>{
      state.isLoading=true
    },
    [insertExamMasterTable.fulfilled]:(state,actions)=>{
      let tableData=actions.payload.tabledata.data
      let success=actions.payload.success
      let Insertmessage=actions.payload.Insertmessage
      if(success==true){
        tableData.map((item)=>{
          item.createdAt=moment(item.createdAt).format('DD MMM YYYY');
        })
        message.success(Insertmessage)
      }
      state.isLoading=false
    },
    [insertExamMasterTable.rejected]:(state)=>{
      state.isLoading=false
    },
    [getExamMasterTable.pending]:(state)=>{
      state.isLoading=true
    },
    [getExamMasterTable.fulfilled]:(state,actions)=>{
      state.isLoading=false
      state.examMasterTableData=actions.payload.getExamData;
      state.examMasterTableData.map((item)=>{
        item.createdAt=moment(item.createdAt).format('DD MMM YYYY')
      })
      state.examDataCount = actions.payload?.getExamCount?.examCount;
     },
     [getExamMasterTable.rejected]:(state)=>{
      state.isLoading=false
    },
    [updateExamMasterTable.pending]:(state)=>{
      state.isLoading=true
    },
     [updateExamMasterTable.fulfilled]:(state,actions)=>{
       let success=actions.payload.success
       if(success==true){
        let editdata=actions.payload.data[0]
       let Updatemessage=actions.payload.message
        state.examMasterTableData.map((item,i)=>{
          if(item.id==editdata.id){
            item.name=editdata.name;
            item.examImage=editdata.examImage
          }
        })
        message.success(Updatemessage)
       }
       state.isLoading=false
     },
     [updateExamMasterTable.rejected]:(state)=>{
      state.isLoading=false
    },
    [deleteExamMasterTable.pending]:(state)=>{
      state.isLoading=true
    },
     [deleteExamMasterTable.fulfilled]:(state,actions)=>{
       let deleteData=actions.payload
       let deletemessage=actions.payload.deleteMessage
       if(deleteData.status==true){
        let examTableData=state.examMasterTableData
        state.examMasterTableData=examTableData.filter((item)=>item.id!=deleteData.payload)
        message.success(deletemessage)
       }
       state.isLoading=false
     },
     [deleteExamMasterTable.rejected]:(state)=>{
      state.isLoading=false
    },
    [approveExam.pending]:(state)=>{
      state.isLoading=true
    },
    [approveExam.fulfilled]:(state,action)=>{
      state.isLoading=false
    },
    [approveExam.pending]:(state)=>{
      state.isLoading=false
    },
    [bulkExamApprove.pending]:(state)=>{
      state.isLoading=true
    },
    [bulkExamApprove.fulfilled]:(state,action)=>{
      state.isLoading=false
    },
    [bulkExamApprove.rejected]:(state)=>{
      state.isLoading=false
    },
    
    [downloadExamQuestionsCount.pending]:(state)=>{
      state.isLoading=true
    },
    [downloadExamQuestionsCount.fulfilled]:(state,action)=>{
      state.isLoading=false
    },
    [downloadExamQuestionsCount.rejected]:(state)=>{
      state.isLoading=false
    },
  }
})

export default ExamSlice.reducer;


