import React, { useState, useEffect, useRef } from 'react';
import { Col, Divider, Image, Row, Tabs } from 'antd';
import TableComponent from './Table/tableComponent';
import moment from 'moment';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteImage from '../asset/image/deleteIcon.svg'
import { getAllPurchaseTest } from './reducers/institutionMasterSlice'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const IndividualPurchaseList = () => {
  let tableHeight = 320
  const [limit, setLimit] = useState(50)
  const roleId = localStorage.getItem("roleId")
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const params = useParams()
  const [filterData, setFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const dispatch = useDispatch()
  const { getAllPurchasetest , isLoading} = useSelector((state) => (state.InstitutionSlice))

  useEffect(() => {
   let datas={
    obj:{
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    },
institutionId:+params.id
   }
    dispatch(getAllPurchaseTest(datas));
  }, [])
    const columns = [
      {
        title: "",
        dataIndex: "index",
        key: "index",
        width: 50,
        render: (_, record, i) => {
                  return (
            <div className='index-id'>{(i+1)+(paginationData>0?(paginationData)*limit:0)}
            </div>
          )
        },
      },
        {
          title: 'Test Name ',
          dataIndex: 'testName',
          key: 'testName',
          onCell: (record, rowIndex) => {
            return {
            };
          }
        },
        {
            title: 'No of Test  ',
            dataIndex: 'numberOfTest',
            key: 'numberOfTest',
            onCell: (record, rowIndex) => {
              return {
              };
            }
          },
          {
            title: 'Amount  ',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => {
                return (
                  <div>
                    <span>{`Rs.${record.amount}`}</span>
                  </div>
                )
              },
          },
        {
          title: 'Validity Date ',
          dataIndex: 'testValidityDate',
          key: 'testValidityDate',
          render: (_, record) => {
            return (
              <div>
                <span>{moment(record.testValidityDate).format('DD MMM YYYY')}</span>
              </div>
            )
          },
          onCell: (record, rowIndex) => {
            return {
              onClick: () => {
              }
            };
          }
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     onCell: (record, rowIndex) => {
        //       return {
        //       };
        //     }
        //   },
        // {
        //   title: 'Action',
        //   key: 'action',
        //   render: (_, record) => {
        //     return (
        //       <>
        //       {
        //         roleId == 1 || roleId == 3 ?
        //         <div className='table-action-link'>
        //         <a style={{paddingRight:10}}>Renewal</a>
                
    
        //       </div>
        //       : <div>
        //         Not Authorized
        //       </div>
        //       }
    
        //       </>
        
        //     )
        //   },
        // },
      ]
      const onSelectChange = (selectData) => {
        setSelectedRowKeys(selectData)
      }
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
      }
   
    return(
        <>
        <div>
        <TableComponent dataSource={getAllPurchasetest} columns={columns} rowSelection={rowSelection}  tableHeight={tableHeight}  limit={limit} loadingStatus={isLoading} />

        </div>
        </>
    )
}
export default IndividualPurchaseList