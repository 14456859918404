import React from 'react';
import { Upload, Image, Tooltip } from 'antd';
import "../../components/styles/CustomFileUpload.scss";
import closeIcon from '../../asset/image/close.svg';
import pdfIcon from '../../asset/image/Pdf.svg';
import { formatFileSizeHelper } from '../../utils/HelperFunction.js';
import videoIcon from '../../asset/image/contentImg.svg';

const CustomFileUpload = ({ filehandleChange, handleFileRemove, file, allowCount, customButton }) => {

    const getImageSrc = (type) => {
        switch (type) {
            case 'application/pdf':
                return pdfIcon;
            case 'video/mp4':
                return videoIcon;
            default:
                return null;
        }
    };

    const getImage = (type) => {
        const imageSrc = getImageSrc(type);
        return (
            imageSrc && <Image className='file-type-icon' src={imageSrc} preview={false} />
        );
    };

    const fileList = file || [];

    const uploadProps = {
        fileList,
        beforeUpload: file => {
            return false;
        },
        onChange: info => {
            if (info.file.status !== 'removed') {
                filehandleChange(info);
            }
        },
        onRemove: file => {
            handleFileRemove(file);
            return true;
        },
        multiple: true,
        maxCount: allowCount,
    };

    const customItemRender = (originNode, file, fileList, actions) => (
        <div className="custom-upload-list-item">
            <div className='upload-file-container'>
                <div className="file-icon">
                    {getImage(file?.type)}
                </div>
                <div className="file-details">
                    <Tooltip placement='top' title={file?.name}>
                        <a href={file?.url} className="file-name" target="_blank" rel="noopener noreferrer">
                            {file?.name ? file?.name : '-'}
                        </a>
                    </Tooltip>
                    <p className="file-size">{formatFileSizeHelper(file?.size)}</p>
                </div>
            </div>
            <div className="remove-icon" onClick={() => actions?.remove(file)}>
                <Image src={closeIcon} className='remove-icon' preview={false} />
            </div>
        </div>
    );

    return (
        <Upload
            {...uploadProps} itemRender={customItemRender}
            className="ImageUpload"
        >
            {customButton}
        </Upload>
    );
};

export default CustomFileUpload;
