import React, { useState, useEffect } from "react";
import { Button, Image, Input, message, Modal, Select, Drawer } from 'antd'
import { Option } from 'antd/lib/mentions';
import {  useFormik } from "formik";
import { SearchOutlined, LoadingOutlined, } from "@ant-design/icons";
import TableComponent from "./Table/tableComponent.js";
import { useDispatch, useSelector } from "react-redux";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { insertClusterDetails, updateClusterDetails, deleteCluster, getClusterMasterTable, getClusterDetailsById, getClusterFilter, getClusterCreatedDateFilter } from "./reducers/ClusterMasterSlice.js";
import { getFrequency } from "./reducers/ClusterGroupMasterSlice.js";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BreadCrumb from "./BreadCrumb.js";
import { addBreadCrumbData } from "./reducers/masterSlice.js";
import PricingList from "../components/Table/PricingList.js";

import { handleToast, hasErrorsInPriceAndFrequencyHelper, handlePricingDataHelper, constructFrequencyHelper } from "../utils/HelperFunction.js";


function ClusterMaster() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [editId, setEditId] = useState();
    const [record, setRecord] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
    const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [paginationData, setPaginationData] = useState(0);
    const [filterData, setFilterData] = useState("");
    const [clusterFilterData, setClusterFilterData] = useState("");
    const [createdAtFilterData, setCreatedAtFilterData] = useState("");
    const [limit, setLimit] = useState(50);
    const [currentTitle, setCurrentTitle] = useState("");
    const [currentId, setCurrentId] = useState(null);
    const [file, setFile] = useState([]);
    const [buttonState, setButtonState] = useState(1);
    const [approveId, setApproveId] = useState(null);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const { TextArea } = Input;
    const [isApproval, setIsApproval] = useState(false);
    const [isSubjectShow, setIsSubjectShow] = useState(false);
    const [open, setOpen] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedCluster, setSelectedCluster] = useState(null);
    const [frequencyMap, setFrequencyMap] = useState({});
    const [isEditAction, setIsEditAction] = useState(false);
    const [prices, setPrices] = useState([]);


    let tableHeight = 320;
    const roleId = localStorage.getItem("roleId");

    const columns = [

        {
            title: "Cluster Name",
            dataIndex: "clusterName",
            key: "clusterName",
            sorter: {
                compare: (a, b) => {
                    if (a.clusterName < b.clusterName) return -1;
                    if (a.clusterName > b.clusterName) return 1;
                    return 0;
                },
                multiple: 3,
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: () => {
                        // navigate("/ClusterMater/" + record.id);
                    },
                };
            },
        },
        {
            title: "Created Date",
            dataIndex: "updatedAt",
            key: "updatedAt",
            sorter: {
                compare: (a, b) => {
                    if (a.updatedAt < b.updatedAt) return -1;
                    if (a.updatedAt > b.updatedAt) return 1;
                    return 0;
                },
                multiple: 3,
            },
            render: (_, record) => {
                return (
                    <div>
                        <span>{moment(record.updatedAt).format("DD MMM YYYY")}</span>
                    </div>
                );
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: () => {
                        // navigate("/ClusterMater/" + record.id);
                    },
                };
            },
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => {
                return (
                    <>
                        {roleId == 1 || roleId == 3 ? (
                            <div className="table-action-link">
                                <a
                                    style={{ paddingRight: 10 }}
                                    onClick={() => editData(record)}
                                >
                                    Edit
                                </a>
                                {roleId == 3 ? (
                                    <Tooltip title="Delete" style={{ padding: 0 }}>
                                        <IconButton>
                                            <Image
                                                src={DeleteImage}
                                                preview={false}
                                                onClick={() => deleteData(record)}
                                            ></Image>
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </div>
                        ) : (
                            <div>Not Authorized</div>
                        )}
                    </>
                );
            },
        },
    ];

    const { clusterMasterTableData, isLoading, tableTotalCount, clusterNameFilter, clusterCreatedAt } = useSelector(
        (state) => state.ClusterMasterSlice,
    );

    const { frequencyData } = useSelector(
        (state) => state.ClusterGroupMasterSlice
    );

    useEffect(() => {
        let obj = {
            offSet: paginationData,
            searchInput: filterData,
            limit: limit,
        };
        dispatch(getClusterMasterTable(obj))
            .unwrap().then((res) => {
                try {
                    // handleToast(res);

                } catch (error) {
                    // handleToast(error);
                }
            });
        dispatch(addBreadCrumbData({}));
    }, []);

    useEffect(() => {
        dispatch(getFrequency())
            .unwrap()
            .then((data) => {
                constructFrequencyHelper(data, setFrequencyMap)
            })
            .catch((error) => { });
        dispatch(getClusterFilter())
        dispatch(getClusterCreatedDateFilter())
    }, []);

    const tableOnChange = (pagination, filters, sort, extra) => {

        setPaginationData(pagination?.current - 1);

        let obj = {
            offSet: paginationData,
            searchInput: filterData,
            limit: limit,
            clusterFilter: clusterFilterData,
            createdAtFilter: createdAtFilterData,
        };
        dispatch(getClusterMasterTable(obj));
    };

    function confirmDelete() {
        setIsModalVisible(false);
        let id = record.id;

        let payload = {};
        let data = {
            id: record.id,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit,
            },
        };

        dispatch(deleteCluster(data))
            .unwrap().then((res) => {
                try {
                    handleToast(res);

                } catch (error) {
                    // handleToast(error); 
                }
            })
    };

    function onModalClose() {
        setIsModalVisible(false);
        setIsRejectModalVisible(false);
    };

    function onClose(values) {
        setVisible(false);
    };

    const onSelectChange = (selectData) => {
        setSelectedRowKeys(selectData);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const closeSubmitModal = () => {
        setSubmitModalVisible(false);
    };

    const showSubmitConfirmation = (values) => {
        setSubmittedData(values);
        setSubmitModalVisible(true);
    };

    const onSearch = (value) => {
        setFilterData(value);
        let obj = {
            offSet: paginationData,
            searchInput: filterData,
            limit: limit,
            clusterFilter: clusterFilterData,
            createdAtFilter: createdAtFilterData,
        };
        debounced(obj);
    };

    const debounced = useDebouncedCallback((obj) => {
        dispatch(getClusterMasterTable(obj));
    }, 1000);

    const handleClusterChange = (e, value) => {
        setClusterFilterData(e);
        let obj = {
            offSet: paginationData,
            limit: limit,
            searchInput: filterData,
            clusterFilter: e,
            createdAtFilter: createdAtFilterData
        };

        dispatch(getClusterMasterTable(obj));
    };

    const handleCreatedAtChange = (e, value) => {
        setCreatedAtFilterData(e);
        let obj = {
            offSet: paginationData,
            limit: limit,
            searchInput: filterData,
            createdAtFilter: e,
            clusterFilter: clusterFilterData
        };
        dispatch(getClusterMasterTable(obj));
    };

    async function onSubmit(values) {

        let data = await handleCluster(values);

        let datas = {
            ...data,
        };
        setVisible(false);
        setSubmitModalVisible(false);
        dispatch(insertClusterDetails(datas)).unwrap().then((res) => {
            try {
                handleToast(res);
                !res?.resSuccess ? setVisible(true) : setVisible(false);
            }
            catch (error) {
                // handleToast(res);
                setVisible(true);
            };
        })
    };

    const editData = async (_data) => {
        formik.resetForm();
        setPrices([]);
        dispatch(getClusterDetailsById(_data.id)).unwrap().then(async (res) => {

            if (res?.success) {

                let { clusterName, planPricing } = res?.data;
                await handlePricingDataHelper(formik, setPrices, planPricing);

                formik.setFieldValue("name", clusterName);
                setIsEditAction(true);
                setVisible(true);
            };
        });

        setCurrentTitle("Edit Cluster");
        setCurrentId(_data.id);
        setButtonState(2);
    };

    const updateData = async () => {

        let data = await handleCluster(formik?.values);

        let datas = {
            id: currentId,
            ...data,
        };

        setUpdateModalVisible(false);
        setVisible(false);
        dispatch(updateClusterDetails(datas))
            .unwrap()
            .then((res) => {
                try {
                    handleToast(res);
                    !res?.resSuccess ? setVisible(true) : setVisible(false);
                } catch (error) {
                    // handleToast(error);
                    setVisible(true);
                }
            });
    };

    const handleCluster = async (values) => {

        const { name, ...rest } = values;

        let pricingPlan = [
            rest,
            ...prices
        ];

        let data = {
            clusterName: name,
            pricingPlan
        };

        return {
            data,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
            }
        }
    };

    const deleteData = (record) => {
        setRecord(record);
        setIsModalVisible(true);
        setButtonState(1);
    };

    const initialValues = {
        name: "",
        actualPrice: "",
        price: "",
        frequencyId: "",
    };

    let validationSchema = yup.object({
        name: yup.string()
            .required("Name is Required")
            .min(2, "Name Needed At Least Two characters")
            .max(100, "Name not more than 100 characters"),
        actualPrice: yup.number()
            .required("Actual price is Required")
            .positive('Actual price must be a positive integer')
            .integer('Actual price must be an integer'),
        price: yup.number()
            .required("Price is Required")
            .positive('Price must be a positive integer')
            .integer('Price must be an integer')
            .when('actualPrice', (actualPrice, schema) => 
                schema.max(actualPrice, 'Price must be less than or equal to the actual price')
        ),
        frequencyId: yup.string().required("Frequency is Required"),
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const openDrawer = () => {
        setIsApproval(false);
        setIsEditAction(false);
        setFile([]);
        setCurrentId(null);
        setVisible(true);
        setButtonState(1);
        setCurrentTitle("New Cluster");
        setPrices([]);
        formik.resetForm({ values: initialValues });
    };

    const closeUpdateModal = () => {
        setUpdateModalVisible(false);
    };

    const showUpdateConfirmation = () => {
        setUpdateModalVisible(true);
    };

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        };
    };

    const reject = () => {
        setIsRejectModalVisible(true);
    };

    const addPrice = (values) => {
        const { name, ...rest } = values;
        const newPrice = { ...rest };
        setPrices([...prices, newPrice]);
        formik.setFieldValue("price", "");
        formik.setFieldValue("frequencyId", "");
        formik.setFieldValue("actualPrice", "");
    };

    const deletePricing = (values, index) => {
        const updatedPrices = prices?.filter((_, i) => i !== index);
        setPrices(updatedPrices);
    };

    const selectedFrequencyIds = prices.map(item => item.frequencyId);

    const breadCrumbData = {};

    return (
        <div className="exam-master-page">
            <div>

                <div className="breadCrumb-Data">
                    <BreadCrumb data={breadCrumbData} loading={isLoading}></BreadCrumb>
                </div>

                <div className="exam-header">
                    <div>
                        <h3 className="primary-header">Cluster Master</h3>
                    </div>

                    <div className="download-new-exam-btn">
                        <div className="add-exam">
                            {roleId == 3 && !params.statusName ? (
                                <span className="add-new-exam">
                                    <Button
                                        type="primary"
                                        className="primary-submit-button"
                                        onClick={() => openDrawer()}
                                    >
                                        {" "}
                                        New Cluster
                                    </Button>
                                </span>
                            ) : null}
                        </div>
                    </div>

                </div>
                <div className="table-header">
                    <div>
                        <div className="golbalSearch">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
                                style={{ width: 262, height: 32 }}
                                onChange={(event) => onSearch(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="cluster-normalPage">
                        <div className="form-control">
                            <label>
                                <span style={{ color: "#646C7A", fontSize: "13px", fontWeight: "500" }}>Sort by</span>
                            </label>
                        </div>
                        {/* <div className='form-control'>
                            <Select
                                id="clusterFilter"
                                name="clusterFilter"
                                showSearch
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                placeholder="Cluster"
                                onChange={handleClusterChange}
                                allowClear
                            >
                                {clusterNameFilter?.map((clusterName, index) => (
                                    <Option key={index} value={clusterName?.name}>
                                        {clusterName?.name}
                                    </Option>
                                ))}

                            </Select>
                        </div> */}
                        <div className='form-control'>
                            <div>
                                <Select
                                    id="createdAtFilter"
                                    name="createdAtFilter"
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    placeholder="Date Created"
                                    onChange={handleCreatedAtChange}
                                    allowClear
                                >
                                    {clusterCreatedAt?.map((date, index) => (
                                        <Option key={index} value={date.date}>
                                            {date.date}
                                        </Option>
                                    ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add exam drawer */}

                <Drawer
                    closable={false}
                    title={currentTitle}
                    placement="right"
                    onClose={() => onClose(formik.values)}
                    open={visible}
                    footer={
                        <div className="footer">
                            <div className="footer-button coachmi-footer">
                                <Button
                                    className="primary-cancel-button coachmi-primary-cancel-button"
                                    onClick={() => onClose(formik.values)}
                                >
                                    Cancel
                                </Button>
                                    <>
                                        {currentId ? (
                                            <Button
                                                type="primary"
                                                className={` 
                                                ${!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} coachmi-primary-submit-button`}
                                                disabled={!formik.isValid || !formik.dirty}
                                                onClick={showUpdateConfirmation}
                                            >
                                                Update
                                            </Button>
                                        ) : (
                                            <Button
                                                type="primary"
                                                className={` 
                                                    ${!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} coachmi-primary-submit-button`}
                                                disabled={!formik.dirty || !formik.isValid}
                                                onClick={() => showSubmitConfirmation(formik.values)}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </>
                            </div>
                        </div>
                    }
                >

                    <div className='basic-form'>
                        <form onKeyDown={onKeyDown}>
                            <div>
                                <div className="form-control">
                                    <label>
                                        Cluster Name<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Input
                                        maxLength={50}
                                        id="name"
                                        name="name"
                                        placeholder="Enter Cluster Name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    ></Input>
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="error">{formik.errors.name}</div>
                                    ) : null}
                                </div>

                                <div className="form-control">
                                    <label>
                                        <span style={{ color: "#2A569E", fontSize: "14px", fontWeight: "500" }}>Subscription Plan</span>
                                    </label>
                                </div>

                                <div className='form-control'>
                                    <label>Actual Price <span style={{ color: 'red' }}>*</span></label>
                                    <Input
                                        type='number'
                                        id="actualPrice"
                                        name="actualPrice"
                                        maxLength={50}
                                        placeholder="Enter a Actual price"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.actualPrice || ''}
                                    />
                                    {formik.touched.actualPrice && formik.errors.actualPrice ?
                                        (
                                            <div className='error'>{formik.errors.actualPrice}</div>
                                        ) : null}
                                </div>

                                <div className='form-control'>
                                    <label>Price <span style={{ color: 'red' }}>*</span></label>
                                    <Input
                                        type='number'
                                        id="price"
                                        name="price"
                                        maxLength={50}
                                        placeholder="Enter a price"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.price || ''}
                                    />
                                    {formik.touched.price && formik.errors.price ?
                                        (
                                            <div className='error'>{formik.errors.price}</div>
                                        ) : null}
                                </div>
                                <div className='form-control'>
                                    <label>Frequency <span style={{ color: 'red' }}>*</span></label>
                                    <Select
                                        placeholder="Select a Frequency"
                                        id="frequencyId"
                                        name="frequencyId"
                                        style={{ width: "100%" }}
                                        onChange={(value) => formik.setFieldValue("frequencyId", value)}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.frequencyId || ''}
                                    >
                                        {frequencyData?.map((data) => (
                                            <Select.Option key={data.id}
                                                disabled={selectedFrequencyIds?.includes(data.id)}
                                                value={data.id}>{data.name}</Select.Option>
                                        ))}
                                    </Select>
                                    {formik.touched.frequencyId && formik.errors.frequencyId ?
                                        (
                                            <div className='error'>{formik.errors.frequencyId}</div>
                                        ) : null}
                                </div>

                                <p>
                                    <span
                                        className="add-pricing"
                                        onClick={() => !hasErrorsInPriceAndFrequencyHelper(isEditAction, formik) && addPrice(formik.values)}
                                        style={{ cursor: hasErrorsInPriceAndFrequencyHelper(isEditAction, formik) ? 'not-allowed' : 'pointer' }}
                                    >
                                        Add Pricing
                                    </span>
                                </p>

                                <div>
                                    <PricingList
                                        prices={prices}
                                        deletePricing={deletePricing}
                                        frequencyMap={frequencyMap}
                                    />
                                </div>

                            </div>
                        </form>
                    </div>

                </Drawer>

                {/* delete modal */}

                <Modal
                    title="Deletion"
                    open={isModalVisible}
                    onCancel={onModalClose}
                    footer={
                        <div>
                            <Button
                                className="primary-cancel-button"
                                onClick={() => onModalClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="primary-submit-button"
                                type="default"
                                onClick={() => confirmDelete()}
                            >
                                OK
                            </Button>
                        </div>
                    }
                >
                    <p>Are you sure you want to delete?</p>
                </Modal>

                {/* update confirmation */}

                <Modal
                    title="Update Confirmation"
                    open={isUpdateModalVisible}
                    onCancel={closeUpdateModal}
                    footer={
                        <div>
                            <Button
                                className="primary-cancel-button"
                                onClick={closeUpdateModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="primary-submit-button"
                                type="default"
                                onClick={updateData}
                            >
                                Update
                            </Button>
                        </div>
                    }
                >
                    <p>Are you sure you want to update?</p>
                </Modal>

                {/* submit Confirmation */}

                <Modal
                    title="Submit Confirmation"
                    open={isSubmitModalVisible}
                    onCancel={closeSubmitModal}
                    footer={
                        <div>
                            <Button
                                className="primary-cancel-button"
                                onClick={closeSubmitModal}
                            >
                                Cancel{" "}
                            </Button>
                            <Button
                                className="primary-submit-button"
                                type="default"
                                onClick={() => onSubmit(formik.values)}
                            >
                                {" "}
                                Submit{" "}
                            </Button>
                        </div>
                    }
                >
                    <p>Are you sure you want to submit?</p>
                </Modal>

            </div>

            <div>
                <TableComponent
                    dataSource={clusterMasterTableData}
                    columns={columns}
                    rowSelection={rowSelection}
                    tableOnChange={tableOnChange}
                    tableHeight={tableHeight}
                    totalData={tableTotalCount}
                    currentPage={paginationData + 1}
                    loadingStatus={isLoading}
                    limit={limit}
                />
            </div>
        </div>
    );
};

export default ClusterMaster;