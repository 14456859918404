import {useNavigate} from 'react-router-dom'
import './styles/dashboard.scss'

function DataEntryDashboard(){

    const navigate=useNavigate()

    const routeToList=()=>{
        navigate('/list')
    }
    return(
        <div>
            <p className='dashboard-header'>Dashboard</p>
            <div className="data-entry-dashboard-body">
            <div className="data-entry-cards">
            <div className="card" onClick={()=>routeToList()}>
            <p className='card-header'>Total Questions created</p>
            <p className='created-question-count'>6000</p>
            </div>
            <div className="card" onClick={()=>routeToList()}>
            <p className='card-header'>Approved Questions</p>
            <p className='approved-question-count'>2000</p>
            </div>
            <div className="card" onClick={()=>routeToList()}>
            <p className='card-header'>Pending Approval</p>
            <p className='pending-question-count'>450</p>
            </div>
            <div className="card" onClick={()=>routeToList()}>
            <p className='card-header'>Rejected Questions</p>
            <p className='rejected-question-count'>20</p>
            </div>
            </div>
        </div>
        </div>
    )
}

export default DataEntryDashboard;