import React, { useState, useEffect, useRef } from 'react';
import { Image, Button, Drawer, Checkbox, Input, Modal, Spin, Form, Upload, Select, Radio, message, Tabs, Tag } from 'antd';
// import { Button, Checkbox, Col, Divider, Form, Image, Input, message, Modal, Radio, Row, Select, Spin } from 'antd'
import importImage from '../asset/image/Import.svg';
import { Formik, useFormik } from 'formik';
import { SearchOutlined, LoadingOutlined, ToTopOutlined, UploadOutlined } from '@ant-design/icons';
import TableComponent from './Table/tableComponent';
import { useDispatch, useSelector } from 'react-redux';
import DeleteImage from '../asset/image/deleteIcon.svg'
import { insertExamMasterTable, getExamMasterTable, updateExamMasterTable, deleteExamMasterTable, approveExam, bulkExamApprove } from './reducers/ExamMasterSlice'
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { getYearAllExam, insertPreviousYearExam, getPreviousYearExam, getAllExam, getAllTypeOfTest, updatePreviousYearExam, removeEditItem, editItem, deletePreviousYearExam, publishPreviousYearExam, activePreviousYearExam, inActivePreviousYearExam, unPublishPreviousYearExam ,downloadLeaderBoard} from './reducers/PreviousYearExamSlice';
import * as yup from 'yup';
import FormList from 'antd/lib/form/FormList';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BreadCrumb from './BreadCrumb';
// import { addBreadCrumbData } from './reducers/masterSlice';
import ReactQuill from 'react-quill';
import { Option } from 'antd/lib/mentions';
import './styles/PreviousYearExam.scss'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getActiveSingleTestPaperCategory } from "./reducers/PreviousYearExamSlice";


function PreviousYearExam() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const params = useParams()
  const antIcon = (<LoadingOutlined style={{ fontSize: 24, }} spin />);
  const [editVisible, setEditVisible] = useState(false);
  const [examDetails, setExamDetails] = useState();
  const [editId, setEditId] = useState();

  const { examData } = useSelector((state) => state.PreviousYearExamSlice)


  const [Options, setSubOptions] = useState(false);
  const [record, setRecord] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const hiddenFile = useRef(null);
  const [image, setImage] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [error, setError] = useState(false)
  const [imageOpened, setImageOpened] = useState(false)
  const [limit, setLimit] = useState(50)
  const [currentTitle, setCurrentTitle] = useState("")
  const [currentId, setCurrentId] = useState(null)
  const [file, setFile] = useState([]);
  const [buttonState, setButtonState] = useState(1);
  const [approveId, setApproveId] = useState(null);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)
  const { TextArea } = Input;
  const [isApproval, setIsApproval] = useState(false)
  const [isPricing, setIsPricing] = useState(false);
  const [isNegative, setIsNegative] = useState(false);
  const [isPublishModalVisible, setIsPublishModalVisible] = useState(false);
  const [isUnPublishModalVisible, setIsUnPublishModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({})
  const [isPublishDisabled, setIsPublishDisabled] = useState(false);
  const [isInactiveDisabled, setIsInactiveDisabled] = useState(true)
  const roleId = localStorage.getItem("roleId")
  const [isPublishing, setIsPublishing] = useState(false);
  

  useEffect(() => {
    let data = {
      examId: params.examId,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    }
    let masterObj = {
      pagination: -1,
      searchValue: "",
      limit: 0
    }
    dispatch(getAllExam(masterObj));
    // dispatch(getAllTypeOfTest())
    dispatch(getPreviousYearExam(data));
    dispatch(getActiveSingleTestPaperCategory(data.examId));
    // dispatch(addBreadCrumbData({}))
  }, [])

  const { previousYearExamTableData, isLoading, examDataCount,activeSingleTestPaperCategoryData } = useSelector((state) => (state.PreviousYearExamSlice))
  let tableHeight = 320

  const [visible, setVisible] = useState(false);
  const [disabledButton, setDisabled] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image"
  ];

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Test Name',
      dataIndex: 'examName',
      key: 'examName',
      width: '11%',
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/PreviousYearExam/" + record.id)
          }
        };
      }
    },
    {
      title: 'Exam Name',
      dataIndex: 'examGroupName',
      key: 'examGroupName',
      width: '10%',
      render: (_, record) => {
        return (
          <div>
            <p>{record.examGroupName}</p>
            {/* <span>{moment(record.updatedAt).format('DD MMM YYYY')}</span> */}
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/PreviousYearExam/" + record.id)
          }
        };
      }
    },
    {
      title: 'Time limit',
      dataIndex: 'timeLimit',
      width: '9%',
      render: (_, record) => {
        return (
          <div>
            <p>{record.timeLimit}</p>

          </div>

        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/PreviousYearExam/" + record.id)
          }
        };
      }
    },
    {
      title: 'Approval Status',
      dataIndex: 'statusName',
      key: 'statusName',
      width: '10%',
      render: (_, record) => {
        return (
          <div>
            <span> {
              record.statusName != 'Approved' ?
                <Tag color="red">{record.statusName}</Tag> :
                <Tag color="green">{record.statusName}</Tag>

            }</span>
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/PreviousYearExam/" + record.id)
          }
        };
      }
    },
    {
      title: 'Active Status',
      dataIndex: 'inActive',
      key: 'inActive',
      width: '9%',
      render: (_, record) => {
        return (
          <div>
            <span> {
              record.inActive ?
                <Tag color="red">inActive</Tag> :
                <Tag color="green">Active</Tag>

            }</span>
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/PreviousYearExam/" + record.id)
          }
        };
      }
    },
    {
      title: 'Status',
      dataIndex: 'isPublish',
      key: 'isPublish',
      width: '9%',
      render: (_, record) => {
        return (
          <div>
            <span> {
              record.isPublish ?
                <Tag color="green">Published</Tag> :
                <Tag color="red">Unpublished</Tag>

            }</span>
          </div>
        )
      }
    }
  ]

  if (roleId == 1 || roleId == 3) {
    const actionColumn = {
      title: 'Action',
      key: 'action',
      width: '8%',
      render: (_, record) => (
        <>{
          record.isPublish == 0 ? <div className='table-action-link'>
            <a style={{ paddingRight: 10, paddingTop: 7 }} onClick={() => editData(record)}>Edit</a>
            {roleId == 3 && (
              <Tooltip title="Delete" style={{ padding: 0 }}>
                <IconButton>
                  <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)} />
                </IconButton>
              </Tooltip>
            )}
          </div> : null
        }</>
      ),
    };

    columns.push(actionColumn);

  }

  if (roleId == 3) {
    const publishButtonColumn = {
      title: '',
      key: '',
      with: '18%',
      render: (_, record) => (
        <div className='publish-button'>
          {roleId == 3 && (
            <>
              {
                record.isPublish ?
                  <Button
                    disabled={!record?.isPublish || (record.statusId == 1 || record.statusId == 3)}
                    className={`primary-submit-button status-active-unpublished `}
                    type='default'
                    onClick={record?.isPublish && record?.statusId == 2 ? () => unPublishPreviousYearExamBtn(record) : ''}
                  >
                    Unpublished
                  </Button> :

                  <Button
                    disabled={record?.isPublish || (record.statusId == 1 || record.statusId == 3)}
                    className={`primary-submit-button status-active`}
                    type='default'
                    onClick={!record?.isPublish && record?.statusId == 2 ? () => publishPreviousYearExamBtn(record) : ''}
                  >
                    Publish
                  </Button>
              }
              <>
                {
                  record.inActive ?
                    <Button
                      className={`primary-submit-button status-active active-status`}
                      type='default'
                      onClick={() => activePreviousYearExamBtn(record, false)}
                    >
                      Active
                    </Button>
                    :
                    <Button
                      className={`primary-submit-button status-active inactive-status`}
                      type='default'
                      onClick={() => activePreviousYearExamBtn(record, true)}
                    >
                      inActive
                    </Button>

                }

              </>

              <Button
                      className={`primary-submit-button status-active inactive-status`}
                      type='default'
                      onClick={() => downloadStudentLeaderBoard(record)}
                    >
                      Download
                    </Button>


            </>
          )}
        </div>
      ),
    };
    columns.push(publishButtonColumn);

  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const editData = (record) => {
    if (record.statusId == 1) {
      setIsApproval(true)
    }
    setCurrentId(record.id)
    setButtonState(2)
    formik.values.name = record.examName;
    formik.values.categoryId = record.categoryId;
    formik.values.instructions = record.instructions;
    formik.values.timeLimit = record.timeLimit;
    formik.values.examId = record.examGroupName;
    formik.values.numberOfQuestions = record.numberOfQuestions;
    formik.values.eachQuestionMark = record.eachQuestionMark;
    formik.values.pricing = record.pricing == 1 ? true : false;
    formik.values.examPrice = record.examPrice;
    formik.values.isNegativeMarkContain = record.isNegativeMarkContain == 1 ? true : false;
    formik.values.negativeMark = record.negativeMark;
    setVisible(true)
    setCurrentTitle("Edit Test")
    setIsNegative(record.isNegativeMarkContain)
  }

  function onClose(values) {
    formik.resetForm();
    setVisible(false);
    setIsApproval(false)
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)


    let data = {
      examId: params.examId,
      obj: {
        pagination: pagination?.current - 1,
        searchValue: filterData,
        limit: limit
      }
    }

    dispatch(getPreviousYearExam(data))
  }
  const onSearch = (value) => {
    setFilterData(value)
    let data = {
      examId: params.examId,
      obj: {
        pagination: paginationData,
        searchValue: value,
        limit: limit
      }
    }
    debounced(data)

  }
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getPreviousYearExam(obj))
  }, 1000)

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }


  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };


  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  function onSubmit(values) {
    let objData = {
      examId: params.examId,
      obj: {
        pagination: paginationData,
        searchValue: '',
        limit: limit
      }
    }
    values.examId = +objData?.examId;
    let data = {
      values: values,
      obj: objData
    }
    setSubmitModalVisible(false);
    setVisible(false);
    dispatch(insertPreviousYearExam(data)).unwrap().then((res) => {
      if (res) {
        formik.resetForm()
      }
    })
  }

  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };

  const activePreviousYearExamBtn = (record, statusName) => {
    setIsPublishDisabled(true);
    setCurrentRecord(record)
    let data = {
      id: record?.id,
      activeStatus: statusName,
      examData: {
        examId: params.examId,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
      }
    }
    dispatch(activePreviousYearExam(data)).unwrap().then((res) => {
    }, [])
  };
  const publishPreviousYearExamBtn = (record) => {

    setCurrentRecord(record)
    setIsPublishModalVisible(true)
  }
  const unPublishPreviousYearExamBtn = (record) => {

    setCurrentRecord(record)
    setIsUnPublishModalVisible(true)
  }

  const confirmPublishPreYearExam = () => {
    setIsPublishModalVisible(false)

    let data = {
      id: currentRecord.id,
      examData: {
        examId: params.examId,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
      }
    }
    dispatch(publishPreviousYearExam(data)).unwrap().then((res) => {

    })
  }

  const confirmUnPublishPreYearExam = () => {
    setIsUnPublishModalVisible(false)

    let data = {
      id: currentRecord.id,
      examData: {
        examId: params.examId,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
      }
    }
    dispatch(unPublishPreviousYearExam(data)).unwrap().then((res) => {

    })
  }

const downloadStudentLeaderBoard = (record) => {
let data = {
    id:record.id,
    obj: {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
  }

  dispatch(downloadLeaderBoard(data)).unwrap().then((res) => {
    const buffer = res.data
    const arrayBuffer = new Uint8Array(buffer).buffer;
    const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelFile], { type: 'application/octet-stream' });
    saveAs(blob, 'Exam Questions Count.xlsx');

  })
}

  const deleteData = (record) => {
    setRecord(record)
    setIsModalVisible(true)
    setButtonState(1)
  }
  function confirmDelete() {
    setIsModalVisible(false)
    let id = record.id
    let data = {
      id: record.id,
      obj: {
        examId: params.examId,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
      }
    }
    dispatch(deletePreviousYearExam(data))
  }
  function onModalClose() {
    setIsModalVisible(false);
    setIsRejectModalVisible(false)
    setIsPublishModalVisible(false)
    setIsUnPublishModalVisible(false)
    remarkFormik.resetForm()
  }
  const initialValues = {
    name: '',
    examId: '',
    categoryId: '',
    instructions: '',
    timeLimit: '',
    numberOfQuestions: '',
    eachQuestionMark: '',
    pricing: '',
    examPrice: '',
    isNegativeMarkContain: false,
    negativeMark: '',
    isPublish: 0,
    inActive: 0
  }

  let validationSchema = yup.object({
    name: yup.string()
      .required("Name is Required")
      .min(2, "Name Needed At Least Two characters")
      .max(100, "Name not more than 100 characters"),
    //   .matches(/^[a-zA-Z0-9@_ ]+$/, 'Only @ and _ special characters are allowed'),

    examId: yup.string().required('Exam is Required'),
    categoryId: yup.string()
      .required('Category Name is required'),
    timeLimit: yup.number()
      .required('Time Limit is required')
      .positive('Time Limit must be a positive integer')
      .integer('Time Limit must be an integer')
      .typeError('Time Limit must be a number')
      .max(999, 'Time Limit cannot be more than 3 digits'),
    numberOfQuestions: yup.number()
      .required('Number of Questions is required')
      .positive('Number of Questions must be a positive integer')
      .integer('Number of Questions must be an integer')
      .typeError('Number of Questions must be a number')
      .max(9999, 'NumberOfQuestions cannot be more than 5 digits'),
    eachQuestionMark: yup.number()
      .required('Mark is Required')
      .positive('Mark of Questions must be a positive integer')
      .max(99, 'Mark cannot be more than 2 digits'),
    pricing: yup.string().required("Pricing is required.").nullable(),
    examPrice: isPricing
      ? yup.number().required('Exam price is required')
        .positive('Number of Questions must be a positive integer')
        .integer('Number of Questions must be an integer')
      : null,
    negativeMark: isNegative ? yup.string().required('Negative Mark is Required').nullable() : null
    // negativeMark: isNegative ? yup
    //   .number()
    //   .positive('Negative Mark must be a positive number')
    //   .integer('Negative Mark must be an integer')
    //   .required('Negative Mark is Required')
    //   .nullable() : null
  })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  const onCheckedChange = (e) => {
    formik.setFieldValue("isNegativeMarkContain", e.target.checked)
    setIsNegative(e.target.checked)
    formik.setFieldValue("negativeMark", 0)
  }

  const onPricingValueChange = (e) => {
    formik.setFieldValue('pricing', e.target.value)
    setIsPricing(e.target.value)
    if (!e.target.value) {
      formik.setFieldValue("examPrice", 1)
    }
  }
  const remarkInitialValues = { remarks: '' }
  const remarkValidationSchema = yup.object({
    remarks: yup.string().required("Remarks is required")
  })

  const remarkFormik = useFormik({
    initialValues: remarkInitialValues,
    validationSchema: remarkValidationSchema
  })
  const openDrawer = () => {
    setIsApproval(false)
    setFile([])
    setCurrentId(null)
    setVisible(true)
    setButtonState(1)
    setCurrentTitle("New Test")
    formik.resetForm({ values: initialValues })
  }

  const onExamChange = (e) => {
    formik.setFieldValue("examId", e)
  }

  const onCategoryChange = (e) => {
    formik.setFieldValue("categoryId", e)
  }


  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };



  const updateData = () => {

    let datas = {
      id: currentId,
      data: formik.values,
      obj: {
        examId: params.examId,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
      }
    }
    datas.data.examId = Number(params?.examId);
    setUpdateModalVisible(false);
    setVisible(false)
    setIsApproval(false)
    dispatch(updatePreviousYearExam(datas))
    formik.resetForm();
  }

  const filehandleChange = (file) => {
    if (file.fileList.length) {
      if (file.file.type == 'image/jpeg' || file.file.type == 'image/jpg' || file.file.type == 'image/png') {
        setFile(file.fileList)
        formik.values.examImage = file.fileList
      }
      else {
        message.error("please upload jpeg, jpg and png formats only ")
      }
    }
    else {
      setFile(file.fileList)
      formik.values.examImage = file.fileList
      formik.validateForm()
    }
  }
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }


  const reject = () => {
    setIsRejectModalVisible(true)
  }

  const addRemarks = () => {
    setIsRejectModalVisible(false)
    let data = {
      examId: currentId,
      remarks: remarkFormik.values.remarks,
      statusId: 3
    }
    dispatch(approveExam(data)).unwrap().then((res) => {
      message.success(res.message)
    })
    setVisible(false)
    remarkFormik.setValues(remarkInitialValues)
  }
  const selectedExam = examData.find(item => item.id == params.examId)
  initialValues.examId = selectedExam?.name;
  const breadCrumbData = {}
  return (
    <div className='exam-master-page'>
      <div>
        <div className='breadCrumb-Data'>
          <BreadCrumb data={breadCrumbData} loading={isLoading}></BreadCrumb>
        </div>
        <div className='exam-header'>
          <div><h3 className='primary-header'>Single Test Paper</h3></div>
          <div className='add-exam'>
            {
              roleId == 3 || roleId == 1 && !params.statusName ?
                <span className='add-new-exam'><Button type='primary' className='primary-submit-button' onClick={() => openDrawer()} >Add Single Test Paper</Button></span>
                : null
            }
          </div>
        </div>
        <div className="all-table-component">
          <div className="golbalSearch" >
            <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
          </div>
        </div>
        {/* Add exam drawer */}
        <Drawer closable={false} title={currentTitle} placement="right" onClose={() => onClose(formik.values)} open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose(formik.values)}>Cancel</Button>
              {
                isApproval && roleId == 3 ?
                  <>
                    <Button className='reject-btn' onClick={() => reject()}>Reject</Button>
                    <Button className={!formik.isValid ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.isValid} style={{ margin: '0px 26px' }} onClick={() => updateData()}>Approve</Button>
                  </>
                  :
                  <>{
                    currentId ?
                      // <Button
                      //   type='primary'
                      //   className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'}
                      //   disabled={!formik.isValid || !formik.dirty}
                      //   onClick={showUpdateConfirmation}
                      // >
                      <Button
                        type='primary'
                        className={'primary-submit-button'}
                        onClick={showUpdateConfirmation}
                      >
                        Update
                      </Button>
                      :
                      <Button type='primary' className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} onClick={() => showSubmitConfirmation(formik.values)}>Submit</Button>

                  }
                  </>
              }
            </div>
          </div>
        )}>
          <form onKeyDown={onKeyDown}>
            <div>

              <div className='form-control'>
                <label>Test Name<span style={{ color: 'red' }}>*</span></label>
                <div className='input-label input-test-lebel' style={{ paddingBottom: 4 }}>
                  <Input maxLength={50} id='name' name='name' placeholder="Enter test name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
                  {formik.touched.name && formik.errors.name ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.name}</div> : null}
                </div>
              </div>
              <div className='form-control'>
                <label>Exam<span style={{ color: 'red' }}>*</span></label>
                <div className='input-label' style={{ paddingBottom: 4 }}>
                  <Input maxLength={50} id='examId' name='examId' placeholder="Enter Exam name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.examId} disabled={selectedExam} readOnly></Input>
                  {formik.touched.examId && formik.errors.examId ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.examId}</div> : null}
                </div>
              </div>
                <div className="form-control">
                <label htmlFor="categoryId">
                  Category <span style={{ color: "red" }}>*</span>
                </label>
                <div>
                  <Select
                    id="categoryId"
                    name="categoryId"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    placeholder="Select Option"
                    onChange={(e) => onCategoryChange(e)}
                    onBlur={formik.handleBlur}
                    value={formik.values.categoryId}
                  >
                    {activeSingleTestPaperCategoryData.length
                      ? activeSingleTestPaperCategoryData.map((data, index) => (
                          <Option value={data.id} key={data.id}>
                            {data.categoryName}
                          </Option>
                        ))
                      : null}
                  </Select>
                  {formik.touched.categoryId &&
                  formik.errors.categoryId ? (
                    <div className="errorMessage" style={{ color: "red" }}>
                      {formik.errors.categoryId}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='form-control'>
                <label htmlFor='instructions'>Instructions <span style={{ color: 'red' }}>*</span> </label>
                <div className='input-label instuction'>
                  <ReactQuill name="instructions" theme="snow" id='instructions' modules={modules} formats={formats} placeholder="Instructions" onChange={(value) => formik.setFieldValue("instructions", value)} value={formik.values.instructions} />
                </div>
              </div>
              <div className='form-control'>
                <label htmlFor='timeLimit'>Time Limit (in minutes) <span style={{ color: 'red' }}>*</span></label>
                <div className='input-label'>
                  <Input type='number' id='timeLimit' placeholder="Enter Time Limit" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.timeLimit} />
                  {formik.touched.timeLimit && formik.errors.timeLimit ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.timeLimit}</div> : null}
                </div>
              </div>

              <div className='form-control'>
                <label htmlFor='numberOfQuestions'>Number Of Questions<span style={{ color: 'red' }}>*</span></label>
                <div className='input-label'>
                  <Input type='number' id='numberOfQuestions' placeholder="Enter Number Of Questions" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.numberOfQuestions} />
                  {formik.touched.numberOfQuestions && formik.errors.numberOfQuestions ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.numberOfQuestions}</div> : null}
                </div>
              </div>
              <div className='form-control'>
                <label htmlFor='eachQuestionMark'>Mark of Each Question <span style={{ color: 'red' }}>*</span> </label>
                <div className='input-label'>
                  <Input type='number' id='eachQuestionMark' placeholder="Enter Mark of each question" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.eachQuestionMark} />
                  {formik.touched.eachQuestionMark && formik.errors.eachQuestionMark ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.eachQuestionMark}</div> : null}
                </div>
              </div>
              <div className='form-control'>
                <Checkbox checked={isNegative} onChange={(e) => onCheckedChange(e)} value={isNegative}> Is Negative Mark</Checkbox>
              </div>
              {
                isNegative ?
                  <div className='form-control'>
                    <label htmlFor='negativeMark'>
                      Negative Mark <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className='input-label'>
                      <Input type='number' id='negativeMark' placeholder="Enter negative mark" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.negativeMark} />
                      {formik.touched.negativeMark && formik.errors.negativeMark ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.negativeMark}</div> : null}
                    </div>
                  </div>
                  : null
              }
              <div class="previous-year-price-plan">
                <label>PricePlan <span style={{ color: 'red' }}>*</span></label>
                <Radio.Group id='pricing' onBlur={formik.handleBlur} onChange={(e) => onPricingValueChange(e)} value={formik.values.pricing}>
                  <Radio value={false}>
                    <p>Free</p>
                  </Radio>
                  <Radio value={true}>
                    <p>Payment</p>
                  </Radio>
                </Radio.Group>
                {formik.touched.pricing && formik.errors.pricing ? <div className='errorMessage' style={{ color: 'red', paddingLeft: 25, position: 'absolute' }}>{formik.errors.pricing}</div> : null}
              </div>
              {formik.values.pricing == true ?
                <div>
                  <div className='Exam-price'>
                    <div className='test-price'>
                      <p>Exam price <span style={{ color: 'red' }}>*</span> </p>
                      <Input id='examPrice' type='number' placeholder="Enter exam price" name='examPrice' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.examPrice}></Input>
                      {formik.touched.examPrice && formik.errors.examPrice ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.examPrice}</div> : null}
                    </div>
                  </div>
                </div>
                : null}
            </div>
          </form>
        </Drawer>

        {/* deletetion modal */}
        <Modal title='Deletion Confirmation' open={isModalVisible} onCancel={onModalClose} footer={
          <div>
            <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
            <Button className='primary-submit-button' type='default' onClick={() => confirmDelete()}>OK</Button>
          </div>
        }>
          <p>Are you sure you want to delete?</p>
        </Modal>

        {/* update model */}
        <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
          <div>
            <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
            <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
          </div>
        }>
          <p>Are you sure you want to update?</p>
        </Modal>


        {/* submit modal */}

        <Modal
          title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
            <div>
              <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
              <Button className="primary-submit-button" type="default" onClick={() => onSubmit(formik.values)}> Submit </Button>
            </div>
          }
        >
          <p>Are you sure you want to submit?</p>
        </Modal>

        {/* reject modal */}

        <Modal title='Rejection' open={isRejectModalVisible} onCancel={onModalClose} footer={
          <div>
            <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
            <Button type='default' className='primary-submit-button' id={!remarkFormik.isValid || !remarkFormik.dirty ? 'disabled-button' : null} disabled={!remarkFormik.isValid || !remarkFormik.dirty} onClick={() => addRemarks()}>OK</Button>
          </div>
        }>
          <div>
            <label htmlFor="remarks">Remarks <span style={{ color: 'red' }}>*</span> </label>
            <TextArea id='remarks' style={{ marginTop: 10, height: 176 }} onChange={remarkFormik.handleChange} onBlur={remarkFormik.handleBlur} value={remarkFormik.values.remarks} > </TextArea>
            {remarkFormik.touched.remarks && remarkFormik.errors.remarks ? <div className='errorMessage'>{remarkFormik.errors.remarks}</div> : null}
          </div>
        </Modal>
      </div>

      <div>
        {/* <TableComponent columns={columns} rowSelection={rowSelection}  tableHeight={tableHeight} totalData={examDataCount} currentPage={paginationData + 1} loadingStatus={isLoading} limit={limit} /> */}
        {/* <TableComponent
        columns={columns}
        dataSource={tableData} 
        rowSelection={rowSelection}
        tableHeight={tableHeight}
        totalData={examDataCount}
        currentPage={paginationData + 1}
        loadingStatus={isLoading}
        limit={limit}
      /> */}
        <TableComponent className="previous-exam-table" dataSource={previousYearExamTableData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={examDataCount} currentPage={paginationData + 1} loadingStatus={isLoading} limit={limit} />
      </div>
      <Modal title='Publish' open={isPublishModalVisible} onCancel={onModalClose} footer={
        <div>
          <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() => confirmPublishPreYearExam()}>OK</Button>
        </div>
      }>
        <p>Are you sure want to Publish <span className='highlight-text'>{currentRecord.examName} </span> ?</p>
      </Modal>

      <Modal title='Unpublish' open={isUnPublishModalVisible} onCancel={onModalClose} footer={
        <div>
          <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() => confirmUnPublishPreYearExam()}>OK</Button>
        </div>
      }>
        <p>Are you sure want to Publish <span className='highlight-text'>{currentRecord.examName} </span> ?</p>
      </Modal>

    </div>
  );
}
export default PreviousYearExam;