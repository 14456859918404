import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

export const createKeyFeatures = createAsyncThunk("KeyFeatures/createKeyFeatures",async (payload,thunk) => {
    try {
            let KeyFeatures = await axios.post(`keyFeatures/createKeyFeatures`, payload.data)
        .then(async (res) => {
          if (res.data.status) {
            message.success(res.data.message)
            await thunk.dispatch(getAllKeyFeatures(payload.obj))

            return res.data;
          }
        });
      return KeyFeatures;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getKeyFeaturesById = createAsyncThunk("KeyFeatures/getKeyFeaturesById",async (payload) => {
    try {
      let KeyFeatures = await axios.get(`keyFeatures/getKeyFeaturesById/${payload.id}`).then((res) => {
          if (res) {
            return res.data;
          }
        });
      return KeyFeatures;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getAllKeyFeatures = createAsyncThunk("KeyFeatures/getAllKeyFeatures",async (payload) => {
    try {
            let KeyFeatures = await axios.post(`keyFeatures/getAllKeyFeatures`, payload).then((res) => {
          if (res.data.success) {
            return res.data;
          }
        });
      return KeyFeatures;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);


export const updateKeyFeatures = createAsyncThunk("KeyFeatures/updateKeyFeatures", async (payload, thunk) => {
    try {
      let KeyFeatures = await axios.put(`keyFeatures/updateKeyFeatures/${payload.id}`, payload.data).then(async (res) => {
        if (res.status) {
          message.success(res.data.message)
        await thunk.dispatch(getAllKeyFeatures(payload.obj))        
        return res.data
        }
      })
      return KeyFeatures
    } catch (error) {
      message.error(error.response.data.message)
  
    }
  })

  export const deleteKeyFeatures = createAsyncThunk('KeyFeatures/deleteKeyFeatures', async (payload, thunk) => {
    try{
        let KeyFeatures = await axios.delete(`keyFeatures/deleteKeyFeatures/${payload.id}`).then(async (res) => {
            if (res.data.success) {
              message.success(res.data.data.message)
              await thunk.dispatch(getAllKeyFeatures(payload.obj))   
              return res.data     
          }
          })
          return KeyFeatures
    }
      catch(error){
        message.error(error.response.data.message)
         }
        })
    
  

const initialState = {
    KeyFeaturesData:[],
    isLoading: false,
    totalDataCount:0
};

const KeyFeaturesSlice = createSlice({
  name: "KeyFeaturesSlice",
  initialState,
  extraReducers: {
    [createKeyFeatures.pending]:(state)=>{
        state.isLoading=true
    },
    [createKeyFeatures.fulfilled]:(state,actions)=>{
        state.isLoading=false
    },
    [createKeyFeatures.rejected]:(state)=>{
        state.isLoading=false
    },
    [getAllKeyFeatures.pending]:(state) =>{
        state.isLoading=true
    },
    [getAllKeyFeatures.fulfilled]:(state,actions) =>{
      state.keyFeaturesData=actions.payload.data.getKeyFeaturesData
      state.totalDataCount=actions.payload.data.getKeyFeaturesCount.KeyFeaturesCount
      state.isLoading=false
    },
    [getAllKeyFeatures.rejected]:(state) =>{
        state.isLoading=false
    },
    [getKeyFeaturesById.pending]:(state) =>{
      state.isLoading=true
    },
    [getKeyFeaturesById.fulfilled]:(state) =>{
      state.isLoading=false
    },
    [getKeyFeaturesById.rejected]:(state) =>{
      state.isLoading=false
    },
    [updateKeyFeatures.pending]:(state) =>{
        state.isLoading=true
    },
    [updateKeyFeatures.fulfilled]:(state,actions) =>{
        state.isLoading=false
    },
    [updateKeyFeatures.rejected]:(state) =>{
        state.isLoading=false
    },
    [deleteKeyFeatures.pending]:(state) =>{
        state.isLoading=true
    },
    [deleteKeyFeatures.fulfilled]:(state,actions) =>{
        state.isLoading=false
    },
    [deleteKeyFeatures.rejected]:(state) =>{
        state.isLoading=false
    },
    
  },
});

export default KeyFeaturesSlice.reducer;
