import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const insertCurriculumDetails = createAsyncThunk(
    'Curriculum/addCurriculum', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.post('coachmi/addCurriculum', payload)
            .then(async (res) => {
                const { success, message } = res?.data;
                if (success) {
                    thunk.dispatch(removeCurriculumDetails());
                    resMessage = message;
                    resSuccess = success;
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            });
        return { resSuccess, resMessage };
    }
);

export const updateCurriculumDetails = createAsyncThunk(
    'Curriculum/updateCurriculumDetails', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.put('coachmi/updateCurriculum/' + payload.id, payload.data)
            .then(async (res) => {
                const { success, message } = res?.data;
                if (success) {
                    resMessage = message;
                    resSuccess = success;
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            })
        return { resSuccess, resMessage };
    }
);

export const deleteCurriculum = createAsyncThunk(
    'Curriculum/deleteCurriculum', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.put('coachmi/deleteEducator/' + payload.id)
            .then(async (res) => {
                const { success, message } = res?.data;
                resSuccess = success;
                resMessage = message
                if (success) {
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            });
        return { resSuccess, resMessage };
    }
);

export const getCurriculumDetailsById = createAsyncThunk(
    "Curriculum/getCurriculumDetailsById", async (payload) => {
        try {
            let clusterDetails = await axios.get(`/coachmi/getCurriculumDetailsById/${payload}`)
                .then((res) => {
                    if (res?.data?.status) {
                        return res?.data
                    }
                })
                .catch((error) => {

                })
            return clusterDetails
        } catch (error) {
        }
    }
);

export const getTestSeriesExamFilter = createAsyncThunk(
    "Curriculum/getTestSeriesExamFilter", async (payload) => {
        try {
            let getTestSeriesExamFilter = await axios.get(`/coachmi/getTestSeriesExamFilter`)
                .then((res) => {
                    if (res?.data?.status) {
                        return res?.data
                    }
                })
                .catch((error) => {})
            return getTestSeriesExamFilter
        } catch (error) {}
    }
);

export const getTestSeriesExam = createAsyncThunk(
    "Curriculum/getTestSeriesExam", async (payload) => {
        try {
            let getTestSeriesExam = await axios.post(`/coachmi/getTestSeriesExam`, payload)
                .then((res) => {
                    if (res?.data?.status) {
                        return res?.data
                    }
                })
                .catch((error) => {})
            return getTestSeriesExam
        } catch (error) { }
    }
);

export const getAllEducatorsByClusterId = createAsyncThunk(
    'OtherVideo/getAllOtherVideo',
    async (payload, thunk) => {
        try {
            let response = [];
            await axios.get(`masters/getAllEducatorsByClusterId/${payload?.id}`)
                .then(async (res) => {
                    response = res?.data?.data;
                })
                .catch((error) => {
                })
            return response;
        } catch (error) {

        };
    }
);

const initialState = {
    isLoading: false,
    curriculumDatails: [],
    getTestSeriesExamFilterData: [],
    getTestSeriesExamData: [],
    educatorDetails: []
};

const CurriculumSlice = createSlice({
    name: 'Curriculum',
    initialState,
    reducers: {
        removeCurriculumDetails: (state, action) => {
            state.curriculumDatails = [];
        },
    },
    extraReducers: {
        [insertCurriculumDetails.pending]: (state, action) => {
            state.isLoading = true
        },
        [insertCurriculumDetails.fulfilled]: (state, action) => {
            state.isLoading = false
        },
        [insertCurriculumDetails.rejected]: (state, action) => {
            state.isLoading = false
        },
        [updateCurriculumDetails.pending]: (state, action) => {
            state.isLoading = true
        },
        [updateCurriculumDetails.fulfilled]: (state, action) => {
            state.isLoading = false
        },
        [updateCurriculumDetails.rejected]: (state, action) => {
            state.isLoading = false
        },
        [deleteCurriculum.pending]: (state, action) => {
            state.isLoading = true
        },
        [deleteCurriculum.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [deleteCurriculum.rejected]: (state, action) => {
            state.isLoading = false
        },
        [getCurriculumDetailsById.pending]: (state, action) => {
            state.isLoading = true
        },
        [getCurriculumDetailsById.fulfilled]: (state, action) => {
            state.isLoading = false
            state.curriculumDatails = action.payload?.data
        },
        [getCurriculumDetailsById.rejected]: (state, action) => {
            state.isLoading = false
        },
        [getTestSeriesExamFilter.pending]: (state, action) => {
            state.isLoading = true
        },
        [getTestSeriesExamFilter.fulfilled]: (state, action) => {
            state.isLoading = false
            state.getTestSeriesExamFilterData = action.payload?.data
        },
        [getTestSeriesExamFilter.rejected]: (state, action) => {
            state.isLoading = false
        },
        [getTestSeriesExam.pending]: (state, action) => {
            state.isLoading = true
        },
        [getTestSeriesExam.fulfilled]: (state, action) => {
            state.isLoading = false
            state.getTestSeriesExamData = action.payload?.data
        },
        [getTestSeriesExam.rejected]: (state, action) => {
            state.isLoading = false
        },
        [getAllEducatorsByClusterId.pending]: (state, action) => {
            state.isLoading = true
        },
        [getAllEducatorsByClusterId.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.educatorDetails = action.payload;
        },
        [getAllEducatorsByClusterId.rejected]: (state, action) => {
            state.isLoading = false
        }
    }
}
);

export const { removeCurriculumDetails } = CurriculumSlice.actions;

export default CurriculumSlice.reducer;