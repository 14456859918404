import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Drawer,
  Modal,
  Table,
  Image,
  Form,
  Checkbox,
  Tag,
  Select
} from "antd";
import { Option } from 'antd/lib/mentions';
import { SearchOutlined } from "@ant-design/icons";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { useDebouncedCallback } from "use-debounce";
import TableComponent from "./Table/tableComponent";
import Loading from "./Loading";
import {
  getAllSingleTestPaperCategory,
  getSingleTestPaperCategoryById,
  addSingleTestPaperCategory,
  updateSingleTestPaperCategory,
  deleteSingleTestPaperCategory,
} from "./reducers/PreviousYearExamSlice";
import { getAllExam } from "./reducers/masterSlice";

const SingleTestPaperCategory = () => {
  const dispatch = useDispatch();
  let tableHeight = 320;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchText, setSearchText] = useState("");

  const {
    isLoading,
    singleTestPaperCategoryData,
    singleTestPaperCategoryDataCount,
  } = useSelector((state) => state.PreviousYearExamSlice);
  const { examData  } = useSelector((state) => state.MasterSlice);
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    };
    let masterObj = {
      pagination: -1,
      searchValue: "",
      limit: 0
  }
    dispatch(getAllSingleTestPaperCategory(obj));
    dispatch(getAllExam(masterObj))
  }, []);

  const initialValues = {
    examId: "",
    categoryName: "",
    isActive: false,
  };

  const validationSchema = yup.object({
    examId: yup.string().required("Exam is Required"),
    categoryName: yup
      .string()
      .required("Category Name is required")
      .min(2, "Category Name Atleast Two characters")
      .max(100, "Category Name must be at most 100 characters"),
  });

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "6%",
      render: (_, record, i) => {
        return (
          <div className="index-id">
            {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
          </div>
        );
      },
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (_, record) => {
        return (
          <div>
            <p>{record.categoryName}</p>
          </div>
        );
      },
    },
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
      render: (_, record) => {
        return (
          <div>
            <p>{record.examName}</p>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (_, record) => {
        return (
          <div>
            <span>
              {record.isActive == 1 ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">InActive</Tag>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="table-action-link">
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)}>
              Edit
            </a>
            <Tooltip style={{ padding: 0 }} title="Delete">
              <IconButton>
                <Image
                  src={DeleteImage}
                  preview={false}
                  onClick={() => deleteData(record)}
                ></Image>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const editData = (record) => {
    setCurrentId(record.id);
    setVisible(true);
    dispatch(getSingleTestPaperCategoryById(record.id))
      .unwrap()
      .then((res) => {
        let tempValues = res.data;
        formik.setValues(tempValues);
      });
    setCurrentTitle("Edit Single Test Paper Category");
  };

  const deleteData = (record) => {
    setCurrentId(record.id);
    setIsModalVisible(true);
  };

  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getAllSingleTestPaperCategory(obj));
  }, 1000);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
    };

    dispatch(getAllSingleTestPaperCategory(obj));
  };

  const openDrawer = () => {
    setCurrentId(null);
    formik.resetForm({ values: initialValues });

    setVisible(true);
    setCurrentTitle("Create Single Test Paper Category");
  };

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false);
      let payload = {
        data: values,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        },
      };
      setSubmitModalVisible(false);
      dispatch(addSingleTestPaperCategory(payload));
    },
  });

  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
    let datas = {
      id: currentId,
      data: formik.values,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };

    setUpdateModalVisible(false);
    setVisible(false);
    dispatch(updateSingleTestPaperCategory(datas));
    formik.resetForm();
  };

  const modalOnCancel = () => {
    setIsModalVisible(false);
  };
  const modalOnOk = () => {
    setIsModalVisible(false);
    setIsModalVisible(false);
    let id = currentId;
    let data = {
      id: id,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };
    dispatch(deleteSingleTestPaperCategory(data));
  };
  const drawerCloser = () => {
    setVisible(false);
    formik.resetForm();
  };

  const onExamChange = (e) => {
    formik.setFieldValue("examId", e)
  }


  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <>
      <div className="admin-page">
        <div className="adminHeader">
          <h3 className="primary-header">Single Test Paper Category</h3>
          <div className="admin-button">
            <Button
              className="primary-submit-button"
              type="primary"
              onClick={openDrawer}
            >
              Add Single Test Paper Category{" "}
            </Button>
          </div>
        </div>
        <div className="all-table-component" style={{ padding: 0, margin: 0 }}>
          <div className="golbalSearch">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
              style={{ width: 262, height: 32 }}
              onChange={(event) => onSearch(event.target.value)}
            />
          </div>
          <TableComponent
            dataSource={singleTestPaperCategoryData}
            columns={columns}
            rowSelection={rowSelection}
            tableOnChange={tableOnChange}
            tableHeight={tableHeight}
            totalData={singleTestPaperCategoryDataCount}
            currentPage={paginationData + 1}
            loadingStatus={isLoading}
            limit={limit}
          />
        </div>
        <Drawer
          title={currentTitle}
          onClose={() => drawerCloser()}
          width="518px"
          closable={false}
          closeIcon={false}
          placement="right"
          open={visible}
          footer={
            <div className="footer">
              <div className="footer-button">
                <Button
                  className="cancel-Button primary-cancel-button"
                  onClick={drawerCloser}
                >
                  Cancel
                </Button>
                {currentId == null ? (
                  <Button
                    className={
                      !formik.isValid || !formik.dirty
                        ? "disabled-button"
                        : "primary-submit-button"
                    }
                    type="primary"
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={showSubmitConfirmation}
                  >
                    Submit{" "}
                  </Button>
                ) : (
                  <Button
                    className={
                      !formik.isValid || !formik.dirty
                        ? "disabled-button"
                        : "primary-submit-button"
                    }
                    type="primary"
                    disabled={!formik.isValid || !formik.dirty}
                    onClick={showUpdateConfirmation}
                  >
                    Update{" "}
                  </Button>
                )}
              </div>
            </div>
          }
        >
          <Formik validateOnChange={false} validateOnBlur={false}>
            <Form>
              <div className="form-control">
                <label htmlFor="examId">
                  Exam <span style={{ color: "red" }}>*</span>
                </label>
                <div>
                  <Select
                    id="examId"
                    name="examId"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    placeholder="Select Option"
                    onChange={(e) => onExamChange(e)}
                    onBlur={formik.handleBlur}
                    value={formik.values.examId}
                  >
                    {examData.length
                      ? examData.map((data, index) => (
                          <Option value={data.id} key={data.id}>
                            {data.name}
                          </Option>
                        ))
                      : null}
                  </Select>
                  {formik.touched.examId &&
                  formik.errors.examId ? (
                    <div className="errorMessage" style={{ color: "red" }}>
                      {formik.errors.examId}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className='form-control'>
                <label htmlFor='categoryName'>Category <span style={{ color: 'red' }}>*</span></label>
                <div className='input-label'>
                  <Input maxLength={50} type='text' id='categoryName' placeholder="Enter a category name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.categoryName} />
                  {formik.touched.categoryName && formik.errors.categoryName ? <div className='errorMessage' style={{ color: 'red' }} >{formik.errors.categoryName}</div> : null}
                </div>
              </div>

              <div className="form-control">
                  <Checkbox
                    id="isActive"
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    // value={formik.values.isActive}
                  >
                    Active
                  </Checkbox>
                </div>
            </Form>
          </Formik>
        </Drawer>
        <Modal
          title="Deletion"
          open={isModalVisible}
          onCancel={modalOnCancel}
          onOk={modalOnOk}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={() => modalOnCancel()}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => modalOnOk()}
              >
                OK
              </Button>
            </div>
          }
        >
          <p>Are you sure want to delete ?</p>
        </Modal>

        {/* update model */}
        <Modal
          title="Update Confirmation"
          open={isUpdateModalVisible}
          onCancel={closeUpdateModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeUpdateModal}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => updateData(formik.values)}
              >
                Update
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to update?</p>
        </Modal>

        {/* submit modal */}

        <Modal
          title="Submit Confirmation"
          open={isSubmitModalVisible}
          onCancel={closeSubmitModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeSubmitModal}
              >
                Cancel{" "}
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={formik.handleSubmit}
              >
                {" "}
                Submit{" "}
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to Submit?</p>
        </Modal>
      </div>

      {isLoading ? (
        <div className="loading-container">
          <Loading></Loading>
        </div>
      ) : null}
    </>
  );
};

export default SingleTestPaperCategory;
