import { Input, Select, Modal, Switch, Form, Button } from 'antd';
import contentIcon from "../../asset/image/video_img.svg";
import '../../components/styles/pricing.scss';
import PricingList from "./PricingList.js";
import { hasErrorsInPriceAndFrequencyHelper } from "../../utils/HelperFunction.js";


const PricePlanModal = ({ visible, onOk, onCancel, formik, videoName, frequencyData = [], prices = [], isEditAction = true, frequencyMap, addPrice, pricingSwitchChange, deletePricing }) => {

    return (
        <Modal className='price-plan-modal' title="Set a Pricing Plan" open={visible} onOk={onOk} onCancel={onCancel}

            footer={                
                <div className="footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className="footer-button" style={{ "columnGap": '10px' }}>
                        <Button style={{ 'borderRadius': '4px' }}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={
                                !formik?.isValid || !formik?.dirty
                                ? 'disabled-button' : 'primary-submit-button'
                            }
                            type="primary"
                            disabled={!formik?.isValid || !formik?.dirty}
                            onClick={onOk}
                            style={{ 'borderRadius': '4px', 'margin': 0 }}
                            >
                            Save
                        </Button>
                    </div>
                </div>
            }

        >
            <div className='price-plan-section'>
                <div className='content-name'>
                    <img src={contentIcon} className='icon' />
                    <p className='video-name'>{videoName ? videoName : '-'}</p>
                </div>
                <div className='subscription-plan'>
                    <p className='sub-plan-title'>Subscription Plan</p>

                    <Form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                        <div>
                            <div>

                                <div className="form-control">
                                        <label>Actual Price<span style={{ color: "red" }}>*</span></label>
                                        <Input
                                            type="text"
                                            id="actualPrice"
                                            name="actualPrice"
                                            placeholder="Enter a Actual price"
                                            onBlur={formik?.handleBlur}
                                            onChange={formik?.handleChange}
                                            value={formik?.values?.actualPrice}
                                        />
                                        {formik?.touched?.actualPrice && formik?.errors?.actualPrice && (
                                            <div className="errorMessage" style={{ color: "red" }}>
                                                {formik?.errors?.actualPrice}
                                            </div>
                                        )}
                                </div>

                                <div className="form-control">
                                    <label>Price<span style={{ color: "red" }}>*</span></label>
                                    <Input
                                        type="text"
                                        id="price"
                                        name="price"
                                        placeholder="Enter a Price"
                                        onBlur={formik?.handleBlur}
                                        onChange={formik?.handleChange}
                                        value={formik?.values?.price}
                                    />
                                    {formik?.touched?.price && formik?.errors?.price && (
                                        <div className="errorMessage" style={{ color: "red" }}>
                                            {formik?.errors?.price}
                                        </div>
                                    )}
                                </div>

                                <div className='form-control'>
                                    <label>Frequency<span style={{ color: "red" }}>*</span></label>
                                    <div>
                                        <Select
                                            id="frequencyId"
                                            placeholder="Select a Frequency"
                                            name="frequencyId"
                                            style={{ width: "100%" }}
                                            onChange={(value) => formik.setFieldValue('frequencyId', value)}
                                            onBlur={formik?.handleBlur}
                                            value={formik?.values?.frequencyId}
                                        >
                                            {frequencyData.map((data) => (
                                                <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    {formik?.touched?.frequencyId && formik?.errors?.frequencyId && (
                                        <div className='errorMessage' style={{ color: 'red' }}>
                                            {formik?.errors?.frequencyId}
                                        </div>
                                    )}
                                    <p>
                                            <span
                                                className="add-pricing"
                                                onClick={() => !hasErrorsInPriceAndFrequencyHelper(isEditAction, formik) && addPrice(formik?.values)}
                                                style={{ cursor: hasErrorsInPriceAndFrequencyHelper(isEditAction, formik) ? 'not-allowed' : 'pointer' }}
                                            >
                                                Add Pricing
                                            </span>
                                        </p>

                                </div>
                            </div>
                            <div className='form-control price-plan-switch'>
                                <div className='form-switch-btn'>
                                    <Switch
                                        id="isFree"
                                        name="isFree"
                                        onChange={pricingSwitchChange}
                                        value={formik?.values?.isFree}
                                    />
                                    <p>Include a free trial</p>
                                </div>
                            </div>
                        </div>
                    </Form>

                    <div>
                        <PricingList
                            prices={prices}
                            deletePricing={deletePricing}
                            frequencyMap={frequencyMap}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PricePlanModal;