import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const insertOtherVideo = createAsyncThunk(
    'OtherVideo/insertOtherVideo', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.post('otherVideoContent/addOtherVideoContent', payload.data)
            .then(async (res) => {
                const { success, message } = res?.data;
                if (success) {
                    resMessage = message;
                    resSuccess = success;
                    await thunk.dispatch(getAllOtherVideo(payload.obj));
                    await thunk.dispatch(getUniqueOtherVideoCreatedDate());
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            });
            return { resSuccess, resMessage };
    }
);

export const updateOtherVideos = createAsyncThunk(
    'OtherVideo/updateOtherVideos', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.put('otherVideoContent/updateOtherVideoContent/' + payload.id, payload.data)
            .then(async (res) => {
                const { success, message } = res?.data;
                if (success) {
                    resMessage = message;
                    resSuccess = success;
                    await thunk.dispatch(getAllOtherVideo(payload.obj));
                    await thunk.dispatch(getUniqueOtherVideoCreatedDate());
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            })
            return { resSuccess, resMessage };
    }
);

export const deleteOtherVideo = createAsyncThunk(
    'OtherVideo/deleteOtherVideo', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.put('otherVideoContent/deleteOtherVideoContent/' + payload.id)
            .then(async (res) => {
                const { success, message } = res?.data;
                resSuccess = success;
                resMessage = message
                if (success) {
                    await thunk.dispatch(getAllOtherVideo(payload.obj));
                    await thunk.dispatch(getUniqueOtherVideoCreatedDate());
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            })
            return { resSuccess, resMessage };
    }
);

export const getAllOtherVideo = createAsyncThunk(
    'OtherVideo/getOtherVideo',
    async (payload, thunk) => {
        try {
            let tableData = [];
            let tableTotalCount;
            let resSuccess = false;
            let resMessage = '';
            await axios.post('otherVideoContent/getAllOtherVideoContent', payload)
                .then(async (res) => {
                    const { success, message, data } = res.data;
                    const { listData, dataCount } = data;
                    resSuccess = success
                    resMessage = message;
                    tableData = listData;
                    tableTotalCount = dataCount;
                })
                .catch((error) => {
                    resMessage = error?.response?.data?.message
                })
                return { tableData, tableTotalCount, resSuccess, resMessage };
            } catch (error) {
            throw error;
        };
    }
);

export const getOtherVideoDetailsById = createAsyncThunk(
    "OtherVideo/getOtherVideoDetailsById", async (payload) => {
        try {
            let otherVideoDetails = await axios.get(`/otherVideoContent/getOtherVideoContentById/${payload}`)
                .then((res) => {
                    const { success } = res;
                    if (res?.data?.status) {
                        return res?.data
                    }
                })
                .catch((error) => {

                })
            return otherVideoDetails
        } catch (error) {
        }
    }
);

export const getAllEducators = createAsyncThunk(
    'OtherVideo/getAllOtherVideo',
    async (payload, thunk) => {
        try {
            let response = [];
            await axios.get('masters/getAllEducators')
                .then(async (res) => {
                    response = res?.data?.data;
                })
                .catch((error) => {
                })
            return response;
        } catch (error) {

        };
    }
);

export const getUniqueOtherVideoCreatedDate = createAsyncThunk(
    'OtherVideo/getUniqueOtherVideoCreatedDate', async (payload, thunk) => {
      try {
        let response = [];
        await axios.get('otherVideoContent/getUniqueOtherVideoContentCreatedDate')
          .then(async (res) => {
            const { status, data } = res.data;
            if (status) {
            };
            response = data;
          })
          .catch((error) => {
          })
        return response;
      } catch (error) {
      }
    }
);

const initialState = {
    otherVideoTableData: [],
    tableTotalCount: 0,
    isLoading: false,
    clusterCreatedAt: [],
    educatorDetails: []
};

const OtherVideoSlice = createSlice({
    name: 'OtherVideo',
    initialState,
    extraReducers: {
        [insertOtherVideo.pending]: (state, action) => {
            state.isLoading = true;
        },
        [insertOtherVideo.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [insertOtherVideo.rejected]: (state, action) => {
            state.isLoading = false;
        },
        [updateOtherVideos.pending]: (state, action) => {
            state.isLoading = true;
        },
        [updateOtherVideos.fulfilled]: (state, action) => {
            state.isLoading = false
        },
        [updateOtherVideos.rejected]: (state, action) => {
            state.isLoading = false;
        },
        [deleteOtherVideo.pending]: (state, action) => {
            state.isLoading = true;
        },
        [deleteOtherVideo.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [deleteOtherVideo.rejected]: (state, action) => {
            state.isLoading = false;
        },
        [getAllOtherVideo.pending]: (state) => {
            state.isLoading = true;
        },
        [getAllOtherVideo.fulfilled]: (state, action) => {
            const { tableData, tableTotalCount } = action.payload;
            state.otherVideoTableData = tableData;
            state.tableTotalCount = tableTotalCount;
            state.isLoading = false
        },
        [getAllOtherVideo.rejected]: (state) => {
            state.isLoading = false;
        },
        [getAllEducators.pending]: (state) => {
            state.isLoading = true;
        },
        [getAllEducators.fulfilled]: (state, action) => {
            state.educatorDetails = action.payload;
            state.isLoading = false
        },
        [getAllEducators.rejected]: (state) => {
            state.isLoading = false;
        },
        [getUniqueOtherVideoCreatedDate.pending]: (state) => {
            state.isLoading = true
        },
        [getUniqueOtherVideoCreatedDate.fulfilled]: (state, actions) => {
            state.isLoading = false
            state.clusterCreatedAt = actions?.payload
        },
        [getUniqueOtherVideoCreatedDate.rejected]: (state) => {
            state.isLoading = false
        },
    }
 }
);

export default OtherVideoSlice.reducer;