import React, { useState, useEffect, useRef } from 'react';
import { Col, Divider, Image, Row, Tabs } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import translate from '../asset/image/translate.svg'
import TableComponent from './Table/tableComponent';

const TestResultsDetails = () => {
const [limit, setLimit] = useState(50)

    let tableHeight = 320
const sampleData =[
    {
       rank:'1',
       name:'Guru',
       timeTaken:'160 mins',
       score:'295'
    },
    {
        rank:'1',
       name:'Guru',
       timeTaken:'160 mins',
       score:'295'
    },
    {
        rank:'1',
       name:'Guru',
       timeTaken:'160 mins',
       score:'295'
    },
    {
        rank:'1',
       name:'Guru',
       timeTaken:'160 mins',
       score:'295'
    },
    {
        rank:'1',
       name:'Guru',
       timeTaken:'160 mins',
       score:'295'
    },
    {
        rank:'1',
       name:'Guru',
       timeTaken:'160 mins',
       score:'295'
    },
    {
        rank:'1',
       name:'Guru',
       timeTaken:'160 mins',
       score:'295'
    },
    {
        rank:'1',
       name:'Guru',
       timeTaken:'160 mins',
       score:'295'
    },
    {
        rank:'1',
       name:'Guru',
       timeTaken:'160 mins',
       score:'295'
    },
]
    const columns = [
        {
          title: 'Rank',
          dataIndex: 'rank',
          key: 'rank',
          onCell: (record, rowIndex) => {
            return {
            //   onClick: () => {
            //     navigate("/ExamMaster/" + record.id)
            //   }
            };
          }
        },
        {
            title: 'Name of the Student ',
            dataIndex: 'name',
            key: 'name',
            onCell: (record, rowIndex) => {
              return {
              //   onClick: () => {
              //     navigate("/ExamMaster/" + record.id)
              //   }
              };
            }
          },
          {
            title: 'Taking Time',
            dataIndex: 'timeTaken',
            key: 'timeTaken',
            onCell: (record, rowIndex) => {
              return {
              //   onClick: () => {
              //     navigate("/ExamMaster/" + record.id)
              //   }
              };
            }
          },
          {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            onCell: (record, rowIndex) => {
              return {
              //   onClick: () => {
              //     navigate("/ExamMaster/" + record.id)
              //   }
              };
            }
          }
      ]
      
    return(
        <>
        <div className='resultsDetails-container'>
            <div className='breadcrumb-container'>
                <p>Back</p>
            </div>
            <div className='test-result-container results-details'>
            <div className='test-details'>
                    <p className='test-date'><span>27 March 2023</span></p>
                    <p className='test-name'><span>UPSC Services Full Test 1</span></p>
                      <div className='split-container'>
                        <p className='total-questions'> Questions</p> 
                        <Divider style={{background:'#7A8B94',marginTop:3}} type='vertical'></Divider>
                        <p className='total-questions'> Marks</p> 
                        <Divider style={{background:'#7A8B94',marginTop:3}} type='vertical'></Divider>
                        <p className='total-questions'>Mins</p> 
                      </div>
                      <div className='language-content'>
                        <div className='language-image'><Image src={translate}></Image></div>
                        <p className='language-text'><span>English,Tamil</span></p>
                      </div>
            </div>
            <div className='attempted-students-details'>
                <p className='attempted-text'><span>Attempted Students </span></p>
                <p className='total-attempted-students'><span>35/40</span></p>
                <p className='attempted-text'><span>explore leaderboard</span></p>
            </div>
        </div>
            
            <div className='results-table'>
            <TableComponent dataSource={sampleData} columns={columns}   tableHeight={tableHeight}  limit={limit} />
                
            </div>
        </div>
        </>
    )
}
export default TestResultsDetails