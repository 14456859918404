import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from './Loading'
import { verifyInstituteToken } from './reducers/institutionMasterSlice'


const InstitutionVerifyToken = () => {

    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLoading } = useSelector((state) => state.InstitutionSlice)
    const [apiCallInProgress, setApiCallInProgress] = useState(false);

    useEffect(() => {
        if (apiCallInProgress) {
            return; // Exit early if an API call is already in progress
        }
        setApiCallInProgress(true)
        localStorage.clear()
        dispatch(verifyInstituteToken(params.token)).unwrap().then((res) => {
            if (res?.success) {
                message.success(res.message)
                navigate("/InstitutionsetPassword/" + res.data.token)
            } else {
                navigate("/login")
            }
        }).finally(() => {
            setApiCallInProgress(false)
        })

    }, [])

    return (
        <>
            {
                isLoading ? <div className='loading-container'>
                    <Loading></Loading>
                </div> : null
            }
        </>
    )
}
export default InstitutionVerifyToken