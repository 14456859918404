import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from 'moment'

export const insertCourseMasterTable = createAsyncThunk(
  'courseMaster/insertCourseMasterTable', async (payload, thunk) => {
    let tabledata = []
    let Insertmessage = '';
    let success = ''
    await axios.post('masters/course/create', payload)
      .then(async (res) => {

        await thunk.dispatch(getCourseMasterTable(payload.institutionDetailId))
        message.success(res.data.message);

      })
      .catch((error) => {
        let insertError = error.response.data;
        message.error(insertError.message);
      })
    return {
      tabledata: tabledata,
      Insertmessage: Insertmessage,
      success: success
    };
  }
)

export const getCourseMasterTable = createAsyncThunk(
  'CourseMaster/getCourseMasterTable', async (id) => {
    let tabledata = []
    let tableData = await axios.get(`/masters/course/getByInstitution/${id}`)
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)


export const getCourseById = createAsyncThunk("Masters/getCourseById", async (payload) => {
  try {
    let getCourseById = await axios.get(`/masters/course/${payload}`).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getCourseById
  } catch (error) {
  }
})
export const updateCourse = createAsyncThunk("Masters/updateCourse", async (payload, thunk) => {
  try {
    console.log('update', payload);
    let updateUser = await axios.put(`/masters/course/${payload.id}`, payload.data).then(async (res) => {
      console.log('ressss', res);

      if (res.data.status) {
        message.success(res.data.message)

        await thunk.dispatch(getCourseMasterTable(payload.data.institutionDetailId))
        return res.data


      }
    })
    return updateUser
  } catch (error) {
    message.error(error.response.data.message)

  }
})
export const deleteCourse = createAsyncThunk("Masters/deleteCourse", async (payload, thunk) => {
  try {
    let updateUser = await axios.delete(`/masters/course/${payload.id}`).then(async (res) => {
      console.log('detele resss', res);
      if (res.data.success) {
        message.success(res.data.message)

        await thunk.dispatch(getCourseMasterTable(payload.institutionId))
        return res.data
      }
    })
    return updateUser
  } catch (error) {
    message.error(error.response.data.message)

  }
})

const initialState = {
  courseMasterTableData: [],
  isLoading: false,
  courseDataCount: 0,
  courseId: {}
}
const CourseSlice = createSlice({
  name: 'CourseSlice',
  initialState,
  extraReducers: {
    [insertCourseMasterTable.pending]: (state) => {
      state.isLoading = true
    },
    [insertCourseMasterTable.fulfilled]: (state, actions) => {
      let tableData = actions.payload.tabledata.data
      let success = actions.payload.success
      let Insertmessage = actions.payload.Insertmessage
      if (success == true) {

        message.success(Insertmessage)
      }
      state.isLoading = false
    },
    [insertCourseMasterTable.rejected]: (state) => {
      state.isLoading = false
    },
    [getCourseMasterTable.pending]: (state) => {
      state.isLoading = true
    },
    [getCourseMasterTable.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.courseMasterTableData = actions.payload;
    },
    [getCourseMasterTable.rejected]: (state) => {
      state.isLoading = false
    },
    [getCourseById.pending]: (state, action) => {
      state.isLoading = true
    },
    [getCourseById.fulfilled]: (state, action) => {
      state.courseId = action.payload
      state.isLoading = false

    },
    [getCourseById.rejected]: (state, action) => {
      state.isLoading = false
    },
    [updateCourse.pending]: (state, action) => {
      state.isLoading = true
    },
    [updateCourse.fulfilled]: (state, action) => {
      state.isLoading = false

    },
    [updateCourse.rejected]: (state, action) => {
      state.isLoading = false
    },
    [deleteCourse.pending]: (state, action) => {
      state.isLoading = true
    },
    [deleteCourse.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [deleteCourse.rejected]: (state, action) => {
      state.isLoading = false
    },
  }

})
export default CourseSlice.reducer;
