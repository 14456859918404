import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Drawer,
  Modal,
  Table,
  Image,
  Form,
  Checkbox,
  Tag,
} from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { useDebouncedCallback } from "use-debounce";
import TableComponent from "./Table/tableComponent";
import Loading from "./Loading";
import { createLeads,getAllLeads,getLeadsById,updateLeads,deleteLeads} from "./reducers/LeadsSlice";

const Leads = () => {
  const dispatch = useDispatch();
  let tableHeight = 320;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchText, setSearchText] = useState("");

  const { leadData, isLoading, totalDataCount } = useSelector((state) => state.Leads);
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    };
    dispatch(getAllLeads(obj));
  }, []);

  const initialValues = {
    email: ""
  };

  const validationSchema = yup.object({

    email: yup.string().email('Invalid Email Id').required("Email is Required"),
  });

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "6%",
      render: (_, record, i) => {
        return (
          <div className="index-id">
            {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => {
        return (
          <div>
            <p>{record.email}</p>
          </div>
        );
      },
    },
  
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="table-action-link">
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)}>
              Edit
            </a>
            <Tooltip style={{ padding: 0 }} title="Delete">
              <IconButton>
                <Image
                  src={DeleteImage}
                  preview={false}
                  onClick={() => deleteData(record)}
                ></Image>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const editData = (record) => {
    setCurrentId(record.id)
    setVisible(true)
    dispatch(getLeadsById(record.id)).unwrap().then((res) => {
      let tempValues = res.data[0]
      formik.setValues(tempValues)
    })
    setCurrentTitle("Edit Leads")

  }

  const deleteData = (record) => {
    setCurrentId(record.id)
    setIsModalVisible(true)
  }

  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getAllLeads(obj));
  }, 1000);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
    };

    dispatch(getAllLeads(obj));
  };

  const openDrawer = () => {
    setCurrentId(null);
    formik.resetForm({ values: initialValues });

    setVisible(true);
    setCurrentTitle("Create Leads");
  };

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false);
      let payload = {
        data: values,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        },
      };
      setSubmitModalVisible(false);
      dispatch(createLeads(payload));
    },
  });

  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
    let datas = {
      id: currentId,
      data: formik.values,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
      }

    setUpdateModalVisible(false);
    setVisible(false)
    dispatch(updateLeads(datas))
    formik.resetForm();
  }

  const modalOnCancel = () => {
    setIsModalVisible(false);
  };
  const modalOnOk = () => {
    setIsModalVisible(false);
    setIsModalVisible(false)
    let id = currentId
    let data = {
      id: id,
      obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
      }
    dispatch(deleteLeads(data));
  };
  const drawerCloser = () => {
    setVisible(false);
    formik.resetForm();
  };

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <>
      <div className="admin-page">
        <div className="adminHeader">
          <h3 className="primary-header">Leads</h3>
          <div className="admin-button">
            <Button
              className="primary-submit-button"
              type="primary"
              onClick={openDrawer}
            >
              Add Leads{" "}
            </Button>
          </div>
        </div>
        <div className="all-table-component" style={{ padding: 0, margin: 0 }}>
          <div className="golbalSearch">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
              style={{ width: 262, height: 32 }}
              onChange={(event) => onSearch(event.target.value)}
            />
          </div>
          <TableComponent
            dataSource={leadData}
            columns={columns}
            rowSelection={rowSelection}
            tableOnChange={tableOnChange}
            tableHeight={tableHeight}
            totalData={totalDataCount}
            currentPage={paginationData + 1}
            loadingStatus={isLoading}
            limit={limit}
          />
        </div>
        <Drawer
          title={currentTitle}
          onClose={() => drawerCloser()}
          width="518px"
          closable={false}
          closeIcon={false}
          placement="right"
          open={visible}
          footer={
            <div className="footer">
              <div className="footer-button">
                <Button
                  className="cancel-Button primary-cancel-button"
                  onClick={drawerCloser}
                >
                  Cancel
                </Button>
                {currentId == null ? (
                  <Button
                    className={
                      !formik.isValid || !formik.dirty
                        ? "disabled-button"
                        : "primary-submit-button"
                    }
                    type="primary"
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={showSubmitConfirmation}
                  >
                    Submit{" "}
                  </Button>
                ) : (
                  <Button
                    className={
                      !formik.isValid || !formik.dirty
                        ? "disabled-button"
                        : "primary-submit-button"
                    }
                    type="primary"
                    disabled={!formik.isValid || !formik.dirty}
                    onClick={showUpdateConfirmation}
                  >
                    Update{" "}
                  </Button>
                )}
              </div>
            </div>
          }
        >
          <Formik validateOnChange={false} validateOnBlur={false}>
            <Form>
             
            <div className='form-control'>
                <label htmlFor='email'>Email
                <span style={{ color: 'red' }}> *</span>
                </label>
                <Input type='text' id='email' placeholder="Enter a Email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                {formik.touched.email && formik.errors.email ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.email}</div> : null}
              </div>  

            </Form>
          </Formik>
        </Drawer>
        <Modal
          title="Deletion"
          open={isModalVisible}
          onCancel={modalOnCancel}
          onOk={modalOnOk}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={() => modalOnCancel()}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => modalOnOk()}
              >
                OK
              </Button>
            </div>
          }
        >
          <p>Are you sure want to delete ?</p>
        </Modal>

        {/* update model */}
        <Modal
          title="Update Confirmation"
          open={isUpdateModalVisible}
          onCancel={closeUpdateModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeUpdateModal}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => updateData(formik.values)}
              >
                Update
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to update?</p>
        </Modal>

        {/* submit modal */}

        <Modal
          title="Submit Confirmation"
          open={isSubmitModalVisible}
          onCancel={closeSubmitModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeSubmitModal}
              >
                Cancel{" "}
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={formik.handleSubmit}
              >
                {" "}
                Submit{" "}
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to Submit?</p>
        </Modal>
      </div>

      {isLoading ? (
        <div className="loading-container">
          <Loading></Loading>
        </div>
      ) : null}
    </>
  );
};

export default Leads;
