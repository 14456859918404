import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, Button, Image } from 'antd';
import CourseOverview from './CourseOverview';
import Curriculum from './Curriculum';
import Price from './Pricing';
import arrowIcon from '../asset/image/arrow-icon.svg';
import '../components/styles/StepperComponent.scss';
import { formValidationSubjects } from "../utils/HelperFunction.js";

const { TabPane } = Tabs;

const StepperComponent = () => {

  const { courseMappingId } = useParams();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState('courseOverview');
  const [buttonTitle, setButtonTitle] = useState('Save & Next');
  const [currentTitle, setCurrentTitle] = useState('New Course');
  const [isSaveNextEnabled, setIsSaveNextEnabled] = useState(false);
  const [isEditAction, setEditAction] = useState(false);
  const [tempCourseMappingId, setTempCourseMappingId] = useState(null);

  const { courseOverViewSaveStatus, curriculumSaveStatus, pricingSaveStatus } = useSelector(
    (state) => state.ClusterCourseSlice
  );
  const courseOverviewRef = useRef(null);
  const curriculumRef = useRef(null);
  const priceRef = useRef(null);

  useEffect(() => {
    courseMappingId ? setCurrentTitle('Edit Course') : setCurrentTitle('New Course');
    setEditAction(!!courseMappingId);
  }, [courseMappingId]);


  // useEffect(() => {
  //   if (!courseMappingId && !tempCourseMappingId) {
  //     navigate(`/StepperComponent`);
  //   };
  // }, [!courseMappingId, !tempCourseMappingId]);

  useEffect(() => {
    const subscription = formValidationSubjects[activeKey].subscribe(isValid => {
      setIsSaveNextEnabled(isValid);
    });
    return () => subscription.unsubscribe();
  }, [activeKey]);

  const callMethod = (ref, saveStatus, updateMethod, insertMethod) => {
    if (isEditAction) {
      ref.current[updateMethod]();
    } else {
      !saveStatus ? ref.current[updateMethod]() : ref.current[insertMethod]();
    }
  };

  const handleSaveAndNext = () => {
    switch (activeKey) {
      case 'courseOverview':
        callMethod(courseOverviewRef, courseOverViewSaveStatus, 'updateCourseOverview', 'insertCourseOverview');
        break;
      case 'curriculum':
        callMethod(curriculumRef, curriculumSaveStatus, 'updateCurriculum', 'insertCurriculum');
        break;
      case 'price':
        callMethod(priceRef, pricingSaveStatus, 'updatePrice', 'insertPrice');
        break;
      default:
        break;
    }
  };

  const handleSaveAndNextChanges = useCallback(() => {
    if (isSaveNextEnabled) {
      const nextKeyIndex = tabItems.findIndex(item => item.key === activeKey) + 1;
      if (nextKeyIndex < tabItems.length) {
        handleTabChange(tabItems[nextKeyIndex].key);
      }
    }
  }, [activeKey, isSaveNextEnabled]);

  const tabItems = [
    {
      key: 'courseOverview',
      label: 'Course Overview',
      component: <CourseOverview ref={courseOverviewRef} setTempCourseMappingId={setTempCourseMappingId} tempCourseMappingId={tempCourseMappingId} handleSaveAndNextChanges={handleSaveAndNextChanges} />,
    },
    {
      key: 'curriculum',
      label: 'Curriculum',
      component: <Curriculum ref={curriculumRef} tempCourseMappingId={tempCourseMappingId} handleSaveAndNextChanges={handleSaveAndNextChanges} />,
    },
    {
      key: 'price',
      label: 'Price',
      component: <Price ref={priceRef} tempCourseMappingId={tempCourseMappingId} handleSaveAndNextChanges={handleSaveAndNextChanges} />,
    },
  ];

  const handleTabChange = (key) => {
    setActiveKey(key);
    if (key === tabItems[tabItems.length - 1].key) {
      setButtonTitle('Save');
    } else {
      setButtonTitle('Save & Next');
    }
  };

  const handlePrevious = () => {
    const prevKeyIndex = tabItems.findIndex(item => item.key === activeKey) - 1;
    if (prevKeyIndex >= 0) {
      handleTabChange(tabItems[prevKeyIndex].key);
    }
  };

  return (
    <>
      <div className='header-container'>
        <div className='title-container'>
          <div className='arrow-icon' onClick={() => navigate(-1)}>
            <Image src={arrowIcon} preview={false} />
          </div>
          <p className='stepper-title'>{currentTitle}</p>
        </div>
        <div className='btn-container'>

          <Button className="primary-cancel-button custom-cancel-button "> Cancel</Button>

          {activeKey !== tabItems[0]?.key && (
            <Button className="primary-previous-button custom-previous-button br-4" onClick={handlePrevious} > Previous </Button>
          )}
          <Button type="primary" className={!isSaveNextEnabled ? 'disabled-button m-0' : 'primary-submit-button m-0'} disabled={!isSaveNextEnabled} onClick={handleSaveAndNext}>
            {buttonTitle}
          </Button>
        </div>
      </div>
      <div className='course-creation-main'>
        <Tabs activeKey={activeKey} onChange={handleTabChange}>
          {tabItems.map((item) => (
            <TabPane tab={item.label} key={item.key}>
              {item.component}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default StepperComponent;
