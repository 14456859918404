import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { Button, Drawer, Form, Image, Input, message, Select } from 'antd';
import moment from 'moment';
import DeleteImage from '../asset/image/deleteIcon.svg';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from './Table/tableComponent';
import { useFormik } from 'formik';
import { Option } from 'antd/lib/mentions';
import * as yup from 'yup';
import { getAllFeedBackData, updateFeedBack } from './reducers/TestMasterSlice';


const RaiseADispute = () => {
  let tableHeight = 320;
  const dispatch = useDispatch()

  const [currentData, setCurrentData] = useState({});
  const [paginationData, setPaginationData] = useState(0);
  const [visible, setVisible] = useState(false);
  const [limit, setLimit] = useState(50);
  const [filterData, setFilterData] = useState("")
  const { feedBackData, totalFeedBackCount, isTestMasterLoading } = useSelector((state) => state.TestMaster)
  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Question',
      dataIndex: 'questionText',
      width: "23%",
      key: 'questionText',
      render: (_, record) => {
        return (
          <div className='' dangerouslySetInnerHTML={{ __html: record.questionText }}>
          </div>
        )
      }
    },
    // {
    //   title: 'Subject',
    //   dataIndex: 'subjectName',
    //   key: 'subjectName',
    // },
    {
      title: 'FeedBack',
      dataIndex: 'feedBack',
      key: 'feedBack',
      width: "23%",
    },
    {
      title: 'Created By',
      dataIndex: 'createdUser',
      key: 'createdUser',
      width: "10%",
    },
    {
      title: 'User Email',
      dataIndex: 'createdEmail',
      key: 'createdEmail',
      width: "16%",
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: "12%",
      render: (_, record) => {
        return (
          <div>
            {
              record.createdAt != null ? <span>{moment(record.createdAt).format('DD MMM YYYY')}</span> : <span>-</span>
            }
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: "10%",
      render: (_, record) => {
        return (
          <>
            {
              record.editShow == true ? <div className='table-action-link'>
                <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
              </div> : <>-</>
            }
          </>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(getAllFeedBackData())
  }, [])

  const formik = useFormik({
    initialValues: { status: '' },
    validationSchema: yup.object({
      status: yup.string().required("Status is Required")
    }),
    onSubmit: (values) => {
      let data = {
        id: currentData.id,
        data: values
      }
      setVisible(false)

      dispatch(updateFeedBack(data)).unwrap().then((res) => {
        message.success(res.message)
      })

    }
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const editData = (record) => {
    formik.resetForm()
    setVisible(true)
    setCurrentData(record)


  }
  const deleteData = (record) => {
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit

    }

  }

  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit

    }
    debounced(obj)
  }
  const debounced = useDebouncedCallback((obj) => {
  }, 1000)

  const drawerCloser = () => {
    setVisible(false)
  }

  return (
    <div className='raise_a_dispute_page'>
      <div className='header'>
        <h3 className='primary-header'>Raise A Dispute</h3>


        <div className="golbalSearch" >
          <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />

        </div>
      </div>
      <div className='all-table-component'>


        <TableComponent dataSource={feedBackData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={totalFeedBackCount} currentPage={paginationData + 1} loadingStatus={isTestMasterLoading} limit={limit} />

      </div>
      <Drawer title="Raise A Dispute Data" onClose={() => setVisible(false)} width="518px" closable={false} closeIcon={false} placement="right" open={visible} footer={(
        <div className='footer'>
          <div className='footer-button'>
            <Button className='primary-cancel-button' onClick={drawerCloser} >Cancel</Button>

            <Button className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} type="primary" disabled={!formik.isValid || !formik.dirty} onClick={formik.handleSubmit} >Submit </Button>
          </div>
        </div>
      )}>
        <div className='basic-form'>
          <>
            <Form>
              <div className='form-control' >
                <label htmlFor='status'>
                  Status <span style={{ color: 'red' }}>*</span>
                </label>
                <div>
                  <Select id="status" name="status" placeholder="Select Option" onChange={(e) => formik.setFieldValue('status', e)} onBlur={formik.handleBlur} value={formik.values.status}   >
                    <Option value={2} key={1} >Action Taken</Option>
                    <Option value={3} key={2} >Not a bug</Option>
                  </Select>
                  {formik.touched.status && formik.errors.status ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.status}</div> : null}
                </div>
              </div>

            </Form>
          </>
        </div>
      </Drawer>

    </div>
  )
}

export default RaiseADispute