import { Table } from 'antd';
import React, { useState } from 'react'

const TableComponent = ({ dataSource, columns, rowSelection, tableOnChange,tableHeight,totalData ,currentPage,loadingStatus ,limit,isCheck }) => {
  return (
    <div className='table-component' >
      <Table  rowSelection={ isCheck ? rowSelection :null}   onChange={tableOnChange} pagination={{
        total:totalData,
        showSizeChanger: true,
        showTotal: ((total) => `Total ${total} items `),
        current: currentPage,
        pageSize: limit,
      }} 
      scroll={{
        y: `calc(100vh - ${tableHeight}px)`,
      }}
      style={{borderRadius:6}}
      loading={loadingStatus}
       dataSource={dataSource} columns={columns} rowKey="id" />
    </div>
  )
}
export default TableComponent