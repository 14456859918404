import React, { useState, useEffect, useRef } from 'react';
import { Col, Divider, Image, Row, Tabs } from 'antd';
import edit from '../asset/image/edit.svg'
import {getinstitutionMasterTable,getInstitutionById}  from './reducers/institutionMasterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loading from './Loading';

const IndividualInstituteDetails = () => {
  const { instituteTableData , isLoading, instituteDataById} = useSelector((state) => (state.InstitutionSlice))
  const params = useParams()
  const dispatch = useDispatch()

    useEffect(() => {
   
        dispatch(getInstitutionById(params.id));
      }, [])
      console.log('ress',instituteDataById)
    return(
        <>
        { isLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> :
            <div>
        <div className='individual-details-content'>
            <div className='individual-details-header'>
                <p className='basic-details'><span>Basic Details</span></p>
              
            </div>
            <div className='individual-details-body'>
            <Row>
              <Col className='personal-content' span={6}>Institution Name :</Col>
              <Col className='personal-answer' style={{textTransform:'capitalize'}} span={18}>{instituteDataById.name}</Col>
              <Col className='personal-content' span={6}>Email ID :</Col>
              <Col className='personal-answer' span={18}>{instituteDataById.email}</Col>
              <Col className='personal-content' span={6}>Password :</Col>
              <Col className='personal-answer' span={18}>*************</Col>
              <Col className='personal-content' span={6}>Phone no :</Col>
              <Col className='personal-answer' span={18}>{instituteDataById.phoneNumber}</Col>
              
              <Col className='personal-content' span={6}>Address :</Col>
              <Col className='personal-answer' span={18}> {instituteDataById.address} </Col>
            </Row>
            </div>
        </div>
        <div className='individual-details-content'>
            <div className='individual-details-header'>
                <p className='basic-details'><span>Contact person Details</span></p>
            </div>
            <div className='individual-details-body'>
            <Row>
              <Col className='personal-content' span={6}>Contact person :   </Col>
              <Col className='personal-answer' style={{textTransform:'capitalize'}} span={18}>{instituteDataById.contactName}</Col>
              <Col className='personal-content' span={6}>Email ID :</Col>
              <Col className='personal-answer' span={18}>{instituteDataById.contactEmail}</Col>
              <Col className='personal-content' span={6}>Phone no :</Col>
              <Col className='personal-answer' span={18}>{instituteDataById.contactNumber}</Col>
            </Row>
            </div>

        </div>
        </div>
        }
        
        </>
    )
}
export default IndividualInstituteDetails