import React, { useState, useEffect, useRef } from 'react';
import { Col, Divider, Image, Row, Tabs } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import translate from '../asset/image/translate.svg'
const TestResultsList = () => {
    const navigate = useNavigate()
    function navigateToResult (){
        navigate('/CoursePage/getAllStudentsResults')
    }
    const item=[
        {
            group:'Tnpsc',
            name:'TNPSC Group II & II A',
            questions:'200',
            marks:'300',
            mins:'180'
        },
        {
            group:'Tnpsc',
            name:'TNPSC Group II & II A',
            questions:'200',
            marks:'300',
            mins:'180'
        },
        {
            group:'Tnpsc',
            name:'TNPSC Group II & II A',
            questions:'200',
            marks:'300',
            mins:'180'
        }
    ]
    return(
        <>
        
        {
        item.map((data, index) => {
            return(
                <div className='test-result-container' onClick={()=>navigateToResult()}>
                <div className='test-details'>
                    <p className='test-date'><span>27 March 2023</span></p>
                    <p className='test-name'><span>UPSC Services Full Test 1</span></p>
                      <div className='split-container'>
                        <p className='total-questions'> Questions</p> 
                        <Divider style={{background:'#7A8B94',marginTop:3}} type='vertical'></Divider>
                        <p className='total-questions'> Marks</p> 
                        <Divider style={{background:'#7A8B94',marginTop:3}} type='vertical'></Divider>
                        <p className='total-questions'>Mins</p> 
                      </div>
                      <div className='language-content'>
                        <div className='language-image'><Image src={translate}></Image></div>
                        <p className='language-text'><span>English,Tamil</span></p>
                      </div>
            </div>
            <div className='attempted-students-details'>
                <p className='attempted-text'><span>Attempted Students </span></p>
                <p className='total-attempted-students'><span>35/40</span></p>
                <p className='attempted-text'><span>explore leaderboard</span></p>
            </div>
        </div>
            )
        })
        }
            
        
        
        </>
    )
}
export default TestResultsList;