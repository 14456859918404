import React from 'react';
import { Upload, Button } from 'antd';
import "../../components/styles/FileUpload.scss";

const FileUpload = ({ filehandleChange, handleFileRemove, file, allowCount, customButton }) => {
    return (
        <Upload
            className="ImageUpload"
            onChange={info => {
                if (info.file.status !== 'removed') {
                    filehandleChange(info);
                }
            }}
            beforeUpload={() => false}
            fileList={file}
            maxCount={allowCount}
            onRemove={handleFileRemove}
        >
            {customButton}
        </Upload>
    );
};

export default FileUpload;
