import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import axios from "axios";


// Add Admin Master Data
export const addAdminData = createAsyncThunk('AdminMaster/addData', async (data, thunk) => {
 try {
  let payloadData = []
  const insertData = await axios.post('user/admin/create', data.payload).then(async(res) => {
   if (res.data.createAdminUser.status) {
    await thunk.dispatch(getAllAdminData(data.obj))
    message.success(res.data.createAdminUser.message)
    return res.data.createAdminUser.data
   } else {
    message.error(res.data.createAdminUser.message)
   }
  })
  return payloadData
 } catch (error) {
    message.error(error.response.data.createAdminUser.message)
 }


})
// Get All Admin Data
export const getAllAdminData = createAsyncThunk('AdminMaster/getAllData', async (payload) => {
 try {
  let getAllData = await axios.post('/user/admin/getUserAll',payload).then((res) => {
   if (res.data.success) {
    return res.data.data
   }
  })
  return getAllData

 } catch (error) {
 }
})


// Update By id 
export const updateAdminDataById = createAsyncThunk('AdminMaster/updateAdminDataById', async (data,thunk) => {
 try {

  let updateData = await axios.put('/user/admin/updateUser/' + data.payload.id, data.payload.data).then(async(res) => {
   if (res.data.adminUserUpdate.status) {
    await thunk.dispatch(getAllAdminData(data.obj))
    message.success(res.data.adminUserUpdate.message)
    return res.data.adminUserUpdate.data

   }else{
    message.error(res.data.adminUserUpdate.message)

   }
  })
  return updateData

 } catch (error) {
 }
})

// Deleted By id 
export const deleteAdminDataById = createAsyncThunk('AdminMaster/deleteAdminDataById', async (id,thunk) => {
 try {
  let updatedId =  id
  let updateData = await axios.delete('/user/admin/deleteUser/'+id).then((res) => {
   if (res.data.success) {
    message.success(res.data.message)
    return res.data.data
   }
   else{
    message.error(res.data.message)
   }
  })
  return updatedId

 } catch (error) {
 }
})


// get By id 
export const getAdminDataById = createAsyncThunk('AdminMaster/getAdminDataById', async (data,thunk) => {
   try {
  
    let getData = await axios.get('/user/admin/getuser/' + data,).then(async(res) => {
      if(res.data.success){
         return res.data.data
      }
    })
    return getData
  
   } catch (error) {
   }

})

const initialState = {
 adminMaster: [],
 isLoading: false,
 totalDataCount:0
}

const AdminSlice = createSlice({
 name: 'AdminMaster',
 initialState,
 reducers: {},
 extraReducers: {
  [addAdminData.pending]: (state) => {
   state.isLoading = true
  },
  [addAdminData.fulfilled]: (state, action) => {
   state.isLoading = false

  },
  [addAdminData.rejected]: (state) => {
   state.isLoading = false

  },
  [getAllAdminData.pending]: (state) => {
   state.isLoading = true

  },
  [getAllAdminData.fulfilled]: (state, action) => {
   state.isLoading = false;

   state.adminMaster = action.payload?.getUserData
   state.totalDataCount = action.payload?.getUserCount?.userCount

  },
  [getAllAdminData.rejected]: (state) => {
   state.isLoading = false;

  },
  [updateAdminDataById.pending]: (state) => {
   state.isLoading = true

  },
  [updateAdminDataById.fulfilled]: (state, action) => {

  },
  [updateAdminDataById.rejected]: (state) => {
   state.isLoading = false;
  },
  [deleteAdminDataById.pending]: (state) => {
   state.isLoading = true
  },
  [deleteAdminDataById.fulfilled]: (state, action) => {
   state.isLoading = false;
   state.adminMaster = state.adminMaster.filter((e)=>e.id !== action.payload)
  },
  [deleteAdminDataById.rejected]: (state) => {
   state.isLoading = false;

  },
  [getAdminDataById.pending]:(state,action) =>{
   state.isLoading = true

  },
  [getAdminDataById.fulfilled]:(state,action) =>{
   state.isLoading = false

},
[getAdminDataById.rejected]:(state,action) =>{
   state.isLoading = false

},
 }
})

export default AdminSlice.reducer