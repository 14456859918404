import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from "moment";

// get subscription master table

export const getSubscriptionMasterTable = createAsyncThunk(
  'SubscriptionMaster/getSubscriptionMasterTable', async (payload) => {
    let tabledata = []
    let tableData = await axios.post('subscription/getSubscription',payload)
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)


// insert subscription master table

export const insertSubscriptionMasterTable = createAsyncThunk(
  'SubscriptionMaster/insertSubscriptionMasterTable', async (payload) => {
    let insertedData = [];
    let success = '';
    let InsertMessage = '';
    payload = {
      name: payload.name,
      noOfCustomTest: Number(payload.noOfCustomTest),
      noOfTest: Number(payload.noOfTest),
      price: Number(payload.price)
    }
    let insertData = await axios.post('subscription/createSubscription', payload)
      .then((res) => {
        insertedData = res.data.data.data;
        success = res.data.data.success;
        InsertMessage = res.data.data.message
      })
      .catch((error) => {
        if(error.response.data.data.success==false){
          message.error(error.response.data.data.message)
        }
      })
    return {
      insertedData: insertedData,
      success: success,
      InsertMessage: InsertMessage
    };
  }
)


// edit subscription master table

export const editSubscriptionMasterTable=createAsyncThunk(
  'ChapterMaster/editSubscriptionMasterTable',async (payload)=>{
    let editedData=[]
    let updateMessage='';
    let success=''
    payload.price=Number(payload.price)
    payload.noOfCustomTest=Number(payload.noOfCustomTest)
    payload.noOfTest=Number(payload.noOfTest)
    let editData=await axios.put('subscription/updateSubscription/'+payload.id,payload)
    .then((res)=>{
      editedData=res.data.updateSubscription.data
      updateMessage=res.data.updateSubscription.message
      success=res.data.updateSubscription.success

    })
    .catch((error)=>{
      let err=error.response.data.updateSubscription.message
      message.error(err)
    })
    return {
      editedData:editedData,
      message:updateMessage,
      success:success
    };
  }
)

// delete subscription master

export const deleteSubscriptionMasterTable=createAsyncThunk(
  'SubjectMaster/deleteExamMasterTable',async (payload)=>{
    let deleteMessage=''
    let success=''
    let deleteData=await axios.delete('subscription/deleteSubscription/'+payload)
    .then((res)=>{
      deleteMessage=res.data.deleteSubscription.message;
      success=res.data.deleteSubscription.success
    })
    .catch((error)=>{
    })
    return {
      deleteMessage:deleteMessage,
      payload:payload,
      success:success
    };
  }
)


const initialState = {
  SubscriptionMasterTableData: [],
  isLoading:false,
  totalDataCount:0
}
const SubscriptionMasterSlice = createSlice({
  name: 'SubscriptionMaster',
  initialState,
  extraReducers: {
    [getSubscriptionMasterTable.pending]:(state)=>{
      state.isLoading=true
    },
    [getSubscriptionMasterTable.fulfilled]: (state, actions) => {
      state.isLoading=false
      state.SubscriptionMasterTableData = actions.payload.getSubscription;
      state.SubscriptionMasterTableData.map((item) => {
        item.createdAt = moment(item.createdAt).format('DD MMM YYYY')
      })
    state.totalDataCount = +actions.payload?.getSubscriptionCount?.subscriptionCount
    },
    [getSubscriptionMasterTable.rejected]:(state)=>{
      state.isLoading=false
    },
    [insertSubscriptionMasterTable.pending]:(state)=>{
      state.isLoading=true
    },
    [insertSubscriptionMasterTable.fulfilled]: (state, actions) => {
      state.isLoading=false
      let tableData = actions.payload.insertedData[0]
      if (actions.payload.success == true) {
        tableData.createdAt = moment(tableData.createdAt).format('DD MMM YYYY')
        message.success(actions.payload.InsertMessage)
        state.SubscriptionMasterTableData = [tableData, ...state.SubscriptionMasterTableData]
      }
      
      state.isLoading=false
    
    },
    [insertSubscriptionMasterTable.rejected]:(state)=>{
      state.isLoading=false
    },
    [editSubscriptionMasterTable.pending]:(state)=>{
      state.isLoading=true
    },
    [editSubscriptionMasterTable.fulfilled]:(state,actions)=>{
      let tableData=actions.payload.editedData[0]
      if(actions.payload.success==true){
        state.SubscriptionMasterTableData.map((item)=>{
          if(tableData.id==item.id){
            item.name=tableData.name;
            item.noOfCustomTest=tableData.noOfCustomTest;
            item.noOfTest=tableData.noOfTest;
            item.price=tableData.price;
            item.createdAt=moment(tableData.createdAt).format('DD MMM YYYY')
            message.success(actions.payload.message)
          }
        })
      }
      state.isLoading=false
    },
    [editSubscriptionMasterTable.rejected]:(state)=>{
      state.isLoading=false
    },
    [deleteSubscriptionMasterTable.pending]:(state)=>{
      state.isLoading=true
    },
    [deleteSubscriptionMasterTable.fulfilled]:(state,actions)=>{
       let deleteData=actions.payload;
       let deletemessage=actions.payload.deleteMessage
       if(deleteData.success==true){
        let SubscriptionMasterTableData=state.SubscriptionMasterTableData
        state.SubscriptionMasterTableData=SubscriptionMasterTableData.filter((item)=>item.id!=deleteData.payload)
        message.success(deletemessage)
       }
       state.isLoading=false
     },
     [deleteSubscriptionMasterTable.rejected]:(state)=>{
      state.isLoading=false
    },

  }
})

export default SubscriptionMasterSlice.reducer;