import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";


export const getCareerDetails = createAsyncThunk('masters/getCareerDetails', async (payload) => {
    try {
        let getAllData = await axios.post('/masters/getCareerDetails',payload).then((res) => {
            if (res.data) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
        console.log(error, 'err');
    }
})


const initialState = {
    isLoading: false,
    CareerDetailsData: [],
    CareerDetailsDataCount: 0
};
const CareerSlice = createSlice({
    name: "CareerSlice",
    initialState,
    extraReducers: {
        [getCareerDetails.pending]: (state) => {
            state.isLoading = true

        },
        [getCareerDetails.fulfilled]: (state, actions) => {
            state.isLoading = false
            state.CareerDetailsData = actions.payload.getCareerData
            state.CareerDetailsDataCount = actions.payload.getCareerCount.careerCount
        },
        [getCareerDetails.rejected]: (state) => {
            state.isLoading = false

        },
    }
})

export default CareerSlice.reducer;
