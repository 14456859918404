import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from "antd";
import axios from "axios";

export const insertCurriculumPricingDetails = createAsyncThunk(
    'Pricing/insertCurriculumPricingDetails', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.post('coachmi/addCurriculumPricing', payload)
            .then(async (res) => {
                const { success, message } = res?.data;
                if (success) {
                    resMessage = message;
                    resSuccess = success;
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            });
        return { resSuccess, resMessage };
    }
);

export const updateCurriculumPricingDetails = createAsyncThunk(
    'Pricing/updateCurriculumPricingDetails', async (payload, thunk) => {
        let resSuccess = false;
        let resMessage = '';
        await axios.put('coachmi/updateCurriculumPricing/' + payload?.data?.courseMappingId, payload.data)
            .then(async (res) => {
                const { success, message } = res?.data;
                if (success) {
                    resMessage = message;
                    resSuccess = success;
                };
            })
            .catch((error) => {
                resMessage = error?.response?.data?.message
            })
        return { resSuccess, resMessage };
    }
);

export const getPricingDetailsById = createAsyncThunk(
    'Pricing/getPricingDetailsById', async (payload, thunk) => {
        try {
            let pricingDetails = await axios.get(`/coachmi/getCurriculumPricingDetailsById/${payload}`)
                .then((res) => {
                    const { data, status } = res;
                    if (status) {
                        return data?.data;
                    };
                })
                .catch((error) => { })
            return pricingDetails
        } catch (error) {
            message.error(error)
        }
    }
);

const initialState = {
    isLoading: false,
    priceplanDetails: {}
};

const PricingSlice = createSlice({
    name: 'Pricing',
    initialState,
    reducers: {
        removeCurriculumPrincingDetails: (state, action) => {
            state.priceplanDetails = {};
        },
    },
    extraReducers: {
        [insertCurriculumPricingDetails.pending]: (state, action) => {
            state.isLoading = true
        },
        [insertCurriculumPricingDetails.fulfilled]: (state, action) => {
            state.isLoading = false
        },
        [insertCurriculumPricingDetails.rejected]: (state, action) => {
            state.isLoading = false
        },
        [updateCurriculumPricingDetails.pending]: (state, action) => {
            state.isLoading = true
        },
        [updateCurriculumPricingDetails.fulfilled]: (state, action) => {
            state.isLoading = false
        },
        [updateCurriculumPricingDetails.rejected]: (state, action) => {
            state.isLoading = false
        },
        [getPricingDetailsById.pending]: (state) => {
            state.isLoading = true
        },
        [getPricingDetailsById.fulfilled]: (state, actions) => {
            state.isLoading = false
            state.priceplanDetails = actions?.payload
        },
        [getPricingDetailsById.rejected]: (state) => {
            state.isLoading = false
        },
    }
});

export const { removeCurriculumPrincingDetails } = PricingSlice.actions;

export default PricingSlice.reducer