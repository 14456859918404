import { React } from 'react';
import coursePriceIcon from '../../asset/image/CoursePricing.svg'
import topicImage from '../../asset/image/topicImage.svg';
import subTopicImg from '../../asset/image/subTopicimg.svg';
import classImg from '../../asset/image/classImage.svg';
import contentImg from '../../asset/image/contentImg.svg';
import { Button, Input, Drawer, Form, Select, Switch, Tooltip, Image } from "antd";
import PricingList from "./PricingList.js";
import { EQuickActionsType } from "../../utils/Variable.js";
import { hasErrorsInPriceAndFrequencyHelper } from "../../utils/HelperFunction.js";
import '../../components/styles/pricing.scss';

const PricingPlanDrawer = ({ 
    visible, currentTitle, formik, isCoursePricing = true, showHeader = false, 
    isEditAction = true, currentSelectedType = '', frequencyData = [], prices = [],
    pricingName = '', pricingChildsDetails = '', frequencyMap, drawerCloser, addPrice, 
    pricingSwitchChange, savePricing, deletePricing 
}) => {

    const getImageSrc = (type) => {
        switch (type) {
            case EQuickActionsType.topic:
                return topicImage;
            case EQuickActionsType.subTopic:
                return subTopicImg;
            case EQuickActionsType.class:
                return classImg;
            case EQuickActionsType.content:
                return contentImg;
            case EQuickActionsType.course:
                return coursePriceIcon;
            default:
                return null;
        }
    };

    const imageSrc = getImageSrc(currentSelectedType);

    return (
        <>

            <Drawer
                title={currentTitle}
                onClose={drawerCloser}
                width="518px"
                closable={false}
                closeIcon={false}
                placement="right"
                open={visible}
                footer={
                    <div className="footer">
                        <div className="footer-button coachmi-footer">
                            <Button
                                className="primary-cancel-button coachmi-primary-cancel-button"
                                onClick={drawerCloser}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={`${!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} coachmi-primary-submit-button`}
                                type="primary"
                                disabled={!formik?.isValid || !formik?.dirty}
                                onClick={savePricing}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                }
            >
                <Form>
                    <div className='price-form'>
                        {
                            showHeader && imageSrc && (
                                <div className='price-form-head'>
                                    <div className={`price-form-card ${EQuickActionsType.content && currentSelectedType ? 'custom-form-card' : ''}`}>
                                        <div>
                                            {imageSrc && (
                                                <Image src={imageSrc} preview={false} alt="Image Icon"
                                                    className="settingIcon-icon"
                                                />
                                            )}
                                        </div>
                                        <div className='price-form-card-head'>
                                            <p className='title'> {pricingName} </p>
                                            <p className='price-form-card-content'>{pricingChildsDetails}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className='price-plan' >
                            <p>
                                Subscription Plan
                            </p>
                        </div>

                        <div className="form-control">
                            <label htmlFor="price">
                                Actual price<span style={{ color: "red" }}> *</span>
                            </label>
                            <Input
                                type="text"
                                id="actualPrice"
                                name="actualPrice"
                                placeholder="Enter a Actual price"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.actualPrice}
                            />
                            {formik?.touched?.actualPrice && formik?.errors?.actualPrice && (
                                <div className="errorMessage" style={{ color: "red" }}>
                                    {formik.errors.actualPrice}
                                </div>
                            )}
                        </div>

                        <div className="form-control">
                            <label htmlFor="price">
                                Price<span style={{ color: "red" }}> *</span>
                            </label>
                            <Input
                                type="text"
                                id="price"
                                name="price"
                                placeholder="Enter a price"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.price}
                            />
                            {formik?.touched?.price && formik?.errors?.price && (
                                <div className="errorMessage" style={{ color: "red" }}>
                                    {formik.errors.price}
                                </div>
                            )}
                        </div>

                        <div className='form-control'>
                            <label htmlFor='frequencyId'>Frequency
                                <span style={{ color: 'red' }}> *</span>
                            </label>
                            <div>
                                <Select
                                    id="frequencyId"
                                    placeholder="Select a Frequency"
                                    name="frequencyId"
                                    style={{ width: "100%" }}
                                    onChange={(value) => formik.setFieldValue('frequencyId', value)}
                                    onBlur={formik.handleBlur}
                                    value={formik?.values?.frequencyId}
                                >
                                    {frequencyData.map((data) => (
                                        <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>
                                    ))}
                                </Select>
                            </div>
                            {formik?.touched?.frequencyId && formik?.errors?.frequencyId && (
                                <div className='errorMessage' style={{ color: 'red' }}>
                                    {formik?.errors?.frequencyId}
                                </div>
                            )}
                        </div>

                        <div className='form-control' >
                            {
                                isCoursePricing ?
                                    <div className='price-form-add'>
                                        <p>
                                            <span
                                                className="add-pricing"
                                                onClick={() => !hasErrorsInPriceAndFrequencyHelper(isEditAction, formik) && addPrice(formik.values)}
                                                style={{ cursor: hasErrorsInPriceAndFrequencyHelper(isEditAction, formik) ? 'not-allowed' : 'pointer' }}
                                            >
                                                Add Pricing
                                            </span>
                                        </p>
                                    </div>
                                    :
                                    <div className='price-free'>
                                        <Switch
                                            id="isFree"
                                            name="isFree"
                                            defaultChecked={true}
                                            onChange={pricingSwitchChange}
                                            value={formik?.values?.isFree}
                                        />
                                        <p>Include a free trial</p>
                                    </div>
                            }
                        </div>

                        <div>
                            <PricingList
                                prices={prices}
                                deletePricing={deletePricing}
                                frequencyMap={frequencyMap}
                            />
                        </div>
                    </div>
                </Form>
            </Drawer>
        </>
    )

};

export default PricingPlanDrawer;
