import { Button, Image } from 'antd'
import React from 'react'
import PageNotFound from '../asset/image/404.svg';
import { useNavigate } from 'react-router-dom';

export const NoMatchRoute = () => {
  const navigate = useNavigate()

  return (
    <div className='pageNotFound-wrapper'>
    <div className="pageNotFound-container">
        
        <Image src={PageNotFound} preview={false}></Image>
        <div className='goToHome'>
        <Button onClick={()=>navigate('/Dashboard')}>Go to Home Page</Button>
        </div>
    </div>
    </div>
  )
}
