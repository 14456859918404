import { Block } from '@material-ui/icons';
import { Button, Col, Image, Input, message, Row } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { setPassword } from './reducers/loginSlice';
import eduTechImage from '../asset/image/eduTech.svg'
import groupone from "../asset/image/groupimg.svg"; 
import Loading from './Loading';

const SetPassword = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const navigate =  useNavigate()
    const location =  useLocation()
    const {isLoading} = useSelector((state)=>state.Loginmaster)
    const formik = useFormik({
        initialValues:{password:'',confirmPassword:''},
        validationSchema: yup.object({
            password:yup.string().required("Password is Required"),
            confirmPassword:yup.string().required("Confirm Password is Required").oneOf([yup.ref("password"), null], "Passwords doesn't match")
        }),
        onSubmit:(values)=>{
            let payload = {}
            payload['token']= params?.token
            payload['data'] =  values

            dispatch(setPassword(payload)).unwrap().then((res)=>{
                if(res.success){
                    message.success(res.message)
                    navigate("/login")
                }
            })
            

        }
    })

  return (
    <>
        <div className='setPassword-container'>
    <Row>
        <Col span={12}>
            <div className='logo-container'>
            <div className="logoimage">
              <div className="image-container">
                <Image preview={false} src={eduTechImage}></Image>
                <Image preview={false} src={groupone} />
              </div>
            </div>

            </div>
        </Col>
        <Col span={12}>
            <div className='password-content-wrapper'>

                <div className='form-container'>
                    <div className='form-wrapper'>
                        <div>
                            <h2 className='header'>Reset Password</h2>
                        </div>
                        <div className='form-control'>
                        <div>
                        <label htmlFor='password'>
                            Password
                        </label>
                        </div>
                        <div>
                            <Input.Password id='password' type="text" placeholder="Enter a Password" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.password} />
                            {formik.touched.password && formik.errors.password ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.password}</div> : null}
                        </div>
                        </div>
                        <div className='form-control'>
                        <div>
                        <label htmlFor='confirmPassword'>
                            Confirm Password
                        </label>
                        </div>
                        <div>
                            <Input.Password id='confirmPassword' type="text" placeholder="Enter a Confirm Password" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.confirmPassword} />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.confirmPassword}</div> : null}
                        </div>
                        </div>
                        <div className='form-control'>
                        <Button  type="primary" className={ !formik.isValid ?'disabled-button remove-margin':'primary-submit-button remove-margin'} disabled={!formik.isValid} onClick={formik.handleSubmit} >Submit </Button> 
                        {/* <Button className={!formik.isValid || !formik.dirty ?'disabled-button':'primary-submit-button'} type="primary" disabled={!formik.dirty || !formik.isValid} onClick={formik.handleSubmit} >Submit </Button>  */}
                        </div>
                        <div>
                         <p  className='navigate'> Back To<a onClick={()=>navigate("/login")}> Login </a></p>
                        </div>
                    </div>


                </div>

            </div>
        </Col>
    </Row>
    </div>  

        {
            isLoading ? <div className='loading-container'>
                <Loading></Loading>
                 </div> : null
            }
    </>

  )
}

export default SetPassword