import React, { useState, useEffect } from 'react';
import { Input, Select, Modal, Checkbox, Tooltip } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { useDebouncedCallback } from 'use-debounce';
import { filteredDataHelper } from "../../utils/HelperFunction";
import { useSelector } from 'react-redux';
import '../../components/styles/curriculam.scss';

const TestMiModal = ({ visible, onOk, onCancel, testMiData, handleCheckboxChange, selectedCards }) => {

    const [tempTestMiData, setTempTestMiData] = useState([]);
    const [examDetails, setExamDetails] = useState([]);
    const [examGroupDetails, setExamGroupDetails] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedExam, setSelectedExam] = useState('');

    const { getTestSeriesExamFilterData } = useSelector((state) => state.CurriculumSlice);

    useEffect(() => {
        setTempTestMiData(testMiData);
    }, [testMiData]);

    useEffect(() => {
        if (Array.isArray(getTestSeriesExamFilterData) && getTestSeriesExamFilterData.length > 0) {
            setExamGroupDetails(getTestSeriesExamFilterData);
            setSelectedGroup(getTestSeriesExamFilterData?.[0]?.id);
            setExamDetails(getTestSeriesExamFilterData?.[0]?.examOptions);
            setSelectedExam(getTestSeriesExamFilterData?.[0]?.examOptions[0].id);
        }
    }, [getTestSeriesExamFilterData]);

    const handleChangeTestMiExamGroup = (id) => {
        setSelectedGroup(id);
        const selectedGroupDetails = examGroupDetails.find(group => group.id === id);
        setExamDetails(selectedGroupDetails ? selectedGroupDetails.examOptions : []);
        setSelectedExam('');
    };

    const handleChangeTestMiExam = (id) => {
        setSelectedExam(id);
    };

    const applyFilters = () => {
        let filteredData = testMiData;

        // Apply search filter
        if (searchInput.trim() !== '') {
            const keysToSearch = ['testName', 'examGroupName', 'numberOfQuestions', 'timeLimit', 'marks'];
            filteredData = filteredDataHelper(filteredData, keysToSearch, searchInput);
        };

        // Apply group filter
        if (selectedGroup) {
            filteredData = filteredData.filter(item => item.examGroupId == selectedGroup);
        };

        // Apply exam filter
        if (selectedExam) {
            filteredData = filteredData.filter(item => item.examId == selectedExam);
        };


        setTempTestMiData(filteredData);
    };

    useEffect(() => {
        applyFilters();
    }, [searchInput, selectedExam, selectedGroup]);

    const onSearchTestMi = useDebouncedCallback((value) => {
        setSearchInput(value);
    }, 1000);

    const selectedCardIds = new Set(selectedCards?.map((item) => item?.id));

    return (
        <Modal className='testmi-modal' title="Testmi" open={visible} onOk={onOk} onCancel={onCancel}>
            <div className='heading-testmi-select'>
                <div className='search'>
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
                        style={{ width: 237, height: 32 }}
                        onChange={(event) => onSearchTestMi(event.target.value)}
                    />
                </div>
                <div className='filter'>
                    <div className='filter-select'>
                        <p className='label-name'>Group</p>
                        <Select
                            className='filter-drop-down'
                            value={selectedGroup}
                            onChange={handleChangeTestMiExamGroup}
                            placeholder="Select Group"
                            allowClear
                        >
                            {(Array.isArray(examGroupDetails) ? examGroupDetails : []).map((data, index) => (
                                <Select.Option key={index} value={data.id}>
                                    <span className='test-series-filter capitalize'>{data?.value}</span>
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className='filter-select'>
                        <p className='label-name'>Exam</p>
                        <Select
                            className='filter-drop-down'
                            value={selectedExam}
                            onChange={handleChangeTestMiExam}
                            placeholder="Select Exam"
                            allowClear
                        >
                            {(Array.isArray(examDetails) ? examDetails : []).map((data, index) => (
                                <Select.Option key={index} value={data.id}>
                                    <span className='test-series-filter capitalize'>{data?.value}</span>
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>

            <div className='card-wrapper'>
                <div className='card-testmi'>
                    {tempTestMiData?.map((e, index) => {
                        const isSelected = selectedCardIds.has(e?.id);
                        const { examGroupName = '-', testName = '-', noOfQuestion = '', marks = '', timeLimit = '' } = e;

                        return (
                            <div
                                key={index}
                                className={`card ${isSelected ? 'checked' : ''}`}
                                onClick={() => handleCheckboxChange(e, !isSelected)}
                            >
                                <div className='title-name'>
                                    <div className='names capitalize'>
                                        <Tooltip placement="top" title={examGroupName}>
                                            <p className='test-group-name exam-name ellipsis-text'>{examGroupName || '-'}</p>
                                        </Tooltip>
                                    </div>
                                    <Checkbox checked={isSelected} />
                                </div>
                                <Tooltip placement="top" title={testName}>
                                    <p className='test-name capitalize'>{testName || '-'}</p>
                                </Tooltip>
                                <div className='details-test'>
                                    <p className='number-question'>{noOfQuestion} Qs.</p>
                                    <p className='mark'>{marks} Marks</p>
                                    <p className='min'>{timeLimit} mins</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

        </Modal>
    );
};

export default TestMiModal;