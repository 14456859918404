import React, { useState } from 'react';
// import { Document, Page, Text, StyleSheet, View, PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTestQuestions, getTestQuestionsOnly } from './reducers/institutionMasterSlice';
import Loading from './Loading';

const PrintQuestionAndAnswerPDF = ({ data, sendDataToParent }) => {

  const dispatch = useDispatch()
  const [pdfData, setpdfData] = useState([]);

  const { isLoading } = useSelector((state) => state.InstitutionSlice)

  const handleDownloadQuestionsClick = async () => {
    let payload = {
      data: data
    }
    await dispatch(getTestQuestionsOnly(payload)).unwrap().then(async (res) => {
      setpdfData(res.data.data)

      const buffer = await res.data;

      function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      }
      const base64 = arrayBufferToBase64(buffer.data)
      // const blob = new Blob([buffer], { type: 'application/pdf' })
      // const pdfUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = 'data:application/pdf;base64,' + base64;
      a.download = 'questions.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setTimeout(() => {
        isDownloaded()
        sendDataToParent(true)
      }, 5000)
    })
  }

  const handleDownloadQuestionsAnswerClick = async () => {
    let payload = {
      data: data
    }
    await dispatch(getTestQuestions(payload)).unwrap().then(async (res) => {
      setpdfData(res.data.data)

      const buffer = await res.data;

      function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      }
      const base64 = arrayBufferToBase64(buffer.data)
      const blob = new Blob([buffer], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = 'data:application/pdf;base64,' + base64;
      a.download = 'questions-answers.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setTimeout(() => {
        isDownloaded()
        sendDataToParent(true)
      }, 5000)
    })
  }

  const isDownloaded = () => {
    setpdfData([])
  }

  return (
    <>
      {
        isLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> :
          <>
            <div id="downloadPDFAll">
              <Button className='download-question' onClick={() => handleDownloadQuestionsClick()}>Download Questions</Button>
              <Button className='download-question-answer' onClick={() => handleDownloadQuestionsAnswerClick()}>Download Questions & Answers</Button>
            </div>
          </>
      }
    </>
  )
};

export default PrintQuestionAndAnswerPDF;
