
import { Progress } from 'antd'


const ProgressBar = ({ progress }) => {
    return (
        <>
            <Progress percent={Math.round(progress)} showInfo={false} />
        </>
    )
};

export default ProgressBar;