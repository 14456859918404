import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from "moment";

export const getAllAffairsData = createAsyncThunk(
  "currentAffairs/getAffairs",
  async (payload) => {
    try {
      let getAllData = await axios
        .post("currentAffairs/getAffairs", payload)
        .then((res) => {
          if (res.data.success) {
            return res.data.data;
          }
        });
      return getAllData;
    } catch (error) {
      message.error();
    }
  }
);

export const getAffairsById = createAsyncThunk(
  "currentAffairs/getAffairsById",
  async (payload, thunk) => {
    try {
      let getAffairs = await axios
        .get(`currentAffairs/getAffairsById/${payload}`)
        .then(async (res) => {
          if (res) {
            return res.data.data;
          }
        });
      return getAffairs;
    } catch (error) {
      message.error();
    }
  }
);

export const addCurrentAffairs = createAsyncThunk(
  "currentAffairs/addData",
  async (data, thunk) => {
    try {
      let payloadData = [];
      const insertData = await axios
        .post("currentAffairs/createAffairs", data.data)
        .then(async (res) => {
          if (res) {
            await thunk.dispatch(getAllAffairsData(data.obj));
            message.success(res.data.data.message);
            return res.data.createAdminUser.data;
          } else {
            message.error(res.data.createAdminUser.message);
          }
        });
      return payloadData;
    } catch (error) {
      message.error(error.response.data.createAdminUser.message);
    }
  }
);

export const updateCurrentAffairs = createAsyncThunk(
  "currentAffairs/updateCurrentAffairs",
  async (payload) => {
    try {
      let updateAffairs = axios
        .put("currentAffairs/updateAffairs/" + payload.id, payload)
        .then((res) => {
          if (res.data.updateAffairs.status) {
            message.success(res.data.updateAffairs.message);
            return res.data.updateAffairs;
          }
        });
      return updateAffairs;
    } catch (error) {}
  }
);

export const deleteCurrrentAffiars = createAsyncThunk(
  "currentAffairs/deleteCurrrentAffiars",
  async (payload) => {
    try {
      let deleteAffairs = axios
        .delete("currentAffairs/deleteAffairs/" + payload)
        .then((res) => {
          if (res.data.success) {
            message.success(res.data.data.message);
            return res.data;
          }
        });
      return deleteAffairs;
    } catch (error) {}
  }
);

export const uploadCurrentAffairsPdf = createAsyncThunk(
  "currentAffairs/uploadCurrentAffairsPdf",
  async (payload) => {
    try {
      let uploadAffairsPdf = axios
        .post("currentAffairs/uploadCurrentAffairsPdf", payload)
        .then((res) => {
          if (res.data.success) {
            message.success(res.data.data.message);
            return res.data;
          }
        });
      return uploadAffairsPdf;
    } catch (error) {}
  }
);

const initialState = {
  CurrentAffairs: [],
  isLoading: false,
  currentDataCount: 0,
  currentData: "",
};

const CurrentAffairs = createSlice({
  name: "CurrentAffiars",
  initialState,
  extraReducers: {
    [getAllAffairsData.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllAffairsData.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.currentData = actions.payload?.getAffairsData;
      state.currentDataCount = actions.payload.getAffairsCount.affairCount;
    },
    [getAllAffairsData.rejected]: (state) => {
      state.isLoading = false;
    },
    [addCurrentAffairs.pending]: (state) => {
      state.isLoading = true;
    },
    [addCurrentAffairs.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [addCurrentAffairs.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateCurrentAffairs.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCurrentAffairs.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [updateCurrentAffairs.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteCurrrentAffiars.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteCurrrentAffiars.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [deleteCurrrentAffiars.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAffairsById.pending]: (state) => {
      state.isLoading = true;
    },
    [getAffairsById.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [getAffairsById.rejected]: (state) => {
      state.isLoading = false;
    },
    [uploadCurrentAffairsPdf.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadCurrentAffairsPdf.fulfilled]: (state, actions) => {
      state.isLoading = false;
    },
    [uploadCurrentAffairsPdf.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});
export default CurrentAffairs.reducer;
