import React, { useState } from "react";
import { Col, Row, Spin, Tabs } from "antd";
import { Image, Input, Space, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from "@ant-design/icons";
import "../App.scss";
import { Button } from "antd";
import logoimage from "../asset/image/logoimg.png";
import groupone from "../asset/image/groupimg.svg";
import group from "../asset/image/centumApp.svg";
import eduTechImage from '../asset/image/eduTech.svg'
import { useDispatch, useSelector } from "react-redux";
import { getloginData } from "./reducers/loginSlice";
import { useHistory, useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import { store } from "./store/store";
import * as yup from 'yup';
import { getInstituteLoginData } from "./reducers/institutionMasterSlice";


const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
  />
);

function Login() {
  const [disable, setDisable] = useState(false)
  const { isLoading } = useSelector((state) => state.Loginmaster)
  const [loadings, setLoadings] = useState(false);
  const [tabStatus, setTabStatus] = useState("1")
  const { TabPane } = Tabs;
  const [apiCallInProgress, setApiCallInProgress] = useState(false);


  const onTabChange = (data) => {
    setTabStatus(data)
  }
  const navigate = useNavigate()
  const Dispatch = useDispatch();
  const validationSchema = yup.object({
    email: yup.string().email('Invalid Email Id').required("Email is Required"),
    password: yup.string().required("Password is Required"),
  })
  const InstitutionvalidationSchema = yup.object({
    email: yup.string().email('Invalid Email Id').required("Email is Required"),
    password: yup.string().required("Password is Required"),
  })

  const initialValues = { email: "", password: "" };
  const instInitialValues = { email: "", password: "" };


  const onSubmit = (values) => {
    if (apiCallInProgress) {
      return; // Exit early if an API call is already in progress
    }
    setApiCallInProgress(true)
    setDisable(true)
    setVisible(false);
    Dispatch(getloginData(values)).unwrap().then((data) => {
      if (data['token']) {
        navigate("/Dashboard")
        message.success("Successfully Logged In");
        setDisable(false)
      } else {
        setDisable(false)
        message.error(data?.response?.data?.message)
      }
    }).finally(() => {
      setApiCallInProgress(false)
    }).catch((error) => {
    })
  };
  const onInstituteSubmit = (values) => {
    if (apiCallInProgress) {
      return; // Exit early if an API call is already in progress
    }
    setApiCallInProgress(true)
    setDisable(true)
    setVisible(false);
    Dispatch(getInstituteLoginData(values)).unwrap().then((data) => {
      if (data['token']) {
        navigate("/course")
        message.success("Successfully Logged In");
        setDisable(false)
      } else {
        setDisable(false)
        message.error(data?.response?.data?.message)
      }
    }).finally(() => {
      setApiCallInProgress(false)
    }).catch((error) => {
    })
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema,
  });

  const instituteFormik = useFormik({
    initialValues: instInitialValues,
    onInstituteSubmit,
    validationSchema: InstitutionvalidationSchema,
  });

  const [visible, setVisible] = useState(false);
  const [disabledButton, setDisabled] = useState(false);


  return (

    <div className="login-wrapper">
      <Row>
        <Col span={12} className="logo-image">
          <div className="logoimage">
            <div className="image-container">
              <Image preview={false} src={eduTechImage}></Image>
              <Image preview={false} src={groupone} />
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="login-content-container">
            <div className="content-wrapper">
              <div className="centumlogo">
                <Image preview={false} src={group} />
              </div>

              <div className="welcometext">Hello Again</div>
              <div className="logintext">Enter to digital learning</div>
              <Tabs activeKey={tabStatus} onChange={onTabChange}>
                <TabPane tab="Admin" key="1">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="login-form">
                      <div className="username">
                        <label className="input-label">Email</label>
                        <div className="inputusername">
                          <Input
                            placeholder="Enter a Email"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          ></Input>
                          {formik.touched.email && formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="password">
                        <div className="input-label " style={{ paddingBottom: 4 }}>Password</div>
                        <Space direction="vertical">
                          <Input.Password
                            placeholder="Enter a Password"
                            iconRender={(visible) =>
                              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                            id="password"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className="error">{formik.errors.password}</div>
                          ) : null}
                        </Space>
                      </div>

                      <div className="forgot-button-wrapper">
                        <p className='forgot-text' ><a onClick={() => navigate("/forgotPassword")} className='forgot-link' >Forgot Password</a></p>
                      </div>
                      <div className="buttonlogin">
                        {/* <Button loading={disable}
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={() => onSubmit(formik.values)}
                    block
                    className="primary-submit-button remove-margin"
                  >
                    Login
                  </Button> */}
                        <Button

                          type="primary"
                          // block
                          className={!formik.isValid ? 'disabled-button remove-margin' : 'primary-submit-button remove-margin'} disabled={!formik.isValid}
                          onClick={() => onSubmit(formik.values)}
                        >
                          Login
                        </Button>



                      </div>
                    </div>
                  </form>
                </TabPane>
                <TabPane tab="Institution" key="2">
                  <form onSubmit={instituteFormik.handleSubmit}>
                    <div className="login-form">
                      <div className="username">
                        <label className="input-label">Email</label>
                        <div className="inputusername">
                          <Input
                            placeholder="Enter a Email"
                            id="email"
                            name="email"
                            onChange={instituteFormik.handleChange}
                            onBlur={instituteFormik.handleBlur}
                            value={instituteFormik.values.email}
                          ></Input>
                          {instituteFormik.touched.email && instituteFormik.errors.email ? (
                            <div className="error">{instituteFormik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="password">
                        <div className="input-label " style={{ paddingBottom: 4 }}>Password</div>
                        <Space direction="vertical">
                          <Input.Password
                            placeholder="Enter a Password"
                            iconRender={(visible) =>
                              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                            id="password"
                            name="password"
                            onChange={instituteFormik.handleChange}
                            onBlur={instituteFormik.handleBlur}
                            value={instituteFormik.values.password}
                          />
                          {instituteFormik.touched.password && instituteFormik.errors.password ? (
                            <div className="error">{instituteFormik.errors.password}</div>
                          ) : null}
                        </Space>
                      </div>

                      <div className="forgot-button-wrapper" >
                        <p className='forgot-text' ><a onClick={() => navigate("/institutionforgotPassword")} className='forgot-link' >Forgot Password</a></p>
                      </div>
                      <div className="buttonlogin">
                        {/* <Button loading={disable}
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={() => onSubmit(formik.values)}
                    block
                    className="primary-submit-button remove-margin"
                  >
                    Login
                  </Button> */}
                        <Button

                          type="primary"
                          // block
                          className={!instituteFormik.isValid ? 'disabled-button remove-margin' : 'primary-submit-button remove-margin'} disabled={!instituteFormik.isValid}
                          onClick={() => onInstituteSubmit(instituteFormik.values)}
                        >
                          Login
                        </Button>



                      </div>
                    </div>
                  </form>
                </TabPane>

              </Tabs>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

}

export default Login;
