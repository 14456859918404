import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const insertCourseMaster = createAsyncThunk(
  'CourseMaster/insertCourseMaster', async (payload, thunk) => {
    let resSuccess = false;
    let resMessage = '';
    let resId = '';
    await axios.post('coachmi/addCourse', payload.data)
      .then(async (res) => {
        const { success, message, data } = res?.data;
        if (success) {
          resMessage = message;
          resSuccess = success;
          resId = data?.id;
        };
      })
      .catch((error) => {
        resMessage = error?.response?.data?.message
      });
    return { resSuccess, resMessage, resId };
  }
);

export const updateCourseMaster = createAsyncThunk(
  'CourseMaster/updateCourseMaster', async (payload, thunk) => {
    let resSuccess = false;
    let resMessage = '';
    await axios.put('coachmi/updateCourse/' + payload.id, payload.data)
      .then(async (res) => {
        const { success, message } = res?.data;
        if (success) {
          resMessage = message;
          resSuccess = success;
        };
      })
      .catch((error) => {
        resMessage = error?.response?.data?.message
      })
    return { resSuccess, resMessage };
  }
);

export const getCourseMaster = createAsyncThunk(
  'CourseMaster/getCourseMaster',
  async (payload) => {
    try {
      let tableData = [];
      let tableTotalCount;
      let resSuccess = false;
      let resMessage = '';
      await axios.post('coachmi/getAllCourseDetails', payload)
        .then(async (res) => {
          const { success, message, data } = res.data;
          const { listData, dataCount } = data;
          resSuccess = success
          resMessage = message;
          tableData = listData;
          tableTotalCount = dataCount;
        })
        .catch((error) => {
          resMessage = error?.response?.data?.message;
        })
      return { tableData, tableTotalCount, resSuccess, resMessage };
    } catch (error) {
      throw error;
    };
  }
);

export const getCourseDetailsById = createAsyncThunk(
  "CourseMaster/getCourseDetailsById", async (payload) => {
    try {
      let clusterDetails = await axios.get(`/coachmi/getCourseDetailsById/${payload}`)
        .then((res) => {
          if (res?.data?.status) {
            return res?.data
          }
        })
        .catch((error) => { })
      return clusterDetails;
    } catch (error) {
    }
  }
);

export const deleteCourse = createAsyncThunk(
  'CourseMaster/deleteCourse', async (payload, thunk) => {
    let resSuccess = false;
    let resMessage = '';
    await axios.put('coachmi/deleteCourse/' + payload?.id)
      .then(async (res) => {
        const { success, message } = res?.data;
        resSuccess = success;
        resMessage = message
        if (success) {
          await thunk.dispatch(getCourseMaster(payload.obj));
          await thunk.dispatch(getCourseClusterFilter());
          await thunk.dispatch(getCourseCreatedDateFilter());
        };
      })
      .catch((error) => {
        resMessage = error?.response?.data?.message
      });
    return { resSuccess, resMessage };
  }
);

export const coursePublishStatusChange = createAsyncThunk(
  'CourseMaster/coursePublishStatusChange', async (payload, thunk) => {
    let resSuccess = false;
    let resMessage = '';
    await axios.put('coachmi/coursePublishStatusChange/' + payload?.id, payload?.datas)
      .then(async (res) => {
        const { success, message } = res?.data;
        resSuccess = success;
        resMessage = message
        if (success) {
          await thunk.dispatch(getCourseMaster(payload?.obj));
          await thunk.dispatch(getCourseClusterFilter());
          await thunk.dispatch(getCourseCreatedDateFilter());
        };
      })
      .catch((error) => {
        resMessage = error?.response?.data?.message
      });
    return { resSuccess, resMessage };
  }
);

export const getClusterType = createAsyncThunk(
  'CourseMaster/getClusterType', async (payload, thunk) => {
    try {
      let response = [];
      await axios.get(`masters/getAllClusterGroupByClusterId/${payload}`)
        .then(async (res) => {
          response = res?.data?.data;
        })
        .catch((error) => {
        })
      return response;
    } catch (error) {

    };
  }
);

export const getVideoType = createAsyncThunk(
  'CourseMaster/getVideoType', async (payload, thunk) => {
    try {
      let response = [];
      await axios.get('masters/getVideoType')
        .then(async (res) => {
          response = res?.data?.data;
        })
        .catch((error) => {
        })
      return response;
    } catch (error) {

    };
  }
);

export const getLanguageType = createAsyncThunk(
  'CourseMaster/getLanguageType', async (payload, thunk) => {
    try {
      let response = [];
      await axios.get('masters/getLanguageType')
        .then(async (res) => {
          response = res?.data?.data;
        })
        .catch((error) => {
        })
      return response;
    } catch (error) {

    };
  }
);

export const getCourseClusterFilter = createAsyncThunk(
  'CourseMaster/getCourseClusterFilter', async (payload, thunk) => {
    try {
      let response = [];
      await axios.get('coachmi/getUniqueCourseClusterName')
        .then(async (res) => {
          const { status, data } = res.data;
          response = data;
        })
        .catch((error) => {
        })
      return response;
    } catch (error) {
    }
  }
);

export const getCourseCreatedDateFilter = createAsyncThunk(
  'CourseMaster/getCourseCreatedDateFilter', async (payload, thunk) => {
    try {
      let response = [];
      const publicData = await axios.get('coachmi/getUniqueCourseCreatedDate')
        .then(async (res) => {
          const { status, data } = res.data;
          if (status) {
          };
          response = data;
        })
        .catch((error) => {
        })
      return response;
    } catch (error) {
    }
  }
);

const initialState = {
  getCourseData: [],
  getCourseDataCount: [],
  isLoading: false,
  getClusterNameData: [],
  courseOverviewData: {},
  getVideoTypeData: [],
  getClusterTypeData: [],
  getLanguageTypeData: [],
  clusterNameFilter: [],
  courseCreatedAt: [],
  examDataCount: 0,
  courseOverViewSaveStatus: true,
  curriculumSaveStatus: true,
  pricingSaveStatus: true,
};

const CourseMasterSlice = createSlice({
  name: 'CourseMaster',
  initialState,
  reducers: {
    removeCourseOverviewDetails: (state, action) => {
      state.courseOverviewData = [];
    },
    setSaveStatus(state, action) {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
  extraReducers: {
    [insertCourseMaster.pending]: (state, action) => {
      state.isLoading = true
    },
    [insertCourseMaster.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [insertCourseMaster.rejected]: (state, action) => {
      state.isLoading = false
    },
    [updateCourseMaster.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCourseMaster.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [updateCourseMaster.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [deleteCourse.pending]: (state, action) => {
      state.isLoading = true
    },
    [deleteCourse.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteCourse.rejected]: (state, action) => {
      state.isLoading = false
    },
    [coursePublishStatusChange.pending]: (state, action) => {
      state.isLoading = true
    },
    [coursePublishStatusChange.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [coursePublishStatusChange.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getCourseMaster.pending]: (state) => {
      state.isLoading = true
    },
    [getCourseMaster.fulfilled]: (state, actions) => {
      state.isLoading = false
      const { tableData, tableTotalCount } = actions.payload;
      state.getCourseData = tableData;
      state.getCourseDataCount = tableTotalCount;
    },
    [getCourseMaster.rejected]: (state) => {
      state.isLoading = false
    },
    [getCourseDetailsById.pending]: (state, action) => {
      state.isLoading = true
    },
    [getCourseDetailsById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.courseOverviewData = action.payload?.data;
    },
    [getCourseDetailsById.rejected]: (state, action) => {
      state.isLoading = false
    },
    [getClusterType.pending]: (state) => {
      state.isLoading = true
    },
    [getClusterType.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.getClusterTypeData = actions.payload;
    },
    [getClusterType.rejected]: (state) => {
      state.isLoading = false
    },
    [getVideoType.pending]: (state) => {
      state.isLoading = true
    },
    [getVideoType.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.getVideoTypeData = actions.payload;
    },
    [getVideoType.rejected]: (state) => {
      state.isLoading = false
    },
    [getLanguageType.pending]: (state) => {
      state.isLoading = true
    },
    [getLanguageType.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.getLanguageTypeData = actions.payload;
    },
    [getLanguageType.rejected]: (state) => {
      state.isLoading = false
    },
    [getCourseClusterFilter.pending]: (state) => {
      state.isLoading = true;
    },
    [getCourseClusterFilter.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.clusterNameFilter = actions?.payload
    },
    [getCourseClusterFilter.rejected]: (state) => {
      state.isLoading = false;
    },
    [getCourseCreatedDateFilter.pending]: (state) => {
      state.isLoading = true;
    },
    [getCourseCreatedDateFilter.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.courseCreatedAt = actions?.payload
    },
    [getCourseCreatedDateFilter.rejected]: (state) => {
      state.isLoading = false;
    },
  }
});

export const { removeCourseOverviewDetails } = CourseMasterSlice.actions;
export const { setSaveStatus } = CourseMasterSlice.actions;


export default CourseMasterSlice.reducer;