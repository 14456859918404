import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Input, Drawer, Select, Modal, Row, Radio } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import emptyImage from '../asset/image/createBundle.svg';
import DeleteImage from '../asset/image/deleteIcon.svg'
import '../components/styles/InstitutionMaster.scss';
import TableComponent from './Table/tableComponent';
import { useDebouncedCallback } from 'use-debounce';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { createInstituteData, getinstitutionMasterTable, getInstitutionById, updateInstitution, deleteInstitutionData, updateInstitutionMail } from './reducers/institutionMasterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { data } from 'jquery';


const InstitutionMaster = () => {
  const navigate = useNavigate()
  const { Option } = Select;
  const { instituteTableData, isLoading, institutionDataCount } = useSelector((state) => (state.InstitutionSlice))

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [record, setRecord] = useState({})
  const [isLimit, setIsLimit] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  const [isVerified, setIsVerified] = useState(true);

  const dispatch = useDispatch()

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getinstitutionMasterTable(obj));
    // dispatch(addBreadCrumbData({}))
  }, [])


  let tableHeight = 320


  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Institution Name',
      dataIndex: 'name',
      key: 'name',
      onCell: (record) => {
        return {
          onClick: () => {
            navigate('/IndividualInstitute/' + record.id + '/getIndividualInstituteDetails')
          }
        }
      },
      render: (text) => text && text.replace(/\b\w/g, (c) => c.toUpperCase()),
    },
    {
      title: 'Admin',
      dataIndex: 'contactName',
      key: 'contactName',

      onCell: (record) => {
        return {
          onClick: () => {
            navigate('/IndividualInstitute/' + record.id + '/getIndividualInstituteDetails')
          }
        }
      }
    },
    {
      title: 'No of Students',
      dataIndex: 'headCount',
      key: 'headCount'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <div className='table-action-link'>
            <a onClick={() => editData(record)}>Edit</a>
            <Tooltip title="Delete" style={{ padding: 0 }}>
              <IconButton>
                <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
              </IconButton>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  // onSelect change

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }

  // row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const search = (value) => {

    setFilterData(value)
    let data = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    }
    debounced(data)

  }
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getinstitutionMasterTable(obj))
  }, 400)




  const initialValues = {
    name: '',
    email: '',
    address: '',
    phoneNumber: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    // courseNeeded:'',
    headCount: '',
    institutionCode: ''
  }

  const validationSchema = yup.object({
    name: yup.string()
      .required("Institute Name is Required")
      .min(2, "Institute Name Needed At Least Two characters")
      .max(100, "Institute Name not more than 100 characters"),

    // .matches(/^(?! )[a-zA-Z0-9@._ ]*(?<! )$/, {message: `Institute name is invalid, allowed only A-Z, a-z, 0-9 and ('@','_','.')`}),
    institutionCode: yup.string().required("Institute code is Required").trim().min(2, "Institute Name Needed At Least Two characters")
      .max(100, "Institute Name not more than 100 characters"),// Remove leading/trailing spaces

    email: yup.string().email("Email must be a valid one").required('Email is required'),
    address: yup.string().required("Address is Required").matches(/^[-/\\(),\w\s]*$/, 'Invalid characters. Only /, \\, (, ), -, and comma are allowed.'),
    phoneNumber: yup.string()
      .required("Phone Number is Required")
      .matches('^[0-9 ]+$', "Invalid Phone Number")
      .trim()
      .min(10, 'Phone Number Must Be Exactly 10')
      .max(10, 'Phone Number Must Be Exactly 10')
      .nullable(),


    // courseNeeded : yup.array().min(1, "Min one course is Required").required("Course is Required"),
    contactName: yup.string().required("Contact Person name is Required").trim()
      .min(2, "Contact Person name Needed At Least Two characters")
      .max(100, "Contact Person name not more than 100 characters"),

    contactNumber: yup.string()
      .required("Mobile Number is Required")
      .matches('^[0-9 ]+$', "Invalid Mobile Number")
      .trim()
      .min(10, 'Mobile Number Must Be Exactly 10')
      .max(10, 'Mobile Number Must Be Exactly 10')
      .nullable(),
    contactEmail: yup.string().email("Email must be a valid").required('Email is required').nullable(),
    headCount: yup
      .number()
      .required('Count is required')
      .max(99999, 'Number of Questions is 0 - 99999')
      .positive('Number of Questions must be a positive integer')
      .integer('Number of Questions must be a positive integer')
      .nullable(),
  })

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };


  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const instituteFormik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log('valuesss', values)
      setVisible(false)

      let datas = {
        data: {
          ...values
        },
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit

        }
      }
      setSubmitModalVisible(false);
      dispatch(createInstituteData(datas))
    },
    validationSchema,
  })
  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };

  // edit data

  const editData = (_data) => {
    console.log('item', _data);
    setCurrentId(_data.id)
    setUpdateStatus(true)
    // instituteFormik.setValues(_data)
    instituteFormik.resetForm()

    dispatch(getInstitutionById(_data.id)).unwrap().then((res) => {
      console.log('ressssss9798432', res);
      setIsVerified(res.isVerified)

      if (res) {
        let tempValues = res
        instituteFormik.setValues(tempValues)
        setVisible(true)
      }
    })
    setCurrentTitle("Edit Institute")

  }

  // delete data
  const deleteData = (_data) => {
    setRecord(_data)
    setCurrentId(_data.id)
    setIsModalVisible(true)
  }

  // onClose
  const onClose = () => {
    setVisible(false);
    instituteFormik.setValues(initialValues)
    instituteFormik.resetForm()
  }

  const updateMail = (value) => {
    let datas = {
      id: currentId,
      data: {
        name: value.name,
        email: value.email
      },
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit

      }
    }
    let resendMail = "resendMail"
    dispatch(updateInstitutionMail({ datas, resendMail: resendMail })).unwrap().then((res) => {
    })
    setVisible(false)
  }

  // update data


  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = (values) => {
    setVisible(false)
    instituteFormik.values['phoneNumber'] = +instituteFormik.values['phoneNumber']
    instituteFormik.values['contactNumber'] = +instituteFormik.values['contactNumber']
    let datas = {
      id: currentId,
      data: {
        ...values
      },
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit

      }
    }
    setUpdateModalVisible(false);
    let updateInstite = 'updateInstite'
    dispatch(updateInstitution({ datas, updateInstite: updateInstite })).unwrap().then((res) => {
    })
    instituteFormik.setValues(initialValues)
    instituteFormik.resetForm()
  }

  // open drawer
  const openDrawer = () => {
    setIsVerified(null)
    setCurrentId(null)
    setVisible(true)
    setUpdateStatus(false)
    setCurrentTitle("Institution creation")
    instituteFormik.resetForm()
  }

  // onChange select

  const onChangeSelect = (e, fieldHelpers) => {
    fieldHelpers.setValue(e)
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit

    }

    dispatch(getinstitutionMasterTable(obj))
  }

  // on modal close
  const onModalClose = () => {
    setIsModalVisible(false);
  }

  // confirm delete
  const confirmDelete = () => {
    setIsModalVisible(false)
    let data = {
      id: currentId,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    }
    dispatch(deleteInstitutionData(data)).unwrap().then((res) => {

    })
  }

  return (
    <div className="institution-master-page">
      <div className="institution-header">
        <p className="header-name">Institution Master</p>
        <div className="create-institution"><Button className='create-button' onClick={() => openDrawer()}>Create Institution</Button></div>
      </div>
      <div className="institution-content">
        <div className="golbalSearch" >
          <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(e) => search(e.target.value)} value={filterData} />
        </div>
        <div className="table-component">
          <TableComponent columns={columns} dataSource={instituteTableData} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={institutionDataCount} currentPage={paginationData + 1} loadingStatus={isLoading} limit={limit} />
        </div>


        {/* drawer */}

        <Drawer className='institution-drawer' closable={false} title={currentTitle} placement="right" onClose={() => onClose()} open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose()}>Cancel</Button>
              {
                isVerified == false ?
                  <Button className='primary-submit-button' type='primary' onClick={() => updateMail(instituteFormik.values)} >Resend Mail</Button>
                  :
                  ''
              }
              {
                currentId == null ?
                  <Button className='primary-submit-button' disabled={!instituteFormik.dirty || !instituteFormik.isValid} type='primary' onClick={() => instituteFormik.handleSubmit()}>Create</Button>
                  :
                  <Button className='primary-submit-button' disabled={!instituteFormik.isValid} type='primary' onClick={() => updateData(instituteFormik.values)} >Update</Button>
              }
            </div>
          </div>

        )}>
          <form >
            <div className='institute-drawer-form'>
              <div>

                <p className='body-header'> <span>Institution Details</span> </p>

                <div className='institutionDetails-content' >
                  <div>
                    <div className='form-control'>
                      <label>Institution Name<span style={{ color: 'red' }}>*</span></label>
                      <Input type='text' maxLength={50} id='name' name='name' placeholder="Enter Institution Name" onChange={instituteFormik.handleChange} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.name}></Input>
                      {instituteFormik.touched.name && instituteFormik.errors.name ? <div className='error'>{instituteFormik.errors.name}</div> : null}
                    </div>
                    <div className='form-control'>
                      <label>Address<span style={{ color: 'red' }}>*</span></label>
                      <Input type='text' style={{ height: '58px' }} maxLength={100} id='address' name='address' placeholder="Enter Address" onChange={instituteFormik.handleChange} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.address}></Input>
                      {instituteFormik.touched.address && instituteFormik.errors.address ? <div className='error'>{instituteFormik.errors.address}</div> : null}
                    </div>
                    <div className='form-control'>
                      <label>Institution Code<span style={{ color: 'red' }}>*</span></label>
                      <Input disabled={updateStatus} type='text' maxLength={50} id='institutionCode' name='institutionCode' placeholder="Enter Institution Code" onChange={instituteFormik.handleChange} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.institutionCode}></Input>
                      {instituteFormik.touched.institutionCode && instituteFormik.errors.institutionCode ? <div className='error'>{instituteFormik.errors.institutionCode}</div> : null}
                    </div>
                    {/* <div className='form-control' style={{width: '398px'}}>
                  <label>Course needed <span style={{ color: 'red' }}>*</span></label>

                  <Select mode="multiple" id="courseNeeded" name="courseNeeded" placeholder="Select Exams" onChange={(e) => { onChangeSelect(e, instituteFormik.getFieldHelpers('courseNeeded')) }} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.courseNeeded} >
                    {options.map((data, index) => (
                    <Option key={index} value={data.id}>{data.name}</Option>
                  ))}
                  </Select>
                  {instituteFormik.touched['courseNeeded'] && instituteFormik.errors.courseNeeded ? <div className='errorMessage' style={{ color: 'red' }}>{instituteFormik.errors.courseNeeded}</div> : null}


                </div>  */}
                  </div>
                  <div>
                    <div className='form-control'>
                      <label>Email ID<span style={{ color: 'red' }}>*</span></label>
                      <Input disabled={updateStatus} type='email' maxLength={50} id='email' name='email' placeholder='Enter mail' onChange={instituteFormik.handleChange} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.email}></Input>
                      {instituteFormik.touched.email && instituteFormik.errors.email ? <div className='error'>{instituteFormik.errors.email}</div> : null}
                    </div>
                    <div className='form-control'>
                      <label>Phone number <span style={{ color: 'red' }}>*</span></label>
                      <Input type='text' maxLength={50} id='phoneNumber' name='phoneNumber' placeholder='Enter phone number' onChange={instituteFormik.handleChange} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.phoneNumber}></Input>
                      {instituteFormik.touched.phoneNumber && instituteFormik.errors.phoneNumber ? <div className='error'>{instituteFormik.errors.phoneNumber}</div> : null}
                    </div>
                  </div>
                </div>
                <div className='divider-line'></div>
              </div>
              <div style={{ paddingTop: '20px' }}>
                <p className='body-header'> <span>Contact person Details</span> </p>

                <div className='institutionDetails-content' >
                  <div>
                    <div className='form-control'>
                      <label>Contact person name<span style={{ color: 'red' }}>*</span></label>
                      <Input type='text' maxLength={50} id='contactName' name='contactName' placeholder="Enter name" onChange={instituteFormik.handleChange} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.contactName}></Input>
                      {instituteFormik.touched.contactName && instituteFormik.errors.contactName ? <div className='error'>{instituteFormik.errors.contactName}</div> : null}
                    </div>
                    <div className='form-control'>
                      <label>Email<span style={{ color: 'red' }}>*</span></label>
                      <Input type='email' maxLength={50} id='contactEmail' name='contactEmail' placeholder='Enter mail' onChange={instituteFormik.handleChange} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.contactEmail}></Input>
                      {instituteFormik.touched.contactEmail && instituteFormik.errors.contactEmail ? <div className='error'>{instituteFormik.errors.contactEmail}</div> : null}
                    </div>
                  </div>
                  <div>
                    <div className='form-control'>
                      <label>Mobile number <span style={{ color: 'red' }}>*</span></label>
                      <Input type='text' maxLength={50} id='contactNumber' name='contactNumber' placeholder='Enter mobile number ' onChange={instituteFormik.handleChange} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.contactNumber}></Input>
                      {instituteFormik.touched.contactNumber && instituteFormik.errors.contactNumber ? <div className='error'>{instituteFormik.errors.contactNumber}</div> : null}
                    </div>
                  </div>
                </div>
                <div className='divider-line'></div>
              </div>

              <div style={{ paddingTop: '20px' }}>
                <p className='body-header'> <span>Student Details</span></p>
                <div style={{ paddingTop: '16px' }}>
                  <p>Head Count<span style={{ color: 'red' }}>*</span></p>
                  <Input type='number' maxLength={50} id='headCount' name='headCount' placeholder="Enter head count" onChange={instituteFormik.handleChange} onBlur={instituteFormik.handleBlur} value={instituteFormik.values.headCount}></Input>
                  {instituteFormik.touched.headCount && instituteFormik.errors.headCount ? <div className='error'>{instituteFormik.errors.headCount}</div> : null}

                </div>
                <div className='divider-line'></div>
              </div>
            </div>
          </form>
        </Drawer>
        {/* delete modal */}

        <Modal title='Deletion' open={isModalVisible} onOk={() => confirmDelete()} onCancel={() => onModalClose()}>
          <p>Are you sure want to delete ?</p>
        </Modal>

        {/* update model */}
        <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
          <div>
            <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
            <Button className='primary-submit-button' type='default' onClick={() => updateData(instituteFormik.values)} >Update</Button>
          </div>
        }>
          <p>Are you sure you want to update?</p>
        </Modal>


        {/* submit modal */}

        <Modal
          title="Create Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
            <div>
              <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
              <Button className="primary-submit-button" type="default" onClick={instituteFormik.handleSubmit}> Create </Button>
            </div>
          }
        >
          <p>Are you sure you want to Create?</p>
        </Modal>

      </div>
    </div>
  )
}

export default InstitutionMaster;