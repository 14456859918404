
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from "moment";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { data } from "jquery";

export const getYearAllExam = createAsyncThunk('PreviousYearExam/Exam', async (payload) => {
  try {
    let getAll = await axios.post('/YearExam/createPreviousYrExam', payload).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getAll

  } catch (error) {
  }
})


/* insert previous year exam */
export const insertPreviousYearExam = createAsyncThunk(
  'PreviousYearExam/insertPreviousYearExam', async (payload, thunk) => {
    let tabledata = []
    let Insertmessage = '';
    let success = ''
    let addPreviousYearExam = await axios.post('previousYearExam/addPreviousYearExam', payload.values)
      .then(async (res) => {
        await thunk.dispatch(getPreviousYearExam(payload.obj))
        message.success(res.data.data.message)
      })
      .catch((error) => {
        let insertError = error.response.data.createExam
        message.error(insertError.message);
      })
    return {
      tabledata: tabledata,
      Insertmessage: Insertmessage,
      success: success
    };
  }
)

//get previousYearExam

export const getPreviousYearExam = createAsyncThunk(
  'PreviousYearExam/getPreviousYearExam', async (payload) => {
    let tabledata = []
    let tableData = await axios.post(`previousYearExam/getSingleTestByExam/${payload.examId}`, payload.obj)
      .then((res) => {
        tabledata = res.data.data;
      })
      .catch((error) => {
      })
    return tabledata;
  }
)

//update previousYearExam

export const updatePreviousYearExam = createAsyncThunk(
  'PreviousYearExam/updatePreviousYearExam', async (payload, thunk) => {
    let tabledata = []
    let updateData = await axios.put('previousYearExam/updatePreviousYearExam/' + payload.id, payload.data)
      .then(async (res) => {
        message.success(res.data.updatePreviousYearExam.message)
        if (res.data.updatePreviousYearExam.status) {
          await thunk.dispatch(getPreviousYearExam(payload.obj))
        }
      })
      .catch((error) => {
        let insertError = error.response.data.updateExam
        message.error(insertError.message);
      })
    return tabledata;
  }
)

//delete previousYearExam

export const deletePreviousYearExam = createAsyncThunk(
  'PreviousYearExam/deletePreviousYearExam', async (payload, thunk) => {
    let deleted = []
    let deleteMessage = ''
    let deleteData = await axios.delete('previousYearExam/deletePreviousYearExam/' + payload.id)
      .then(async (res) => {
        if (res.data.data.status) {
          await thunk.dispatch(getPreviousYearExam(payload.obj))
        }
        deleteMessage = res.data.message;
        deleted = res.data.data
      })
      .catch((error) => {
        //  return error.response.data
      })
    return {
      deleteMessage: deleteMessage,
      payload: payload,
      status: deleted.status
    };
  }
)
export const publishPreviousYearExam = createAsyncThunk("previousYearExam/publishPreviousYearExam", async (payload, thunk) => {
  try {
    let publishUser = await axios.put(`/previousYearExam/publishPreviousYearExam/${payload.id}`).then(async (res) => {
      if (res.data.status) {
        message.success(res.data.message)
        await thunk.dispatch(getPreviousYearExam(payload.examData))
        return res.data.data
      }
    })
    return publishUser
  }
  catch (error) {
    message.error(error?.response?.data?.message);
  }
})

export const unPublishPreviousYearExam = createAsyncThunk("previousYearExam/unPublishPreviousYearExam", async (payload, thunk) => {
  try {
    let publishUser = await axios.put(`/previousYearExam/unPublishPreviousYearExam/${payload.id}`).then(async (res) => {
      if (res.data.status) {
        message.success(res.data.message)
        await thunk.dispatch(getPreviousYearExam(payload.examData))
        return res.data.data
      }
    })
    return publishUser
  }
  catch (error) {
    message.error(error?.response?.data?.message);
  }
})

export const activePreviousYearExam = createAsyncThunk("previousYearExam/activePreviousYearExam", async (payload, thunk) => {
  try {
    let publishUser = await axios.put(`/previousYearExam/activePreviousYearExam/${payload.id}`, { activeStatus: payload.activeStatus }).then(async (res) => {
      if (res.data.status) {
        message.success(res.data.message)
        await thunk.dispatch(getPreviousYearExam(payload.examData))
        return res.data.data
      }
    })
    return publishUser
  }
  catch (error) {
    message.error(error?.response?.data?.message);
  }
})

export const getAllExam = createAsyncThunk("Masters/Exam", async (payload) => {
  try {
    let getAll = await axios.post('/masters/getExam', payload).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getAll

  } catch (error) {
  }
})

export const downloadLeaderBoard = createAsyncThunk("previousYearExam/downloadStudentLeaderBoard", async(payload)=>{
  try{
  let downloadXl = await axios.get(`/previousYearExam/downloadStudentLeaderBoard/${payload.id}`).then(async (res) => {
    if(res.data.success){
      message.success(res.data.message)
      return res.data.data
    }
    else{
      message.error(res.data.message)
    }
  })
  return downloadXl
  }
  catch(error){
    message.error(error?.response?.data?.message);
  }
})

export const getAllSingleTestPaperCategory = createAsyncThunk(
  'PreviousYearExam/getAllSingleTestPaperCategory', async (payload) => {
    let tableData = await axios.post(`previousYearExam/getAllSingleTestPaperCategory`, payload)
      .then((res) => {
        return res.data.data
      })
      .catch((error) => {
        let insertError = error.response.data.updateExam
        message.error(insertError.message);
      })
      return tableData
  }
)

export const getActiveSingleTestPaperCategory = createAsyncThunk(
  'PreviousYearExam/getActiveSingleTestPaperCategory', async (payload) => {
    let tableData = await axios.get(`previousYearExam/getActiveSingleTestPaperCategory/${payload}`)
      .then((res) => {
        return res.data.data
      })
      .catch((error) => {
        let insertError = error.response.data.updateExam
        message.error(insertError.message);
      })
      return tableData
  }
)

export const getSingleTestPaperCategoryById = createAsyncThunk(
  'PreviousYearExam/getSingleTestPaperCategoryById', async (id) => {
    let tableData = await axios.get(`previousYearExam/getSingleTestPaperCategory/${id}`,)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        let insertError = error.response.data.updateExam
        message.error(insertError.message);
        })

        return tableData

  }
)

export const addSingleTestPaperCategory = createAsyncThunk(
  'PreviousYearExam/addSingleTestPaperCategory', async (payload,thunk) => {
    console.log(payload,"++++++++++++++++++");
    let tableData = await axios.post(`previousYearExam/addSingleTestPaperCategory`, payload.data)
      .then(async (res) => {
        message.success(res.data.data.message)
        await thunk.dispatch(getAllSingleTestPaperCategory(payload.obj))
      })
      .catch((error) => {
        let insertError = error.response.data.updateExam
        message.error(insertError.message);
      })

      return tableData
  }
)

export const updateSingleTestPaperCategory = createAsyncThunk(
  'PreviousYearExam/updateSingleTestPaperCategory', async (payload, thunk) => {
    let updateData = await axios.put('previousYearExam/updateSingleTestPaperCategory/' + payload.id, payload.data)
      .then(async (res) => {
        message.success(res.data.data.message)
        await thunk.dispatch(getAllSingleTestPaperCategory(payload.obj))
       
      })
      .catch((error) => {
        let insertError = error.response.data.updateExam
        message.error(insertError.message);
      })

      return updateData
  }
)

export const deleteSingleTestPaperCategory = createAsyncThunk(
  'PreviousYearExam/deleteSingleTestPaperCategory', async (payload, thunk) => {
    let deleteData = await axios.delete('previousYearExam/deleteSingleTestPaperCategory/' + payload.id)
      .then(async (res) => {
        message.success(res.data.data.message)
          await thunk.dispatch(getAllSingleTestPaperCategory(payload.obj))
          return deleteData
      })
      .catch((error) => {
        let insertError = error.response.data.updateExam
        message.error(insertError.message);
      })
  }
)

const initialState = {
  previousYearExamTableData: [],
  examData: [],
  examDataCount: '',
  singleTestPaperCategoryData:[],
  singleTestPaperCategoryDataCount:'',
  activeSingleTestPaperCategoryData:[]
}
const PreviousYearExamSlice = createSlice({
  name: 'PreviousYearExam',
  initialState,

  extraReducers: {
    [insertPreviousYearExam.pending]: (state) => {
      state.isLoading = true
    },
    [insertPreviousYearExam.fulfilled]: (state, actions) => {

      let tableData = actions.payload.tabledata.data
      let success = actions.payload.success
      let Insertmessage = actions.payload.Insertmessage
      if (success == true) {
        tableData.map((item) => {
          item.createdAt = moment(item.createdAt).format('DD MMM YYYY');
        })
        message.success(Insertmessage)
      }
      state.isLoading = false
    },
    [insertPreviousYearExam.rejected]: (state) => {
      state.isLoading = false
    },
    [getPreviousYearExam.pending]: (state) => {
      state.isLoading = true
    },
    [getPreviousYearExam.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.previousYearExamTableData = actions.payload.previousYearExam;
      state.previousYearExamTableData.map((item) => {
        item.createdAt = moment(item.createdAt).format('DD MMM YYYY')
      })
      state.examDataCount = actions.payload?.getExamCount?.[0]['examCount'];
    }, [getPreviousYearExam.rejected]: (state) => {
      state.isLoading = false
    },
    [getAllExam.pending]: (state, action) => {
      state.isLoading = true

    },
    [getAllExam.fulfilled]: (state, action) => {
      state.isLoading = false
      state.examData = action.payload.getExamData

    }, [getAllExam.rejected]: (state, action) => {
      state.isLoading = false
    },
    [updatePreviousYearExam.pending]: (state) => {
      state.isLoading = true
    },
    [updatePreviousYearExam.fulfilled]: (state, actions) => {
      let success = actions.payload.success
      if (success == true) {
        let updateData = actions.payload.data[0]
        let Updatemessage = actions.payload.message
        state.previousYearExamTableData.map((item, i) => {
          if (item.id == updateData.id) {
            item.name = updateData.name;
            // item.examImage=updateData.examImage
          }
        })
        message.success(Updatemessage)
      }
      state.isLoading = false
    },
    [updatePreviousYearExam.rejected]: (state) => {
      state.isLoading = false
    },
    [deletePreviousYearExam.pending]: (state) => {
      state.isLoading = true
    },
    [deletePreviousYearExam.fulfilled]: (state, actions) => {
      let deleteData = actions.payload
      let deletemessage = actions.payload.deleteMessage
      if (deleteData.status == true) {
        let examTableData = state.previousYearExamTableData
        state.previousYearExamTableData = examTableData.filter((item) => item.id != deleteData.payload)
        message.success(deletemessage)
      }
      state.isLoading = false
    },
    [deletePreviousYearExam.rejected]: (state) => {
      state.isLoading = false
    },
    [downloadLeaderBoard.pending]:(state) => {
      state.isLoading = true
    },
    [downloadLeaderBoard.fulfilled]:(state,actions) => {
      state.isLoading = false
    },
    [downloadLeaderBoard.rejected]:(state) => {
      state.isLoading = false
    },
    [getAllSingleTestPaperCategory.pending]:(state) => {
      state.isLoading = true
    },
    [getAllSingleTestPaperCategory.fulfilled]:(state,actions) => {
      state.singleTestPaperCategoryData=actions.payload.getSingleTestPaperCategoryData
      state.singleTestPaperCategoryDataCount=actions.payload.getSingleTestPaperCategoryCount.singleTestPaperCategoryCount
      state.isLoading = false
    },
    [getAllSingleTestPaperCategory.rejected]:(state) => {
      state.isLoading = false
    },
    [getSingleTestPaperCategoryById.pending]:(state) => {
      state.isLoading = true
    },
    [getSingleTestPaperCategoryById.fulfilled]:(state,actions) => {
      state.isLoading = false
    },
    [getSingleTestPaperCategoryById.rejected]:(state) => {
      state.isLoading = false
    },
    [addSingleTestPaperCategory.pending]:(state) => {
      state.isLoading = true
    },
    [addSingleTestPaperCategory.fulfilled]:(state,actions) => {
      state.isLoading = false
    },
    [addSingleTestPaperCategory.rejected]:(state) => {
      state.isLoading = false
    },
    [updateSingleTestPaperCategory.pending]:(state) => {
      state.isLoading = true
    },
    [updateSingleTestPaperCategory.fulfilled]:(state,actions) => {
      state.isLoading = false
    },
    [updateSingleTestPaperCategory.rejected]:(state) => {
      state.isLoading = false
    },
    [deleteSingleTestPaperCategory.pending]:(state) => {
      state.isLoading = true
    },
    [deleteSingleTestPaperCategory.fulfilled]:(state,actions) => {
      state.isLoading = false
    },
    [deleteSingleTestPaperCategory.rejected]:(state) => {
      state.isLoading = false
    },
    [getActiveSingleTestPaperCategory.pending]:(state) => {
      state.isLoading = true
    },
    [getActiveSingleTestPaperCategory.fulfilled]:(state,actions) => {
      state.activeSingleTestPaperCategoryData = actions.payload
      state.isLoading = false
    },
    [getActiveSingleTestPaperCategory.rejected]:(state) => {
      state.isLoading = false
    },
  }
})
export default PreviousYearExamSlice.reducer;
