import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";

// get coupon type
export const getCouponType = createAsyncThunk(
  "CouponMaster/getCouponType", async (payload) => {
    try {
      let couponType = axios.post('testMaster/getCouponType', payload)
        .then((res) => {
          if (res.data.success) {
            return res.data
          }
        })
      return couponType;
    }
    catch (error) {
    }
  }
)
// create coupon type
export const createCoupon = createAsyncThunk(
  "CouponMaster/createCoupon", async (payload) => {
    try {
      let createCoupon = axios.post('testMaster/createCouponDetail', payload)
        .then((res) => {
          if (res.data.createCouponDetail.success) {
            return res.data.createCouponDetail
          }
        })
      return createCoupon;
    }
    catch (error) {
    }
  }
)// get coupon type
export const getCouponDetails = createAsyncThunk(
  "CouponMaster/getCouponDetails", async (payload) => {
    try {
      let couponDetails = axios.post('testMaster/getCouponDetail', payload)
        .then((res) => {
          if (res.data.success) {
            return res.data
          }
        })
      return couponDetails;
    }
    catch (error) {
    }
  }
)

// get edit details
export const getEditData = createAsyncThunk(
  "CouponMaster/getEditData", async (payload) => {
    try {
      let Editcoupon = axios.get('testMaster/getCouponDetail/' + payload)
        .then((res) => {
          if (res.data.success) {
            return res.data
          }
        })
      return Editcoupon;
    }
    catch (error) {
    }
  }
)

// get private coupon by Id
export const getCouponMappingId = createAsyncThunk(
  "CouponMaster/getCouponMappingId", async (payload) => {
    try {
      let Editcoupon = axios.get('testMaster/getCouponPrivateCoupon/' + payload)
        .then((res) => {
          if (res.data.success) {
            return res.data
          }
        })
      return Editcoupon;
    }
    catch (error) {
    }
  }
)

// update coupon private
export const updatePrivateCoupon = createAsyncThunk(
  "CouponMaster/updatePrivateCoupon", async (payload,thunk) => {
    try {
      let updateCouponData = axios.put('testMaster/updatePrivateCoupon/' + payload.id, payload.values)
        .then(async (res) => {
          await thunk.dispatch(getCouponStudentDetails(payload.obj))
          if (res.data) {
            return res.data
          }
        })
      return updateCouponData;
    }
    catch (error) {
    }
  }
)
// update coupon details
export const updateCouponDetails = createAsyncThunk(
  "CouponMaster/updateCouponDetails", async (payload) => {
    try {
      let updateCouponData = axios.put('testMaster/updateCouponDetail/' + payload.id, payload)
        .then((res) => {
          if (res.data.updateCouponDetail.success) {
            return res.data.updateCouponDetail
          }
        })
      return updateCouponData;
    }
    catch (error) {
    }
  }
)

// delete private coupon 
export const deletePrivateCoupon = createAsyncThunk(
  "CouponMaster/deletePrivateCoupon", async (payload,thunk) => {
    try {
      console.log(payload,'payloadpayload');
      let deleteCouponData = axios.delete('testMaster/deletePrivateCoupon/' + payload?.currentId)
        .then(async (res) => {
          console.log(res,'^^^^6');
          await thunk.dispatch(getCouponStudentDetails(payload.obj))
          return res.data
        })
      return deleteCouponData;
    }
    catch (error) {
    }
  }
)

// delete coupon details
export const deleteCouponDetails = createAsyncThunk(
  "CouponMaster/deleteCouponDetails", async (payload) => {
    try {
      let deleteCouponData = axios.delete('testMaster/deleteCouponDetail/' + payload)
        .then((res) => {
          if (res.data.success) {
            return res.data
          }
        })
      return deleteCouponData;
    }
    catch (error) {
    }
  }
)

// get coupon type
export const getCouponPrivateActive = createAsyncThunk(
  "CouponMaster/getCouponPrivateActive", async (payload) => {
    try {
      let privateCouponData = axios.post('testMaster/getCouponPrivateActive', payload)
        .then((res) => {
          if (res.data.success) {
            return res.data
          }
        })
      return privateCouponData;
    }
    catch (error) {
    }
  }
)

// get coupon type
export const getCouponStudentDetails = createAsyncThunk(
  "CouponMaster/getCouponStudentDetails", async (payload) => {
    try {
      let CouponStudentData = axios.post('testMaster/getCouponStudentDetails', payload)
        .then((res) => {
          if (res.data.success) {
            return res.data
          }
        })
      return CouponStudentData;
    }
    catch (error) {
    }
  }
)

export const studentDetailsActive = createAsyncThunk(
  "CouponMaster/studentDetailsActive", async (payload) => {
    try {
      let studentData = axios.post('testMaster/studentDetailsActive', payload)
        .then((res) => {
          if (res.data.success) {
            return res.data
          }
        })
      return studentData;
    }
    catch (error) {
    }
  }
)

export const couponStudentMappingDetails = createAsyncThunk(
  "CouponMaster/couponStudentMappingDetails", async (payload) => {
    try {
      let studentData = axios.post('testMaster/couponStudentMappingDetails', payload)
        .then((res) => {
            return res.data
        }).catch((error)=>{
          return error.response
        });
      return studentData;
    }
    catch (error) {
      console.log(error,'errror...................');
    };
  }
);

export const excelBulkUploadCouponStudent = createAsyncThunk(
  "CouponMaster/excelBulkUploadCouponStudent", async (payload) => {
    try {
      let studentData = axios.post('testMaster/excelBulkUploadCouponStudent', payload.excelFile)
        .then((res) => {
          if (res.status) {
            return res
          }
        })
      return studentData;
    }
    catch (error) {
    }
  }
)

const initialState = {
  couponType: '',
  couponDetails: '',
  couponPrivateAll: '',
  couponPrivateStudent: '',
  studentDetailsData: '',
  isCouponLoading: false,
}

const CouponSlice = createSlice({
  name: "CouponMaster",
  initialState,
  extraReducers: {
    [getCouponType.pending]: (state) => {
      state.isCouponLoading = true
    },
    [getCouponType.fulfilled]: (state, action) => {
      state.isCouponLoading = false
      if (action.payload.success) {
        state.couponType = action.payload.data.getCouponTypeData
      }
    },
    [getCouponType.rejected]: (state) => {
      state.isCouponLoading = false
    },
    [getCouponDetails.pending]: (state) => {
      state.isCouponLoading = true
    },
    [getCouponDetails.fulfilled]: (state, action) => {
      state.isCouponLoading = false
      if (action.payload.success) {
        state.couponDetails = action.payload.data.getCouponDetailData
      }
    },
    [getCouponDetails.rejected]: (state) => {
      state.isCouponLoading = false
    },
    [createCoupon.pending]: (state) => {
      state.isCouponLoading = true
    },
    [createCoupon.fulfilled]: (state, action) => {
      state.isCouponLoading = false
    },
    [createCoupon.rejected]: (state) => {
      state.isCouponLoading = false
    },
    [updateCouponDetails.pending]: (state) => {
      state.isCouponLoading = false
    },
    [updateCouponDetails.fulfilled]: (state, action) => {
      state.isCouponLoading = false
    },
    [updateCouponDetails.rejected]: (state) => {
      state.isCouponLoading = false
    },
    [getEditData.pending]: (state) => {
      state.isCouponLoading = true
    },
    [getEditData.fulfilled]: (state, action) => {
      state.isCouponLoading = false
    },
    [getEditData.rejected]: (state) => {
      state.isCouponLoading = false
    },

    [getCouponPrivateActive.pending]: (state) => {
      state.isCouponLoading = true
    },
    [getCouponPrivateActive.fulfilled]: (state, action) => {
      state.isCouponLoading = false;
      state.couponPrivateAll = action.payload.data
    },
    [getCouponPrivateActive.rejected]: (state) => {
      state.isCouponLoading = false
    },

    [getCouponStudentDetails.pending]: (state) => {
      state.isCouponLoading = true
    },
    [getCouponStudentDetails.fulfilled]: (state, action) => {
      state.isCouponLoading = false
      state.couponPrivateStudent = action.payload.data.getPrivateCouponData
    },
    [getCouponStudentDetails.rejected]: (state) => {
      state.isCouponLoading = false
    },

    [studentDetailsActive.pending]: (state) => {
      state.isCouponLoading = true
    },
    [studentDetailsActive.fulfilled]: (state, action) => {
      state.isCouponLoading = false
      state.studentDetailsData = action.payload.data
    },
    [studentDetailsActive.rejected]: (state) => {
      state.isCouponLoading = false
    },

    [couponStudentMappingDetails.pending]: (state) => {
      state.isCouponLoading = true
    },
    [couponStudentMappingDetails.fulfilled]: (state, action) => {
      state.isCouponLoading = false
    },
    [couponStudentMappingDetails.rejected]: (state) => {
      state.isCouponLoading = false
    },

    [excelBulkUploadCouponStudent.pending]: (state) => {
      state.isCouponLoading = true
    },
    [excelBulkUploadCouponStudent.fulfilled]: (state, action) => {
      state.isCouponLoading = false
    },
    [excelBulkUploadCouponStudent.rejected]: (state) => {
      state.isCouponLoading = false
    },





  }
})

export default CouponSlice.reducer;